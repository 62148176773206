@charset "UTF-8";
/* ----------------------------------------------------------------------
>>> TABLE OF CONTENT
-------------------------------------------------------------------------
1 - Basic Elements & Classes
2 - Top Navbar
3 - Sidebar
4 - Nav
5 - Main Container
6 - Footer
7 - Settings
8 - Off sidebar
9 - Utilities
10 - Dropdown Menu
11 - Tabs
12 - Panles
13 - Pagination
14 - Accordion
15 - Animations
16 - Progress Bars
17 - Labels and Badges
18 - Form Elements
19 - Icons
20 - Buttons
21 - Links
22 - Form Wizard
23 - Login
24 - Invoice
25 - Timeline
26 - Tables
27 - User Profile
28 - Calendar
29 - Todo
30 - Chat
31 - Messages
32 - Modals
33 - Maps
34 - Pricing Table
35 - Error
36 - Page Title
37 - Tooltips
38 - Popovers
39 - Date Picker
40 - Pages
41 - Posts
/* ---------------------------------------------------------------------- */
/*



*/
/* Mixin */
.mb0 {
  margin-bottom: 0px!important;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb45 {
  margin-bottom: 45px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.full-color {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
  opacity: 1;
}
.text-inactive-ac {
  color: red;
  display: block;
  margin: 0;
  padding: 0;
  text-align: right;
}
/* ---------------------------------------------------------------------- */
/*  Basic Elements & Classes
/* ---------------------------------------------------------------------- */
html,
body {
  height: 100%;
  width: 100%;
}
html {
  font-size: 100%;
}
body {
  color: #8e8e93;
  direction: ltr;
  overflow-x: hidden;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  background: #f7f7f8;
  -webkit-font-smoothing: antialiased !important;
}
body.bg_style_1 {
  background: rgba(0, 0, 0, 0) !important;
}
body.bg_style_2 {
  background: rgba(0, 0, 0, 0) !important;
}
body.bg_style_3 {
  background: rgba(0, 0, 0, 0) !important;
}
body.bg_style_4 {
  background: rgba(0, 0, 0, 0) !important;
}
body.bg_style_5 {
  background: rgba(0, 0, 0, 0) !important;
}
::-moz-selection {
  background: #5F8295;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #5F8295;
  text-shadow: none;
  color: #ffffff;
}
a,
a:focus,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none !important;
  cursor: pointer;
}
button {
  outline: 0 !important;
}
h1,
h2 {
  font-family: "Raleway", sans-serif;
}
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
  margin-top: 0;
  color: #5b5b60;
}
.text-bold h1,
.text-bold h2,
.text-bold h3,
.text-bold h4,
.text-bold h5,
h1.text-bold,
h2.text-bold,
h3.text-bold,
h4.text-bold,
h5.text-bold,
h1 .text-bold,
h2 .text-bold,
h3 .text-bold,
h4 .text-bold,
h5 .text-bold {
  font-weight: 600;
}
h5.over-title {
  font-family: "Lato", sans-serif;
  font-size: 17px;
}
.mainTitle {
  margin: 20px 0;
}
.mainTitle small {
  color: #8e8e93;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  display: block;
  margin: 10px 0;
}
.mainTitle i {
  font-size: 18px;
}
.ct-callout {
  border-color: #007AFF;
  border-image: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 0 5px;
  margin: 20px 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.4);
}
.main-wrapper {
  position: relative;
}
.ajax-white-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
  display: none;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1049;
}
.ajax-white-backdrop:before {
  background: #000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  top: 60px;
  left: 50%;
  margin-left: -20px;
  content: "";
  position: absolute;
}
.ajax-white-backdrop:after {
  content: "\f110";
  font-family: FontAwesome;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 60px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@media (min-width: 992px) {
  .app-navbar-fixed {
    padding-top: 65px;
  }
}
#app {
  height: auto;
  min-height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}
#app .app-content {
  height: 100%;
}
#app .app-content:before,
#app .app-content:after {
  content: " ";
  display: table;
}
#app .app-content:after {
  clear: both;
}
@media (max-width: 991px) {
  #app {
    width: 100%;
  }
  #app .app-content {
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -ms-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1050;
  }
  #app.app-slide-off > .app-content {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    z-index: 2;
    position: absolute;
    -webkit-transform: translate3d(75px, 0, 0);
    transform: translate3d(75px, 0, 0);
    -webkit-transition: transform 300ms ease 0s;
    -moz-transition: transform 300ms ease 0s;
    -ms-transition: transform 300ms ease 0s;
    -o-transition: transform 300ms ease 0s;
  }
}
.app-content-loading {
  background: #000;
  border-radius: 10px;
  height: 100px;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100px;
  z-index: 1051;
}
.loading-spinner {
  font-size: 50px;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}
.well {
  box-shadow: none;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #48b1cc;
  color: #fff;
}
.nav-pills > li > a {
  border-radius: 10px;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #48b1cc;
  border-color: #48b1cc;
}
.container-fullw {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}
@media (max-width: 768px) {
  .container-fullw {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.unstyled-list li {
  list-style-type: none;
}
.inline-list li {
  display: inline-block;
}
/* ---------------------------------------------------------------------- */
/*  Top Navbar
/* ---------------------------------------------------------------------- */
#topnav.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  border: none;
  margin-bottom: 0;
  height: 65px;
}
#topnav.navbar .loading-bar-wrapper {
  position: absolute;
  bottom: 0;
  height: 3px;
  left: 0;
  right: 0;
  z-index: 999;
}
#topnav.navbar .navbar-header {
  z-index: 10;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  height: 65px;
}
@media (min-width: 992px) {
  #topnav.navbar .navbar-header {
    border-right: 1px solid #eeeeee;
    width: 200px;
  }
}
#topnav.navbar .navbar-header a {
  line-height: 65px;
  height: 65px;
}
#topnav.navbar .navbar-header .navbar-brand {
  padding: 20px;
}
#topnav.navbar .navbar-header .navbar-brand img {
  display: inline-block;
  margin-top: -6px;
}
@media (max-width: 400px) {
  #topnav.navbar .navbar-header .navbar-brand {
    width: 70%;
    padding-left: 0;
    padding-right: 0;
  }
}
#topnav.navbar .navbar-header .menu-toggler {
  padding: 0 15px;
  font-size: 18px;
  color: #c8c7cc;
  position: absolute;
  top: 0;
  right: 0;
}
#topnav.navbar .navbar-header .sidebar-toggler {
  font-size: 18px;
  padding-right: 15px;
  color: #c8c7cc;
}
#topnav.navbar .navbar-header .sidebar-toggler:hover {
  color: #aeacb4;
}
#topnav.navbar .navbar-header .sidebar-mobile-toggler {
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  color: #c8c7cc;
}
#topnav.navbar .navbar-header .sidebar-mobile-toggler:hover {
  color: #aeacb4;
}
#topnav.navbar .navbar-collapse {
  border-bottom: 1px solid #eeeeee;
  border-top: none;
  background: #ffffff;
  padding-left: 15px !important;
  padding-right: 15px;
  position: relative;
}
@media (min-width: 768px) {
  #topnav.navbar .navbar-collapse {
    margin-left: 75px;
    height: 65px !important;
  }
  #topnav.navbar .navbar-collapse .nav {
    height: auto !important;
  }
}
@media (max-width: 767px) {
  #topnav.navbar .navbar-collapse {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #topnav.navbar .navbar-collapse .nav {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
#topnav.navbar .navbar-collapse.in {
  overflow-y: visible;
}
#topnav.navbar .navbar-brand > img {
  max-height: 33px;
}
@media (min-width: 992px) {
  .app-navbar-fixed #topnav.navbar .navbar-collapse,
  .app-navbar-fixed #topnav.navbar .navbar-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 992px) {
  .app-sidebar-closed #topnav.navbar .navbar-header {
    width: 75px;
  }
  .app-sidebar-closed #topnav.navbar .navbar-header .navbar-brand {
    display: none;
  }
  .app-sidebar-closed #topnav.navbar .navbar-header .sidebar-toggler {
    float: none !important;
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .app-sidebar-closed #topnav.navbar .navbar-collapse {
    margin-left: 75px;
  }
}
@media (max-width: 991px) {
  #topnav.navbar {
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .app-sidebar-fixed #topnav.navbar .navbar-header {
    position: fixed;
    top: 0;
  }
}
#topnav.navbar .close-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  cursor: pointer;
  z-index: 1;
  min-height: 40px;
}
#topnav.navbar .close-handle .arrow-left,
#topnav.navbar .close-handle .arrow-right {
  background-color: #eeeeee;
  border-radius: 10px;
  bottom: 15px;
  height: 8px;
  left: 50%;
  position: absolute;
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  width: 24px;
  margin-left: -12px;
  cursor: pointer;
}
#topnav.navbar .close-handle .arrow-left {
  -webkit-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
  -moz-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
  -ms-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
  -o-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
}
#topnav.navbar .close-handle .arrow-right {
  -webkit-transform: translate3d(8px, 0px, 0px) rotate(10deg);
  -moz-transform: translate3d(8px, 0px, 0px) rotate(10deg);
  -ms-transform: translate3d(8px, 0px, 0px) rotate(10deg);
  -o-transform: translate3d(8px, 0px, 0px) rotate(10deg);
}
#topnav.navbar .navbar-right .nav {
  margin: 0;
}
#topnav.navbar .navbar-right .nav .dropdown-light:after {
  right: 36px;
  left: auto;
  top: -9px;
}
#topnav.navbar .navbar-right .nav .dropdown-light:before {
  right: 35px;
  left: auto;
  top: -11px;
}
#topnav.navbar .navbar-right .badge {
  background-color: #C82E29;
  color: #ffffff;
  padding: 4px 6px;
  position: absolute;
  top: 3px;
  font-size: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  right: auto;
  margin-left: 13px;
}
#topnav.navbar .navbar-right .dot-badge {
  border-radius: 100%;
  border-style: solid;
  border-width: 2px;
  height: 10px;
  margin: 2px;
  position: absolute !important;
  border-color: #ffffff;
  top: 6px;
  width: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  right: auto;
  margin-left: 10px;
}
#topnav.navbar .navbar-right .view-all {
  text-align: center;
}
#topnav.navbar .navbar-right .drop-down-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 5px 5px 0 5px;
}
@media (min-width: 992px) {
  #topnav.navbar .navbar-right {
    position: relative;
    margin: 1px 15px 0 0;
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  #topnav.navbar .navbar-right {
    position: relative;
    margin: 1px 35px 0 0;
  }
}
@media (min-width: 768px) {
  #topnav.navbar .navbar-right > li {
    float: left;
  }
}
@media (max-width: 767px) {
  #topnav.navbar .navbar-right > li {
    border-bottom: 1px solid #eeeeee;
  }
  #topnav.navbar .navbar-right > li:last-child {
    margin-bottom: 40px;
  }
}
#topnav.navbar .navbar-right > li > a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #8e8e93;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-transform: uppercase;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  padding: 5px 15px 5px 15px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  #topnav.navbar .navbar-right > li > a {
    margin-right: 3px;
  }
}
#topnav.navbar .navbar-right > li > a:hover {
  background: #efeff0;
}
#topnav.navbar .navbar-right > li > a i {
  display: block;
  margin-top: 7px;
  color: #c8c7cc;
  font-size: 18px;
}
#topnav.navbar .navbar-right > li.current-user > a {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  float: left;
}
#topnav.navbar .navbar-right > li.current-user > a img {
  float: left;
  margin-right: 5px;
  width: 39px;
  margin-top: -2px;
  border-radius: 60%;
}
#topnav.navbar .navbar-right > li.current-user > a span {
  float: left;
  line-height: 18px;
  margin-top: 3px;
}
#topnav.navbar .navbar-right > li.current-user > a i {
  margin: 0;
  font-size: 14px;
}
#topnav.navbar .navbar-right > li.current-user > a i.fa-exchange {
  margin-bottom: 2px;
  font-size: 17px;
}
#topnav.navbar .navbar-right > li.current-user > a .account-text {
  font-size: 11px;
  font-weight: 600;
  margin-top: 3px;
}
@media (max-width: 767px) {
  #topnav.navbar .navbar-right > li.current-user a {
    display: block;
    float: none;
  }
  #topnav.navbar .navbar-right > li.current-user a img {
    display: inline-block;
    margin-right: 0;
    float: none;
  }
  #topnav.navbar .navbar-right > li.current-user a span {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0;
    float: none;
    margin-top: 0;
  }
  #topnav.navbar .navbar-right > li.current-user a i {
    font-size: 14px;
    line-height: 60px;
    color: #ffffff !important;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    height: 39px;
    line-height: 39px;
    margin: 10px auto;
    width: 39px;
    border-radius: 20%;
  }
  #topnav.navbar .navbar-right > li.current-user.open i,
  #topnav.navbar .navbar-right > li.current-user a:hover i {
    opacity: 1;
    background-color: #0077b4 !important;
  }
}
#topnav.navbar .navbar-right > li.current-user .businame {
  display: block;
  float: none !important;
  width: 150px;
  text-align: left;
  font-weight: 600;
  margin-top: -5px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#topnav.navbar .navbar-right > li.current-businame > a {
  padding-top: 8px;
  padding-bottom: 8px;
}
#topnav.navbar .navbar-right > li.current-businame > a .username {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#topnav.navbar .navbar-right .dropdown-menu:before {
  top: -11px;
  right: 35px;
  left: auto;
}
#topnav.navbar .navbar-right .dropdown-menu:after {
  top: -9px;
  right: 36px;
  left: auto;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-subview .fa-stack {
  height: 1.9em;
  line-height: 1.9em;
  vertical-align: top;
  margin-right: 5px;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-large {
  width: 300px;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper .media-body {
  width: auto !important;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #8e8e93;
  display: block;
  margin-bottom: 5px;
  padding: 10px;
  border-bottom: none;
  background-color: #f7f7f8;
  text-align: left;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a.unread {
  background-color: #edecee;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:hover,
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:focus,
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:active {
  background-color: #e9e8ea;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .thread-image {
  float: left;
  height: 50px;
  margin-right: 8px;
  width: 50px;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .author {
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .preview {
  display: block;
  font-size: 11px;
  line-height: normal;
  color: #aeacb4;
}
#topnav.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .time {
  display: block;
  float: right;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  color: #aeacb4;
}
@media (max-width: 767px) {
  #topnav.navbar .navbar-right .dropdown-menu {
    position: relative;
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    margin: 0;
  }
  #topnav.navbar .navbar-right .dropdown-menu:before {
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
  #topnav.navbar .navbar-right .dropdown-menu:after {
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  #topnav.navbar .navbar-right > li {
    float: left;
    width: 100%;
  }
}
/* ---------------------------------------------------------------------- */
/*  Sidebar
/* ---------------------------------------------------------------------- */
#sidebar {
  width: 75px;
  float: left;
  left: 0;
  bottom: 0;
  background: #f7f7f8;
  overflow: visible;
  z-index: 9998;
}
#sidebar > div,
#sidebar .sidebar-container {
  height: 100%;
}
.app-sidebar-fixed #sidebar > .sidebar-container {
  overflow-y: hidden;
  overflow-x: visible;
  position: relative;
}
.app-mobile #sidebar {
  overflow: auto;
}
#sidebar .wrapper {
  padding: 20px 15px;
}
.app-navbar-fixed.app-sidebar-fixed #sidebar {
  top: 0;
}
#sidebar nav {
  min-height: 100%;
}
@media (min-width: 992px) {
  #sidebar {
    margin-top: 65px;
  }
  #sidebar nav {
    border-right: 1px solid #eeeeee;
  }
  .app-navbar-fixed #sidebar {
    margin-top: 0;
  }
  .app-sidebar-fixed #sidebar {
    position: fixed;
    top: 65px !important;
    margin-top: 0;
  }
  .app-sidebar-closed #sidebar {
    width: 75px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  #sidebar:before {
    background-color: inherit;
    border: inherit;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    width: inherit;
    z-index: -1;
    border-right: 1px solid #eeeeee;
  }
}
@media (max-width: 991px) {
  #sidebar {
    position: fixed !important;
    top: 65px !important;
    left: 0;
    float: none;
    -webkit-transform: translate3d(-75px, 0, 0);
    transform: translate3d(-75px, 0, 0);
    -webkit-transition: transform 300ms ease 0s;
    -moz-transition: transform 300ms ease 0s;
    -ms-transition: transform 300ms ease 0s;
    -o-transition: transform 300ms ease 0s;
  }
  .app-slide-off #sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: transform 300ms ease 0s;
    -moz-transition: transform 300ms ease 0s;
    -ms-transition: transform 300ms ease 0s;
    -o-transition: transform 300ms ease 0s;
  }
  #sidebar nav {
    border-right: none !important;
  }
  #sidebar > .sidebar-container {
    overflow-y: hidden;
    overflow-x: visible;
    position: relative;
  }
}
/* ---------------------------------------------------------------------- */
/*  Nav
/* ---------------------------------------------------------------------- */
.item-content {
  align-items: center;
  display: table-row;
}
.item-content .item-media {
  padding: 10px 0px 10px 0px;
  width: 40px;
  text-align: center;
}
.item-content .item-inner {
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eeeeee;
  display: table-cell;
  padding: 10px 15px 10px 0;
  width: 100%;
}
.no-touch .item-content .item-inner {
  -webkit-transition: border 300ms linear;
  -moz-transition: border 300ms linear;
  -ms-transition: border 300ms linear;
  -o-transition: border 300ms linear;
}
.active .item-content .item-inner,
.open .item-content .item-inner,
li:last-child .item-content .item-inner {
  border-bottom-color: transparent !important;
}
#sidebar > div nav > ul {
  background: #ffffff;
  list-style: none;
  padding: 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
#sidebar > div nav > ul > li a {
  color: #8e8e93;
  border-bottom: none;
}
#sidebar > div nav > ul > li a i {
  font-weight: normal;
}
#sidebar > div nav > ul > li > a {
  display: table;
}
#sidebar > div nav > ul > li.hover {
  background: #efeff0;
}
.no-touch #sidebar > div nav > ul > li:hover {
  background: #efeff0;
}
#sidebar > div nav > ul > li.active {
  background: #c8c7cc;
}
#sidebar > div nav > ul > li.active > a {
  border-top: none !important;
  color: #ffffff;
}
#sidebar > div nav > ul > li.active > a i {
  color: #ffffff;
}
#sidebar > div nav > ul li {
  border: none;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}
.no-touch #sidebar > div nav > ul li {
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
}
@media (min-width: 992px) {
  .app-sidebar-closed #sidebar > div nav > ul li ul.sub-menu {
    display: none !important;
  }
}
#sidebar > div nav > ul li.active > ul.sub-menu {
  display: block;
  padding: 0;
}
#sidebar > div nav > ul li > a {
  margin-top: -1px !important;
  font-size: 14px;
}
#sidebar > div nav > ul li > a:active:focus {
  background: #d5d4d8;
}
.no-touch #sidebar > div nav > ul li > a:hover .item-inner {
  border-bottom-color: transparent;
}
#sidebar > div nav > ul li a {
  border: none;
  font-weight: 300;
  margin: 0;
  position: relative;
  text-decoration: none;
}
#sidebar > div nav > ul li a i[class*="ti-"] {
  margin-right: 5px;
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 2px;
}
#sidebar > div nav > ul li a .icon-arrow {
  float: right;
  margin-right: 6px !important;
  font-style: normal;
  font-weight: normal;
  color: #eeeeee;
  font-size: 12px;
}
#sidebar > div nav > ul li a .icon-arrow:before {
  content: "\e64a";
  font-family: themify;
}
#sidebar > div nav > ul li.open {
  background: #edecee;
}
#sidebar > div nav > ul li.open > a .icon-arrow {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
#sidebar > div nav > ul li.open > a .icon-arrow:before {
  content: "\e64b";
}
ul.sub-menu {
  clear: both;
  list-style: none;
  padding: 1px 0;
  display: none;
  margin: 0;
  background: #f7f7f8;
}
ul.sub-menu li {
  background: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1px !important;
  padding: 0;
}
.no-touch ul.sub-menu li:hover {
  background: #edecee;
}
ul.sub-menu li a {
  background: none;
  display: block;
  font-size: 14px !important;
  font-weight: 300;
  border: none;
  margin: 0;
  padding-bottom: 5px;
  padding-left: 45px !important;
  padding-right: 10px;
  padding-top: 5px;
  text-decoration: none;
}
ul.sub-menu li a .icon-arrow {
  margin-right: 22px;
}
ul.sub-menu li.dropdown-header {
  padding-left: 40px !important;
}
ul.sub-menu li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  margin-left: 40px !important;
}
ul.sub-menu li ul.sub-menu li a {
  padding-left: 60px !important;
}
ul.sub-menu li ul.sub-menu li ul.sub-menu li a {
  padding-left: 80px !important;
}
@media (min-width: 992px) {
  .app-sidebar-closed ul.sub-menu {
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    background: #ffffff;
    left: 75px;
    position: absolute;
    padding: 1px 0;
    overflow-y: auto;
    overflow-x: visible;
    height: auto;
    bottom: auto;
    width: 75px;
    z-index: 1050;
    display: none;
  }
  .app-sidebar-closed ul.sub-menu > li a {
    padding-left: 20px !important;
    padding-right: 5px;
  }
  .app-sidebar-closed ul.sub-menu > li ul.sub-menu {
    position: relative;
    left: 0;
    width: 100%;
    border: none !important;
  }
  .app-sidebar-closed ul.sub-menu > li ul.sub-menu li a {
    padding-left: 30px !important;
  }
  .app-sidebar-closed ul.sub-menu > li ul.sub-menu li ul.sub-menu li a {
    padding-left: 50px !important;
  }
}
@media (min-width: 992px) {
  .app-sidebar-closed .app-aside {
    overflow: visible !important;
  }
  .app-sidebar-closed .app-aside .search-form {
    background: #e2e2e4;
    border-bottom: 1px solid #eeeeee;
  }
  .app-sidebar-closed .app-aside .search-form > a {
    height: 45px;
    display: block;
    padding: 13px 15px;
    text-align: center;
    font-size: 18px;
    color: #aeacb4;
  }
  .app-sidebar-closed .app-aside .search-form .navbar-form {
    display: none;
  }
  .app-sidebar-closed .app-aside li a .icon-arrow {
    float: right;
    margin-right: 6px !important;
    font-style: normal;
    font-weight: normal;
    color: #eeeeee;
    font-size: 12px;
  }
  .app-sidebar-closed .app-aside li a .icon-arrow:before {
    content: "\e64a";
    font-family: themify;
  }
  .app-sidebar-closed .app-aside li.open > a .icon-arrow {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  .app-sidebar-closed .app-aside li.open > a .icon-arrow:before {
    content: "\e64b";
  }
  .app-sidebar-closed .app-aside > .item-inner {
    left: 75px;
    top: 0;
    padding: 13px 0;
    border-bottom: none !important;
    width: 75px;
    background: #efeff0;
    margin-left: 0;
    z-index: 1051;
  }
  .app-sidebar-closed .app-aside > .item-inner.active {
    background: #c8c7cc !important;
  }
  .app-sidebar-closed .app-aside > .item-inner:before {
    content: "";
    background: none;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #eeeeee;
    border-right: none;
    border-top: 12px solid transparent;
    display: block;
    float: right;
    height: 0;
    position: absolute;
    left: 0;
    top: 11px;
    width: 0;
    z-index: 1;
  }
  .app-sidebar-closed .app-aside > .item-inner:after {
    content: "";
    background: none;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #efeff0;
    border-top: 12px solid transparent;
    display: block;
    float: right;
    height: 0;
    position: absolute;
    left: -1px;
    top: 11px;
    width: 0;
    z-index: 1;
  }
  .app-sidebar-closed .app-aside > .item-inner .title {
    margin-left: 20px;
    color: #fff !important;
    font-size: 14px;
  }
  .app-sidebar-closed .app-aside > .item-inner .icon-arrow {
    display: none;
  }
  .app-sidebar-closed .app-aside > ul.sub-menu {
    width: 75px;
    display: block !important;
    overflow: auto;
    padding: 0;
    left: 75px;
  }
  .app-sidebar-closed .app-aside > ul.sub-menu > li a {
    color: #8e8e93;
    font-size: 14px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .app-sidebar-closed #sidebar > div nav > ul {
    padding: 0;
  }
  .no-touch .app-sidebar-closed #sidebar > div nav > ul li {
    -webkit-transition: background-color 0ms linear;
    -moz-transition: background-color 0ms linear;
    -ms-transition: background-color 0ms linear;
    -o-transition: background-color 0ms linear;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.open {
    background: inherit;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.hover {
    background: #efeff0;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active:before {
    border-right: none !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active:after {
    border-right: none !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active:hover:after {
    border-left-color: #c8c7cc;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active a {
    margin: 0 !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a {
    height: 45px;
    display: block;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a i:not([class*="fa-stack"]) {
    font-size: 18px;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-content {
    display: block;
    padding-left: 10px;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-media {
    display: block;
    text-align: center;
    padding: 13px 15px;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-inner {
    display: none;
    position: absolute;
    left: 75px;
    top: 0;
    padding: 13px 0;
    bottom: 0;
    border-bottom: none !important;
    width: 75px;
    background: #efeff0;
    margin-left: 0;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-inner .title {
    margin-left: 20px;
    color: #aeacb4 !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-inner .icon-arrow {
    display: none;
  }
  .app-sidebar-closed #sidebar .s-open {
    width: 75px;
  }
  .app-sidebar-closed #sidebar .navbar-form {
    height: 45px;
    position: absolute;
    z-index: 1;
    width: 75px;
  }
  .app-sidebar-closed #sidebar .navbar-form .s-remove,
  .app-sidebar-closed #sidebar .navbar-form .form-group {
    display: block;
  }
  .app-sidebar-closed #sidebar .navbar-form a {
    font-size: 18px;
    display: block;
    text-align: center;
    padding: 3px 0;
    color: #aeacb4;
  }
  .app-sidebar-closed #sidebar .navbar-form a.s-remove {
    display: none;
  }
  .app-sidebar-closed #sidebar .navbar-form .form-group {
    left: 74px;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    top: 0;
    position: absolute;
    width: 210px;
    z-index: 1;
    background: #e2e2e4;
    padding: 10px 10px 10px 0;
    display: none;
    bottom: -1px;
    top: -1px;
  }
  .app-sidebar-closed #sidebar .navbar-form .form-group input {
    background: rgba(255, 255, 255, 0.3);
  }
  .app-sidebar-closed #sidebar .navbar-form .form-group .search-button {
    top: 8px;
  }
  .app-sidebar-closed #sidebar > .navbar-form .s-remove {
    display: block !important;
    padding: 3px 0;
    text-align: center;
  }
  .app-sidebar-closed #sidebar > .navbar-form .form-group {
    display: block !important;
  }
  .app-sidebar-closed #sidebar .navbar-title {
    display: none;
  }
}
.navbar-title {
  color: #808285;
  font-size: 10px;
  letter-spacing: 0.2px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 20px !important;
  padding-bottom: 5px !important;
  text-transform: uppercase;
}
.s-open {
  display: none;
}
.navbar-form {
  background: #e2e2e4;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 8px;
  position: relative;
}
.navbar-form a {
  display: none;
}
.navbar-form .search-button {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none !important;
  color: #c8c7cc;
  position: absolute;
  right: 5px;
  top: -2px;
  transition: all 100ms ease-in 0s;
}
.navbar-form .search-button:hover {
  transition: all 300ms ease-in 0s;
}
.navbar-form .form-control {
  background: transparent;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #8e8e93;
  display: block;
  transition: all 300ms ease-in 0s;
  width: 100%;
  font-size: 12px;
  height: 25px;
  border-radius: 3px !important;
}
.navbar-form .form-control:focus {
  background-color: #ffffff !important;
  border-color: #eeeeee;
  transition: all 300ms ease-in 0s;
}
.navbar-form .form-control:focus + .search-button {
  color: #aeacb4;
}
.navbar-form .form-group {
  display: block;
}
.hr-line-solid {
  border-top: 1px solid #e7eaec;
  color: #ffffff;
  background-color: #ffffff;
  height: 0.5px;
}
/* ---------------------------------------------------------------------- */
/*  Main Content
/* ---------------------------------------------------------------------- */
.main-content {
  min-height: 100%;
  position: relative;
}
.main-content .container {
  max-width: none;
  width: 100% !important;
  display: inline-block;
}
.main-content > div.container {
  padding-bottom: 50px;
  display: block;
}
@media (min-width: 992px) {
  .main-content {
    margin-top: 65px;
    margin-left: 75px;
  }
  .app-navbar-fixed .main-content {
    margin-top: 0;
  }
  .app-navbar-fixed .main-content:before,
  .app-navbar-fixed .main-content:after {
    content: " ";
    display: table;
  }
  .app-navbar-fixed .main-content:after {
    clear: both;
  }
  .app-sidebar-closed .main-content {
    margin-left: 75px;
  }
}
@media (max-width: 991px) {
  .main-content {
    padding-top: 65px;
  }
}
.main-content:not(.brandforce-10):not(.sonicwall-9):not(.ingram-micro-8) page-analytics-component .page-analytics .page-title h1,
.main-content:not(.brandforce-10):not(.sonicwall-9):not(.ingram-micro-8) page-analytics-component .page-analytics .page-title h4,
.main-content:not(.brandforce-10):not(.sonicwall-9):not(.ingram-micro-8) page-analytics-component .page-analytics .page-filtes .filter-heading {
  font-family: inherit;
}
.main-content:not(.brandforce-10):not(.sonicwall-9):not(.ingram-micro-8) page-analytics-component .page-analytics indi-high-charts tspan {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* ---------------------------------------------------------------------- */
/*  Footer
/* ---------------------------------------------------------------------- */
#app > footer {
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  font-size: 12px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1088;
}
#app > footer .footer-inner {
  padding: 0 10px;
  line-height: 50px;
}
@media (min-width: 992px) {
  #app > footer {
    margin-left: 75px;
  }
  #app.app-sidebar-closed > footer {
    margin-left: 75px;
  }
  #app.app-footer-fixed > footer {
    position: fixed;
  }
}
@media (max-width: 480px) {
  #app .footer-inner {
    white-space: nowrap;
    font-size: 3.5vw;
  }
}
.go-top {
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 0;
  text-decoration: none;
  line-height: 50px;
  text-align: center;
}
/* ---------------------------------------------------------------------- */
/*  Settings
/* ---------------------------------------------------------------------- */
.settings {
  position: fixed !important;
  right: -240px;
  top: 120px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: right 0.2s ease 0s;
  -moz-transition: right 0.2s ease 0s;
  -ms-transition: right 0.2s ease 0s;
  -o-transition: right 0.2s ease 0s;
  border-left: 1px solid #eeeeee !important;
  border-bottom: 1px solid #eeeeee !important;
  width: 240px;
  z-index: 1050;
}
.settings.active {
  right: 0;
}
.settings > .btn {
  background: #ffffff !important;
  border-color: #eeeeee !important;
  border-right-width: 0;
  left: -44px;
  padding: 10px 15px;
  position: absolute;
  top: 41px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.settings > .btn:hover,
.settings > .btn:active,
.settings > .btn:focus {
  box-shadow: none !important;
}
.settings.panel {
  border-radius: 0 !important;
  border-top: none;
  border-right: none;
  background: #ffffff;
  border-color: #eeeeee !important;
}
.settings.panel .panel-heading {
  border-top-width: 1px;
  border-top-style: solid;
  padding: 11px 15px;
  background: #efeff0;
  border-color: #eeeeee;
  border-radius: 0 !important;
}
.settings.panel .panel-body {
  padding: 0;
}
.settings.panel .setting-box {
  border-bottom: 1px solid #eeeeee;
  padding: 11px 10px 10px;
}
.settings.panel .setting-box .setting-title {
  padding: 5px 0;
}
.settings.panel .setting-box:last-child {
  border-bottom: none;
}
.colors-row {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  padding-bottom: 0 !important;
  padding-top: 5px !important;
  border-bottom: none !important;
}
.color-theme {
  display: table-cell;
  height: 100%;
  vertical-align: top;
  padding-right: 5px;
}
.color-theme:last-child {
  padding-right: 0;
}
.color-theme .color-layout > label {
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
}
.color-theme .color-layout > label .ti-check {
  color: #fff;
  display: block;
  font-size: 12px;
  height: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
}
.color-theme .color-layout > label > input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.color-theme .color-layout > label > input[type="radio"]:checked + .ti-check {
  opacity: 1 !important;
}
.color-theme .color-layout > label > .color {
  display: block;
  height: 18px;
  position: relative;
}
.color-theme .color-layout > label > .split {
  display: block;
  position: relative;
}
.color-theme .color-layout > label > .split:before,
.color-theme .color-layout > label > .split:after {
  content: " ";
  display: table;
}
.color-theme .color-layout > label > .split:after {
  clear: both;
}
.color-theme .color-layout > label > .split > .color {
  display: block;
  height: 20px;
  position: relative;
}
.color-theme .color-layout > label > .split > .color:first-child {
  float: left;
  width: 30%;
}
.color-theme .color-layout > label > .split > .color:last-child {
  float: right;
  width: 70%;
}
.color-theme .color-layout > label > .split > .color .element {
  position: absolute;
  height: 5px;
  width: 100%;
  top: 5px;
}
.color-theme .color-layout > label > .split.header > .color {
  height: 10px;
}
.theme-1 .th-header {
  background: #ffffff;
}
.theme-1 .th-collapse {
  background: #ffffff;
}
.theme-1 .th-sidebar {
  background: #ffffff;
}
.theme-1 .th-body {
  background: #EFEFF4;
}
.theme-1 .element {
  background: #007aff;
}
.theme-2 .th-header {
  background: #22262E;
}
.theme-2 .th-collapse {
  background: #ffffff;
}
.theme-2 .th-sidebar {
  background: #1B1E24;
}
.theme-2 .th-body {
  background: #E5E9EC;
}
.theme-2 .element {
  background: #D1351F;
}
.theme-3 .th-header {
  background: #485058;
}
.theme-3 .th-collapse {
  background: #485058;
}
.theme-3 .th-sidebar {
  background: #788290;
}
.theme-3 .th-body {
  background: #F0F3F4;
}
.theme-3 .element {
  background: #1FBBA6;
}
.theme-4 .th-header {
  background: #E7E7E7;
}
.theme-4 .th-collapse {
  background: #F1F1F1;
}
.theme-4 .th-sidebar {
  background: #F3F3F3;
}
.theme-4 .th-body {
  background: #F8F8F8;
}
.theme-4 .element {
  background: #00BDCC;
}
.theme-5 .th-header {
  background: #2C3543;
}
.theme-5 .th-collapse {
  background: #2C3543;
}
.theme-5 .th-sidebar {
  background: #364150;
}
.theme-5 .th-body {
  background: #F8F8F8;
}
.theme-5 .element {
  background: #81B441;
}
.theme-6 .th-header {
  background: #1C2B36;
}
.theme-6 .th-collapse {
  background: #ffffff;
}
.theme-6 .th-sidebar {
  background: #1C2B36;
}
.theme-6 .th-body {
  background: #F0F3F4;
}
.theme-6 .element {
  background: #131E26;
}
/* ---------------------------------------------------------------------- */
/*  Utilities
/* ---------------------------------------------------------------------- */
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.no-radius {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  -moz-background-clip: padding !important;
  -webkit-background-clip: padding-box !important;
  background-clip: padding-box !important;
}
.no-border {
  border: none !important;
}
.no-border-top {
  border-top: none !important;
}
.no-border-bottom {
  border-bottom: none !important;
}
.border-right {
  border-right-style: solid;
  border-right-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}
.border-left {
  border-left-style: solid;
  border-left-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}
.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}
.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}
.border-light {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
.border-around {
  border: 1px solid #eeeeee;
}
.panel-white .border-light,
.partition-white .border-light {
  border-color: rgba(0, 0, 0, 0.07) !important;
}
.border-dark {
  border-color: rgba(0, 0, 0, 0.2);
}
.panel-white .border-dark,
.partition-white .border-dark {
  border-color: rgba(0, 0, 0, 0.3) !important;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.no-display {
  display: none !important;
}
.display-table {
  display: table !important;
}
.display-table-cell {
  display: table-cell !important;
}
.example {
  margin: 15px 0;
  padding: 14px 19px;
}
.space5 {
  display: block;
  margin-bottom: 5px !important;
  clear: both;
}
.space7 {
  margin-bottom: 7px !important;
  clear: both;
}
.space10 {
  margin-bottom: 10px !important;
  clear: both;
}
.space12 {
  margin-bottom: 12px !important;
  clear: both;
}
.space15 {
  margin-bottom: 15px !important;
  clear: both;
}
.space20 {
  margin-bottom: 20px !important;
  clear: both;
}
.padding-0 {
  padding: 0 !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-15 {
  padding: 15px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-25 {
  padding: 25px !important;
}
.padding-30 {
  padding: 30px !important;
}
.padding-35 {
  padding: 35px !important;
}
.padding-40 {
  padding: 40px !important;
}
.padding-horizontal-5 {
  padding: 0 5px !important;
}
.padding-horizontal-10 {
  padding: 0 10px !important;
}
.padding-horizontal-15 {
  padding: 0 15px !important;
}
.padding-horizontal-20 {
  padding: 0 20px !important;
}
.padding-vertical-10 {
  padding: 10px 0 !important;
}
.padding-vertical-15 {
  padding: 15px 0 !important;
}
.padding-vertical-20 {
  padding: 20px 0 !important;
}
.padding-bottom-0 {
  padding-bottom: 0px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-bottom-50 {
  padding-bottom: 30px !important;
}
.padding-top-0 {
  padding-top: 0px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-50 {
  padding-top: 30px !important;
}
.padding-left-0 {
  padding-left: 0px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-right-0 {
  padding-right: 0px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-xs-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-top-neg-5 {
  margin-top: -5px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-right-0 {
  margin-right: 0px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-left-0 {
  margin-left: 0px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.min-height-155 {
  min-height: 155px !important;
}
.min-height-180 {
  min-height: 180px !important;
}
.min-height-200 {
  min-height: 200px !important;
}
.min-height-230 {
  min-height: 230px !important;
}
.min-height-250 {
  min-height: 250px !important;
}
.min-height-270 {
  min-height: 270px !important;
}
.min-height-280 {
  min-height: 280px !important;
}
.min-height-300 {
  min-height: 300px !important;
}
.min-height-350 {
  min-height: 350px !important;
}
.height-50 {
  height: 50px !important;
}
.height-100 {
  height: 100px !important;
}
.height-155 {
  height: 155px !important;
}
.height-180 {
  height: 180px !important;
}
.height-200 {
  height: 200px !important;
}
.height-230 {
  height: 230px !important;
}
.height-250 {
  height: 250px !important;
}
.height-270 {
  height: 270px !important;
}
.height-300 {
  height: 300px !important;
}
.height-330 {
  height: 330px !important;
}
.height-340 {
  height: 330px !important;
}
.height-350 {
  height: 350px !important;
}
.height-360 {
  height: 360px !important;
}
.height-370 {
  height: 370px !important;
}
.height-375 {
  height: 375px !important;
}
.height-380 {
  height: 380px !important;
}
.height-390 {
  height: 390px !important;
}
.height-400 {
  height: 400px !important;
}
.height-410 {
  height: 410px !important;
}
.height-420 {
  height: 420px !important;
}
.height-430 {
  height: 430px !important;
}
.height-450 {
  height: 450px !important;
}
.height-470 {
  height: 470px !important;
}
.width-200 {
  width: 200px !important;
}
.width-400 {
  width: 400px !important;
}
.full-width {
  width: 100%;
}
.full-height {
  width: 100%;
}
.auto-width {
  width: auto!important;
}
.vertical-align-bottom {
  vertical-align: bottom !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.vertical-align-top {
  vertical-align: top !important;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.text-nowrap {
  white-space: nowrap;
}
.text-muted {
  color: #c2c2c5;
}
.text-bold {
  font-weight: bold !important;
}
.text-normal {
  font-weight: normal !important;
}
.text-extra-small {
  font-size: 11px !important;
}
.text-small {
  font-size: 12px !important;
}
.text-large {
  font-size: 17px !important;
}
.text-extra-large {
  font-size: 18px !important;
}
h1.text-extra-large {
  font-size: 50px !important;
}
.text-primary {
  color: #48b1cc !important;
}
.text-grey {
  color: #c8c7cc !important;
}
.text-azure {
  color: #0095C8 !important;
}
.text-orange {
  color: #FF6600 !important;
}
.text-green {
  color: #1FBBA6 !important;
}
.text-blue {
  color: #5F8295 !important;
}
.text-pink {
  color: #DD5A82 !important;
}
.text-purple {
  color: #DD5A82 !important;
}
.text-bricky {
  color: #894550 !important;
}
.text-yellow {
  color: #FFB848 !important;
}
.text-red {
  color: #C82E29 !important;
}
.text-white {
  color: #ffffff !important;
}
a.text-white {
  color: rgba(255, 255, 255, 0.6) !important;
}
a.text-white:hover {
  color: #ffffff !important;
}
.text-indigenous-blue {
  color: #47B8D9 !important;
}
.text-dark {
  color: #5b5b60 !important;
}
a.text-dark:hover {
  color: #6f6f74 !important;
}
.text-light {
  color: #c2c2c5 !important;
}
a.text-light:hover {
  color: #adadb1 !important;
}
.text-white-transparent {
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-u {
  text-transform: uppercase;
}
.text-l {
  text-transform: lowercase;
}
.text-cap {
  text-transform: capitalize;
}
/* ---------------------------------------------------------------------- */
/*  Background Colors
/* ---------------------------------------------------------------------- */
.no-bg {
  background: none !important;
}
.bg-white {
  background: #ffffff !important;
}
.bg-black {
  background: #2C2F3B !important;
  color: #ffffff;
}
.bg-primary {
  background: #48b1cc !important;
  color: #ffffff;
}
.bg-light-primary {
  background: #70c2d7 !important;
  color: #ffffff;
}
.bg-dark-primary {
  background: #3196b0 !important;
  color: #ffffff;
}
.bg-success {
  background: #5cb85c !important;
  color: #ffffff;
}
.bg-info {
  background: #46b8da !important;
  color: #ffffff;
}
.bg-warning {
  background: #eea236 !important;
  color: #ffffff;
}
.bg-danger {
  background: #d43f3a !important;
  color: #ffffff;
}
.bg-azure {
  background: #0095C8 !important;
  color: #ffffff;
}
.bg-light-azure {
  background: #00a8e2 !important;
  color: #ffffff;
}
.bg-dark-azure {
  background: #006f95 !important;
  color: #ffffff;
}
.bg-red {
  background: #C82E29 !important;
  color: #ffffff;
}
.bg-light-red {
  background: #da4f4a !important;
  color: #ffffff;
}
.bg-dark-red {
  background: #9e2420 !important;
  color: #ffffff;
}
.bg-green {
  background: #1FBBA6 !important;
  color: #ffffff;
}
.bg-light-green {
  background: #30ddc6 !important;
  color: #ffffff;
}
.bg-dark-green {
  background: #188f7f !important;
  color: #ffffff;
}
.bg-orange {
  background: #FF6600 !important;
  color: #ffffff;
}
.bg-light-orange {
  background: #ff8533 !important;
  color: #ffffff;
}
.bg-dark-orange {
  background: #cc5200 !important;
  color: #ffffff;
}
.bg-blue {
  background: #5F8295 !important;
  color: #ffffff;
}
.bg-light-blue {
  background: #7c9bab !important;
  color: #ffffff;
}
.bg-dark-blue {
  background: #4b6776 !important;
  color: #ffffff;
}
.bg-pink {
  background: #DD5A82 !important;
  color: #ffffff;
}
.bg-light-pink {
  background: #e684a2 !important;
  color: #ffffff;
}
.bg-dark-pink {
  background: #d43062 !important;
  color: #ffffff;
}
.bg-purple {
  background: #804C75 !important;
  color: #ffffff;
}
.bg-light-purple {
  background: #a05f92 !important;
  color: #ffffff;
}
.bg-dark-purple {
  background: #603958 !important;
  color: #ffffff;
}
.bg-beige {
  background: #D2BD8E !important;
  color: #ffffff;
}
.bg-light-beige {
  background: #d9c8a0 !important;
  color: #ffffff;
}
.bg-dark-beige {
  background: #c3a86a !important;
  color: #ffffff;
}
.bg-grey {
  background: #c8c7cc !important;
  color: #ffffff;
}
.bg-light-grey {
  background: #f7f7f8 !important;
}
.bg-dark-grey {
  background: #aeacb4 !important;
  color: #ffffff;
}
.bg-yellow {
  background: #FFB848 !important;
  color: #ffffff;
}
.bg-light-yellow {
  background: #ffcc7b !important;
  color: #ffffff;
}
.bg-dark-yellow {
  background: #ffa415 !important;
  color: #ffffff;
}
.bg-bricky {
  background: #894550 !important;
  color: #ffffff;
}
.bg-light-bricky {
  background: #aa5764 !important;
  color: #ffffff;
}
.bg-dark-bricky {
  background: #67343c !important;
  color: #ffffff;
}
.bg-body {
  background: #F1F3FA;
}
.center {
  text-align: center;
  margin: 0 auto;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.circle-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-shadow: 0 1px 2px #C3C3C3;
  -moz-box-shadow: 0 1px 2px #C3C3C3;
  box-shadow: 0 1px 2px #C3C3C3;
}
.circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 55px;
  text-align: center;
  display: inline-block;
  -webkit-box-shadow: 0 1px 2px #C3C3C3;
  -moz-box-shadow: 0 1px 2px #C3C3C3;
  box-shadow: 0 1px 2px #C3C3C3;
}
.rate .value {
  font-size: 30px;
  font-weight: 600;
}
.rate i {
  vertical-align: middle;
  padding-bottom: 15px;
}
.rate .percentage {
  vertical-align: middle;
  font-size: 15px;
  padding-bottom: 15px;
}
.rate img {
  display: block;
  position: relative;
  width: 100%;
}
.overlayer {
  display: block;
  position: absolute;
  z-index: 101;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0px, #000000 100%);
  opacity: 1;
}
.overlayer.fullwidth {
  width: 100%;
}
.overlayer .overlayer-wrapper {
  display: block;
  position: relative;
  z-index: 100;
}
.bottom-left {
  bottom: 0;
  left: 0;
  position: absolute;
}
.tags {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin: 0 0 10px 18px;
  padding: 2px 10px 1px;
  position: relative;
  transition: all 0.25s linear;
  white-space: nowrap;
  line-height: 21px;
}
.tooltip-notification {
  cursor: pointer;
  right: 0;
  margin-top: 3px;
  padding: 5px 0;
  position: absolute;
  top: 25px;
  z-index: 10000;
}
.toolbar .tooltip-notification {
  top: 60px;
  right: 20px;
}
.tooltip-notification .tooltip-notification-arrow {
  border-width: 0 5px 5px;
  left: 80%;
  margin-left: -5px;
  top: 0;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #292B2E;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.tooltip-notification .tooltip-notification-inner {
  background-color: #292B2E;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 12px;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  line-height: normal;
}
.tooltip-notification .tooltip-notification-inner .message {
  color: #BFC0C1;
  font-size: 11px;
  margin-top: -4px;
}
.tooltip-notification .tooltip-notification-inner > div {
  width: 100px;
}
.float-none {
  float: none;
}
.background-dark {
  background: rgba(0, 0, 0, 0.2);
}
.background-light {
  background: rgba(255, 255, 255, 0.6) !important;
}
.radius-3 {
  border-radius: 3px;
}
.radius-5 {
  border-radius: 5px;
}
.radius-10 {
  border-radius: 10px;
}
.stack-right-bottom {
  left: auto !important;
  right: -0.5em !important;
  top: auto !important;
  bottom: -0.5em !important;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}
.noTransform {
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
}
.vertical-bottom {
  vertical-align: bottom;
}
.vertical-middle {
  vertical-align: middle;
}
.vertical-top {
  vertical-align: top;
}
.show-grid {
  margin-bottom: 15px;
}
.show-grid [class^="col-"] {
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}
.no-wrap {
  white-space: nowrap;
}
.pre {
  white-space: pre;
  /* CSS 2.0 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3.0 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: -moz-pre-wrap;
  /* Mozilla */
  white-space: -hp-pre-wrap;
  /* HP Printers */
  word-wrap: break-word;
  /* IE 5+ */
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.modal-overflow-content {
  /* <- this could be deprecated when modal layout is more flexible */
  max-height: 400px;
  overflow: auto;
}
.aspect-ratio {
  height: 0;
  position: relative;
}
.aspect-ratio--16x9 {
  padding-bottom: 56.25%;
}
.aspect-ratio--4x3 {
  padding-bottom: 75%;
}
.aspect-ratio--8x5 {
  padding-bottom: 62.5%;
}
.aspect-ratio--object-center {
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 100;
  transform: translateY(-50%);
}
.sticky {
  position: sticky;
}
.sticky.sticky-fixed.is-sticky {
  position: fixed;
  top: 0;
  backface-visibility: hidden;
}
.sticky.sticky-fixed.is-absolute {
  position: absolute;
}
.sticky-wrap {
  float: left;
  clear: both;
  width: 100%;
}
div.sticky-wrap + div {
  clear: both;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.no-transition {
  -webkit-transition: height 0.01s;
  -moz-transition: height 0.01s;
  -ms-transition: height 0.01s;
  -o-transition: height 0.01s;
  transition: height 0.01s;
}
.opacity-0 {
  opacity: 0!important;
}
.nopadding {
  padding-left: 0px;
  padding-right: 0px;
}
/* ---------------------------------------------------------------------- */
/* Dropdown Menu
/* ---------------------------------------------------------------------- */
.dropdown-menu.dropdown-dark {
  background-color: #2B3034;
  border: 1px solid #1A1C1E;
  border-radius: 0;
  box-shadow: none;
  list-style: none outside none;
  max-width: 300px;
  min-width: 166px;
  padding: 0;
  position: absolute;
  text-shadow: none;
  z-index: 1000;
}
.dropdown-menu.dropdown-dark:after {
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 10px;
  content: "";
  border-bottom-color: #2B3034;
  border-top-width: 0;
  content: " ";
  top: -9px;
  left: 10px;
}
.dropdown-menu.dropdown-dark.pull-right:after {
  left: auto;
  right: 10px;
}
.dropdown-menu.dropdown-dark .dropdown-header {
  padding: 8px 8px 6px;
  color: #f7f7f8;
  font-size: 11px;
}
.dropdown-menu.dropdown-dark .divider {
  background: none;
}
.dropdown-menu.dropdown-dark li p,
.dropdown-menu.dropdown-dark li a,
.dropdown-menu.dropdown-dark .drop-down-wrapper li p,
.dropdown-menu.dropdown-dark .drop-down-wrapper li a {
  color: #EDEDED;
  border-bottom-color: #1A1C1E;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  padding: 8px 8px 6px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  white-space: normal !important;
  display: block;
}
.dropdown-menu.dropdown-dark li:last-child a {
  border-bottom: none !important;
  border-radius: 0 0 6px 6px;
}
.dropdown-menu.dropdown-dark > li > a:hover,
.dropdown-menu.dropdown-dark > li > a:focus,
.dropdown-menu.dropdown-dark .dropdown-submenu:hover > a,
.dropdown-menu.dropdown-dark .dropdown-submenu:focus > a,
.dropdown-menu.dropdown-dark .drop-down-wrapper li a:hover,
.dropdown-menu.dropdown-dark .drop-down-wrapper li a:focus {
  background-color: #212326;
}
.dropdown-menu.dropdown-light {
  background-color: #FFFFFF;
  border: 1px solid rgba(17, 17, 17, 0.15);
  max-width: 300px;
  min-width: 166px;
  border-radius: 7px;
  padding: 0;
}
.dropdown-menu.dropdown-light:before,
.dropdown-menu.dropdown-light:after {
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.dropdown-menu.dropdown-light:before {
  border-bottom-color: rgba(17, 17, 17, 0.15);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 11px;
  content: "";
  border-top-width: 0;
  content: " ";
  top: -11px;
  left: 10px;
}
.dropdown-menu.dropdown-light:after {
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 10px;
  content: "";
  border-bottom-color: #FFFFFF;
  border-top-width: 0;
  content: " ";
  top: -9px;
  left: 11px;
}
.dropdown-menu.dropdown-light.pull-right:after {
  left: auto;
  right: 11px;
}
.dropdown-menu.dropdown-light.pull-right:before {
  left: auto;
  right: 10px;
}
.dropdown-menu.dropdown-light li a {
  border-bottom: 1px solid #DDDDDD;
  font-size: 14px;
  line-height: 35px;
  color: #48b1cc;
  text-align: center;
}
.dropdown-menu.dropdown-light li:first-child a {
  border-radius: 7px 7px 0 0;
}
.dropdown-menu.dropdown-light li:last-child a {
  border-radius: 0 0 7px 7px;
}
.dropdown-menu.dropdown-light li:last-child a {
  border-bottom: none;
}
.drop-down-wrapper .list-group-item {
  border-left: none;
  border-right: none;
  margin-top: 0;
}
.drop-down-wrapper .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.drop-down-wrapper .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-header {
  padding: 5px 10px;
}
/* ---------------------------------------------------------------------- */
/* Tabs
/* ---------------------------------------------------------------------- */
.nav-tabs {
  margin-bottom: 0;
  padding: 0;
}
.nav-tabs.nav-justified > li > a {
  border-left: none !important;
  border-radius: 0;
  border-top: none !important;
  border-right: none !important;
}
.nav-tabs > li {
  margin-bottom: -3px;
  text-align: center;
}
.nav-tabs > li.dropdown.active a {
  cursor: pointer;
}
.nav-tabs > li.dropdown .dropdown-menu > .active a,
.nav-tabs > li.dropdown .dropdown-menu > .active a:hover,
.nav-tabs > li.dropdown .dropdown-menu > .active a:focus {
  background-color: #f7f7f8;
  color: #ffffff;
}
.nav-tabs > li > a {
  border: 0 none;
  border-radius: 0;
  color: #8E9AA2;
  min-width: 70px;
  padding: 15px 20px;
}
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
  border-width: 0;
}
.nav-tabs > .active a,
.nav-tabs > .active a:hover,
.nav-tabs > .active a:focus {
  border: 0 none;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  color: #505458;
}
.panel-tabs,
.tabbable {
  background-color: #E6E8E8;
  padding: 1px;
  margin-bottom: 15px;
}
.tabbable[type="pills"] {
  background: none;
}
.tabbable[type="pills"] > .nav {
  margin-bottom: 10px;
}
.panel-tabs {
  padding: 0;
}
.panel-tabs .panel-heading {
  background: #EFEFF4;
}
.panel-tabs .panel-body {
  padding: 0;
}
.panel-tabs .tabbable {
  margin: 0;
  padding: 0;
}
.panel-tabs .nav-tabs {
  border: medium none;
  float: right;
  margin-top: -48px;
}
.panel-tabs .nav-tabs > li > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.tab-content {
  background-color: #FFFFFF;
  position: relative;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
  padding: 15px;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-left.tabbable {
  background-color: none;
  padding: 0;
}
.tabs-left .tab-content {
  border-top: 1px solid #EFEFF4;
  border-right: 1px solid #EFEFF4;
  border-bottom: 1px solid #EFEFF4;
  overflow: hidden;
}
.tabs-left > .nav-tabs {
  border: none;
  float: left;
  margin-bottom: 0;
  top: auto;
}
.tabs-left > .nav-tabs > li {
  float: none;
  text-align: left;
}
.tabs-left > .nav-tabs > li .display-ib {
  font-size: 22px;
  color: #9b9b9b;
  width: 100%;
}
.tabs-left > .nav-tabs > li.nav-tab-is-active {
  color: #404040;
}
.tabs-left > .nav-tabs > li.nav-tab-is-active .display-ib {
  color: #404040;
}
.tabs-left > .nav-tabs > li.nav-tab:hover {
  color: #4a4a4a;
}
.tabs-left > .nav-tabs > li.nav-tab:hover .display-ib {
  color: #4a4a4a;
}
.tabs-left > .nav-tabs > li > a,
.tabs-left > .nav-tabs > li a:focus,
.tabs-left > .nav-tabs > li a:hover {
  border-color: #EFEFF4;
  margin: 0 -1px 0 0;
}
.tabs-left > .nav-tabs > li.active > a,
.tabs-left > .nav-tabs > li.active a:focus,
.tabs-left > .nav-tabs > li.active a:hover {
  border-color: #EFEFF4;
  border-right: none;
  border-left: 1px solid #EFEFF4;
  border-top-width: 1px;
  margin: 0 -1px;
}
.tabs-right.tabbable {
  background-color: none;
  padding: 0;
}
.tabs-right .tab-content {
  border-top: 1px solid #EFEFF4;
  border-left: 1px solid #EFEFF4;
  border-bottom: 1px solid #EFEFF4;
  overflow: hidden;
}
.tabs-right > .nav-tabs {
  border: none;
  float: right;
  margin-bottom: 0;
  top: auto;
}
.tabs-right > .nav-tabs > li {
  text-align: left;
  float: none;
}
.tabs-right > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li a:focus,
.tabs-right > .nav-tabs > li a:hover {
  border-color: #EFEFF4;
  margin: 0 0 0 -1px;
}
.tabs-right > .nav-tabs > li.active > a,
.tabs-right > .nav-tabs > li.active a:focus,
.tabs-right > .nav-tabs > li.active a:hover {
  border-color: #EFEFF4;
  border-right: 1px solid #EFEFF4 !important;
  border-left: none;
  border-top-width: 1px;
  margin: 0 -1px;
}
.tabs-below > .nav-tabs {
  border-bottom-width: 0;
  border-color: #EFEFF4;
  margin-bottom: 0;
  margin-top: 0;
  top: auto;
}
.tabs-below > .nav-tabs > li {
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a,
.tabs-below > .nav-tabs > li a:focus,
.tabs-below > .nav-tabs > li a:hover {
  border-color: #EFEFF4;
}
.tabs-below > .nav-tabs > li.active > a,
.tabs-below > .nav-tabs > li.active a:focus,
.tabs-below > .nav-tabs > li.active a:hover {
  border-bottom: none;
  border-color: transparent #EFEFF4 #EFEFF4;
  margin-top: 0;
}
.partition-dark .nav-tabs {
  border: none;
}
.partition-dark .nav-tabs > li {
  margin-bottom: 0;
}
.partition-dark .nav-tabs > li.active > a,
.partition-dark .nav-tabs > li.active > a:hover,
.partition-dark .nav-tabs > li.active a:focus {
  background-color: #2C2F3B;
  border: none;
  color: #ffffff;
}
.partition-dark .nav-tabs > li.active > a:before,
.partition-dark .nav-tabs > li.active a:after {
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.partition-dark .nav-tabs > li.active > a:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #2C2F3B;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  z-index: 1;
}
.partition-dark .nav-tabs > li > a {
  border: none;
  color: #f7f7f8;
  padding: 10px 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.partition-dark .nav-tabs > li > a:hover,
.partition-dark .nav-tabs > li a:focus {
  background-color: #2C2F3B;
}
.partition-dark .tab-content {
  z-index: 0;
  color: #2C2F3B;
}
.add-component-modal .nav-tabs {
  max-height: 300px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
/* ---------------------------------------------------------------------- */
/*  Panels
/* ---------------------------------------------------------------------- */
.panel {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #8e8e93;
  border: none;
  position: relative;
}
.panel-footer {
  position: relative;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: inherit;
  padding: 15px;
  background: none;
}
.panel-heading {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 15px;
  min-height: 50px;
}
.panel-heading .panel-heading-tabs {
  list-style: none;
  top: 0;
  right: 0;
  position: absolute;
  margin: 0;
  padding: 0;
}
.panel-heading .panel-heading-tabs > li {
  float: left;
  padding: 0 15px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: inherit;
  height: 50px;
  line-height: 50px;
}
.panel-heading .panel-heading-tabs > li.panel-tools {
  position: relative;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  right: auto;
  top: auto;
}
.panel-heading .panel-heading-tabs > li.panel-tools .tmp-tool {
  right: 20px;
  top: auto;
}
.panel-heading .panel-heading-tabs > li.panel-tools .dropdown {
  line-height: normal;
}
.core-box .heading {
  margin-bottom: 15px;
}
.core-box .core-icon {
  width: 33.3333%;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.core-box .core-icon .icon-big {
  line-height: 80px;
}
.core-box .core-content {
  margin-left: 33.3333%;
  min-height: 120px;
}
.core-box .heading {
  font-size: 12px;
}
.core-box .title {
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 0;
  font-weight: 400;
}
.core-box .subtitle {
  font-size: 14px;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.core-box .content {
  line-height: 20px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 60px;
}
.core-box .content .fa {
  font-size: 50px;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}
.core-box .view-more {
  float: right;
}
.core-box .view-more i {
  display: inline-block;
}
.panel-tools {
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 12px;
}
.panel-portfolio .portfolio-grid {
  padding: 0;
  text-align: center;
  overflow: hidden;
  max-height: 200px;
  position: relative;
}
.panel-portfolio .portfolio-grid .item {
  overflow: hidden !important;
}
.panel-portfolio .navigator {
  position: relative;
  text-align: center;
  margin-top: -40px;
  margin-bottom: 10px;
  z-index: 101;
}
.panel-transparent,
.partition-transparent {
  background: none;
  position: relative;
  box-shadow: none;
  color: #5b5b60;
}
.panel-transparent .panel-tools .btn,
.partition-transparent .panel-tools .btn,
.panel-transparent .panel-tools > a,
.partition-transparent .panel-tools > a {
  color: rgba(128, 128, 128, 0.7) !important;
}
.panel-transparent .panel-tools .btn:hover,
.partition-transparent .panel-tools .btn:hover,
.panel-transparent .panel-tools > a:hover,
.partition-transparent .panel-tools > a:hover {
  color: #4d4d4d !important;
}
.panel-primary,
.partition-primary {
  background-color: #48b1cc;
  position: relative;
  color: #ffffff;
}
.panel-primary .panel-heading,
.partition-primary .panel-heading {
  background-color: #48b1cc;
  border: none;
}
.panel-primary .panel-tools .btn,
.partition-primary .panel-tools .btn,
.panel-primary .panel-tools > a,
.partition-primary .panel-tools > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.panel-primary .panel-tools .btn:hover,
.partition-primary .panel-tools .btn:hover,
.panel-primary .panel-tools > a:hover,
.partition-primary .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-light-primary,
.partition-light-primary {
  background-color: #70c2d7;
  position: relative;
  color: #ffffff;
}
.panel-light-primary .panel-heading,
.partition-light-primary .panel-heading {
  background-color: #70c2d7;
  border: none;
}
.panel-light-primary .panel-tools .btn,
.partition-light-primary .panel-tools .btn,
.panel-light-primary .panel-tools > a,
.partition-light-primary .panel-tools > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.panel-light-primary .panel-tools .btn:hover,
.partition-light-primary .panel-tools .btn:hover,
.panel-light-primary .panel-tools > a:hover,
.partition-light-primary .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-dark-primary,
.partition-dark-primary {
  background-color: #3196b0;
  position: relative;
  color: #ffffff;
}
.panel-dark-primary .panel-heading,
.partition-dark-primary .panel-heading {
  background-color: #3196b0;
  border: none;
}
.panel-dark-primary .panel-tools .btn,
.partition-dark-primary .panel-tools .btn,
.panel-dark-primary .panel-tools > a,
.partition-dark-primary .panel-tools > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.panel-dark-primary .panel-tools .btn:hover,
.partition-dark-primary .panel-tools .btn:hover,
.panel-dark-primary .panel-tools > a:hover,
.partition-dark-primary .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-grey,
.partition-grey {
  background-color: #c8c7cc;
  position: relative;
  color: #48474d;
}
.panel-grey .panel-tools .btn,
.partition-grey .panel-tools .btn,
.panel-grey .panel-tools > a,
.partition-grey .panel-tools > a {
  color: rgba(128, 128, 128, 0.7) !important;
}
.panel-grey .panel-tools .btn:hover,
.partition-grey .panel-tools .btn:hover,
.panel-grey .panel-tools > a:hover,
.partition-grey .panel-tools > a:hover {
  color: #48474d !important;
}
.panel-light-grey,
.partition-light-grey {
  background-color: #f7f7f8;
  position: relative;
  color: #8e8e93;
}
.panel-light-grey .panel-tools .btn,
.partition-light-grey .panel-tools .btn,
.panel-light-grey .panel-tools > a,
.partition-light-grey .panel-tools > a {
  color: rgba(128, 128, 128, 0.7) !important;
}
.panel-light-grey .panel-tools .btn:hover,
.partition-light-grey .panel-tools .btn:hover,
.panel-light-grey .panel-tools > a:hover,
.partition-light-grey .panel-tools > a:hover {
  color: #8e8e93 !important;
}
.panel-orange,
.partition-orange {
  background-color: #FF6600;
  position: relative;
  color: #ffffff;
}
.panel-orange .panel-tools .btn,
.partition-orange .panel-tools .btn,
.panel-orange .panel-tools > a,
.partition-orange .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-orange .panel-tools .btn:hover,
.partition-orange .panel-tools .btn:hover,
.panel-orange .panel-tools > a:hover,
.partition-orange .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-purple,
.partition-purple {
  background-color: #804C75;
  position: relative;
  color: #ffffff;
}
.panel-purple .panel-tools .btn,
.partition-purple .panel-tools .btn,
.panel-purple .panel-tools > a,
.partition-purple .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-purple .panel-tools .btn:hover,
.partition-purple .panel-tools .btn:hover,
.panel-purple .panel-tools > a:hover,
.partition-purple .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-pink,
.partition-pink {
  background-color: #DD5A82;
  position: relative;
  color: #ffffff;
}
.panel-pink .panel-tools .btn,
.partition-pink .panel-tools .btn,
.panel-pink .panel-tools > a,
.partition-pink .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-pink .panel-tools .btn:hover,
.partition-pink .panel-tools .btn:hover,
.panel-pink .panel-tools > a:hover,
.partition-pink .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-azure,
.partition-azure {
  background-color: #0095C8;
  position: relative;
  color: #ffffff;
}
.panel-azure .panel-tools .btn,
.partition-azure .panel-tools .btn,
.panel-azure .panel-tools > a,
.partition-azure .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-azure .panel-tools .btn:hover,
.partition-azure .panel-tools .btn:hover,
.panel-azure .panel-tools > a:hover,
.partition-azure .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-bricky,
.partition-bricky {
  background-color: #894550;
  position: relative;
  color: #ffffff;
}
.panel-bricky .panel-tools .btn,
.partition-bricky .panel-tools .btn,
.panel-bricky .panel-tools > a,
.partition-bricky .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-bricky .panel-tools .btn:hover,
.partition-bricky .panel-tools .btn:hover,
.panel-bricky .panel-tools > a:hover,
.partition-bricky .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-green,
.partition-green {
  background-color: #1FBBA6;
  position: relative;
  color: #ffffff;
}
.panel-green .panel-tools .btn,
.partition-green .panel-tools .btn,
.panel-green .panel-tools > a,
.partition-green .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-green .panel-tools .btn:hover,
.partition-green .panel-tools .btn:hover,
.panel-green .panel-tools > a:hover,
.partition-green .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-red,
.partition-red {
  background-color: #C82E29;
  position: relative;
  color: #ffffff;
}
.panel-red .panel-tools .btn,
.partition-red .panel-tools .btn,
.panel-red .panel-tools > a,
.partition-red .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-red .panel-tools .btn:hover,
.partition-red .panel-tools .btn:hover,
.panel-red .panel-tools > a:hover,
.partition-red .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-blue,
.partition-blue {
  background-color: #5F8295;
  position: relative;
  color: #ffffff;
}
.panel-blue .panel-tools .btn,
.partition-blue .panel-tools .btn,
.panel-blue .panel-tools > a,
.partition-blue .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-blue .panel-tools .btn:hover,
.partition-blue .panel-tools .btn:hover,
.panel-blue .panel-tools > a:hover,
.partition-blue .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-yellow,
.partition-yellow {
  background-color: #FFB848;
  position: relative;
  color: #2C2F3B;
}
.panel-yellow .panel-tools .btn,
.partition-yellow .panel-tools .btn,
.panel-yellow .panel-tools > a,
.partition-yellow .panel-tools > a {
  color: rgba(0, 0, 0, 0.7) !important;
}
.panel-yellow .panel-tools .btn:hover,
.partition-yellow .panel-tools .btn:hover,
.panel-yellow .panel-tools > a:hover,
.partition-yellow .panel-tools > a:hover {
  color: #2C2F3B !important;
}
.panel-white,
.partition-white {
  background-color: #ffffff;
  position: relative;
  color: #5b5b60;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.panel-dark,
.partition-dark {
  background-color: #2C2F3B;
  position: relative;
  color: #ffffff;
}
.panel-dark .panel-tools .btn,
.partition-dark .panel-tools .btn,
.panel-dark .panel-tools > a,
.partition-dark .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-dark .panel-tools .btn:hover,
.partition-dark .panel-tools .btn:hover,
.panel-dark .panel-tools > a:hover,
.partition-dark .panel-tools > a:hover {
  color: #ffffff !important;
}
.panel-scroll {
  overflow: hidden;
  position: relative;
  width: auto;
  padding-right: 10px !important;
}
.panel-note {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: none;
}
.panel-note .panel-heading {
  border-bottom: 0 none;
  padding-top: 30px;
  padding-bottom: 10px;
  top: 0;
  z-index: 3;
  position: relative;
}
.panel-note .panel-heading h3 {
  margin: 0;
}
.panel-note .panel-body {
  padding: 0;
  margin: 0 15px;
  line-height: 18px;
}
.panel-note .panel-footer {
  background: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  padding: 10px 15px;
  margin-bottom: 0;
}
.panel-note .panel-footer .avatar-note {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
}
.panel-note .panel-footer .avatar-note img {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.panel-note .panel-footer .author-note {
  display: block;
  line-height: 14px;
}
.panel-note .panel-footer .timestamp {
  color: #999999;
  display: inline-block;
  font-size: 11px;
}
.panel-note .note-options a {
  margin-left: 10px;
  font-size: 12px;
}
.panel-note .note-options a:hover {
  text-decoration: none;
}
[class^="partition-"]:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
/* ---------------------------------------------------------------------- */
/*  Backdrop
/* ---------------------------------------------------------------------- */
.full-white-backdrop {
  background-color: #FFFFFF;
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1049;
}
.panel.panel-full-screen {
  bottom: 0;
  left: 10px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1048;
  background-color: #FFFFFF;
}
.tmp-tool {
  position: fixed;
  right: 40px;
  top: 20px;
}
/* ---------------------------------------------------------------------- */
/*  Spinner
/* ---------------------------------------------------------------------- */
.csspinner {
  position: relative;
}
.csspinner:before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.csspinner:after {
  z-index: 2;
  content: "";
  position: absolute;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}
.load1:after {
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  border-radius: 50%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #48b1cc;
  -webkit-animation: load1 1.1s infinite linear;
  animation: load1 1.1s infinite linear;
}
@-webkit-keyframes load1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.load2:after {
  width: 30px;
  height: 30px;
  background-color: #48b1cc;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  -webkit-animation: load2 1.2s infinite ease-in-out;
  animation: load2 1.2s infinite ease-in-out;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes load2 {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/* ---------------------------------------------------------------------- */
/*  Pagination
/* ---------------------------------------------------------------------- */
.pagination {
  padding-left: 15px;
  padding-right: 15px;
}
.pagination > li > a,
.pagination > li > span {
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #48b1cc;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #48b1cc;
  border-color: #48b1cc;
}
.pagination.squared {
  border-radius: 0 !important;
}
.pagination.squared li:first-child a,
.pagination.squared li:first-child > span {
  border-bottom-left-radius: 0px !important;
  border-left-width: 1px;
  border-top-left-radius: 0px !important;
}
.pagination.squared li:last-child > a,
.pagination.squared li:last-child > span {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.pagination-blue li a {
  background: #f7f7f8;
  border: none !important;
  color: #8e8e93;
  display: inline-block;
  margin-right: 1px;
}
.pagination-blue li a:hover {
  background: #e7e7e9;
  color: #424246;
  cursor: pointer;
}
.pagination-blue li:first-child a {
  border: none !important;
}
.pagination-blue li.disabled a {
  background: #f7f7f8;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
}
.pagination-blue li.disabled a:hover,
.pagination-blue li.disabled a:focus {
  background: #f7f7f8;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  cursor: default !important;
}
.pagination-blue li.active a {
  background: #5F8295 !important;
  border: none !important;
  color: #ffffff !important;
  cursor: default !important;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.pagination-blue li [class^="fa-"],
.pagination-blue li [class*=" fa-"],
.pagination-blue li [class*="ti-"] {
  color: #5F8295;
}
.pagination-green li a {
  background: #f7f7f8;
  border: none !important;
  color: #8e8e93;
  display: inline-block;
  margin-right: 1px;
}
.pagination-green li a:hover {
  background: #e7e7e9;
  color: #424246;
  cursor: pointer;
}
.pagination-green li:first-child a {
  border: none !important;
}
.pagination-green li.disabled a {
  background: #f7f7f8;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
}
.pagination-green li.disabled a:hover,
.pagination-green li.disabled a:focus {
  background: #f7f7f8;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  cursor: default !important;
}
.pagination-green li.active a {
  background: #1FBBA6 !important;
  border: none !important;
  color: #ffffff !important;
  cursor: default !important;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.pagination-green li [class^="fa-"],
.pagination-green li [class*=" fa-"],
.pagination-green li [class*="ti-"] {
  color: #1FBBA6;
}
.pagination-red li a {
  background: #f7f7f8;
  border: none !important;
  color: #8e8e93;
  display: inline-block;
  margin-right: 1px;
}
.pagination-red li a:hover {
  background: #e7e7e9;
  color: #424246;
  cursor: pointer;
}
.pagination-red li:first-child a {
  border: none !important;
}
.pagination-red li.disabled a {
  background: #f7f7f8;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
}
.pagination-red li.disabled a:hover,
.pagination-red li.disabled a:focus {
  background: #f7f7f8;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  cursor: default !important;
}
.pagination-red li.active a {
  background: #C82E29 !important;
  border: none !important;
  color: #ffffff !important;
  cursor: default !important;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.pagination-red li [class^="fa-"],
.pagination-red li [class*=" fa-"],
.pagination-red li [class*="ti-"] {
  color: #C82E29;
}
/* ---------------------------------------------------------------------- */
/*  Accordion
/* ---------------------------------------------------------------------- */
.accordion .panel {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.accordion .panel-heading {
  padding: 0;
  background-image: none;
  height: auto;
  min-height: 0;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.accordion .panel-heading .accordion-toggle {
  background-color: #E6E8E8;
  color: #5b5b60;
  display: block;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  position: relative;
}
.accordion .panel-heading .accordion-toggle:hover,
.accordion .panel-heading .accordion-toggle:focus {
  text-decoration: none;
}
.accordion .panel-heading .accordion-toggle.collapsed {
  background-color: #F3F3F3;
  border-color: #D9D9D9;
  border-width: 0 0 0 1px;
  color: #aeacb4;
  font-weight: 300;
}
.accordion .panel-heading .accordion-toggle.collapsed > .icon-arrow {
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
}
.accordion .panel-heading .accordion-toggle.collapsed > .icon-arrow:before {
  content: "\f067" !important;
}
.accordion .panel-heading .accordion-toggle > .icon-arrow {
  font-size: 11px;
  padding: 0 5px;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f7f7f8;
}
.accordion .panel-heading .accordion-toggle > .icon-arrow:before {
  content: "\f068";
}
.accordion .panel-body {
  border: none !important;
}
.accordion.panel-group .panel + .panel {
  margin-top: 1px;
}
.accordion.accordion-white .panel-heading .accordion-toggle {
  background-color: #ffffff;
}
.accordion.accordion-white .panel-heading .accordion-toggle.collapsed {
  background-color: #ffffff;
}
.accordion.accordion-white.panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #a8a8ac;
}
.accordion.heading-white .accordion-toggle {
  background-color: #ffffff;
  color: #8e8e93;
}
.accordion.heading-white .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.accordion.no-padding .panel-body {
  padding: 0;
}
.accordion.body-light-grey .panel-body {
  background: #f7f7f8 !important;
}
.accordion .panel-group {
  margin-bottom: 0;
}
.accordion .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid transparent;
}
/* ---------------------------------------------------------------------- */
/*  Animations
/* ---------------------------------------------------------------------- */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUpShort {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort;
}
@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort;
}
.smooth.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}
.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}
.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}
.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}
.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}
.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}
.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}
.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}
.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}
.fade-in.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}
.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight 0.65s;
  animation: fadeInRight 0.65s;
}
.fade-in-right.ng-leave,
.fade-out-right.ng-leave {
  -webkit-animation: fadeOutLeft 0.65s;
  animation: fadeOutLeft 0.65s;
  position: absolute;
}
.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft 0.6s;
  animation: fadeInLeft 0.6s;
}
.fade-in-left.ng-leave,
.fade-out-left.ng-leave {
  -webkit-animation: fadeOutRight 0.6s;
  animation: fadeOutRight 0.6s;
  position: absolute;
}
.fade-in-up.ng-enter {
  -webkit-animation: fadeInUpShort 0.5s;
  animation: fadeInUpShort 0.5s;
}
.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUpShort 0.5s;
  animation: fadeOutUpShort 0.5s;
}
.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}
.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}
.heart-pulse {
  -webkit-animation: heart-pulse 1s linear infinite;
  -moz-animation: heart-pulse 1s linear infinite;
  -ms-animation: heart-pulse 1s linear infinite;
  animation: heart-pulse 1s linear infinite;
}
@keyframes "heart-pulse" {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: #ff0000;
  }
}
@-moz-keyframes heart-pulse {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: #ff0000;
  }
}
@-webkit-keyframes "heart-pulse" {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: #ff0000;
  }
}
@-ms-keyframes "heart-pulse" {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: #ff0000;
  }
}
/* ---------------------------------------------------------------------- */
/* Progress Bar
/* ---------------------------------------------------------------------- */
.progress {
  box-shadow: none;
}
.progress.progress-xs {
  height: 7px;
}
.progress.progress-sm {
  height: 12px;
}
.progress.progress-lg {
  height: 20px;
}
.progress.transparent-black {
  background-color: rgba(0, 0, 0, 0.2);
}
.progress-bar {
  background-color: #48b1cc;
}
.progress-bar-success {
  background-color: #5cb85c;
}
.progress-bar-info {
  background-color: #46b8da;
}
.progress-bar-warning {
  background-color: #eea236;
}
.progress-bar-danger {
  background-color: #d43f3a;
}
.app-ingram-body .progress-bar {
  background-color: #0077b4;
}
/* ---------------------------------------------------------------------- */
/*  Labels and Badges
/* ---------------------------------------------------------------------- */
.badge {
  background-color: #48b1cc;
}
.badge-success {
  background-color: #5CB85C;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #449D44;
}
.badge-warning {
  background-color: #F0AD4E;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #EC971F;
}
.badge-info {
  background-color: #5BC0DE;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #31B0D5;
}
.badge-danger {
  background-color: #D9534F;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #C9302C;
}
.badge-new {
  background-color: #007AFF;
  border-radius: 12px 12px 12px 12px !important;
  font-size: 11px !important;
  font-weight: 300;
  height: 18px;
  padding: 3px 6px;
  text-align: center;
  text-shadow: none !important;
  vertical-align: middle;
}
.label-default {
  background-color: #48b1cc;
}
.label {
  font-size: 85% !important;
  padding: 0.4em 0.6em !important;
}
.label-inverse,
.badge-inverse {
  background-color: #555555;
}
.two-letter-label {
  height: 35px;
  width: 35px;
  border-radius: 30px;
  line-height: 35px;
  font-weight: bold;
  color: white;
  margin-right: 3px;
  margin-bottom: 3px;
  text-transform: uppercase;
  text-align: center;
}
.turquoise {
  background: #1abc9c;
}
.emerland {
  background: #2ecc71;
}
.peterriver {
  background: #3498db;
}
.amethyst {
  background: #9b59b6;
}
.wetasphalt {
  background: #34495e;
}
.greensea {
  background: #16a085;
}
.nephritis {
  background: #27ae60;
}
.text-nephritis {
  color: #27ae60;
}
.belizehole {
  background: #2980b9;
}
.wisteria {
  background: #8e44ad;
}
.midnightblue {
  background: #2c3e50;
}
.sunflower {
  background: #f1c40f;
}
.carrot {
  background: #e67e22;
}
.alizarin {
  background: #e74c3c;
}
.clouds {
  background: #ecf0f1;
  color: #999;
}
.concrete {
  background: #95a5a6;
}
.orange {
  background: #f39c12;
}
.pumpkin {
  background: #d35400;
}
.pomegranate {
  background: #c0392b;
}
.silver {
  background: #bdc3c7;
}
.asbestos {
  background: #7f8c8d;
}
/* ---------------------------------------------------------------------- */
/*  Forms
/* ---------------------------------------------------------------------- */
fieldset {
  background: #ffffff;
  border: 1px solid #e6e8e8;
  border-radius: 5px;
  margin: 20px 0 20px 0 !important;
  padding: 25px !important;
  position: relative;
}
fieldset .form-group {
  margin-left: 0;
  margin-right: 0;
}
fieldset legend {
  background: inherit;
  font-family: "Lato", sans-serif;
  color: #48b1cc;
  font-size: 15px;
  left: 10px;
  padding: 0 10px;
  position: absolute;
  top: -12px;
  font-weight: 400;
  width: auto !important;
  border: none !important;
}
.form-control::-moz-placeholder {
  color: #c2c2c5;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #c2c2c5;
  opacity: 1;
}
.form-control::-webkit-input-placeholder {
  color: #c2c2c5;
  opacity: 1;
}
textarea,
select,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #eeeeee;
  border-radius: 0 0 0 0 !important;
  color: #5b5b60;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 4px;
  transition-duration: 0.1s;
  box-shadow: none !important;
  -webkit-transition: 300ms ease-in-out;
  -moz-transition: 300ms ease-in-out;
  -ms-transition: 300ms ease-in-out;
  -o-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}
textarea:hover,
select:hover,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="date"]:hover,
input[type="month"]:hover,
input[type="time"]:hover,
input[type="week"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="color"]:hover {
  border-color: #d5d5d5;
}
textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  box-shadow: none !important;
  border-color: #e1e1e1 !important;
  background-color: #F8F8F8 !important;
}
textarea.underline,
select.underline,
input[type="text"].underline,
input[type="password"].underline,
input[type="datetime"].underline,
input[type="datetime-local"].underline,
input[type="date"].underline,
input[type="month"].underline,
input[type="time"].underline,
input[type="week"].underline,
input[type="number"].underline,
input[type="email"].underline,
input[type="url"].underline,
input[type="search"].underline,
input[type="tel"].underline,
input[type="color"].underline {
  background: none !important;
  background-image: none;
  border: 1px solid #eeeeee;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0 0 0 0 !important;
  color: #5b5b60;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 4px;
  transition-duration: 0.1s;
  box-shadow: none !important;
  -webkit-transition: border 300ms ease-out;
  -moz-transition: border 300ms ease-out;
  -ms-transition: border 300ms ease-out;
  -o-transition: border 300ms ease-out;
}
textarea.underline:hover,
select.underline:hover,
input[type="text"].underline:hover,
input[type="password"].underline:hover,
input[type="datetime"].underline:hover,
input[type="datetime-local"].underline:hover,
input[type="date"].underline:hover,
input[type="month"].underline:hover,
input[type="time"].underline:hover,
input[type="week"].underline:hover,
input[type="number"].underline:hover,
input[type="email"].underline:hover,
input[type="url"].underline:hover,
input[type="search"].underline:hover,
input[type="tel"].underline:hover,
input[type="color"].underline:hover {
  border-color: #d5d5d5;
}
textarea.underline:focus,
select.underline:focus,
input[type="text"].underline:focus,
input[type="password"].underline:focus,
input[type="datetime"].underline:focus,
input[type="datetime-local"].underline:focus,
input[type="date"].underline:focus,
input[type="month"].underline:focus,
input[type="time"].underline:focus,
input[type="week"].underline:focus,
input[type="number"].underline:focus,
input[type="email"].underline:focus,
input[type="url"].underline:focus,
input[type="search"].underline:focus,
input[type="tel"].underline:focus,
input[type="color"].underline:focus {
  box-shadow: none !important;
  border-color: #48b1cc !important;
  background-color: none !important;
}
textarea[disabled],
input[disabled],
select[disabled] {
  background: #f7f7f8 !important;
  border-color: #F8F8F8 !important;
}
textarea[disabled]:hover,
input[disabled]:hover,
select[disabled]:hover,
textarea[disabled]:focus,
input[disabled]:focus,
select[disabled]:focus {
  background: #f7f7f8 !important;
  border-color: #F8F8F8;
}
legend {
  color: inherit;
  font-size: 16px;
  font-weight: 300;
}
.input-group-addon {
  background-color: #48b1cc;
  border: 1px solid #48b1cc;
  color: #ffffff;
}
select[multiple="multiple"]:focus option {
  background: #F8F8F8 !important;
}
.has-error .form-control:focus,
.has-warning .form-control:focus,
.has-success .form-control:focus,
.has-error .form-control:hover,
.has-warning .form-control:hover,
.has-success .form-control:hover {
  box-shadow: none;
}
.validation-invalid,
.validation-valid {
  font-size: 12px;
  padding: 0 5px;
}
.has-error .control-label {
  color: #d43f3a !important;
}
.has-error .error {
  color: #a94442;
}
.has-error .form-control {
  color: #5b5b60;
  border-color: #F8F8F8;
  border-bottom-color: #a94442 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: dotted;
  border-color: #a94442 !important;
  border-width: 1px !important;
  border-style: dotted;
}
.has-error .form-control:focus,
.has-error .form-control:hover {
  border-color: #F8F8F8;
  border-bottom-color: #a94442 !important;
}
.has-error label {
  color: #a94442 !important;
}
.has-warning {
  color: #eea236;
}
.has-warning .control-label {
  color: #8e8e93 !important;
}
.has-warning .form-control {
  color: #5b5b60;
  border-color: #F8F8F8;
  border-bottom-color: #8a6d3b !important;
  border-bottom-width: 1px !important;
  border-bottom-style: dotted;
  border-color: #8a6d3b !important;
  border-width: 1px !important;
  border-style: dotted;
}
.has-warning .form-control:focus,
.has-warning .form-control:hover {
  border-color: #F8F8F8;
  border-bottom-color: #8a6d3b !important;
}
.has-warning label {
  color: #8a6d3b;
}
.has-success {
  color: #5cb85c;
}
.has-success .control-label {
  color: #8e8e93 !important;
}
.has-success .form-control {
  color: #5b5b60;
  border-color: #F8F8F8;
  border-bottom-color: #3c763d !important;
  border-bottom-width: 1px !important;
  border-bottom-style: dotted;
  border-color: #3c763d !important;
  border-width: 1px !important;
  border-style: dotted;
}
.has-success .form-control:focus,
.has-success .form-control:hover {
  border-color: #F8F8F8;
  border-bottom-color: #3c763d !important;
}
.has-success label {
  color: #3c763d;
}
label {
  font-weight: normal;
  color: #444444;
}
span.input-icon,
span.input-help {
  display: block;
  position: relative;
}
.input-icon > input {
  padding-left: 30px !important;
  padding-right: 6px;
}
.input-icon.input-icon-right > input {
  padding-left: 6px !important;
  padding-right: 30px !important;
}
span.input-help > input {
  padding-left: 30px;
  padding-right: 6px;
}
.input-icon > [class*="fa-"],
.input-icon > [class*="ti-"] {
  bottom: 0;
  color: #48b1cc;
  display: inline-block;
  left: 5px;
  line-height: 35px;
  padding: 0 3px;
  position: absolute;
  top: -1px;
  z-index: 2;
}
.input-icon.input-icon-right > [class*="fa-"],
.input-icon.input-icon-right > [class*="ti-"] {
  left: auto;
  right: 4px;
}
.help-button {
  background-color: #65BCDA;
  border-radius: 100% 100% 100% 100%;
  color: #FFFFFF;
  cursor: default;
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  padding: 0;
  text-align: center;
  width: 20px;
  line-height: 20px;
  top: 7px;
  left: 7px;
}
.help-button:before {
  content: "\f128";
  display: inline;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  font-style: normal;
}
.form-control {
  color: inherit;
}
select.form-control {
  background-color: #FFFFFF;
  border: 1px solid #eeeeee;
  border-radius: 0 0 0 0;
  color: #5b5b60;
}
select.form-control option {
  padding: 3px 4px;
}
.form-control.search-select {
  padding: 0 !important;
  box-shadow: none;
  border: none;
}
textarea.autosize {
  min-height: 71px;
}
textarea.autosize.area-animated:focus {
  vertical-align: top;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
  overflow: auto;
  word-wrap: break-word;
  resize: horizontal;
}
.note-editor {
  position: relative;
  padding-top: 12px;
  background: #ffffff;
}
.note-editor textarea {
  line-height: 20px;
  margin-top: 10px;
  min-height: 140px;
  border: none;
  padding: 0;
  position: relative;
}
.note-editor textarea:focus {
  background-color: #ffffff !important;
}
.note-editor:before {
  position: absolute;
  height: 12px;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  padding-top: 1px;
}
.radio label.radio-inline,
.checkbox label.checkbox-inline {
  display: inline-block;
}
.radio-inline,
.radio-inline + .radio-inline,
.checkbox-inline,
.checkbox-inline + .checkbox-inline {
  margin-right: 10px !important;
  margin-top: 5px !important;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}
.checkbox-inline-right {
  margin-right: 10px !important;
  margin-top: 5px !important;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
  margin: 0!important;
  padding: 5px 0 10px 10px!important;
  width: 100%;
  text-transform: capitalize;
}
.checkbox-inline-right.clip-check label {
  padding: 0 0 0 20px!important;
  width: 100%;
  text-align: left;
}
.checkbox-inline-right.clip-check label:before {
  left: auto;
  right: 0;
  margin: 0;
}
.checkbox-inline-right.clip-check label:after {
  left: auto;
  right: -4px;
}
.checkbox-table {
  display: inline-block;
  margin: 2px 0 0 0;
  line-height: 10px;
}
.checkbox-table label {
  margin-bottom: 0;
}
.help-inline {
  margin-top: 6px;
  color: #737373;
}
.help-block.error {
  color: #B94A48;
}
.symbol.required:before {
  content: "*";
  display: inline;
  color: #E6674A;
}
.has-success .symbol:before {
  content: "\f00c";
  display: inline;
  font-family: FontAwesome;
  color: #468847;
}
.has-error .symbol:before {
  content: "\f00d";
  display: inline;
  font-family: FontAwesome;
  color: #C82E29;
}
.has-warning .symbol:before {
  content: "\f071";
  display: inline;
  font-family: FontAwesome;
  color: #eea236;
}
.has-error .note-editor,
.has-error .cke_chrome {
  border-color: #B94A48 !important;
}
.form-group {
  position: relative;
}
.form-group .text {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 7px;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: default;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  max-width: 50px;
}
/* Default custom select inspired by tympanus.net */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  font-size: 100%;
  width: 100%;
  max-width: 1500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.cs-select.disabled {
  opacity: 0.5;
}
div.cs-select.disabled span {
  cursor: default !important;
}
div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}
.cs-select select {
  display: none;
}
.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 5px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em;
}
.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cs-select > span::after {
  content: '\25BE';
  right: 1em;
}
.cs-select .cs-selected span::after {
  content: '\e64c';
  margin-left: 1em;
  font-family: "themify";
  font-size: 60%;
}
.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cs-select.cs-active {
  z-index: 101;
}
/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}
.cs-select.cs-active .cs-options {
  visibility: visible;
}
.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cs-select ul span {
  padding: 1em;
}
.cs-select ul li.cs-focus span {
  background-color: #ddd;
}
/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}
.cs-select li.cs-optgroup > span {
  cursor: default;
}
div.cs-skin-slide {
  color: #5b5b60;
  font-size: 14px;
  width: 100%;
}
div.cs-skin-slide::before {
  content: '';
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1, 4, 1);
  transform: scale3d(1, 4, 1);
  background: #f7f7f8;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.cs-skin-slide > span {
  height: 34px;
  line-height: 20px;
  border: 1px solid #eeeeee;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
}
.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}
.cs-skin-slide > span::after,
.cs-skin-slide.cs-active > span::after {
  font-family: 'themify';
  content: '\e68e';
  color: #48b1cc;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 17px;
}
.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.cs-skin-slide .cs-options {
  background: transparent;
  width: 100%;
  height: 400%;
  padding: 5px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.cs-skin-slide.cs-active .cs-options {
  overflow-y: auto;
}
.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  margin-bottom: 5px;
}
.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  padding: 0.8em 1em;
}
.cs-skin-slide .cs-options li span:empty {
  display: none;
}
.cs-skin-slide .cs-options li:hover,
.cs-skin-slide .cs-options li.cs-focus {
  background: #eaeaec;
}
.cs-skin-slide .cs-options li.cs-selected {
  color: #ffffff;
  background: #48b1cc;
}
.cs-select [class^="ti-"],
.cs-select [class*=" ti-"],
.cs-select [class^="fa-"],
.cs-select [class*=" fa-"] {
  line-height: inherit !important;
  display: block !important;
}
.cs-select [class^="ti-"] span,
.cs-select [class*=" ti-"] span,
.cs-select [class^="fa-"] span,
.cs-select [class*=" fa-"] span {
  font-family: "Raleway", sans-serif;
  padding-left: 30px !important;
}
.cs-select [class^="ti-"]:before,
.cs-select [class*=" ti-"]:before,
.cs-select [class^="fa-"]:before,
.cs-select [class*=" fa-"]:before {
  font-family: 'themify';
  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  font-size: 17px;
}
.cs-select [class^="ti-"]:before,
.cs-select [class*=" ti-"]:before {
  font-family: 'themify';
}
.cs-select [class^="fa-"]:before,
.cs-select [class*=" fa-"]:before {
  font-family: 'fontawesome';
}
/* Skin Elastic */
div.cs-skin-elastic {
  background: transparent;
  font-size: 14px;
  color: #5b5b60;
}
.cs-skin-elastic > span {
  background-color: #fff;
  z-index: 100;
  border: 1px solid #c8c7cc;
  height: 34px;
  line-height: 20px;
}
.cs-skin-elastic > span::after {
  font-family: 'themify';
  content: '\e64b';
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #48b1cc;
}
.cs-skin-elastic .cs-options {
  overflow: visible;
  background: transparent;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
}
.cs-skin-elastic.cs-active .cs-options {
  pointer-events: auto;
}
.cs-skin-elastic .cs-options > ul::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  background: #f7f7f8;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.cs-skin-elastic.cs-active .cs-options > ul::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.6s ease-out;
  animation: expand 0.6s ease-out;
}
.cs-skin-elastic .cs-options ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
}
.cs-skin-elastic.cs-active .cs-options ul li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
}
.cs-skin-elastic .cs-options span {
  background-repeat: no-repeat;
  background-position: 1.5em 50%;
  background-size: 2em auto;
  padding: 0.8em 1em 0.8em 1em;
}
.cs-skin-elastic .cs-options li:hover,
.cs-skin-elastic .cs-options li.cs-focus {
  background: #eaeaec;
}
.cs-skin-elastic .cs-options .cs-selected,
.cs-skin-elastic .cs-options .cs-selected:hover {
  color: #ffffff !important;
  background: #48b1cc !important;
}
@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}
@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  cursor: pointer;
  line-height: normal;
}
.clip-check {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
}
.clip-check label {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  padding-left: 30px !important;
  position: relative;
  line-height: 23px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  white-space: nowrap;
}
.clip-check label:before {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
  border-radius: 0;
  top: 1px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.clip-check label:after {
  display: inline-block;
  font-size: 11px;
  height: 19px;
  left: 4px;
  position: absolute;
  top: -1px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  width: 19px;
}
.clip-check input[type="checkbox"] {
  display: none;
}
.clip-check input[type="checkbox"]:checked + label:before {
  border-width: 10px;
}
.clip-check input[type="checkbox"]:checked + label:after {
  color: #fff;
  content: "\f00c";
  font-family: "FontAwesome";
}
.clip-check input[type="checkbox"][disabled] + label {
  opacity: 0.65;
}
.clip-check input[type="checkbox"][disabled] + label:before {
  background-color: #F8F8F8;
}
.clip-check.check-md label {
  margin-right: 15px;
  padding-left: 35px !important;
  line-height: 28px;
}
.clip-check.check-md label:before {
  height: 25px;
  width: 25px;
}
.clip-check.check-md label:after {
  font-size: 14px;
  height: 24px;
  left: 5px;
  width: 24px;
}
.clip-check.check-md input[type="checkbox"]:checked + label:before {
  border-width: 12px;
}
.clip-check.check-lg label {
  margin-right: 15px;
  padding-left: 40px !important;
  line-height: 33px;
}
.clip-check.check-lg label:before {
  height: 30px;
  width: 30px;
}
.clip-check.check-lg label:after {
  font-size: 17px;
  height: 29px;
  left: 6px;
  width: 29px;
}
.clip-check.check-lg input[type="checkbox"]:checked + label:before {
  border-width: 15px;
}
.clip-check.check-success input[type="checkbox"]:checked + label:before {
  border-color: #5cb85c;
}
.clip-check.check-primary input[type="checkbox"]:checked + label:before {
  border-color: #48b1cc;
}
.clip-check.check-warning input[type="checkbox"]:checked + label:before {
  border-color: #eea236;
}
.clip-check.check-danger input[type="checkbox"]:checked + label:before {
  border-color: #d43f3a;
}
.clip-check.check-info input[type="checkbox"]:checked + label:before {
  border-color: #46b8da;
}
.clip-check.check-purple input[type="checkbox"]:checked + label:before {
  border-color: #804C75;
}
.ingram-micro-8 .clip-check.check-info input[type="checkbox"]:checked + label:before {
  border-color: #0077b4;
}
.clip-radio {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
}
.clip-radio label {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  padding-left: 25px !important;
  position: relative;
  margin-bottom: 6px;
  line-height: 23px;
}
.clip-radio label:before {
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
  border-radius: 99px;
  bottom: 2px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
}
.clip-radio label:after {
  border: 1px solid #eeeeee;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
  border-radius: 99px;
  bottom: 2px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
}
.clip-radio input[type="radio"] {
  display: none;
}
.clip-radio input[type="radio"]:checked + label:before {
  border-width: 6px;
  background: #F8F8F8;
  border-color: #ffffff;
}
.clip-radio input[type="radio"][disabled] + label {
  opacity: 0.65;
}
.clip-radio.radio-md label {
  padding-left: 30px !important;
  line-height: 28px;
}
.clip-radio.radio-md label:before {
  height: 25px;
  width: 25px;
}
.clip-radio.radio-md label:after {
  height: 25px;
  width: 25px;
}
.clip-radio.radio-md input[type="radio"] {
  display: none;
}
.clip-radio.radio-md input[type="radio"]:checked + label:before {
  border-width: 7px;
}
.clip-radio.radio-lg label {
  padding-left: 35px !important;
  line-height: 33px;
}
.clip-radio.radio-lg label:before {
  height: 30px;
  width: 30px;
}
.clip-radio.radio-lg label:after {
  height: 30px;
  width: 30px;
}
.clip-radio.radio-lg input[type="radio"] {
  display: none;
}
.clip-radio.radio-lg input[type="radio"]:checked + label:before {
  border-width: 8px;
}
.clip-radio.radio-success input[type="radio"]:checked + label:before {
  background: #5cb85c;
}
.clip-radio.radio-primary input[type="radio"]:checked + label:before {
  background: #48b1cc;
}
.clip-radio.radio-info input[type="radio"]:checked + label:before {
  background: #46b8da;
}
.clip-radio.radio-warning input[type="radio"]:checked + label:before {
  background: #eea236;
}
.clip-radio.radio-danger input[type="radio"]:checked + label:before {
  background: #d43f3a;
}
.clip-radio.radio-purple input[type="radio"]:checked + label:before {
  background: #804C75;
}
.main-content .switch {
  width: 44px;
  height: 20px;
  background: #dddddd !important;
  box-shadow: inset 0px 0px 0px 1px rgba(182, 182, 182, 0.8) !important;
  top: 4px;
}
.main-content .switch small {
  top: -3px;
  box-shadow: 1px 1px 2px 1px rgba(174, 174, 174, 0.25), inset 0px -1px 0px 0px rgba(174, 174, 174, 0.85), inset 0px 1px 0px 0px rgba(174, 174, 174, 0.25) !important;
  width: 25px;
  height: 25px;
  left: -1px;
  background: #fdfdfd;
  background: linear-gradient(to bottom, #fdfdfd 25%, #e7e7e7 100%);
}
.main-content .switch.checked small {
  left: 20px;
  box-shadow: -1px 1px 2px 1px rgba(174, 174, 174, 0.25), inset 0px -1px 0px 0px rgba(174, 174, 174, 0.85), inset 0px 1px 0px 0px rgba(174, 174, 174, 0.25) !important;
}
.main-content .switch.checked {
  background: #2ecc71 !important;
}
.ui-select-multiple input.ui-select-search {
  width: auto !important;
}
.form-group label {
  margin-top: 8px;
}
.form-group label .fa-info-circle {
  font-size: 11px;
  position: relative;
  top: -2px;
  left: 2px;
  color: #444444;
}
textarea.form-control.text-area-control-large {
  min-height: 200px;
}
/* ---------------------------------------------------------------------- */
/*  Icons
/* ---------------------------------------------------------------------- */
.fa-round {
  border: 0.1em solid #eee;
  border-radius: 100%;
  padding: 0.2em 0.25em 0.15em;
}
.the-icons {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.the-icons .fa-hover {
  display: block;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  color: #8B91A0;
  cursor: pointer;
}
.no-touch .the-icons .fa-hover {
  -webkit-transition: font-size 0.2s ease 0s;
  -moz-transition: font-size 0.2s ease 0s;
  -ms-transition: font-size 0.2s ease 0s;
  -o-transition: font-size 0.2s ease 0s;
}
.no-touch .the-icons .fa-hover:hover {
  font-size: 20px;
}
.no-touch .the-icons .fa-hover:hover .fa,
.no-touch .the-icons .fa-hover:hover [class*="ti-"] {
  font-size: 20px;
}
.the-icons .fa-hover .fa,
.the-icons .fa-hover [class*="ti-"] {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  text-align: right;
  width: 32px;
  color: #48b1cc;
}
.big-icon {
  font-size: 100px;
}
/* ---------------------------------------------------------------------- */
/*  Buttons
/* ---------------------------------------------------------------------- */
.btn {
  transition: all 0.3s ease 0s !important;
  background-image: none !important;
  box-shadow: none !important;
  outline: none !important;
  position: relative;
}
.btn.no-transtion-btn {
  transition: none !important;
}
.btn:after {
  content: "";
  position: absolute;
  transition: all 0.3s ease 0s;
  z-index: -1;
}
.btn-wide {
  min-width: 120px;
}
.ladda-button[data-style="contract"][data-loading] {
  border-radius: 50%;
  min-width: 38px !important;
  overflow: hidden;
  width: 38px !important;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  box-shadow: none;
}
.btn-o {
  background: none !important;
}
.btn-default {
  background-color: #ffffff;
  border-color: #eeeeee;
  color: #8e8e93;
}
.btn-default:hover {
  background-color: #ffffff;
  border-color: #e1e1e1;
}
.btn-default:active,
.btn-default.active,
.btn-default.active:focus,
.btn-default:active:focus,
.btn-default:active:hover {
  background-color: #f8f8f8;
  border-color: #fbfbfb;
  color: #5b5b60 !important;
}
.btn-default:focus {
  background-color: #ffffff;
  border-color: #e1e1e1;
  color: #5b5b60 !important;
}
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #cfcfd1;
}
.btn-default .caret {
  border-top-color: #8e8e93;
}
.dropup .btn-default .caret {
  border-bottom: 4px solid #8e8e93;
}
.btn-primary {
  background-color: #48b1cc;
  border-color: #48b1cc;
  color: #ffffff;
}
.open .btn-primary.dropdown-toggle {
  background-color: #84cbdd;
  border-color: #84cbdd;
}
.btn-primary:hover {
  background-color: #70c2d7 !important;
  border-color: #70c2d7;
  color: #ffffff;
}
.btn-primary:active,
.btn-primary.active,
.btn-primary.active:focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.dropdown-toggle:active:hover {
  background-color: #37a7c4 !important;
  border-color: #37a7c4;
}
.btn-primary:focus {
  background-color: #84cbdd;
  border-color: #84cbdd;
}
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #98d3e2;
  border-color: #98d3e2;
  color: #ffffff;
}
.btn-primary.btn-o {
  border: 1px solid #48b1cc;
  color: #48b1cc;
}
.btn-primary.btn-o:hover {
  color: #70c2d7;
  border-color: #70c2d7;
  background: none !important;
}
.btn-primary.btn-o:active,
.btn-primary.btn-o.active,
.btn-primary.btn-o.active:focus,
.btn-primary.btn-o:active:focus,
.btn-primary.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #2b859c !important;
  color: #2b859c !important;
}
.btn-primary.btn-o:focus {
  background-color: #98d3e2;
  border-color: #98d3e2;
  color: #70c2d7;
}
.btn-primary.btn-o.disabled:hover,
.btn-primary.btn-o.disabled:focus,
.btn-primary.btn-o.disabled:active,
.btn-primary.btn-o.disabled.active,
.btn-primary.btn-o[disabled],
.btn-primary.btn-o[disabled]:hover,
.btn-primary.btn-o[disabled]:focus,
.btn-primary.btn-o[disabled]:active,
.btn-primary.btn-o[disabled].active,
fieldset[disabled] .btn-primary.btn-o:hover,
fieldset[disabled] .btn-primary.btn-o:focus,
fieldset[disabled] .btn-primary.btn-o:active,
fieldset[disabled] .btn-primary.btn-o.active {
  border-color: #98d3e2;
  color: #98d3e2;
}
.btn-primary.btn-o .caret {
  border-top-color: #48b1cc;
}
.btn-primary .caret {
  border-top-color: #ffffff;
}
.dropup .btn-primary .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #ffffff;
}
.open .btn-success.dropdown-toggle {
  background-color: #91cf91;
  border-color: #91cf91;
}
.btn-success:hover {
  background-color: #80c780 !important;
  border-color: #80c780;
  color: #ffffff;
}
.btn-success:active,
.btn-success.active,
.btn-success.active:focus,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success.dropdown-toggle:active:hover {
  background-color: #4cae4c !important;
  border-color: #4cae4c;
}
.btn-success:focus {
  background-color: #91cf91;
  border-color: #91cf91;
}
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #a3d7a3;
  border-color: #a3d7a3;
  color: #ffffff;
}
.btn-success.btn-o {
  border: 1px solid #5cb85c;
  color: #5cb85c;
}
.btn-success.btn-o:hover {
  color: #80c780;
  border-color: #80c780;
  background: none !important;
}
.btn-success.btn-o:active,
.btn-success.btn-o.active,
.btn-success.btn-o.active:focus,
.btn-success.btn-o:active:focus,
.btn-success.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #3d8b3d !important;
  color: #3d8b3d !important;
}
.btn-success.btn-o:focus {
  background-color: #a3d7a3;
  border-color: #a3d7a3;
  color: #80c780;
}
.btn-success.btn-o.disabled:hover,
.btn-success.btn-o.disabled:focus,
.btn-success.btn-o.disabled:active,
.btn-success.btn-o.disabled.active,
.btn-success.btn-o[disabled],
.btn-success.btn-o[disabled]:hover,
.btn-success.btn-o[disabled]:focus,
.btn-success.btn-o[disabled]:active,
.btn-success.btn-o[disabled].active,
fieldset[disabled] .btn-success.btn-o:hover,
fieldset[disabled] .btn-success.btn-o:focus,
fieldset[disabled] .btn-success.btn-o:active,
fieldset[disabled] .btn-success.btn-o.active {
  border-color: #a3d7a3;
  color: #a3d7a3;
}
.btn-success.btn-o .caret {
  border-top-color: #5cb85c;
}
.btn-success .caret {
  border-top-color: #ffffff;
}
.dropup .btn-success .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-info {
  background-color: #46b8da;
  border-color: #46b8da;
  color: #ffffff;
}
.open .btn-info.dropdown-toggle {
  background-color: #86d0e7;
  border-color: #86d0e7;
}
.btn-info:hover {
  background-color: #71c8e3 !important;
  border-color: #71c8e3;
  color: #ffffff;
}
.btn-info:active,
.btn-info.active,
.btn-info.active:focus,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info.dropdown-toggle:active:hover {
  background-color: #28a1c5 !important;
  border-color: #28a1c5;
}
.btn-info:focus {
  background-color: #86d0e7;
  border-color: #86d0e7;
}
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #9bd9eb;
  border-color: #9bd9eb;
  color: #ffffff;
}
.btn-info.btn-o {
  border: 1px solid #46b8da;
  color: #46b8da;
}
.btn-info.btn-o:hover {
  color: #71c8e3;
  border-color: #71c8e3;
  background: none !important;
}
.btn-info.btn-o:active,
.btn-info.btn-o.active,
.btn-info.btn-o.active:focus,
.btn-info.btn-o:active:focus,
.btn-info.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #2390b0 !important;
  color: #2390b0 !important;
}
.btn-info.btn-o:focus {
  background-color: #9bd9eb;
  border-color: #9bd9eb;
  color: #71c8e3;
}
.btn-info.btn-o.disabled:hover,
.btn-info.btn-o.disabled:focus,
.btn-info.btn-o.disabled:active,
.btn-info.btn-o.disabled.active,
.btn-info.btn-o[disabled],
.btn-info.btn-o[disabled]:hover,
.btn-info.btn-o[disabled]:focus,
.btn-info.btn-o[disabled]:active,
.btn-info.btn-o[disabled].active,
fieldset[disabled] .btn-info.btn-o:hover,
fieldset[disabled] .btn-info.btn-o:focus,
fieldset[disabled] .btn-info.btn-o:active,
fieldset[disabled] .btn-info.btn-o.active {
  border-color: #9bd9eb;
  color: #9bd9eb;
}
.btn-info.btn-o .caret {
  border-top-color: #46b8da;
}
.btn-info .caret {
  border-top-color: #ffffff;
}
.dropup .btn-info .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-warning {
  background-color: #eea236;
  border-color: #eea236;
  color: #ffffff;
}
.open .btn-warning.dropdown-toggle {
  background-color: #f4c37d;
  border-color: #f4c37d;
}
.btn-warning:hover {
  background-color: #f2b865 !important;
  border-color: #f2b865;
  color: #ffffff;
}
.btn-warning:active,
.btn-warning.active,
.btn-warning.active:focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning.dropdown-toggle:active:hover {
  background-color: #ec971e !important;
  border-color: #ec971e;
}
.btn-warning:focus {
  background-color: #f4c37d;
  border-color: #f4c37d;
}
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #f6ce94;
  border-color: #f6ce94;
  color: #ffffff;
}
.btn-warning.btn-o {
  border: 1px solid #eea236;
  color: #eea236;
}
.btn-warning.btn-o:hover {
  color: #f2b865;
  border-color: #f2b865;
  background: none !important;
}
.btn-warning.btn-o:active,
.btn-warning.btn-o.active,
.btn-warning.btn-o.active:focus,
.btn-warning.btn-o:active:focus,
.btn-warning.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #c77c11 !important;
  color: #c77c11 !important;
}
.btn-warning.btn-o:focus {
  background-color: #f6ce94;
  border-color: #f6ce94;
  color: #f2b865;
}
.btn-warning.btn-o.disabled:hover,
.btn-warning.btn-o.disabled:focus,
.btn-warning.btn-o.disabled:active,
.btn-warning.btn-o.disabled.active,
.btn-warning.btn-o[disabled],
.btn-warning.btn-o[disabled]:hover,
.btn-warning.btn-o[disabled]:focus,
.btn-warning.btn-o[disabled]:active,
.btn-warning.btn-o[disabled].active,
fieldset[disabled] .btn-warning.btn-o:hover,
fieldset[disabled] .btn-warning.btn-o:focus,
fieldset[disabled] .btn-warning.btn-o:active,
fieldset[disabled] .btn-warning.btn-o.active {
  border-color: #f6ce94;
  color: #f6ce94;
}
.btn-warning.btn-o .caret {
  border-top-color: #eea236;
}
.btn-warning .caret {
  border-top-color: #ffffff;
}
.dropup .btn-warning .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-danger {
  background-color: #d43f3a;
  border-color: #d43f3a;
  color: #ffffff;
}
.open .btn-danger.dropdown-toggle {
  background-color: #e27c79;
  border-color: #e27c79;
}
.btn-danger:hover {
  background-color: #dd6864 !important;
  border-color: #dd6864;
  color: #ffffff;
}
.btn-danger:active,
.btn-danger.active,
.btn-danger.active:focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger.dropdown-toggle:active:hover {
  background-color: #c9312c !important;
  border-color: #c9312c;
}
.btn-danger:focus {
  background-color: #e27c79;
  border-color: #e27c79;
}
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #e6918e;
  border-color: #e6918e;
  color: #ffffff;
}
.btn-danger.btn-o {
  border: 1px solid #d43f3a;
  color: #d43f3a;
}
.btn-danger.btn-o:hover {
  color: #dd6864;
  border-color: #dd6864;
  background: none !important;
}
.btn-danger.btn-o:active,
.btn-danger.btn-o.active,
.btn-danger.btn-o.active:focus,
.btn-danger.btn-o:active:focus,
.btn-danger.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #9f2723 !important;
  color: #9f2723 !important;
}
.btn-danger.btn-o:focus {
  background-color: #e6918e;
  border-color: #e6918e;
  color: #dd6864;
}
.btn-danger.btn-o.disabled:hover,
.btn-danger.btn-o.disabled:focus,
.btn-danger.btn-o.disabled:active,
.btn-danger.btn-o.disabled.active,
.btn-danger.btn-o[disabled],
.btn-danger.btn-o[disabled]:hover,
.btn-danger.btn-o[disabled]:focus,
.btn-danger.btn-o[disabled]:active,
.btn-danger.btn-o[disabled].active,
fieldset[disabled] .btn-danger.btn-o:hover,
fieldset[disabled] .btn-danger.btn-o:focus,
fieldset[disabled] .btn-danger.btn-o:active,
fieldset[disabled] .btn-danger.btn-o.active {
  border-color: #e6918e;
  color: #e6918e;
}
.btn-danger.btn-o .caret {
  border-top-color: #d43f3a;
}
.btn-danger .caret {
  border-top-color: #ffffff;
}
.dropup .btn-danger .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-azure {
  background-color: #0095C8;
  border-color: #0095C8;
  color: #ffffff;
}
.open .btn-azure.dropdown-toggle {
  background-color: #16c3ff;
  border-color: #16c3ff;
}
.btn-azure:hover {
  background-color: #00bbfb !important;
  border-color: #00bbfb;
  color: #ffffff;
}
.btn-azure:active,
.btn-azure.active,
.btn-azure.active:focus,
.btn-azure:active:focus,
.btn-azure:active:hover,
.btn-azure.dropdown-toggle:active:hover {
  background-color: #0082af !important;
  border-color: #0082af;
}
.btn-azure:focus {
  background-color: #16c3ff;
  border-color: #16c3ff;
  color: #ffffff;
}
.btn-azure.disabled:hover,
.btn-azure.disabled:focus,
.btn-azure.disabled:active,
.btn-azure.disabled.active,
.btn-azure[disabled],
.btn-azure[disabled]:hover,
.btn-azure[disabled]:focus,
.btn-azure[disabled]:active,
.btn-azure[disabled].active,
fieldset[disabled] .btn-azure:hover,
fieldset[disabled] .btn-azure:focus,
fieldset[disabled] .btn-azure:active,
fieldset[disabled] .btn-azure.active {
  background-color: #2fcaff;
  border-color: #2fcaff;
  color: #ffffff;
}
.btn-azure.btn-o {
  border: 1px solid #0095C8;
  color: #0095C8;
}
.btn-azure.btn-o:hover {
  color: #00bbfb;
  border-color: #00bbfb;
  background: none !important;
}
.btn-azure.btn-o:active,
.btn-azure.btn-o.active,
.btn-azure.btn-o.active:focus,
.btn-azure.btn-o:active:focus,
.btn-azure.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #005c7c !important;
  color: #005c7c !important;
}
.btn-azure.btn-o:focus {
  background-color: #2fcaff;
  border-color: #2fcaff;
  color: #00bbfb;
}
.btn-azure.btn-o.disabled:hover,
.btn-azure.btn-o.disabled:focus,
.btn-azure.btn-o.disabled:active,
.btn-azure.btn-o.disabled.active,
.btn-azure.btn-o[disabled],
.btn-azure.btn-o[disabled]:hover,
.btn-azure.btn-o[disabled]:focus,
.btn-azure.btn-o[disabled]:active,
.btn-azure.btn-o[disabled].active,
fieldset[disabled] .btn-azure.btn-o:hover,
fieldset[disabled] .btn-azure.btn-o:focus,
fieldset[disabled] .btn-azure.btn-o:active,
fieldset[disabled] .btn-azure.btn-o.active {
  border-color: #2fcaff;
  color: #2fcaff;
}
.btn-azure.btn-o .caret {
  border-top-color: #0095C8;
}
.btn-azure .caret {
  border-top-color: #ffffff;
}
.dropup .btn-azure .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-azure {
  background-color: #00a8e2;
  border-color: #00a8e2;
  color: #ffffff;
}
.open .btn-light-azure.dropdown-toggle {
  background-color: #2fcaff;
  border-color: #2fcaff;
}
.btn-light-azure:hover {
  background-color: #16c3ff !important;
  border-color: #16c3ff;
  color: #ffffff;
}
.btn-light-azure:active,
.btn-light-azure.active,
.btn-light-azure.active:focus,
.btn-light-azure:active:focus,
.btn-light-azure:active:hover,
.btn-light-azure.dropdown-toggle:active:hover {
  background-color: #0095c8 !important;
  border-color: #0095c8;
}
.btn-light-azure:focus {
  background-color: #2fcaff;
  border-color: #2fcaff;
  color: #ffffff;
}
.btn-light-azure.disabled:hover,
.btn-light-azure.disabled:focus,
.btn-light-azure.disabled:active,
.btn-light-azure.disabled.active,
.btn-light-azure[disabled],
.btn-light-azure[disabled]:hover,
.btn-light-azure[disabled]:focus,
.btn-light-azure[disabled]:active,
.btn-light-azure[disabled].active,
fieldset[disabled] .btn-light-azure:hover,
fieldset[disabled] .btn-light-azure:focus,
fieldset[disabled] .btn-light-azure:active,
fieldset[disabled] .btn-light-azure.active {
  background-color: #48d0ff;
  border-color: #48d0ff;
  color: #ffffff;
}
.btn-light-azure.btn-o {
  border: 1px solid #00a8e2;
  color: #00a8e2;
}
.btn-light-azure.btn-o:hover {
  color: #16c3ff;
  border-color: #16c3ff;
  background: none !important;
}
.btn-light-azure.btn-o:active,
.btn-light-azure.btn-o.active,
.btn-light-azure.btn-o.active:focus,
.btn-light-azure.btn-o:active:focus,
.btn-light-azure.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #006f95 !important;
  color: #006f95 !important;
}
.btn-light-azure.btn-o:focus {
  background-color: #48d0ff;
  border-color: #48d0ff;
  color: #16c3ff;
}
.btn-light-azure.btn-o.disabled:hover,
.btn-light-azure.btn-o.disabled:focus,
.btn-light-azure.btn-o.disabled:active,
.btn-light-azure.btn-o.disabled.active,
.btn-light-azure.btn-o[disabled],
.btn-light-azure.btn-o[disabled]:hover,
.btn-light-azure.btn-o[disabled]:focus,
.btn-light-azure.btn-o[disabled]:active,
.btn-light-azure.btn-o[disabled].active,
fieldset[disabled] .btn-light-azure.btn-o:hover,
fieldset[disabled] .btn-light-azure.btn-o:focus,
fieldset[disabled] .btn-light-azure.btn-o:active,
fieldset[disabled] .btn-light-azure.btn-o.active {
  border-color: #48d0ff;
  color: #48d0ff;
}
.btn-light-azure.btn-o .caret {
  border-top-color: #00a8e2;
}
.btn-light-azure .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-azure .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-azure {
  background-color: #006f95;
  border-color: #006f95;
  color: #ffffff;
}
.open .btn-dark-azure.dropdown-toggle {
  background-color: #00a8e2;
  border-color: #00a8e2;
}
.btn-dark-azure:hover {
  background-color: #0095c8 !important;
  border-color: #0095c8;
  color: #ffffff;
}
.btn-dark-azure:active,
.btn-dark-azure.active,
.btn-dark-azure.active:focus,
.btn-dark-azure:active:focus,
.btn-dark-azure:active:hover,
.btn-dark-azure.dropdown-toggle:active:hover {
  background-color: #005c7b !important;
  border-color: #005c7b;
}
.btn-dark-azure:focus {
  background-color: #00a8e2;
  border-color: #00a8e2;
  color: #ffffff;
}
.btn-dark-azure.disabled:hover,
.btn-dark-azure.disabled:focus,
.btn-dark-azure.disabled:active,
.btn-dark-azure.disabled.active,
.btn-dark-azure[disabled],
.btn-dark-azure[disabled]:hover,
.btn-dark-azure[disabled]:focus,
.btn-dark-azure[disabled]:active,
.btn-dark-azure[disabled].active,
fieldset[disabled] .btn-dark-azure:hover,
fieldset[disabled] .btn-dark-azure:focus,
fieldset[disabled] .btn-dark-azure:active,
fieldset[disabled] .btn-dark-azure.active {
  background-color: #00bbfb;
  border-color: #00bbfb;
  color: #ffffff;
}
.btn-dark-azure.btn-o {
  border: 1px solid #006f95;
  color: #006f95;
}
.btn-dark-azure.btn-o:hover {
  color: #0095c8;
  border-color: #0095c8;
  background: none !important;
}
.btn-dark-azure.btn-o:active,
.btn-dark-azure.btn-o.active,
.btn-dark-azure.btn-o.active:focus,
.btn-dark-azure.btn-o:active:focus,
.btn-dark-azure.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #003648 !important;
  color: #003648 !important;
}
.btn-dark-azure.btn-o:focus {
  background-color: #00bbfb;
  border-color: #00bbfb;
  color: #0095c8;
}
.btn-dark-azure.btn-o.disabled:hover,
.btn-dark-azure.btn-o.disabled:focus,
.btn-dark-azure.btn-o.disabled:active,
.btn-dark-azure.btn-o.disabled.active,
.btn-dark-azure.btn-o[disabled],
.btn-dark-azure.btn-o[disabled]:hover,
.btn-dark-azure.btn-o[disabled]:focus,
.btn-dark-azure.btn-o[disabled]:active,
.btn-dark-azure.btn-o[disabled].active,
fieldset[disabled] .btn-dark-azure.btn-o:hover,
fieldset[disabled] .btn-dark-azure.btn-o:focus,
fieldset[disabled] .btn-dark-azure.btn-o:active,
fieldset[disabled] .btn-dark-azure.btn-o.active {
  border-color: #00bbfb;
  color: #00bbfb;
}
.btn-dark-azure.btn-o .caret {
  border-top-color: #006f95;
}
.btn-dark-azure .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-azure .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-blue {
  background-color: #5F8295;
  border-color: #5F8295;
  color: #ffffff;
}
.open .btn-blue.dropdown-toggle {
  background-color: #8ba6b5;
  border-color: #8ba6b5;
}
.btn-blue:hover {
  background-color: #7c9bab !important;
  border-color: #7c9bab;
  color: #ffffff;
}
.btn-blue:active,
.btn-blue.active,
.btn-blue.active:focus,
.btn-blue:active:focus,
.btn-blue:active:hover,
.btn-blue.dropdown-toggle:active:hover {
  background-color: #557485 !important;
  border-color: #557485;
}
.btn-blue:focus {
  background-color: #8ba6b5;
  border-color: #8ba6b5;
  color: #ffffff;
}
.btn-blue.disabled:hover,
.btn-blue.disabled:focus,
.btn-blue.disabled:active,
.btn-blue.disabled.active,
.btn-blue[disabled],
.btn-blue[disabled]:hover,
.btn-blue[disabled]:focus,
.btn-blue[disabled]:active,
.btn-blue[disabled].active,
fieldset[disabled] .btn-blue:hover,
fieldset[disabled] .btn-blue:focus,
fieldset[disabled] .btn-blue:active,
fieldset[disabled] .btn-blue.active {
  background-color: #9bb2bf;
  border-color: #9bb2bf;
  color: #ffffff;
}
.btn-blue.btn-o {
  border: 1px solid #5F8295;
  color: #5F8295;
}
.btn-blue.btn-o:hover {
  color: #7c9bab;
  border-color: #7c9bab;
  background: none !important;
}
.btn-blue.btn-o:active,
.btn-blue.btn-o.active,
.btn-blue.btn-o.active:focus,
.btn-blue.btn-o:active:focus,
.btn-blue.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #415966 !important;
  color: #415966 !important;
}
.btn-blue.btn-o:focus {
  background-color: #9bb2bf;
  border-color: #9bb2bf;
  color: #7c9bab;
}
.btn-blue.btn-o.disabled:hover,
.btn-blue.btn-o.disabled:focus,
.btn-blue.btn-o.disabled:active,
.btn-blue.btn-o.disabled.active,
.btn-blue.btn-o[disabled],
.btn-blue.btn-o[disabled]:hover,
.btn-blue.btn-o[disabled]:focus,
.btn-blue.btn-o[disabled]:active,
.btn-blue.btn-o[disabled].active,
fieldset[disabled] .btn-blue.btn-o:hover,
fieldset[disabled] .btn-blue.btn-o:focus,
fieldset[disabled] .btn-blue.btn-o:active,
fieldset[disabled] .btn-blue.btn-o.active {
  border-color: #9bb2bf;
  color: #9bb2bf;
}
.btn-blue.btn-o .caret {
  border-top-color: #5F8295;
}
.btn-blue .caret {
  border-top-color: #ffffff;
}
.dropup .btn-blue .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-blue {
  background-color: #7c9bab;
  border-color: #7c9bab;
  color: #ffffff;
}
.open .btn-light-blue.dropdown-toggle {
  background-color: #aabec9;
  border-color: #aabec9;
}
.btn-light-blue:hover {
  background-color: #9bb2bf !important;
  border-color: #9bb2bf;
  color: #ffffff;
}
.btn-light-blue:active,
.btn-light-blue.active,
.btn-light-blue.active:focus,
.btn-light-blue:active:focus,
.btn-light-blue:active:hover,
.btn-light-blue.dropdown-toggle:active:hover {
  background-color: #6c8fa1 !important;
  border-color: #6c8fa1;
}
.btn-light-blue:focus {
  background-color: #aabec9;
  border-color: #aabec9;
  color: #ffffff;
}
.btn-light-blue.disabled:hover,
.btn-light-blue.disabled:focus,
.btn-light-blue.disabled:active,
.btn-light-blue.disabled.active,
.btn-light-blue[disabled],
.btn-light-blue[disabled]:hover,
.btn-light-blue[disabled]:focus,
.btn-light-blue[disabled]:active,
.btn-light-blue[disabled].active,
fieldset[disabled] .btn-light-blue:hover,
fieldset[disabled] .btn-light-blue:focus,
fieldset[disabled] .btn-light-blue:active,
fieldset[disabled] .btn-light-blue.active {
  background-color: #bacad3;
  border-color: #bacad3;
  color: #ffffff;
}
.btn-light-blue.btn-o {
  border: 1px solid #7c9bab;
  color: #7c9bab;
}
.btn-light-blue.btn-o:hover {
  color: #9bb2bf;
  border-color: #9bb2bf;
  background: none !important;
}
.btn-light-blue.btn-o:active,
.btn-light-blue.btn-o.active,
.btn-light-blue.btn-o.active:focus,
.btn-light-blue.btn-o:active:focus,
.btn-light-blue.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #557485 !important;
  color: #557485 !important;
}
.btn-light-blue.btn-o:focus {
  background-color: #bacad3;
  border-color: #bacad3;
  color: #9bb2bf;
}
.btn-light-blue.btn-o.disabled:hover,
.btn-light-blue.btn-o.disabled:focus,
.btn-light-blue.btn-o.disabled:active,
.btn-light-blue.btn-o.disabled.active,
.btn-light-blue.btn-o[disabled],
.btn-light-blue.btn-o[disabled]:hover,
.btn-light-blue.btn-o[disabled]:focus,
.btn-light-blue.btn-o[disabled]:active,
.btn-light-blue.btn-o[disabled].active,
fieldset[disabled] .btn-light-blue.btn-o:hover,
fieldset[disabled] .btn-light-blue.btn-o:focus,
fieldset[disabled] .btn-light-blue.btn-o:active,
fieldset[disabled] .btn-light-blue.btn-o.active {
  border-color: #bacad3;
  color: #bacad3;
}
.btn-light-blue.btn-o .caret {
  border-top-color: #7c9bab;
}
.btn-light-blue .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-blue .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-blue {
  background-color: #4b6776;
  border-color: #4b6776;
  color: #ffffff;
}
.open .btn-dark-blue.dropdown-toggle {
  background-color: #6c8fa1;
  border-color: #6c8fa1;
}
.btn-dark-blue:hover {
  background-color: #5f8295 !important;
  border-color: #5f8295;
  color: #ffffff;
}
.btn-dark-blue:active,
.btn-dark-blue.active,
.btn-dark-blue.active:focus,
.btn-dark-blue:active:focus,
.btn-dark-blue:active:hover,
.btn-dark-blue.dropdown-toggle:active:hover {
  background-color: #415966 !important;
  border-color: #415966;
}
.btn-dark-blue:focus {
  background-color: #6c8fa1;
  border-color: #6c8fa1;
  color: #ffffff;
}
.btn-dark-blue.disabled:hover,
.btn-dark-blue.disabled:focus,
.btn-dark-blue.disabled:active,
.btn-dark-blue.disabled.active,
.btn-dark-blue[disabled],
.btn-dark-blue[disabled]:hover,
.btn-dark-blue[disabled]:focus,
.btn-dark-blue[disabled]:active,
.btn-dark-blue[disabled].active,
fieldset[disabled] .btn-dark-blue:hover,
fieldset[disabled] .btn-dark-blue:focus,
fieldset[disabled] .btn-dark-blue:active,
fieldset[disabled] .btn-dark-blue.active {
  background-color: #7c9bab;
  border-color: #7c9bab;
  color: #ffffff;
}
.btn-dark-blue.btn-o {
  border: 1px solid #4b6776;
  color: #4b6776;
}
.btn-dark-blue.btn-o:hover {
  color: #5f8295;
  border-color: #5f8295;
  background: none !important;
}
.btn-dark-blue.btn-o:active,
.btn-dark-blue.btn-o.active,
.btn-dark-blue.btn-o.active:focus,
.btn-dark-blue.btn-o:active:focus,
.btn-dark-blue.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #2d3e47 !important;
  color: #2d3e47 !important;
}
.btn-dark-blue.btn-o:focus {
  background-color: #7c9bab;
  border-color: #7c9bab;
  color: #5f8295;
}
.btn-dark-blue.btn-o.disabled:hover,
.btn-dark-blue.btn-o.disabled:focus,
.btn-dark-blue.btn-o.disabled:active,
.btn-dark-blue.btn-o.disabled.active,
.btn-dark-blue.btn-o[disabled],
.btn-dark-blue.btn-o[disabled]:hover,
.btn-dark-blue.btn-o[disabled]:focus,
.btn-dark-blue.btn-o[disabled]:active,
.btn-dark-blue.btn-o[disabled].active,
fieldset[disabled] .btn-dark-blue.btn-o:hover,
fieldset[disabled] .btn-dark-blue.btn-o:focus,
fieldset[disabled] .btn-dark-blue.btn-o:active,
fieldset[disabled] .btn-dark-blue.btn-o.active {
  border-color: #7c9bab;
  color: #7c9bab;
}
.btn-dark-blue.btn-o .caret {
  border-top-color: #4b6776;
}
.btn-dark-blue .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-blue .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-green {
  background-color: #1FBBA6;
  border-color: #1FBBA6;
  color: #ffffff;
}
.open .btn-green.dropdown-toggle {
  background-color: #46e0cc;
  border-color: #46e0cc;
}
.btn-green:hover {
  background-color: #30ddc6 !important;
  border-color: #30ddc6;
  color: #ffffff;
}
.btn-green:active,
.btn-green.active,
.btn-green.active:focus,
.btn-green:active:focus,
.btn-green:active:hover,
.btn-green.dropdown-toggle:active:hover {
  background-color: #1ba593 !important;
  border-color: #1ba593;
}
.btn-green:focus {
  background-color: #46e0cc;
  border-color: #46e0cc;
  color: #ffffff;
}
.btn-green.disabled:hover,
.btn-green.disabled:focus,
.btn-green.disabled:active,
.btn-green.disabled.active,
.btn-green[disabled],
.btn-green[disabled]:hover,
.btn-green[disabled]:focus,
.btn-green[disabled]:active,
.btn-green[disabled].active,
fieldset[disabled] .btn-green:hover,
fieldset[disabled] .btn-green:focus,
fieldset[disabled] .btn-green:active,
fieldset[disabled] .btn-green.active {
  background-color: #5ce4d2;
  border-color: #5ce4d2;
  color: #ffffff;
}
.btn-green.btn-o {
  border: 1px solid #1FBBA6;
  color: #1FBBA6;
}
.btn-green.btn-o:hover {
  color: #30ddc6;
  border-color: #30ddc6;
  background: none !important;
}
.btn-green.btn-o:active,
.btn-green.btn-o.active,
.btn-green.btn-o.active:focus,
.btn-green.btn-o:active:focus,
.btn-green.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #14796c !important;
  color: #14796c !important;
}
.btn-green.btn-o:focus {
  background-color: #5ce4d2;
  border-color: #5ce4d2;
  color: #30ddc6;
}
.btn-green.btn-o.disabled:hover,
.btn-green.btn-o.disabled:focus,
.btn-green.btn-o.disabled:active,
.btn-green.btn-o.disabled.active,
.btn-green.btn-o[disabled],
.btn-green.btn-o[disabled]:hover,
.btn-green.btn-o[disabled]:focus,
.btn-green.btn-o[disabled]:active,
.btn-green.btn-o[disabled].active,
fieldset[disabled] .btn-green.btn-o:hover,
fieldset[disabled] .btn-green.btn-o:focus,
fieldset[disabled] .btn-green.btn-o:active,
fieldset[disabled] .btn-green.btn-o.active {
  border-color: #5ce4d2;
  color: #5ce4d2;
}
.btn-green.btn-o .caret {
  border-top-color: #1FBBA6;
}
.btn-green .caret {
  border-top-color: #ffffff;
}
.dropup .btn-green .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-green {
  background-color: #30ddc6;
  border-color: #30ddc6;
  color: #ffffff;
}
.open .btn-light-green.dropdown-toggle {
  background-color: #72e8d8;
  border-color: #72e8d8;
}
.btn-light-green:hover {
  background-color: #5ce4d2 !important;
  border-color: #5ce4d2;
  color: #ffffff;
}
.btn-light-green:active,
.btn-light-green.active,
.btn-light-green.active:focus,
.btn-light-green:active:focus,
.btn-light-green:active:hover,
.btn-light-green.dropdown-toggle:active:hover {
  background-color: #23d1b9 !important;
  border-color: #23d1b9;
}
.btn-light-green:focus {
  background-color: #72e8d8;
  border-color: #72e8d8;
  color: #ffffff;
}
.btn-light-green.disabled:hover,
.btn-light-green.disabled:focus,
.btn-light-green.disabled:active,
.btn-light-green.disabled.active,
.btn-light-green[disabled],
.btn-light-green[disabled]:hover,
.btn-light-green[disabled]:focus,
.btn-light-green[disabled]:active,
.btn-light-green[disabled].active,
fieldset[disabled] .btn-light-green:hover,
fieldset[disabled] .btn-light-green:focus,
fieldset[disabled] .btn-light-green:active,
fieldset[disabled] .btn-light-green.active {
  background-color: #88ebde;
  border-color: #88ebde;
  color: #ffffff;
}
.btn-light-green.btn-o {
  border: 1px solid #30ddc6;
  color: #30ddc6;
}
.btn-light-green.btn-o:hover {
  color: #5ce4d2;
  border-color: #5ce4d2;
  background: none !important;
}
.btn-light-green.btn-o:active,
.btn-light-green.btn-o.active,
.btn-light-green.btn-o.active:focus,
.btn-light-green.btn-o:active:focus,
.btn-light-green.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #1ba593 !important;
  color: #1ba593 !important;
}
.btn-light-green.btn-o:focus {
  background-color: #88ebde;
  border-color: #88ebde;
  color: #5ce4d2;
}
.btn-light-green.btn-o.disabled:hover,
.btn-light-green.btn-o.disabled:focus,
.btn-light-green.btn-o.disabled:active,
.btn-light-green.btn-o.disabled.active,
.btn-light-green.btn-o[disabled],
.btn-light-green.btn-o[disabled]:hover,
.btn-light-green.btn-o[disabled]:focus,
.btn-light-green.btn-o[disabled]:active,
.btn-light-green.btn-o[disabled].active,
fieldset[disabled] .btn-light-green.btn-o:hover,
fieldset[disabled] .btn-light-green.btn-o:focus,
fieldset[disabled] .btn-light-green.btn-o:active,
fieldset[disabled] .btn-light-green.btn-o.active {
  border-color: #88ebde;
  color: #88ebde;
}
.btn-light-green.btn-o .caret {
  border-top-color: #30ddc6;
}
.btn-light-green .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-green .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-green {
  background-color: #188f7f;
  border-color: #188f7f;
  color: #ffffff;
}
.open .btn-dark-green.dropdown-toggle {
  background-color: #23d1b9;
  border-color: #23d1b9;
}
.btn-dark-green:hover {
  background-color: #1fbba6 !important;
  border-color: #1fbba6;
  color: #ffffff;
}
.btn-dark-green:active,
.btn-dark-green.active,
.btn-dark-green.active:focus,
.btn-dark-green:active:focus,
.btn-dark-green:active:hover,
.btn-dark-green.dropdown-toggle:active:hover {
  background-color: #14796c !important;
  border-color: #14796c;
}
.btn-dark-green:focus {
  background-color: #23d1b9;
  border-color: #23d1b9;
  color: #ffffff;
}
.btn-dark-green.disabled:hover,
.btn-dark-green.disabled:focus,
.btn-dark-green.disabled:active,
.btn-dark-green.disabled.active,
.btn-dark-green[disabled],
.btn-dark-green[disabled]:hover,
.btn-dark-green[disabled]:focus,
.btn-dark-green[disabled]:active,
.btn-dark-green[disabled].active,
fieldset[disabled] .btn-dark-green:hover,
fieldset[disabled] .btn-dark-green:focus,
fieldset[disabled] .btn-dark-green:active,
fieldset[disabled] .btn-dark-green.active {
  background-color: #30ddc6;
  border-color: #30ddc6;
  color: #ffffff;
}
.btn-dark-green.btn-o {
  border: 1px solid #188f7f;
  color: #188f7f;
}
.btn-dark-green.btn-o:hover {
  color: #1fbba6;
  border-color: #1fbba6;
  background: none !important;
}
.btn-dark-green.btn-o:active,
.btn-dark-green.btn-o.active,
.btn-dark-green.btn-o.active:focus,
.btn-dark-green.btn-o:active:focus,
.btn-dark-green.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #0d4e45 !important;
  color: #0d4e45 !important;
}
.btn-dark-green.btn-o:focus {
  background-color: #30ddc6;
  border-color: #30ddc6;
  color: #1fbba6;
}
.btn-dark-green.btn-o.disabled:hover,
.btn-dark-green.btn-o.disabled:focus,
.btn-dark-green.btn-o.disabled:active,
.btn-dark-green.btn-o.disabled.active,
.btn-dark-green.btn-o[disabled],
.btn-dark-green.btn-o[disabled]:hover,
.btn-dark-green.btn-o[disabled]:focus,
.btn-dark-green.btn-o[disabled]:active,
.btn-dark-green.btn-o[disabled].active,
fieldset[disabled] .btn-dark-green.btn-o:hover,
fieldset[disabled] .btn-dark-green.btn-o:focus,
fieldset[disabled] .btn-dark-green.btn-o:active,
fieldset[disabled] .btn-dark-green.btn-o.active {
  border-color: #30ddc6;
  color: #30ddc6;
}
.btn-dark-green.btn-o .caret {
  border-top-color: #188f7f;
}
.btn-dark-green .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-green .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-orange {
  background-color: #FF6600;
  border-color: #FF6600;
  color: #ffffff;
}
.open .btn-orange.dropdown-toggle {
  background-color: #ff944d;
  border-color: #ff944d;
}
.btn-orange:hover {
  background-color: #ff8533 !important;
  border-color: #ff8533;
  color: #ffffff;
}
.btn-orange:active,
.btn-orange.active,
.btn-orange.active:focus,
.btn-orange:active:focus,
.btn-orange:active:hover,
.btn-orange.dropdown-toggle:active:hover {
  background-color: #e65c00 !important;
  border-color: #e65c00;
}
.btn-orange:focus {
  background-color: #ff944d;
  border-color: #ff944d;
  color: #ffffff;
}
.btn-orange.disabled:hover,
.btn-orange.disabled:focus,
.btn-orange.disabled:active,
.btn-orange.disabled.active,
.btn-orange[disabled],
.btn-orange[disabled]:hover,
.btn-orange[disabled]:focus,
.btn-orange[disabled]:active,
.btn-orange[disabled].active,
fieldset[disabled] .btn-orange:hover,
fieldset[disabled] .btn-orange:focus,
fieldset[disabled] .btn-orange:active,
fieldset[disabled] .btn-orange.active {
  background-color: #ffa366;
  border-color: #ffa366;
  color: #ffffff;
}
.btn-orange.btn-o {
  border: 1px solid #FF6600;
  color: #FF6600;
}
.btn-orange.btn-o:hover {
  color: #ff8533;
  border-color: #ff8533;
  background: none !important;
}
.btn-orange.btn-o:active,
.btn-orange.btn-o.active,
.btn-orange.btn-o.active:focus,
.btn-orange.btn-o:active:focus,
.btn-orange.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #b34700 !important;
  color: #b34700 !important;
}
.btn-orange.btn-o:focus {
  background-color: #ffa366;
  border-color: #ffa366;
  color: #ff8533;
}
.btn-orange.btn-o.disabled:hover,
.btn-orange.btn-o.disabled:focus,
.btn-orange.btn-o.disabled:active,
.btn-orange.btn-o.disabled.active,
.btn-orange.btn-o[disabled],
.btn-orange.btn-o[disabled]:hover,
.btn-orange.btn-o[disabled]:focus,
.btn-orange.btn-o[disabled]:active,
.btn-orange.btn-o[disabled].active,
fieldset[disabled] .btn-orange.btn-o:hover,
fieldset[disabled] .btn-orange.btn-o:focus,
fieldset[disabled] .btn-orange.btn-o:active,
fieldset[disabled] .btn-orange.btn-o.active {
  border-color: #ffa366;
  color: #ffa366;
}
.btn-orange.btn-o .caret {
  border-top-color: #FF6600;
}
.btn-orange .caret {
  border-top-color: #ffffff;
}
.dropup .btn-orange .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-orange {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #ffffff;
}
.open .btn-light-orange.dropdown-toggle {
  background-color: #ffb380;
  border-color: #ffb380;
}
.btn-light-orange:hover {
  background-color: #ffa366 !important;
  border-color: #ffa366;
  color: #ffffff;
}
.btn-light-orange:active,
.btn-light-orange.active,
.btn-light-orange.active:focus,
.btn-light-orange:active:focus,
.btn-light-orange:active:hover,
.btn-light-orange.dropdown-toggle:active:hover {
  background-color: #ff7519 !important;
  border-color: #ff7519;
}
.btn-light-orange:focus {
  background-color: #ffb380;
  border-color: #ffb380;
  color: #ffffff;
}
.btn-light-orange.disabled:hover,
.btn-light-orange.disabled:focus,
.btn-light-orange.disabled:active,
.btn-light-orange.disabled.active,
.btn-light-orange[disabled],
.btn-light-orange[disabled]:hover,
.btn-light-orange[disabled]:focus,
.btn-light-orange[disabled]:active,
.btn-light-orange[disabled].active,
fieldset[disabled] .btn-light-orange:hover,
fieldset[disabled] .btn-light-orange:focus,
fieldset[disabled] .btn-light-orange:active,
fieldset[disabled] .btn-light-orange.active {
  background-color: #ffc299;
  border-color: #ffc299;
  color: #ffffff;
}
.btn-light-orange.btn-o {
  border: 1px solid #ff8533;
  color: #ff8533;
}
.btn-light-orange.btn-o:hover {
  color: #ffa366;
  border-color: #ffa366;
  background: none !important;
}
.btn-light-orange.btn-o:active,
.btn-light-orange.btn-o.active,
.btn-light-orange.btn-o.active:focus,
.btn-light-orange.btn-o:active:focus,
.btn-light-orange.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #e55c00 !important;
  color: #e55c00 !important;
}
.btn-light-orange.btn-o:focus {
  background-color: #ffc299;
  border-color: #ffc299;
  color: #ffa366;
}
.btn-light-orange.btn-o.disabled:hover,
.btn-light-orange.btn-o.disabled:focus,
.btn-light-orange.btn-o.disabled:active,
.btn-light-orange.btn-o.disabled.active,
.btn-light-orange.btn-o[disabled],
.btn-light-orange.btn-o[disabled]:hover,
.btn-light-orange.btn-o[disabled]:focus,
.btn-light-orange.btn-o[disabled]:active,
.btn-light-orange.btn-o[disabled].active,
fieldset[disabled] .btn-light-orange.btn-o:hover,
fieldset[disabled] .btn-light-orange.btn-o:focus,
fieldset[disabled] .btn-light-orange.btn-o:active,
fieldset[disabled] .btn-light-orange.btn-o.active {
  border-color: #ffc299;
  color: #ffc299;
}
.btn-light-orange.btn-o .caret {
  border-top-color: #ff8533;
}
.btn-light-orange .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-orange .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-orange {
  background-color: #cc5200;
  border-color: #cc5200;
  color: #ffffff;
}
.open .btn-dark-orange.dropdown-toggle {
  background-color: #ff751a;
  border-color: #ff751a;
}
.btn-dark-orange:hover {
  background-color: #ff6600 !important;
  border-color: #ff6600;
  color: #ffffff;
}
.btn-dark-orange:active,
.btn-dark-orange.active,
.btn-dark-orange.active:focus,
.btn-dark-orange:active:focus,
.btn-dark-orange:active:hover,
.btn-dark-orange.dropdown-toggle:active:hover {
  background-color: #b34700 !important;
  border-color: #b34700;
}
.btn-dark-orange:focus {
  background-color: #ff751a;
  border-color: #ff751a;
  color: #ffffff;
}
.btn-dark-orange.disabled:hover,
.btn-dark-orange.disabled:focus,
.btn-dark-orange.disabled:active,
.btn-dark-orange.disabled.active,
.btn-dark-orange[disabled],
.btn-dark-orange[disabled]:hover,
.btn-dark-orange[disabled]:focus,
.btn-dark-orange[disabled]:active,
.btn-dark-orange[disabled].active,
fieldset[disabled] .btn-dark-orange:hover,
fieldset[disabled] .btn-dark-orange:focus,
fieldset[disabled] .btn-dark-orange:active,
fieldset[disabled] .btn-dark-orange.active {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #ffffff;
}
.btn-dark-orange.btn-o {
  border: 1px solid #cc5200;
  color: #cc5200;
}
.btn-dark-orange.btn-o:hover {
  color: #ff6600;
  border-color: #ff6600;
  background: none !important;
}
.btn-dark-orange.btn-o:active,
.btn-dark-orange.btn-o.active,
.btn-dark-orange.btn-o.active:focus,
.btn-dark-orange.btn-o:active:focus,
.btn-dark-orange.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #803300 !important;
  color: #803300 !important;
}
.btn-dark-orange.btn-o:focus {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #ff6600;
}
.btn-dark-orange.btn-o.disabled:hover,
.btn-dark-orange.btn-o.disabled:focus,
.btn-dark-orange.btn-o.disabled:active,
.btn-dark-orange.btn-o.disabled.active,
.btn-dark-orange.btn-o[disabled],
.btn-dark-orange.btn-o[disabled]:hover,
.btn-dark-orange.btn-o[disabled]:focus,
.btn-dark-orange.btn-o[disabled]:active,
.btn-dark-orange.btn-o[disabled].active,
fieldset[disabled] .btn-dark-orange.btn-o:hover,
fieldset[disabled] .btn-dark-orange.btn-o:focus,
fieldset[disabled] .btn-dark-orange.btn-o:active,
fieldset[disabled] .btn-dark-orange.btn-o.active {
  border-color: #ff8533;
  color: #ff8533;
}
.btn-dark-orange.btn-o .caret {
  border-top-color: #cc5200;
}
.btn-dark-orange .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-orange .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-red {
  background-color: #C82E29;
  border-color: #C82E29;
  color: #ffffff;
}
.open .btn-red.dropdown-toggle {
  background-color: #de635f;
  border-color: #de635f;
}
.btn-red:hover {
  background-color: #da4f4a !important;
  border-color: #da4f4a;
  color: #ffffff;
}
.btn-red:active,
.btn-red.active,
.btn-red.active:focus,
.btn-red:active:focus,
.btn-red:active:hover,
.btn-red.dropdown-toggle:active:hover {
  background-color: #b32925 !important;
  border-color: #b32925;
}
.btn-red:focus {
  background-color: #de635f;
  border-color: #de635f;
  color: #ffffff;
}
.btn-red.disabled:hover,
.btn-red.disabled:focus,
.btn-red.disabled:active,
.btn-red.disabled.active,
.btn-red[disabled],
.btn-red[disabled]:hover,
.btn-red[disabled]:focus,
.btn-red[disabled]:active,
.btn-red[disabled].active,
fieldset[disabled] .btn-red:hover,
fieldset[disabled] .btn-red:focus,
fieldset[disabled] .btn-red:active,
fieldset[disabled] .btn-red.active {
  background-color: #e37874;
  border-color: #e37874;
  color: #ffffff;
}
.btn-red.btn-o {
  border: 1px solid #C82E29;
  color: #C82E29;
}
.btn-red.btn-o:hover {
  color: #da4f4a;
  border-color: #da4f4a;
  background: none !important;
}
.btn-red.btn-o:active,
.btn-red.btn-o.active,
.btn-red.btn-o.active:focus,
.btn-red.btn-o:active:focus,
.btn-red.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #891f1c !important;
  color: #891f1c !important;
}
.btn-red.btn-o:focus {
  background-color: #e37874;
  border-color: #e37874;
  color: #da4f4a;
}
.btn-red.btn-o.disabled:hover,
.btn-red.btn-o.disabled:focus,
.btn-red.btn-o.disabled:active,
.btn-red.btn-o.disabled.active,
.btn-red.btn-o[disabled],
.btn-red.btn-o[disabled]:hover,
.btn-red.btn-o[disabled]:focus,
.btn-red.btn-o[disabled]:active,
.btn-red.btn-o[disabled].active,
fieldset[disabled] .btn-red.btn-o:hover,
fieldset[disabled] .btn-red.btn-o:focus,
fieldset[disabled] .btn-red.btn-o:active,
fieldset[disabled] .btn-red.btn-o.active {
  border-color: #e37874;
  color: #e37874;
}
.btn-red.btn-o .caret {
  border-top-color: #C82E29;
}
.btn-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-red .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-red {
  background-color: #da4f4a;
  border-color: #da4f4a;
  color: #ffffff;
}
.open .btn-light-red.dropdown-toggle {
  background-color: #e78d8a;
  border-color: #e78d8a;
}
.btn-light-red:hover {
  background-color: #e37874 !important;
  border-color: #e37874;
  color: #ffffff;
}
.btn-light-red:active,
.btn-light-red.active,
.btn-light-red.active:focus,
.btn-light-red:active:focus,
.btn-light-red:active:hover,
.btn-light-red.dropdown-toggle:active:hover {
  background-color: #d63a35 !important;
  border-color: #d63a35;
}
.btn-light-red:focus {
  background-color: #e78d8a;
  border-color: #e78d8a;
  color: #ffffff;
}
.btn-light-red.disabled:hover,
.btn-light-red.disabled:focus,
.btn-light-red.disabled:active,
.btn-light-red.disabled.active,
.btn-light-red[disabled],
.btn-light-red[disabled]:hover,
.btn-light-red[disabled]:focus,
.btn-light-red[disabled]:active,
.btn-light-red[disabled].active,
fieldset[disabled] .btn-light-red:hover,
fieldset[disabled] .btn-light-red:focus,
fieldset[disabled] .btn-light-red:active,
fieldset[disabled] .btn-light-red.active {
  background-color: #eba19f;
  border-color: #eba19f;
  color: #ffffff;
}
.btn-light-red.btn-o {
  border: 1px solid #da4f4a;
  color: #da4f4a;
}
.btn-light-red.btn-o:hover {
  color: #e37874;
  border-color: #e37874;
  background: none !important;
}
.btn-light-red.btn-o:active,
.btn-light-red.btn-o.active,
.btn-light-red.btn-o.active:focus,
.btn-light-red.btn-o:active:focus,
.btn-light-red.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #b32925 !important;
  color: #b32925 !important;
}
.btn-light-red.btn-o:focus {
  background-color: #eba19f;
  border-color: #eba19f;
  color: #e37874;
}
.btn-light-red.btn-o.disabled:hover,
.btn-light-red.btn-o.disabled:focus,
.btn-light-red.btn-o.disabled:active,
.btn-light-red.btn-o.disabled.active,
.btn-light-red.btn-o[disabled],
.btn-light-red.btn-o[disabled]:hover,
.btn-light-red.btn-o[disabled]:focus,
.btn-light-red.btn-o[disabled]:active,
.btn-light-red.btn-o[disabled].active,
fieldset[disabled] .btn-light-red.btn-o:hover,
fieldset[disabled] .btn-light-red.btn-o:focus,
fieldset[disabled] .btn-light-red.btn-o:active,
fieldset[disabled] .btn-light-red.btn-o.active {
  border-color: #eba19f;
  color: #eba19f;
}
.btn-light-red.btn-o .caret {
  border-top-color: #da4f4a;
}
.btn-light-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-red .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-red {
  background-color: #9e2420;
  border-color: #9e2420;
  color: #ffffff;
}
.open .btn-dark-red.dropdown-toggle {
  background-color: #d63a35;
  border-color: #d63a35;
}
.btn-dark-red:hover {
  background-color: #c82e29 !important;
  border-color: #c82e29;
  color: #ffffff;
}
.btn-dark-red:active,
.btn-dark-red.active,
.btn-dark-red.active:focus,
.btn-dark-red:active:focus,
.btn-dark-red:active:hover,
.btn-dark-red.dropdown-toggle:active:hover {
  background-color: #891f1c !important;
  border-color: #891f1c;
}
.btn-dark-red:focus {
  background-color: #d63a35;
  border-color: #d63a35;
  color: #ffffff;
}
.btn-dark-red.disabled:hover,
.btn-dark-red.disabled:focus,
.btn-dark-red.disabled:active,
.btn-dark-red.disabled.active,
.btn-dark-red[disabled],
.btn-dark-red[disabled]:hover,
.btn-dark-red[disabled]:focus,
.btn-dark-red[disabled]:active,
.btn-dark-red[disabled].active,
fieldset[disabled] .btn-dark-red:hover,
fieldset[disabled] .btn-dark-red:focus,
fieldset[disabled] .btn-dark-red:active,
fieldset[disabled] .btn-dark-red.active {
  background-color: #da4f4a;
  border-color: #da4f4a;
  color: #ffffff;
}
.btn-dark-red.btn-o {
  border: 1px solid #9e2420;
  color: #9e2420;
}
.btn-dark-red.btn-o:hover {
  color: #c82e29;
  border-color: #c82e29;
  background: none !important;
}
.btn-dark-red.btn-o:active,
.btn-dark-red.btn-o.active,
.btn-dark-red.btn-o.active:focus,
.btn-dark-red.btn-o:active:focus,
.btn-dark-red.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #5e1613 !important;
  color: #5e1613 !important;
}
.btn-dark-red.btn-o:focus {
  background-color: #da4f4a;
  border-color: #da4f4a;
  color: #c82e29;
}
.btn-dark-red.btn-o.disabled:hover,
.btn-dark-red.btn-o.disabled:focus,
.btn-dark-red.btn-o.disabled:active,
.btn-dark-red.btn-o.disabled.active,
.btn-dark-red.btn-o[disabled],
.btn-dark-red.btn-o[disabled]:hover,
.btn-dark-red.btn-o[disabled]:focus,
.btn-dark-red.btn-o[disabled]:active,
.btn-dark-red.btn-o[disabled].active,
fieldset[disabled] .btn-dark-red.btn-o:hover,
fieldset[disabled] .btn-dark-red.btn-o:focus,
fieldset[disabled] .btn-dark-red.btn-o:active,
fieldset[disabled] .btn-dark-red.btn-o.active {
  border-color: #da4f4a;
  color: #da4f4a;
}
.btn-dark-red.btn-o .caret {
  border-top-color: #9e2420;
}
.btn-dark-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-red .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-purple {
  background-color: #804C75;
  border-color: #804C75;
  color: #ffffff;
}
.open .btn-purple.dropdown-toggle {
  background-color: #aa6f9d;
  border-color: #aa6f9d;
}
.btn-purple:hover {
  background-color: #a05f92 !important;
  border-color: #a05f92;
  color: #ffffff;
}
.btn-purple:active,
.btn-purple.active,
.btn-purple.active:focus,
.btn-purple:active:focus,
.btn-purple:active:hover,
.btn-purple.dropdown-toggle:active:hover {
  background-color: #704366 !important;
  border-color: #704366;
}
.btn-purple:focus {
  background-color: #aa6f9d;
  border-color: #aa6f9d;
  color: #ffffff;
}
.btn-purple.disabled:hover,
.btn-purple.disabled:focus,
.btn-purple.disabled:active,
.btn-purple.disabled.active,
.btn-purple[disabled],
.btn-purple[disabled]:hover,
.btn-purple[disabled]:focus,
.btn-purple[disabled]:active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple:hover,
fieldset[disabled] .btn-purple:focus,
fieldset[disabled] .btn-purple:active,
fieldset[disabled] .btn-purple.active {
  background-color: #b37fa8;
  border-color: #b37fa8;
  color: #ffffff;
}
.btn-purple.btn-o {
  border: 1px solid #804C75;
  color: #804C75;
}
.btn-purple.btn-o:hover {
  color: #a05f92;
  border-color: #a05f92;
  background: none !important;
}
.btn-purple.btn-o:active,
.btn-purple.btn-o.active,
.btn-purple.btn-o.active:focus,
.btn-purple.btn-o:active:focus,
.btn-purple.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #503049 !important;
  color: #503049 !important;
}
.btn-purple.btn-o:focus {
  background-color: #b37fa8;
  border-color: #b37fa8;
  color: #a05f92;
}
.btn-purple.btn-o.disabled:hover,
.btn-purple.btn-o.disabled:focus,
.btn-purple.btn-o.disabled:active,
.btn-purple.btn-o.disabled.active,
.btn-purple.btn-o[disabled],
.btn-purple.btn-o[disabled]:hover,
.btn-purple.btn-o[disabled]:focus,
.btn-purple.btn-o[disabled]:active,
.btn-purple.btn-o[disabled].active,
fieldset[disabled] .btn-purple.btn-o:hover,
fieldset[disabled] .btn-purple.btn-o:focus,
fieldset[disabled] .btn-purple.btn-o:active,
fieldset[disabled] .btn-purple.btn-o.active {
  border-color: #b37fa8;
  color: #b37fa8;
}
.btn-purple.btn-o .caret {
  border-top-color: #804C75;
}
.btn-purple .caret {
  border-top-color: #ffffff;
}
.dropup .btn-purple .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-purple {
  background-color: #a05f92;
  border-color: #a05f92;
  color: #ffffff;
}
.open .btn-light-purple.dropdown-toggle {
  background-color: #bd8fb3;
  border-color: #bd8fb3;
}
.btn-light-purple:hover {
  background-color: #b37fa8 !important;
  border-color: #b37fa8;
  color: #ffffff;
}
.btn-light-purple:active,
.btn-light-purple.active,
.btn-light-purple.active:focus,
.btn-light-purple:active:focus,
.btn-light-purple:active:hover,
.btn-light-purple.dropdown-toggle:active:hover {
  background-color: #905684 !important;
  border-color: #905684;
}
.btn-light-purple:focus {
  background-color: #bd8fb3;
  border-color: #bd8fb3;
  color: #ffffff;
}
.btn-light-purple.disabled:hover,
.btn-light-purple.disabled:focus,
.btn-light-purple.disabled:active,
.btn-light-purple.disabled.active,
.btn-light-purple[disabled],
.btn-light-purple[disabled]:hover,
.btn-light-purple[disabled]:focus,
.btn-light-purple[disabled]:active,
.btn-light-purple[disabled].active,
fieldset[disabled] .btn-light-purple:hover,
fieldset[disabled] .btn-light-purple:focus,
fieldset[disabled] .btn-light-purple:active,
fieldset[disabled] .btn-light-purple.active {
  background-color: #c69fbe;
  border-color: #c69fbe;
  color: #ffffff;
}
.btn-light-purple.btn-o {
  border: 1px solid #a05f92;
  color: #a05f92;
}
.btn-light-purple.btn-o:hover {
  color: #b37fa8;
  border-color: #b37fa8;
  background: none !important;
}
.btn-light-purple.btn-o:active,
.btn-light-purple.btn-o.active,
.btn-light-purple.btn-o.active:focus,
.btn-light-purple.btn-o:active:focus,
.btn-light-purple.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #704366 !important;
  color: #704366 !important;
}
.btn-light-purple.btn-o:focus {
  background-color: #c69fbe;
  border-color: #c69fbe;
  color: #b37fa8;
}
.btn-light-purple.btn-o.disabled:hover,
.btn-light-purple.btn-o.disabled:focus,
.btn-light-purple.btn-o.disabled:active,
.btn-light-purple.btn-o.disabled.active,
.btn-light-purple.btn-o[disabled],
.btn-light-purple.btn-o[disabled]:hover,
.btn-light-purple.btn-o[disabled]:focus,
.btn-light-purple.btn-o[disabled]:active,
.btn-light-purple.btn-o[disabled].active,
fieldset[disabled] .btn-light-purple.btn-o:hover,
fieldset[disabled] .btn-light-purple.btn-o:focus,
fieldset[disabled] .btn-light-purple.btn-o:active,
fieldset[disabled] .btn-light-purple.btn-o.active {
  border-color: #c69fbe;
  color: #c69fbe;
}
.btn-light-purple.btn-o .caret {
  border-top-color: #a05f92;
}
.btn-light-purple .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-purple .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-purple {
  background-color: #603958;
  border-color: #603958;
  color: #ffffff;
}
.open .btn-dark-purple.dropdown-toggle {
  background-color: #905684;
  border-color: #905684;
}
.btn-dark-purple:hover {
  background-color: #804c75 !important;
  border-color: #804c75;
  color: #ffffff;
}
.btn-dark-purple:active,
.btn-dark-purple.active,
.btn-dark-purple.active:focus,
.btn-dark-purple:active:focus,
.btn-dark-purple:active:hover,
.btn-dark-purple.dropdown-toggle:active:hover {
  background-color: #503049 !important;
  border-color: #503049;
}
.btn-dark-purple:focus {
  background-color: #905684;
  border-color: #905684;
  color: #ffffff;
}
.btn-dark-purple.disabled:hover,
.btn-dark-purple.disabled:focus,
.btn-dark-purple.disabled:active,
.btn-dark-purple.disabled.active,
.btn-dark-purple[disabled],
.btn-dark-purple[disabled]:hover,
.btn-dark-purple[disabled]:focus,
.btn-dark-purple[disabled]:active,
.btn-dark-purple[disabled].active,
fieldset[disabled] .btn-dark-purple:hover,
fieldset[disabled] .btn-dark-purple:focus,
fieldset[disabled] .btn-dark-purple:active,
fieldset[disabled] .btn-dark-purple.active {
  background-color: #a05f92;
  border-color: #a05f92;
  color: #ffffff;
}
.btn-dark-purple.btn-o {
  border: 1px solid #603958;
  color: #603958;
}
.btn-dark-purple.btn-o:hover {
  color: #804c75;
  border-color: #804c75;
  background: none !important;
}
.btn-dark-purple.btn-o:active,
.btn-dark-purple.btn-o.active,
.btn-dark-purple.btn-o.active:focus,
.btn-dark-purple.btn-o:active:focus,
.btn-dark-purple.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #301d2c !important;
  color: #301d2c !important;
}
.btn-dark-purple.btn-o:focus {
  background-color: #a05f92;
  border-color: #a05f92;
  color: #804c75;
}
.btn-dark-purple.btn-o.disabled:hover,
.btn-dark-purple.btn-o.disabled:focus,
.btn-dark-purple.btn-o.disabled:active,
.btn-dark-purple.btn-o.disabled.active,
.btn-dark-purple.btn-o[disabled],
.btn-dark-purple.btn-o[disabled]:hover,
.btn-dark-purple.btn-o[disabled]:focus,
.btn-dark-purple.btn-o[disabled]:active,
.btn-dark-purple.btn-o[disabled].active,
fieldset[disabled] .btn-dark-purple.btn-o:hover,
fieldset[disabled] .btn-dark-purple.btn-o:focus,
fieldset[disabled] .btn-dark-purple.btn-o:active,
fieldset[disabled] .btn-dark-purple.btn-o.active {
  border-color: #a05f92;
  color: #a05f92;
}
.btn-dark-purple.btn-o .caret {
  border-top-color: #603958;
}
.btn-dark-purple .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-purple .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-beige {
  background-color: #D2BD8E;
  border-color: #D2BD8E;
  color: #ffffff;
}
.open .btn-beige.dropdown-toggle {
  background-color: #e8ddc5;
  border-color: #e8ddc5;
}
.btn-beige:hover {
  background-color: #e1d2b2 !important;
  border-color: #e1d2b2;
  color: #ffffff;
}
.btn-beige:active,
.btn-beige.active,
.btn-beige.active:focus,
.btn-beige:active:focus,
.btn-beige:active:hover,
.btn-beige.dropdown-toggle:active:hover {
  background-color: #cbb27c !important;
  border-color: #cbb27c;
}
.btn-beige:focus {
  background-color: #e8ddc5;
  border-color: #e8ddc5;
  color: #ffffff;
}
.btn-beige.disabled:hover,
.btn-beige.disabled:focus,
.btn-beige.disabled:active,
.btn-beige.disabled.active,
.btn-beige[disabled],
.btn-beige[disabled]:hover,
.btn-beige[disabled]:focus,
.btn-beige[disabled]:active,
.btn-beige[disabled].active,
fieldset[disabled] .btn-beige:hover,
fieldset[disabled] .btn-beige:focus,
fieldset[disabled] .btn-beige:active,
fieldset[disabled] .btn-beige.active {
  background-color: #efe8d7;
  border-color: #efe8d7;
  color: #ffffff;
}
.btn-beige.btn-o {
  border: 1px solid #D2BD8E;
  color: #D2BD8E;
}
.btn-beige.btn-o:hover {
  color: #e1d2b2;
  border-color: #e1d2b2;
  background: none !important;
}
.btn-beige.btn-o:active,
.btn-beige.btn-o.active,
.btn-beige.btn-o.active:focus,
.btn-beige.btn-o:active:focus,
.btn-beige.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #bc9d57 !important;
  color: #bc9d57 !important;
}
.btn-beige.btn-o:focus {
  background-color: #efe8d7;
  border-color: #efe8d7;
  color: #e1d2b2;
}
.btn-beige.btn-o.disabled:hover,
.btn-beige.btn-o.disabled:focus,
.btn-beige.btn-o.disabled:active,
.btn-beige.btn-o.disabled.active,
.btn-beige.btn-o[disabled],
.btn-beige.btn-o[disabled]:hover,
.btn-beige.btn-o[disabled]:focus,
.btn-beige.btn-o[disabled]:active,
.btn-beige.btn-o[disabled].active,
fieldset[disabled] .btn-beige.btn-o:hover,
fieldset[disabled] .btn-beige.btn-o:focus,
fieldset[disabled] .btn-beige.btn-o:active,
fieldset[disabled] .btn-beige.btn-o.active {
  border-color: #efe8d7;
  color: #efe8d7;
}
.btn-beige.btn-o .caret {
  border-top-color: #D2BD8E;
}
.btn-beige .caret {
  border-top-color: #ffffff;
}
.dropup .btn-beige .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-beige {
  background-color: #d9c8a0;
  border-color: #d9c8a0;
  color: #ffffff;
}
.open .btn-light-beige.dropdown-toggle {
  background-color: #efe8d7;
  border-color: #efe8d7;
}
.btn-light-beige:hover {
  background-color: #e8ddc5 !important;
  border-color: #e8ddc5;
  color: #ffffff;
}
.btn-light-beige:active,
.btn-light-beige.active,
.btn-light-beige.active:focus,
.btn-light-beige:active:focus,
.btn-light-beige:active:hover,
.btn-light-beige.dropdown-toggle:active:hover {
  background-color: #d2bd8e !important;
  border-color: #d2bd8e;
}
.btn-light-beige:focus {
  background-color: #efe8d7;
  border-color: #efe8d7;
  color: #ffffff;
}
.btn-light-beige.disabled:hover,
.btn-light-beige.disabled:focus,
.btn-light-beige.disabled:active,
.btn-light-beige.disabled.active,
.btn-light-beige[disabled],
.btn-light-beige[disabled]:hover,
.btn-light-beige[disabled]:focus,
.btn-light-beige[disabled]:active,
.btn-light-beige[disabled].active,
fieldset[disabled] .btn-light-beige:hover,
fieldset[disabled] .btn-light-beige:focus,
fieldset[disabled] .btn-light-beige:active,
fieldset[disabled] .btn-light-beige.active {
  background-color: #f6f2e9;
  border-color: #f6f2e9;
  color: #ffffff;
}
.btn-light-beige.btn-o {
  border: 1px solid #d9c8a0;
  color: #d9c8a0;
}
.btn-light-beige.btn-o:hover {
  color: #e8ddc5;
  border-color: #e8ddc5;
  background: none !important;
}
.btn-light-beige.btn-o:active,
.btn-light-beige.btn-o.active,
.btn-light-beige.btn-o.active:focus,
.btn-light-beige.btn-o:active:focus,
.btn-light-beige.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #c3a86a !important;
  color: #c3a86a !important;
}
.btn-light-beige.btn-o:focus {
  background-color: #f6f2e9;
  border-color: #f6f2e9;
  color: #e8ddc5;
}
.btn-light-beige.btn-o.disabled:hover,
.btn-light-beige.btn-o.disabled:focus,
.btn-light-beige.btn-o.disabled:active,
.btn-light-beige.btn-o.disabled.active,
.btn-light-beige.btn-o[disabled],
.btn-light-beige.btn-o[disabled]:hover,
.btn-light-beige.btn-o[disabled]:focus,
.btn-light-beige.btn-o[disabled]:active,
.btn-light-beige.btn-o[disabled].active,
fieldset[disabled] .btn-light-beige.btn-o:hover,
fieldset[disabled] .btn-light-beige.btn-o:focus,
fieldset[disabled] .btn-light-beige.btn-o:active,
fieldset[disabled] .btn-light-beige.btn-o.active {
  border-color: #f6f2e9;
  color: #f6f2e9;
}
.btn-light-beige.btn-o .caret {
  border-top-color: #d9c8a0;
}
.btn-light-beige .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-beige .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-beige {
  background-color: #c3a86a;
  border-color: #c3a86a;
  color: #ffffff;
}
.open .btn-dark-beige.dropdown-toggle {
  background-color: #d9c8a0;
  border-color: #d9c8a0;
}
.btn-dark-beige:hover {
  background-color: #d2bd8e !important;
  border-color: #d2bd8e;
  color: #ffffff;
}
.btn-dark-beige:active,
.btn-dark-beige.active,
.btn-dark-beige.active:focus,
.btn-dark-beige:active:focus,
.btn-dark-beige:active:hover,
.btn-dark-beige.dropdown-toggle:active:hover {
  background-color: #bc9d57 !important;
  border-color: #bc9d57;
}
.btn-dark-beige:focus {
  background-color: #d9c8a0;
  border-color: #d9c8a0;
  color: #ffffff;
}
.btn-dark-beige.disabled:hover,
.btn-dark-beige.disabled:focus,
.btn-dark-beige.disabled:active,
.btn-dark-beige.disabled.active,
.btn-dark-beige[disabled],
.btn-dark-beige[disabled]:hover,
.btn-dark-beige[disabled]:focus,
.btn-dark-beige[disabled]:active,
.btn-dark-beige[disabled].active,
fieldset[disabled] .btn-dark-beige:hover,
fieldset[disabled] .btn-dark-beige:focus,
fieldset[disabled] .btn-dark-beige:active,
fieldset[disabled] .btn-dark-beige.active {
  background-color: #e1d2b2;
  border-color: #e1d2b2;
  color: #ffffff;
}
.btn-dark-beige.btn-o {
  border: 1px solid #c3a86a;
  color: #c3a86a;
}
.btn-dark-beige.btn-o:hover {
  color: #d2bd8e;
  border-color: #d2bd8e;
  background: none !important;
}
.btn-dark-beige.btn-o:active,
.btn-dark-beige.btn-o.active,
.btn-dark-beige.btn-o.active:focus,
.btn-dark-beige.btn-o:active:focus,
.btn-dark-beige.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #a18340 !important;
  color: #a18340 !important;
}
.btn-dark-beige.btn-o:focus {
  background-color: #e1d2b2;
  border-color: #e1d2b2;
  color: #d2bd8e;
}
.btn-dark-beige.btn-o.disabled:hover,
.btn-dark-beige.btn-o.disabled:focus,
.btn-dark-beige.btn-o.disabled:active,
.btn-dark-beige.btn-o.disabled.active,
.btn-dark-beige.btn-o[disabled],
.btn-dark-beige.btn-o[disabled]:hover,
.btn-dark-beige.btn-o[disabled]:focus,
.btn-dark-beige.btn-o[disabled]:active,
.btn-dark-beige.btn-o[disabled].active,
fieldset[disabled] .btn-dark-beige.btn-o:hover,
fieldset[disabled] .btn-dark-beige.btn-o:focus,
fieldset[disabled] .btn-dark-beige.btn-o:active,
fieldset[disabled] .btn-dark-beige.btn-o.active {
  border-color: #e1d2b2;
  color: #e1d2b2;
}
.btn-dark-beige.btn-o .caret {
  border-top-color: #c3a86a;
}
.btn-dark-beige .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-beige .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-yellow {
  background-color: #FFB848;
  border-color: #FFB848;
  color: #ffffff;
}
.open .btn-yellow.dropdown-toggle {
  background-color: #ffd695;
  border-color: #ffd695;
}
.btn-yellow:hover {
  background-color: #ffcc7b !important;
  border-color: #ffcc7b;
  color: #ffffff;
}
.btn-yellow:active,
.btn-yellow.active,
.btn-yellow.active:focus,
.btn-yellow:active:focus,
.btn-yellow:active:hover,
.btn-yellow.dropdown-toggle:active:hover {
  background-color: #ffae2e !important;
  border-color: #ffae2e;
}
.btn-yellow:focus {
  background-color: #ffd695;
  border-color: #ffd695;
  color: #ffffff;
}
.btn-yellow.disabled:hover,
.btn-yellow.disabled:focus,
.btn-yellow.disabled:active,
.btn-yellow.disabled.active,
.btn-yellow[disabled],
.btn-yellow[disabled]:hover,
.btn-yellow[disabled]:focus,
.btn-yellow[disabled]:active,
.btn-yellow[disabled].active,
fieldset[disabled] .btn-yellow:hover,
fieldset[disabled] .btn-yellow:focus,
fieldset[disabled] .btn-yellow:active,
fieldset[disabled] .btn-yellow.active {
  background-color: #ffe0ae;
  border-color: #ffe0ae;
  color: #ffffff;
}
.btn-yellow.btn-o {
  border: 1px solid #FFB848;
  color: #FFB848;
}
.btn-yellow.btn-o:hover {
  color: #ffcc7b;
  border-color: #ffcc7b;
  background: none !important;
}
.btn-yellow.btn-o:active,
.btn-yellow.btn-o.active,
.btn-yellow.btn-o.active:focus,
.btn-yellow.btn-o:active:focus,
.btn-yellow.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #fa9900 !important;
  color: #fa9900 !important;
}
.btn-yellow.btn-o:focus {
  background-color: #ffe0ae;
  border-color: #ffe0ae;
  color: #ffcc7b;
}
.btn-yellow.btn-o.disabled:hover,
.btn-yellow.btn-o.disabled:focus,
.btn-yellow.btn-o.disabled:active,
.btn-yellow.btn-o.disabled.active,
.btn-yellow.btn-o[disabled],
.btn-yellow.btn-o[disabled]:hover,
.btn-yellow.btn-o[disabled]:focus,
.btn-yellow.btn-o[disabled]:active,
.btn-yellow.btn-o[disabled].active,
fieldset[disabled] .btn-yellow.btn-o:hover,
fieldset[disabled] .btn-yellow.btn-o:focus,
fieldset[disabled] .btn-yellow.btn-o:active,
fieldset[disabled] .btn-yellow.btn-o.active {
  border-color: #ffe0ae;
  color: #ffe0ae;
}
.btn-yellow.btn-o .caret {
  border-top-color: #FFB848;
}
.btn-yellow .caret {
  border-top-color: #ffffff;
}
.dropup .btn-yellow .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-yellow {
  background-color: #ffcc7b;
  border-color: #ffcc7b;
  color: #ffffff;
}
.open .btn-light-yellow.dropdown-toggle {
  background-color: #ffe9c7;
  border-color: #ffe9c7;
}
.btn-light-yellow:hover {
  background-color: #ffe0ae !important;
  border-color: #ffe0ae;
  color: #ffffff;
}
.btn-light-yellow:active,
.btn-light-yellow.active,
.btn-light-yellow.active:focus,
.btn-light-yellow:active:focus,
.btn-light-yellow:active:hover,
.btn-light-yellow.dropdown-toggle:active:hover {
  background-color: #ffc261 !important;
  border-color: #ffc261;
}
.btn-light-yellow:focus {
  background-color: #ffe9c7;
  border-color: #ffe9c7;
  color: #ffffff;
}
.btn-light-yellow.disabled:hover,
.btn-light-yellow.disabled:focus,
.btn-light-yellow.disabled:active,
.btn-light-yellow.disabled.active,
.btn-light-yellow[disabled],
.btn-light-yellow[disabled]:hover,
.btn-light-yellow[disabled]:focus,
.btn-light-yellow[disabled]:active,
.btn-light-yellow[disabled].active,
fieldset[disabled] .btn-light-yellow:hover,
fieldset[disabled] .btn-light-yellow:focus,
fieldset[disabled] .btn-light-yellow:active,
fieldset[disabled] .btn-light-yellow.active {
  background-color: #fff3e1;
  border-color: #fff3e1;
  color: #ffffff;
}
.btn-light-yellow.btn-o {
  border: 1px solid #ffcc7b;
  color: #ffcc7b;
}
.btn-light-yellow.btn-o:hover {
  color: #ffe0ae;
  border-color: #ffe0ae;
  background: none !important;
}
.btn-light-yellow.btn-o:active,
.btn-light-yellow.btn-o.active,
.btn-light-yellow.btn-o.active:focus,
.btn-light-yellow.btn-o:active:focus,
.btn-light-yellow.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #ffae2e !important;
  color: #ffae2e !important;
}
.btn-light-yellow.btn-o:focus {
  background-color: #fff3e1;
  border-color: #fff3e1;
  color: #ffe0ae;
}
.btn-light-yellow.btn-o.disabled:hover,
.btn-light-yellow.btn-o.disabled:focus,
.btn-light-yellow.btn-o.disabled:active,
.btn-light-yellow.btn-o.disabled.active,
.btn-light-yellow.btn-o[disabled],
.btn-light-yellow.btn-o[disabled]:hover,
.btn-light-yellow.btn-o[disabled]:focus,
.btn-light-yellow.btn-o[disabled]:active,
.btn-light-yellow.btn-o[disabled].active,
fieldset[disabled] .btn-light-yellow.btn-o:hover,
fieldset[disabled] .btn-light-yellow.btn-o:focus,
fieldset[disabled] .btn-light-yellow.btn-o:active,
fieldset[disabled] .btn-light-yellow.btn-o.active {
  border-color: #fff3e1;
  color: #fff3e1;
}
.btn-light-yellow.btn-o .caret {
  border-top-color: #ffcc7b;
}
.btn-light-yellow .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-yellow .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-dark-yellow {
  background-color: #ffa415;
  border-color: #ffa415;
  color: #ffffff;
}
.open .btn-dark-yellow.dropdown-toggle {
  background-color: #ffc262;
  border-color: #ffc262;
}
.btn-dark-yellow:hover {
  background-color: #ffb848 !important;
  border-color: #ffb848;
  color: #ffffff;
}
.btn-dark-yellow:active,
.btn-dark-yellow.active,
.btn-dark-yellow.active:focus,
.btn-dark-yellow:active:focus,
.btn-dark-yellow:active:hover,
.btn-dark-yellow.dropdown-toggle:active:hover {
  background-color: #fb9900 !important;
  border-color: #fb9900;
}
.btn-dark-yellow:focus {
  background-color: #ffc262;
  border-color: #ffc262;
  color: #ffffff;
}
.btn-dark-yellow.disabled:hover,
.btn-dark-yellow.disabled:focus,
.btn-dark-yellow.disabled:active,
.btn-dark-yellow.disabled.active,
.btn-dark-yellow[disabled],
.btn-dark-yellow[disabled]:hover,
.btn-dark-yellow[disabled]:focus,
.btn-dark-yellow[disabled]:active,
.btn-dark-yellow[disabled].active,
fieldset[disabled] .btn-dark-yellow:hover,
fieldset[disabled] .btn-dark-yellow:focus,
fieldset[disabled] .btn-dark-yellow:active,
fieldset[disabled] .btn-dark-yellow.active {
  background-color: #ffcc7b;
  border-color: #ffcc7b;
  color: #ffffff;
}
.btn-dark-yellow.btn-o {
  border: 1px solid #ffa415;
  color: #ffa415;
}
.btn-dark-yellow.btn-o:hover {
  color: #ffb848;
  border-color: #ffb848;
  background: none !important;
}
.btn-dark-yellow.btn-o:active,
.btn-dark-yellow.btn-o.active,
.btn-dark-yellow.btn-o.active:focus,
.btn-dark-yellow.btn-o:active:focus,
.btn-dark-yellow.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #c77a00 !important;
  color: #c77a00 !important;
}
.btn-dark-yellow.btn-o:focus {
  background-color: #ffcc7b;
  border-color: #ffcc7b;
  color: #ffb848;
}
.btn-dark-yellow.btn-o.disabled:hover,
.btn-dark-yellow.btn-o.disabled:focus,
.btn-dark-yellow.btn-o.disabled:active,
.btn-dark-yellow.btn-o.disabled.active,
.btn-dark-yellow.btn-o[disabled],
.btn-dark-yellow.btn-o[disabled]:hover,
.btn-dark-yellow.btn-o[disabled]:focus,
.btn-dark-yellow.btn-o[disabled]:active,
.btn-dark-yellow.btn-o[disabled].active,
fieldset[disabled] .btn-dark-yellow.btn-o:hover,
fieldset[disabled] .btn-dark-yellow.btn-o:focus,
fieldset[disabled] .btn-dark-yellow.btn-o:active,
fieldset[disabled] .btn-dark-yellow.btn-o.active {
  border-color: #ffcc7b;
  color: #ffcc7b;
}
.btn-dark-yellow.btn-o .caret {
  border-top-color: #ffa415;
}
.btn-dark-yellow .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-yellow .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-grey {
  background-color: #c8c7cc;
  border-color: #c8c7cc;
  color: #ffffff;
}
.open .btn-grey.dropdown-toggle {
  background-color: #efeff0;
  border-color: #efeff0;
}
.btn-grey:hover {
  background-color: #e2e2e4 !important;
  border-color: #e2e2e4;
  color: #ffffff;
}
.btn-grey:active,
.btn-grey.active,
.btn-grey.active:focus,
.btn-grey:active:focus,
.btn-grey:active:hover,
.btn-grey.dropdown-toggle:active:hover {
  background-color: #bbbac0 !important;
  border-color: #bbbac0;
}
.btn-grey:focus {
  background-color: #efeff0;
  border-color: #efeff0;
  color: #ffffff;
}
.btn-grey.disabled:hover,
.btn-grey.disabled:focus,
.btn-grey.disabled:active,
.btn-grey.disabled.active,
.btn-grey[disabled],
.btn-grey[disabled]:hover,
.btn-grey[disabled]:focus,
.btn-grey[disabled]:active,
.btn-grey[disabled].active,
fieldset[disabled] .btn-grey:hover,
fieldset[disabled] .btn-grey:focus,
fieldset[disabled] .btn-grey:active,
fieldset[disabled] .btn-grey.active {
  background-color: #fcfcfd;
  border-color: #fcfcfd;
  color: #ffffff;
}
.btn-grey.btn-o {
  border: 1px solid #c8c7cc;
  color: #c8c7cc;
}
.btn-grey.btn-o:hover {
  color: #e2e2e4;
  border-color: #e2e2e4;
  background: none !important;
}
.btn-grey.btn-o:active,
.btn-grey.btn-o.active,
.btn-grey.btn-o.active:focus,
.btn-grey.btn-o:active:focus,
.btn-grey.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #a19fa8 !important;
  color: #a19fa8 !important;
}
.btn-grey.btn-o:focus {
  background-color: #fcfcfd;
  border-color: #fcfcfd;
  color: #e2e2e4;
}
.btn-grey.btn-o.disabled:hover,
.btn-grey.btn-o.disabled:focus,
.btn-grey.btn-o.disabled:active,
.btn-grey.btn-o.disabled.active,
.btn-grey.btn-o[disabled],
.btn-grey.btn-o[disabled]:hover,
.btn-grey.btn-o[disabled]:focus,
.btn-grey.btn-o[disabled]:active,
.btn-grey.btn-o[disabled].active,
fieldset[disabled] .btn-grey.btn-o:hover,
fieldset[disabled] .btn-grey.btn-o:focus,
fieldset[disabled] .btn-grey.btn-o:active,
fieldset[disabled] .btn-grey.btn-o.active {
  border-color: #fcfcfd;
  color: #fcfcfd;
}
.btn-grey.btn-o .caret {
  border-top-color: #c8c7cc;
}
.btn-grey .caret {
  border-top-color: #ffffff;
}
.dropup .btn-grey .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-light-grey {
  background-color: #f7f7f8;
  border-color: #f7f7f8;
  color: #8e8e93;
}
.open .btn-light-grey.dropdown-toggle {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light-grey:hover {
  background-color: #ffffff !important;
  border-color: #ffffff;
  color: #8e8e93;
}
.btn-light-grey:active,
.btn-light-grey.active,
.btn-light-grey.active:focus,
.btn-light-grey:active:focus,
.btn-light-grey:active:hover,
.btn-light-grey.dropdown-toggle:active:hover {
  background-color: #eaeaec !important;
  border-color: #eaeaec;
}
.btn-light-grey:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #8e8e93;
}
.btn-light-grey.disabled:hover,
.btn-light-grey.disabled:focus,
.btn-light-grey.disabled:active,
.btn-light-grey.disabled.active,
.btn-light-grey[disabled],
.btn-light-grey[disabled]:hover,
.btn-light-grey[disabled]:focus,
.btn-light-grey[disabled]:active,
.btn-light-grey[disabled].active,
fieldset[disabled] .btn-light-grey:hover,
fieldset[disabled] .btn-light-grey:focus,
fieldset[disabled] .btn-light-grey:active,
fieldset[disabled] .btn-light-grey.active {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #c2c2c5;
}
.btn-light-grey.btn-o {
  border: 1px solid #f7f7f8;
  color: #f7f7f8;
}
.btn-light-grey.btn-o:hover {
  color: #a8a8ac;
  border-color: #ffffff;
  background: none !important;
}
.btn-light-grey.btn-o:active,
.btn-light-grey.btn-o.active,
.btn-light-grey.btn-o.active:focus,
.btn-light-grey.btn-o:active:focus,
.btn-light-grey.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #d0cfd3 !important;
  color: #68686d !important;
}
.btn-light-grey.btn-o:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-light-grey.btn-o.disabled:hover,
.btn-light-grey.btn-o.disabled:focus,
.btn-light-grey.btn-o.disabled:active,
.btn-light-grey.btn-o.disabled.active,
.btn-light-grey.btn-o[disabled],
.btn-light-grey.btn-o[disabled]:hover,
.btn-light-grey.btn-o[disabled]:focus,
.btn-light-grey.btn-o[disabled]:active,
.btn-light-grey.btn-o[disabled].active,
fieldset[disabled] .btn-light-grey.btn-o:hover,
fieldset[disabled] .btn-light-grey.btn-o:focus,
fieldset[disabled] .btn-light-grey.btn-o:active,
fieldset[disabled] .btn-light-grey.btn-o.active {
  border-color: #ffffff;
  color: #c2c2c5;
}
.btn-light-grey.btn-o .caret {
  border-top-color: #8e8e93;
}
.btn-light-grey .caret {
  border-top-color: #8e8e93;
}
.dropup .btn-light-grey .caret {
  border-bottom: 4px solid #8e8e93;
}
.btn-dark-grey {
  background-color: #aeacb4;
  border-color: #aeacb4;
  color: #ffffff;
}
.open .btn-dark-grey.dropdown-toggle {
  background-color: #d5d4d8;
  border-color: #d5d4d8;
}
.btn-dark-grey:hover {
  background-color: #c8c7cc !important;
  border-color: #c8c7cc;
  color: #ffffff;
}
.btn-dark-grey:active,
.btn-dark-grey.active,
.btn-dark-grey.active:focus,
.btn-dark-grey:active:focus,
.btn-dark-grey:active:hover,
.btn-dark-grey.dropdown-toggle:active:hover {
  background-color: #a19fa8 !important;
  border-color: #a19fa8;
}
.btn-dark-grey:focus {
  background-color: #d5d4d8;
  border-color: #d5d4d8;
  color: #ffffff;
}
.btn-dark-grey.disabled:hover,
.btn-dark-grey.disabled:focus,
.btn-dark-grey.disabled:active,
.btn-dark-grey.disabled.active,
.btn-dark-grey[disabled],
.btn-dark-grey[disabled]:hover,
.btn-dark-grey[disabled]:focus,
.btn-dark-grey[disabled]:active,
.btn-dark-grey[disabled].active,
fieldset[disabled] .btn-dark-grey:hover,
fieldset[disabled] .btn-dark-grey:focus,
fieldset[disabled] .btn-dark-grey:active,
fieldset[disabled] .btn-dark-grey.active {
  background-color: #e2e2e4;
  border-color: #e2e2e4;
  color: #ffffff;
}
.btn-dark-grey.btn-o {
  border: 1px solid #aeacb4;
  color: #aeacb4;
}
.btn-dark-grey.btn-o:hover {
  color: #c8c7cc;
  border-color: #c8c7cc;
  background: none !important;
}
.btn-dark-grey.btn-o:active,
.btn-dark-grey.btn-o.active,
.btn-dark-grey.btn-o.active:focus,
.btn-dark-grey.btn-o:active:focus,
.btn-dark-grey.btn-o:active:hover {
  background-color: #a19fa8 !important;
  border-color: #86848f !important;
  color: #86848f !important;
}
.btn-dark-grey.btn-o:focus {
  background-color: #e2e2e4;
  border-color: #e2e2e4;
  color: #c8c7cc;
}
.btn-dark-grey.btn-o.disabled:hover,
.btn-dark-grey.btn-o.disabled:focus,
.btn-dark-grey.btn-o.disabled:active,
.btn-dark-grey.btn-o.disabled.active,
.btn-dark-grey.btn-o[disabled],
.btn-dark-grey.btn-o[disabled]:hover,
.btn-dark-grey.btn-o[disabled]:focus,
.btn-dark-grey.btn-o[disabled]:active,
.btn-dark-grey.btn-o[disabled].active,
fieldset[disabled] .btn-dark-grey.btn-o:hover,
fieldset[disabled] .btn-dark-grey.btn-o:focus,
fieldset[disabled] .btn-dark-grey.btn-o:active,
fieldset[disabled] .btn-dark-grey.btn-o.active {
  border-color: #e2e2e4;
  color: #e2e2e4;
}
.btn-dark-grey.btn-o .caret {
  border-top-color: #aeacb4;
}
.btn-dark-grey .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-grey .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-transparent-white {
  background: none;
  border-color: rgba(255, 255, 255, 0.6);
  border-width: 1px;
  color: #ffffff !important;
}
.btn-transparent-white:hover,
.btn-transparent-white:focus,
.btn-transparent-white:active,
.btn-transparent-white.active {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.8);
}
.btn-transparent-white.disabled:hover,
.btn-transparent-white.disabled:focus,
.btn-transparent-white.disabled:active,
.btn-transparent-white.disabled.active,
.btn-transparent-white[disabled]:hover,
.btn-transparent-white[disabled]:focus,
.btn-transparent-white[disabled]:active,
.btn-transparent-white[disabled].active,
fieldset[disabled] .btn-transparent-white:hover,
fieldset[disabled] .btn-transparent-white:focus,
fieldset[disabled] .btn-transparent-white:active,
fieldset[disabled] .btn-transparent-white.active {
  background: none;
  border-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}
.btn-transparent-white .caret {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.dropup .btn-transparent-white .caret {
  border-bottom: 4px solid rgba(0, 0, 0, 0.1);
}
.btn-transparent-grey {
  background: none;
  border-color: rgba(128, 128, 128, 0.3);
  border-width: 1px;
  color: #c8c7cc !important;
}
.btn-transparent-grey:hover,
.btn-transparent-grey:focus,
.btn-transparent-grey:active,
.btn-transparent-grey.active {
  background-color: rgba(128, 128, 128, 0.1);
  border-color: rgba(128, 128, 128, 0.6);
  box-shadow: none !important;
}
.btn-transparent-grey.disabled:hover,
.btn-transparent-grey.disabled:focus,
.btn-transparent-grey.disabled:active,
.btn-transparent-grey.disabled.active,
.btn-transparent-grey[disabled]:hover,
.btn-transparent-grey[disabled]:focus,
.btn-transparent-grey[disabled]:active,
.btn-transparent-grey[disabled].active,
fieldset[disabled] .btn-transparent-grey:hover,
fieldset[disabled] .btn-transparent-grey:focus,
fieldset[disabled] .btn-transparent-grey:active,
fieldset[disabled] .btn-transparent-grey.active {
  background: none;
  border-color: rgba(128, 128, 128, 0.3);
  color: #c8c7cc;
}
.btn-transparent-grey .caret {
  border-top-color: #c8c7cc;
}
.dropup .btn-transparent-grey .caret {
  border-bottom: 4px solid #c8c7cc;
}
.btn-transparent-red {
  background: none;
  border-color: rgba(255, 0, 0, 0.6);
  border-width: 2px;
  color: #C82E29 !important;
}
.btn-transparent-red:hover,
.btn-transparent-red:focus,
.btn-transparent-red:active,
.btn-transparent-red.active {
  background-color: rgba(255, 0, 0, 0.1);
  border-color: rgba(255, 0, 0, 0.8);
}
.btn-transparent-red.disabled:hover,
.btn-transparent-red.disabled:focus,
.btn-transparent-red.disabled:active,
.btn-transparent-red.disabled.active,
.btn-transparent-red[disabled]:hover,
.btn-transparent-red[disabled]:focus,
.btn-transparent-red[disabled]:active,
.btn-transparent-red[disabled].active,
fieldset[disabled] .btn-transparent-red:hover,
fieldset[disabled] .btn-transparent-red:focus,
fieldset[disabled] .btn-transparent-red:active,
fieldset[disabled] .btn-transparent-red.active {
  background: none;
  border-color: rgba(255, 0, 0, 0.3);
  color: #C82E29;
}
.btn-transparent-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-transparent-red .caret {
  border-bottom: 4px solid #ffffff;
}
.btn-squared {
  border-radius: 0 !important;
}
.btn-icon {
  background-color: #ffffff;
  border: 1px solid #DDDDDD;
  border-radius: 2px 2px 2px 2px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  cursor: pointer;
  height: 80px;
  display: block;
  font-size: 14px;
  padding: 5px 0 0;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  color: #8e8e93;
}
.btn-icon:hover {
  border-color: #A5A5A5;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  color: #8e8e93;
  text-decoration: none;
  text-shadow: 0 1px 0 #FFFFFF;
}
.btn-icon .badge {
  border-radius: 12px 12px 12px 12px !important;
  border-style: solid;
  border-width: 0;
  box-shadow: none;
  color: #FFFFFF !important;
  font-size: 11px !important;
  font-weight: 300;
  padding: 3px 7px;
  position: absolute;
  right: -5px;
  text-shadow: none;
  top: -5px;
}
.btn-icon [class^="fa-"],
.btn-icon [class*=" fa-"] {
  clear: both;
  display: block;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file .svg-inject {
  vertical-align: middle;
}
.iconic-elem-primary {
  fill: #48b1cc !important;
}
.iconic-elem-secondary {
  stroke: #fff !important;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.buttons-widget .btn,
.buttons-widget .bootstrap-switch,
.buttons-widget button {
  margin-bottom: 10px;
}
.buttons-widget .btn-group button,
.buttons-widget .btn-group-vertical button,
.buttons-widget .btn-group .btn,
.buttons-widget .btn-group-vertical .btn {
  margin-bottom: 0;
}
.button-o {
  background: none;
  border: 1px solid #007aff;
  border-radius: 5px;
  box-sizing: border-box;
  color: #007aff;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  height: 29px;
  line-height: 27px;
  margin: 0;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/***
Dropdown checkboxes
***/
.dropdown-checkboxes {
  padding: 5px;
}
.dropdown-checkboxes label {
  display: block;
  font-weight: normal;
  line-height: 20px;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  border-left-color: rgba(255, 255, 255, 0.5) !important;
  margin: 0;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  border-top-color: rgba(255, 255, 255, 0.5) !important;
  margin: 0;
}
/***
Social Icons
***/
.social-icons ul {
  list-style: none;
  margin: 0 0 -1px 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 40px;
  overflow: hidden;
}
.social-icons ul li a {
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
  height: 80px;
  opacity: 0.6;
  text-indent: -9999px;
  transition: all 0.2s ease 0s;
  width: 40px;
  top: 0;
  font-size: 16px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.social-icons ul li a:hover {
  top: -40px;
}
.social-icons ul li a:before {
  position: absolute;
  width: 40px;
  height: 40px;
  font-family: FontAwesome;
  text-indent: 0;
  line-height: 40px;
  top: 0;
  left: 0;
  text-align: center;
  color: #999999;
}
.social-icons ul li a:after {
  position: absolute;
  width: 40px;
  height: 40px;
  font-family: FontAwesome;
  text-indent: 0;
  line-height: 40px;
  top: 40px;
  left: 0;
  text-align: center;
  color: #FFFFFF;
}
.social-icons ul li.social-twitter a:before,
.social-icons ul li.social-twitter a:after {
  content: "\f099";
}
.social-icons ul li.social-twitter a:after {
  background: #48C4D2;
}
.social-icons ul li.social-dribbble a:before,
.social-icons ul li.social-dribbble a:after {
  content: "\f17d";
}
.social-icons ul li.social-dribbble a:after {
  background: #EF5B92;
}
.social-icons ul li.social-facebook a:before,
.social-icons ul li.social-facebook a:after {
  content: "\f09a";
}
.social-icons ul li.social-facebook a:after {
  background: #3B5998;
}
.social-icons ul li.social-google a:before,
.social-icons ul li.social-google a:after {
  content: "\f0d5";
}
.social-icons ul li.social-google a:after {
  background: #DD4B39;
}
.social-icons ul li.social-linkedin a:before,
.social-icons ul li.social-linkedin a:after {
  content: "\f0e1";
}
.social-icons ul li.social-linkedin a:after {
  background: #71B2D0;
}
.social-icons ul li.social-stripe a:before,
.social-icons ul li.social-stripe a:after {
  content: "\f1f5";
}
.social-icons ul li.social-stripe a:after {
  background: #000000;
}
.social-icons ul li.social-youtube a:before,
.social-icons ul li.social-youtube a:after {
  content: "\f167";
}
.social-icons ul li.social-youtube a:after {
  background: #F45750;
}
.social-icons ul li.social-rss a:before,
.social-icons ul li.social-rss a:after {
  content: "\f09e";
}
.social-icons ul li.social-rss a:after {
  background: #FE9900;
}
.social-icons ul li.social-behance a:before,
.social-icons ul li.social-behance a:after {
  content: "\f1b4";
}
.social-icons ul li.social-behance a:after {
  background: #5EB5FB;
}
.social-icons ul li.social-dropbox a:before,
.social-icons ul li.social-dropbox a:after {
  content: "\f16b";
}
.social-icons ul li.social-dropbox a:after {
  background: #85BEEC;
}
.social-icons ul li.social-github a:before,
.social-icons ul li.social-github a:after {
  content: "\f09b";
}
.social-icons ul li.social-github a:after {
  background: #94A5B0;
}
.social-icons ul li.social-skype a:before,
.social-icons ul li.social-skype a:after {
  content: "\f17e";
}
.social-icons ul li.social-skype a:after {
  background: #70CCEF;
}
.social-icons ul li.social-spotify a:before,
.social-icons ul li.social-spotify a:after {
  content: "\f1bc";
}
.social-icons ul li.social-spotify a:after {
  background: #8BD05A;
}
.social-icons ul li.social-stumbleupon a:before,
.social-icons ul li.social-stumbleupon a:after {
  content: "\f1a4";
}
.social-icons ul li.social-stumbleupon a:after {
  background: #F08066;
}
.social-icons ul li.social-tumblr a:before,
.social-icons ul li.social-tumblr a:after {
  content: "\f173";
}
.social-icons ul li.social-tumblr a:after {
  background: #85A5C3;
}
.social-icons ul li.social-vimeo a:before,
.social-icons ul li.social-vimeo a:after {
  content: "\f194";
}
.social-icons ul li.social-vimeo a:after {
  background: #7C99A8;
}
.social-icons ul li.social-wordpress a:before,
.social-icons ul li.social-wordpress a:after {
  content: "\f19a";
}
.social-icons ul li.social-wordpress a:after {
  background: #649EB9;
}
.social-icons ul li.social-xing a:before,
.social-icons ul li.social-xing a:after {
  content: "\f168";
}
.social-icons ul li.social-xing a:after {
  background: #4F9394;
}
.social-icons ul li.social-yahoo a:before,
.social-icons ul li.social-yahoo a:after {
  content: "\f19e";
}
.social-icons ul li.social-yahoo a:after {
  background: #C38DCF;
}
.social-icons ul li.social-vk a:before,
.social-icons ul li.social-vk a:after {
  content: "\f189";
}
.social-icons ul li.social-vk a:after {
  background: #708FAC;
}
.social-icons ul li.social-instagram a:before,
.social-icons ul li.social-instagram a:after {
  content: "\f16d";
}
.social-icons ul li.social-instagram a:after {
  background: #CBAA97;
}
.social-icons ul li.social-reddit a:before,
.social-icons ul li.social-reddit a:after {
  content: "\f1a1";
}
.social-icons ul li.social-reddit a:after {
  background: #FF7A52;
}
.social-icons ul li.social-flickr a:before,
.social-icons ul li.social-flickr a:after {
  content: "\f16e";
}
.social-icons ul li.social-flickr a:after {
  background: #FF4DA9;
}
.social-icons ul li.social-foursquare a:before,
.social-icons ul li.social-foursquare a:after {
  content: "\f180";
}
.social-icons ul li.social-foursquare a:after {
  background: #6FCCF4;
}
.social-icons ul li.social-paypal a:before,
.social-icons ul li.social-paypal a:after {
  content: "\f1ed";
}
.social-icons ul li.social-paypal a:after {
  background: #003087;
}
.social-icons ul li.social-pinterest a:before,
.social-icons ul li.social-pinterest a:after {
  content: "\f0d2";
}
.social-icons ul li.social-pinterest a:after {
  background: #cc2127;
}
.social-icons ul li.social-google-wallet a:before,
.social-icons ul li.social-google-wallet a:after {
  content: "\f1ee";
}
.social-icons ul li.social-google-wallet a:after {
  background: #ffffff;
}
.social-icons ul li.social-authorize a:before,
.social-icons ul li.social-authorize a:after {
  content: "\f09d";
}
.social-icons ul li.social-authorize a:after {
  background: #5fabe4;
}
.social-icons .btn-icon-stripe {
  background: #000000;
  opacity: 0.8;
}
.social-icons .btn-icon-paypal {
  background: #003087;
  opacity: 0.8;
}
.social-icons .btn-icon-facebook {
  background: #3B5998;
  opacity: 0.8;
}
.social-icons .btn-icon-twitter {
  background: #48C4D2;
  opacity: 0.8;
}
.social-icons .btn-icon-google-plus {
  background: #DD4B39;
  opacity: 0.8;
}
.btn-scroll {
  position: relative;
  overflow: hidden;
  font-family: "Raleway", sans-serif !important;
  line-height: inherit !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  font-size: 14px;
}
.btn-scroll[class*="fa-"]:before {
  font-family: FontAwesome;
}
.btn-scroll[class*="ti-"]:before {
  font-family: themify;
}
.btn-scroll:after {
  content: "";
  position: absolute;
  transition: all 0.3s ease 0s;
  z-index: -1;
}
.btn-scroll:before {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  position: relative;
  text-transform: none;
  font-size: 100%;
  height: 100%;
  line-height: 2.5;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.btn-scroll span {
  backface-visibility: hidden;
  display: inline-block;
  height: 100%;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.btn-scroll.btn-scroll-top:hover span {
  -webkit-transform: translateY(300%);
  -moz-transform: translateY(300%);
  -ms-transform: translateY(300%);
  -o-transform: translateY(300%);
}
.btn-scroll.btn-scroll-top:hover:before {
  top: 0;
}
.btn-scroll.btn-scroll-top:before {
  left: 0;
  top: -100%;
}
.btn-scroll.btn-scroll-left:hover span {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -o-transform: translateX(200%);
}
.btn-scroll.btn-scroll-left:hover:before {
  left: 0;
}
.btn-scroll.btn-scroll-left:before {
  left: -100%;
  top: 0;
}
/* ---------------------------------------------------------------------- */
/*  Links
/* ---------------------------------------------------------------------- */
/*from http://tympanus.net/Development/CreativeLinkEffects/*/
nav.links {
  text-align: center;
}
nav.links a {
  color: #8e8e93;
  display: inline-block;
  font-size: 1.35em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 15px 25px;
  outline: medium none;
  position: relative;
  text-decoration: none;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
}
[class^="cl-effect-"] a,
[class*=" cl-effect-"] a {
  position: relative;
}
/* Effect 1: Brackets */
.cl-effect-1 a::before,
.cl-effect-1 a::after {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}
.cl-effect-1 a::before {
  margin-right: 10px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
}
.cl-effect-1 a::after {
  margin-left: 10px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}
.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}
/* Effect 2: 3D rolling links, idea from http://hakim.se/thoughts/rolling-links */
.cl-effect-2 a {
  line-height: 44px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}
.cl-effect-2 a span {
  position: relative;
  display: inline-block;
  padding: 0 14px;
  background: #48b1cc;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.csstransforms3d .cl-effect-2 a span::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3196b0;
  content: attr(data-hover);
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.cl-effect-2 a:hover span,
.cl-effect-2 a:focus span {
  -webkit-transform: rotateX(90deg) translateY(-22px);
  -moz-transform: rotateX(90deg) translateY(-22px);
  transform: rotateX(90deg) translateY(-22px);
}
.csstransforms3d .cl-effect-2 a:hover span::before,
.csstransforms3d .cl-effect-2 a:focus span::before {
  background: #3196b0;
}
/* Effect 3: bottom line slides/fades in */
.cl-effect-3 a {
  padding: 8px 0;
}
.cl-effect-3 a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.1);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}
.cl-effect-3 a:hover::after,
.cl-effect-3 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}
/* Effect 4: bottom border enlarge */
.cl-effect-4 a {
  padding: 0 0 10px;
}
.cl-effect-4 a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #48b1cc;
  content: '';
  opacity: 0;
  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}
.cl-effect-4 a:hover::after,
.cl-effect-4 a:focus::after {
  height: 5px;
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}
/* Effect 5: same word slide in */
.cl-effect-5 a {
  overflow: hidden;
  padding: 0 4px;
  height: 1.35em;
}
.cl-effect-5 a span {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cl-effect-5 a span::before {
  position: absolute;
  top: 100%;
  content: attr(data-hover);
  font-weight: 700;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.cl-effect-5 a:hover span,
.cl-effect-5 a:focus span {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}
/* Effect 5: same word slide in and border bottom */
.cl-effect-6 a {
  margin: 0 10px;
  padding: 10px 20px;
}
.cl-effect-6 a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #48b1cc;
  content: '';
  -webkit-transition: top 0.3s;
  -moz-transition: top 0.3s;
  transition: top 0.3s;
}
.cl-effect-6 a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background: #48b1cc;
  content: '';
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  transition: height 0.3s;
}
.cl-effect-6 a:hover::before {
  top: 100%;
  opacity: 1;
}
.cl-effect-6 a:hover::after {
  height: 100%;
}
/* Effect 7: second border slides up */
.cl-effect-7 a {
  padding: 12px 10px 10px;
  color: #566473;
  text-shadow: none;
  font-weight: 700;
}
.cl-effect-7 a::before,
.cl-effect-7 a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #566473;
  content: '';
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  transform: scale(0.85);
}
.cl-effect-7 a::after {
  opacity: 0;
  -webkit-transition: top 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: top 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: top 0.3s, opacity 0.3s, transform 0.3s;
}
.cl-effect-7 a:hover::before,
.cl-effect-7 a:hover::after,
.cl-effect-7 a:focus::before,
.cl-effect-7 a:focus::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.cl-effect-7 a:hover::after,
.cl-effect-7 a:focus::after {
  top: 0%;
  opacity: 1;
}
/* Effect 8: border slight translate */
.cl-effect-8 a {
  padding: 10px 20px;
}
.cl-effect-8 a::before,
.cl-effect-8 a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #8e8e93;
  content: '';
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.cl-effect-8 a::after {
  border-color: #48b1cc;
  opacity: 0;
  -webkit-transform: translateY(-7px) translateX(6px);
  -moz-transform: translateY(-7px) translateX(6px);
  transform: translateY(-7px) translateX(6px);
}
.cl-effect-8 a:hover::before,
.cl-effect-8 a:focus::before {
  opacity: 0;
  -webkit-transform: translateY(5px) translateX(-5px);
  -moz-transform: translateY(5px) translateX(-5px);
  transform: translateY(5px) translateX(-5px);
}
.cl-effect-8 a:hover::after,
.cl-effect-8 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px) translateX(0px);
  -moz-transform: translateY(0px) translateX(0px);
  transform: translateY(0px) translateX(0px);
}
/* Effect 9: second text and borders */
.cl-effect-9 a {
  margin: 0 20px;
  padding: 18px 20px;
}
.cl-effect-9 a::before,
.cl-effect-9 a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #48b1cc;
  content: '';
  opacity: 0.2;
  -webkit-transition: opacity 0.3s, height 0.3s;
  -moz-transition: opacity 0.3s, height 0.3s;
  transition: opacity 0.3s, height 0.3s;
}
.cl-effect-9 a::after {
  top: 100%;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}
.cl-effect-9 a span:first-child {
  z-index: 2;
  display: block;
  font-weight: 300;
}
.cl-effect-9 a span:last-child {
  z-index: 1;
  display: block;
  padding: 8px 0 0 0;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: none;
  text-transform: none;
  font-size: 0.75em;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}
.cl-effect-9 a:hover::before,
.cl-effect-9 a:focus::before {
  height: 6px;
}
.cl-effect-9 a:hover::before,
.cl-effect-9 a:hover::after,
.cl-effect-9 a:focus::before,
.cl-effect-9 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}
.cl-effect-9 a:hover span:last-child,
.cl-effect-9 a:focus span:last-child {
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  transform: translateY(0%);
}
/* Effect 10: reveal, push out */
.cl-effect-10 {
  position: relative;
  z-index: 1;
}
.cl-effect-10 a {
  overflow: hidden;
  margin: 0 15px;
}
.cl-effect-10 a span {
  display: block;
  padding: 10px 20px;
  background: #48b1cc;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cl-effect-10 a::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  background: #3196b0;
  color: #ffffff;
  content: attr(data-hover);
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translateX(-25%);
}
.cl-effect-10 a:hover span,
.cl-effect-10 a:focus span {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
}
.cl-effect-10 a:hover::before,
.cl-effect-10 a:focus::before {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  transform: translateX(0%);
}
/* Effect 11: text fill based on Lea Verou's animation http://dabblet.com/gist/6046779 */
.cl-effect-11 a {
  padding: 10px 0;
  border-top: 2px solid #48b1cc;
  color: #0972b4;
  text-shadow: none;
}
.cl-effect-11 a::before {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 10px 0;
  max-width: 0;
  border-bottom: 2px solid #48b1cc;
  color: #48b1cc;
  content: attr(data-hover);
  -webkit-transition: max-width 0.5s;
  -moz-transition: max-width 0.5s;
  transition: max-width 0.5s;
}
.cl-effect-11 a:hover::before,
.cl-effect-11 a:focus::before {
  max-width: 100%;
}
/* Effect 12: circle */
.cl-effect-12 a::before,
.cl-effect-12 a::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  content: '';
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.2);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.2);
  transform: translateX(-50%) translateY(-50%) scale(0.2);
}
.cl-effect-12 a::after {
  width: 90px;
  height: 90px;
  border-width: 6px;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.8);
  transform: translateX(-50%) translateY(-50%) scale(0.8);
}
.cl-effect-12 a:hover::before,
.cl-effect-12 a:hover::after,
.cl-effect-12 a:focus::before,
.cl-effect-12 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -moz-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
}
/* Effect 13: three circles */
.cl-effect-13 a {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.cl-effect-13 a::before {
  position: absolute;
  top: 100%;
  left: 50%;
  color: transparent;
  content: '\2022';
  text-shadow: 0 0 transparent;
  font-size: 1.2em;
  -webkit-transition: text-shadow 0.3s, color 0.3s;
  -moz-transition: text-shadow 0.3s, color 0.3s;
  transition: text-shadow 0.3s, color 0.3s;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}
.cl-effect-13 a:hover::before,
.cl-effect-13 a:focus::before {
  color: #48b1cc;
  text-shadow: 10px 0 #48b1cc, -10px 0 #48b1cc;
}
.cl-effect-13 a:hover,
.cl-effect-13 a:focus {
  color: #48b1cc;
}
/* Effect 14: border switch */
.cl-effect-14 a {
  padding: 0 20px;
  height: 45px;
  line-height: 45px;
}
.cl-effect-14 a::before,
.cl-effect-14 a::after {
  position: absolute;
  width: 45px;
  height: 2px;
  background: #48b1cc;
  content: '';
  opacity: 0.2;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.cl-effect-14 a::before {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
}
.cl-effect-14 a::after {
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.cl-effect-14 a:hover::before,
.cl-effect-14 a:hover::after,
.cl-effect-14 a:focus::before,
.cl-effect-14 a:focus::after {
  opacity: 1;
}
.cl-effect-14 a:hover::before,
.cl-effect-14 a:focus::before {
  left: 50%;
  -webkit-transform: rotate(0deg) translateX(-50%);
  -moz-transform: rotate(0deg) translateX(-50%);
  transform: rotate(0deg) translateX(-50%);
}
.cl-effect-14 a:hover::after,
.cl-effect-14 a:focus::after {
  right: 50%;
  -webkit-transform: rotate(0deg) translateX(50%);
  -moz-transform: rotate(0deg) translateX(50%);
  transform: rotate(0deg) translateX(50%);
}
/* Effect 15: scale down, reveal */
.cl-effect-15 a {
  color: #48b1cc !important;
  font-weight: 700;
  text-shadow: none;
}
.cl-effect-15 a::before {
  color: #8e8e93;
  content: attr(data-hover);
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.cl-effect-15 a:hover::before,
.cl-effect-15 a:focus::before {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
}
/* Effect 16: fall down */
.cl-effect-16 a {
  color: #8e8e93;
  text-shadow: 0 0 1px rgba(111, 134, 134, 0.3);
}
.cl-effect-16 a::before {
  color: #48b1cc;
  content: attr(data-hover);
  position: absolute;
  opacity: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  -webkit-transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
  -moz-transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
  transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}
.cl-effect-16 a:hover::before,
.cl-effect-16 a:focus::before {
  -webkit-transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
  -moz-transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
  transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
  opacity: 1;
}
/* Effect 17: move up fade out, push border */
.cl-effect-17 a {
  color: #8e8e93;
  text-shadow: none;
  padding: 10px 0;
}
.cl-effect-17 a::before {
  color: #48b1cc;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  content: attr(data-hover);
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}
.cl-effect-17 a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #48b1cc;
  opacity: 0;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}
.cl-effect-17 a:hover::before,
.cl-effect-17 a:focus::before {
  opacity: 0;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  transform: translateY(-2px);
}
.cl-effect-17 a:hover::after,
.cl-effect-17 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}
/* Effect 18: cross */
.cl-effect-18 {
  position: relative;
  z-index: 1;
}
.cl-effect-18 a {
  padding: 0 5px;
  color: #8e8e93;
  font-weight: 700;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.cl-effect-18 a::before,
.cl-effect-18 a::after {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  height: 2px;
  margin-top: -1px;
  background: #48b1cc;
  content: '';
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}
.cl-effect-18 a::before {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  transform: translateY(-20px);
}
.cl-effect-18 a::after {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
}
.cl-effect-18 a:hover,
.cl-effect-18 a:focus {
  color: #48b1cc;
}
.cl-effect-18 a:hover::before,
.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::before,
.cl-effect-18 a:focus::after {
  opacity: 0.3;
}
.cl-effect-18 a:hover::before,
.cl-effect-18 a:focus::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
/* Effect 19: 3D side */
.cl-effect-19 a {
  line-height: 2em;
  margin: 15px;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 800px;
  width: 200px;
}
.cl-effect-19 a span {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 14px;
  background: #48b1cc;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.4s, background 0.4s;
  -moz-transition: -moz-transform 0.4s, background 0.4s;
  transition: transform 0.4s, background 0.4s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50% -100px;
  -moz-transform-origin: 50% 50% -100px;
  transform-origin: 50% 50% -100px;
}
.csstransforms3d .cl-effect-19 a span::before {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: #3196b0;
  content: attr(data-hover);
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  transition: background 0.4s;
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  transform-origin: 0 50%;
  pointer-events: none;
}
.cl-effect-19 a:hover span,
.cl-effect-19 a:focus span {
  background: #3196b0;
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.csstransforms3d .cl-effect-19 a:hover span::before,
.csstransforms3d .cl-effect-19 a:focus span::before {
  background: #70c2d7;
}
/* Effect 20: 3D side */
.cl-effect-20 a {
  line-height: 2em;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 800px;
}
.cl-effect-20 a span {
  position: relative;
  display: inline-block;
  padding: 3px 15px 0;
  background: #48b1cc;
  box-shadow: inset 0 3px #3196b0;
  color: #ffffff;
  -webkit-transition: background 0.6s;
  -moz-transition: background 0.6s;
  transition: background 0.6s;
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}
.cl-effect-20 a span::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #70c2d7;
  color: #ffffff;
  content: attr(data-hover);
  -webkit-transform: rotateX(270deg);
  -moz-transform: rotateX(270deg);
  transform: rotateX(270deg);
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
  pointer-events: none;
}
.cl-effect-20 a:hover span,
.cl-effect-20 a:focus span {
  background: #3196b0;
}
.cl-effect-20 a:hover span::before,
.cl-effect-20 a:focus span::before {
  -webkit-transform: rotateX(10deg);
  -moz-transform: rotateX(10deg);
  transform: rotateX(10deg);
}
/* Effect 21: borders slight translate */
.cl-effect-21 a {
  padding: 10px;
  color: #237546;
  font-weight: 700;
  text-shadow: none;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.cl-effect-21 a::before,
.cl-effect-21 a::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}
.cl-effect-21 a::before {
  top: 0;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}
.cl-effect-21 a::after {
  bottom: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}
.cl-effect-21 a:hover,
.cl-effect-21 a:focus {
  color: #fff;
}
.cl-effect-21 a:hover::before,
.cl-effect-21 a:focus::before,
.cl-effect-21 a:hover::after,
.cl-effect-21 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}
/* ---------------------------------------------------------------------- */
/*  Form Wizard
 /* ---------------------------------------------------------------------- */
.swMain > ul {
  display: table;
  list-style: none;
  margin: 0 0 40px 0;
  padding: 10px 0;
  position: relative;
  width: 100%;
  background: #f7f7f8;
  border-radius: 5px;
}
.swMain > ul li {
  display: table-cell;
  text-align: center;
  width: 1%;
}
.swMain > ul li > a:before {
  border-top: 4px solid #c8c7cc;
  content: "";
  display: block;
  font-size: 0;
  height: 1px;
  overflow: hidden;
  position: relative;
  top: 21px;
  width: 100%;
  z-index: 1;
}
.swMain > ul li:first-child > a:before {
  left: 50%;
  max-width: 51%;
}
.swMain > ul li:last-child > a:before {
  max-width: 50%;
  width: 50%;
}
.swMain > ul li > a.selected:before,
.swMain li > a.done:before {
  border-color: #48b1cc;
}
.swMain > ul .stepNumber {
  background-color: #ffffff;
  border: 5px solid #c8c7cc;
  border-radius: 100% 100% 100% 100%;
  color: #546474;
  display: inline-block;
  font-size: 15px;
  height: 40px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 2;
}
.swMain > ul li > a.selected .stepNumber {
  border-color: #48b1cc;
}
.swMain ul li > a.done .stepNumber,
.swMain > ul li:last-child > a.selected .stepNumber {
  border-color: #48b1cc;
  background-color: #48b1cc;
  color: #fff;
  text-indent: -9999px;
}
.swMain ul li > a.done .stepNumber:before,
.swMain > ul li:last-child > a.selected .stepNumber:before {
  content: "\f00c";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  margin-right: 7px;
  text-indent: 0;
}
.swMain ul li > a.done.wait .stepNumber {
  background-color: #F6F6F6 !important;
  color: #CCCCCC !important;
  text-indent: 0px !important;
}
.swMain ul li > a.done.wait .stepNumber:before {
  content: "" !important;
}
.swMain > ul li .stepDesc {
  color: #8e8e93;
  display: block;
  font-size: 14px;
  margin-top: 4px;
  max-width: 100%;
  table-layout: fixed;
  text-align: center;
  word-wrap: break-word;
  z-index: 104;
}
.swMain > ul li > a.selected .stepDesc,
.swMain li > a.done .stepDesc {
  color: #2B3D53;
}
.swMain > ul li > a:hover {
  text-decoration: none;
}
.swMain > ul li > a.disabled {
  cursor: default;
}
.swMain .progress {
  margin-bottom: 30px;
}
.swMain .stepContainer {
  height: auto !important;
}
.swMain .loader {
  display: none;
}
.swMain [class^="button"],
.swMain [class*=" button"] {
  display: none;
}
.swMain span.ui-select-match > span {
  display: inline-table;
}
/* ---------------------------------------------------------------------- */
/*  Login
/* ---------------------------------------------------------------------- */
.main-login {
  margin-top: 20px;
  position: relative;
}
.main-login p.error {
  color: red;
  margin: auto;
  text-align: center;
  padding: 12px;
}
.main-login.rvlvr-login {
  font-family: "varela";
}
.main-login.rvlvr-login legend,
.main-login.rvlvr-login a,
.main-login.rvlvr-login .fa-user,
.main-login.rvlvr-login .fa-lock {
  color: #0071cd;
}
.main-login.rvlvr-login .fa-facebook,
.main-login.rvlvr-login .fa-twitter {
  color: #364862;
}
.main-login.rvlvr-login a.hidden-xs,
.main-login.rvlvr-login p,
.main-login.rvlvr-login input,
.main-login.rvlvr-login .copyright,
.main-login.rvlvr-login .forgot {
  color: #4C4C4C;
}
.main-login.rvlvr-login .btn-o {
  color: #0071cd;
  border-color: #0071cd;
}
.main-login.rvlvr-login .btn-o:hover {
  color: #0071cd;
  border-color: #0071cd;
}
.main-login.rvlvr-login ::placeholder {
  color: #4C4C4C;
  opacity: 1;
  /* Firefox */
}
.main-login.rvlvr-login :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4C4C4C;
}
.main-login.rvlvr-login ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #4C4C4C;
}
@media (max-width: 991px) {
  .main-login {
    margin-top: 65px;
  }
}
.main-login .logo {
  padding: 0 10px;
}
.main-login .box-login,
.main-login .box-forgot,
.main-login .box-register {
  background: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;
  margin: 15px 0 65px 0;
}
.main-login .form fieldset {
  border: none;
  margin: 0;
  padding: 10px 0 0;
}
.main-login a.forgot {
  color: #909090;
  font-size: 12px;
  position: absolute;
  right: 10px;
  text-shadow: 1px 1px 1px #FFFFFF;
  top: 9px;
}
.main-login label {
  color: #7F7F7F;
  font-size: 14px;
  margin-top: 5px;
}
.main-login .copyright {
  font-size: 11px;
  margin: 0 auto;
  padding: 10px 10px 0;
  text-align: center;
}
.main-login .form-actions:before,
.main-login .form-actions:after {
  content: "";
  display: table;
  line-height: 0;
}
.main-login .form-actions:after {
  clear: both;
}
.main-login .form-actions {
  margin-top: 15px;
  padding-top: 10px;
  display: block;
}
.main-login .new-account {
  border-top: 1px dotted #EEEEEE;
  margin-top: 15px;
  padding-top: 10px;
  display: block;
}
.main-login .support-link {
  text-align: center;
}
.main-login .support-link a {
  color: #48b1cc;
  display: inline-block;
  word-break: break-all;
}
/* ---------------------------------------------------------------------- */
/*  Lock Screen
/* ---------------------------------------------------------------------- */
.lock-screen {
  margin: -150px 0 0 -100px;
  width: 200px;
  left: 50%;
  position: absolute;
  top: 50%;
}
.lock-screen .box-ls {
  overflow: hidden;
  text-align: center;
}
.lock-screen .user-info {
  margin: 10px 0;
}
.lock-screen .user-info h4 {
  color: #666666;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin-top: 0;
}
.lock-screen .user-info > span {
  color: #666666;
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}
/* ---------------------------------------------------------------------- */
/*  CONTACT PASSWORD
/* ---------------------------------------------------------------------- */
.contact-passoword-view {
  margin: auto;
  max-width: 400px;
}
.contact-passoword-view p.error {
  color: red;
  margin: auto;
  text-align: center;
  padding: 12px;
}
.contact-passoword-view p.info {
  color: green;
  margin: auto;
  text-align: center;
  padding: 12px;
}
.contact-passoword-view button.disabled {
  pointer-events: none;
}
/* ---------------------------------------------------------------------- */
/*  Invoice
/* ---------------------------------------------------------------------- */
.invoice {
  margin-bottom: 20px;
}
.invoice .invoice-logo {
  margin-bottom: 20px;
}
.invoice table {
  margin: 30px 0;
}
.invoice .invoice-logo p {
  font-size: 20px;
  line-height: 28px;
  padding: 25px 0;
  text-align: right;
}
.invoice .invoice-logo p small {
  display: block;
  font-size: 14px;
}
.invoice h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300 !important;
}
.invoice-details {
  padding-top: 30px;
}
.invoice .invoice-block {
  text-align: right;
}
.invoice .invoice-block .amounts {
  font-size: 14px;
  margin-top: 20px;
}
/* ---------------------------------------------------------------------- */
/*  Timeline
/* ---------------------------------------------------------------------- */
div.timeline {
  margin: 0;
  overflow: hidden;
  position: relative;
}
div.timeline .columns {
  margin: 0;
  padding: 0;
  list-style: none;
}
div.timeline .columns > li:nth-child(2n+1) {
  float: left;
  width: 50%;
  clear: left;
}
div.timeline .columns > li:nth-child(2n+1) .timeline_element {
  float: right;
  left: 10%;
  margin-right: 30px;
  left: 0;
  opacity: 1;
}
div.timeline .columns > li:nth-child(2n+1) .timeline_element:before {
  right: -27px;
  top: 15px;
}
div.timeline .columns > li:nth-child(2n+1) .timeline_element:after {
  right: -35px;
  top: 10px;
}
div.timeline .columns > li:nth-child(2n+2) {
  float: right;
  margin-top: 20px;
  width: 50%;
  clear: right;
}
div.timeline .columns > li:nth-child(2n+2) .timeline_element {
  float: left;
  margin-left: 30px;
  right: 10%;
  opacity: 1;
  right: 0;
}
div.timeline .columns > li:nth-child(2n+2) .timeline_element:before {
  left: -27px;
  top: 15px;
}
div.timeline .columns > li:nth-child(2n+2) .timeline_element:after {
  left: -35px;
  top: 10px;
}
div.timeline .date_separator {
  clear: both;
  height: 60px;
  position: relative;
  text-align: center;
}
div.timeline .date_separator span {
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  margin-top: -16px;
  position: absolute;
  top: -200%;
  width: 200px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  background-color: #48b1cc;
  color: #ffffff;
}
div.timeline .spine {
  border-radius: 2px;
  position: absolute;
  top: 0;
  width: 4px;
  left: 50%;
  margin-left: -2px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
div.timeline .column_center .timeline_element {
  margin: 20px auto;
  opacity: 1;
}
div.timeline .column_center .timeline_element:before,
div.timeline .column_center .timeline_element:after {
  display: none;
}
.timeline_element {
  border-radius: 5px;
  clear: both;
  margin: 30px 0;
  padding: 20px;
  opacity: 0;
  position: relative;
  transition: all 0.2s linear 0s;
  min-width: 66.6667%;
  text-shadow: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}
.timeline_element.partition-white {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.timeline_element.partition-white:hover {
  border: 1px solid rgba(0, 0, 0, 0.04);
}
.timeline_element.partition-white:after {
  background-color: #ffffff;
}
.timeline_element.partition-white:hover:after {
  background-color: #c3c2c7;
  border: 1px solid #ffffff;
}
.timeline_element.partition-green {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-green:hover {
  border: none;
}
.timeline_element.partition-green:after {
  background-color: #1FBBA6;
}
.timeline_element.partition-green:hover:after {
  background-color: #ffffff;
  border: 1px solid #1FBBA6;
}
.timeline_element.partition-orange {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-orange:hover {
  border: none;
}
.timeline_element.partition-orange:after {
  background-color: #FF6600;
}
.timeline_element.partition-orange:hover:after {
  background-color: #ffffff;
  border: 1px solid #FF6600;
}
.timeline_element.partition-blue {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-blue:hover {
  border: none;
}
.timeline_element.partition-blue:after {
  background-color: #5F8295;
}
.timeline_element.partition-blue:hover:after {
  background-color: #ffffff;
  border: 1px solid #5F8295;
}
.timeline_element.partition-red {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-red:hover {
  border: none;
}
.timeline_element.partition-red:after {
  background-color: #C82E29;
}
.timeline_element.partition-red:hover:after {
  background-color: #ffffff;
  border: 1px solid #C82E29;
}
.timeline_element.partition-azure {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-azure:hover {
  border: none;
}
.timeline_element.partition-azure:after {
  background-color: #0095C8;
}
.timeline_element.partition-azure:hover:after {
  background-color: #ffffff;
  border: 1px solid #0095C8;
}
.timeline_element.partition-purple {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-purple:hover {
  border: none;
}
.timeline_element.partition-purple:after {
  background-color: #804C75;
}
.timeline_element.partition-purple:hover:after {
  background-color: #ffffff;
  border: 1px solid #804C75;
}
.timeline_element:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
.timeline_element:before {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 26px;
  border-top: 1px dashed #CCCCCC;
}
.timeline_element:after {
  border-radius: 100%;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  background-color: #BBBBBB;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}
.timeline_element:hover:after {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  z-index: 100;
}
.timeline_element .timeline_title {
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  padding-top: 10px;
  white-space: nowrap;
}
.timeline_element .timeline_title h4 {
  line-height: 30px;
}
.timeline_element .timeline_date {
  display: block;
}
.timeline_element .timeline_date .day {
  font-size: 52px;
  letter-spacing: -2px;
}
.timeline_element .timeline_content {
  padding-top: 10px;
  padding-bottom: 10px;
}
.timeline_element .readmore {
  padding: 10px 0;
  text-align: right;
}
.timeline-scrubber {
  padding: 8px 0 8px 1px;
  top: 60px;
  right: 0;
  width: 100px;
  z-index: 1;
  list-style: none;
  position: absolute;
}
.timeline-scrubber li {
  margin-bottom: 1px;
}
.timeline-scrubber li:nth-last-child(2) a {
  border-color: #ffffff;
  color: #ffffff;
}
.timeline-scrubber li:last-child a {
  border-color: #ffffff;
  color: #ffffff;
}
.timeline-scrubber a {
  border-left: 5px solid #f7f7f8;
  color: #f7f7f8;
  display: block;
  font-weight: normal;
  outline: medium none;
  padding: 4px 0 4px 6px;
}
.timeline-scrubber a:hover {
  border-color: #c3c2c7 !important;
  color: #c3c2c7 !important;
}
.timeline-scrubber .selected > a {
  border-left-color: #aeacb4 !important;
  color: #aeacb4 !important;
  font-weight: bold !important;
}
/* ie8 fixes */
.ie8 div.timeline_element:after {
  display: none;
}
.ie8 div.timeline_element:before {
  display: none;
}
/**/
.timeline-xs {
  margin: 0;
  padding: 0;
  list-style: none;
}
.timeline-xs .timeline-item {
  position: relative;
  border-left: 1px solid #c8c7cc;
}
.timeline-xs .timeline-item:after {
  background-color: #fff;
  border-color: #48b1cc;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  content: "";
  height: 9px;
  left: 0;
  margin-left: -5px;
  position: absolute;
  width: 9px;
}
.timeline-xs .timeline-item p {
  margin: 0;
  padding-bottom: 10px;
}
.timeline-xs .timeline-item.success {
  border-left-color: #5cb85c;
}
.timeline-xs .timeline-item.success:after {
  border-color: #5cb85c;
}
.timeline-xs .timeline-item.danger {
  border-left-color: #d43f3a;
}
.timeline-xs .timeline-item.danger:after {
  border-color: #d43f3a;
}
.timeline-xs .timeline-item.info {
  border-left-color: #46b8da;
}
.timeline-xs .timeline-item.info:after {
  border-color: #46b8da;
}
.timeline-xs .timeline-item.warning {
  border-left-color: #eea236;
}
.timeline-xs .timeline-item.warning:after {
  border-color: #eea236;
}
.timeline-xs .timeline-item:before,
.timeline-xs .timeline-item:after {
  content: " ";
  display: table;
}
.timeline-xs .timeline-item:after {
  clear: both;
  bottom: auto;
  top: 4px;
}
@media (max-width: 991px) {
  /* 991px */
  div.timeline {
    margin: 0;
  }
  div.timeline .columns li {
    float: none !important;
    width: 100% !important;
  }
  .timeline_element {
    margin: 20px auto !important;
  }
  .timeline_element:after {
    display: none;
  }
  .timeline_element:before {
    display: none;
  }
  .timeline-scrubber {
    display: none;
  }
}
/* ---------------------------------------------------------------------- */
/*  Tables
/* ---------------------------------------------------------------------- */
.table {
  border-collapse: separate;
}
.table > thead > tr > th {
  border-top: none;
}
.table-bordered.table > thead > tr > th {
  border-bottom: 1px solid #ddd;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-bottom: 1px solid #e2e2e4;
  color: #5b5b60 !important;
  border-top: none;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #e7e7e9;
}
.table > tbody > tr.active > td,
.table > tbody > tr.active > th {
  background-color: #e7e7e9 !important;
  color: #5b5b60 !important;
}
.table > tbody > tr.active:hover > td,
.table > tbody > tr.active:hover > th {
  background-color: #dddcdf !important;
  color: #5b5b60 !important;
}
th.center,
td.center {
  text-align: center;
}
td.center .dropdown-menu {
  text-align: left;
}
td.small-width {
  width: 15%;
}
.table thead tr {
  color: #707070;
  font-weight: normal;
}
.table.table-bordered {
  border-right: none;
  border-bottom: none;
}
.table.table-bordered thead tr th {
  vertical-align: middle;
}
.table.table-bordered > thead > tr > th,
.table.table-bordered > tbody > tr > th,
.table.table-bordered > tfoot > tr > th,
.table.table-bordered > thead > tr > td,
.table.table-bordered > tbody > tr > td,
.table.table-bordered > tfoot > tr > td {
  border-top: none;
  border-left: none;
}
.table.table-bordered > thead > tr > th,
.table.table-bordered > tbody > tr > th,
.table.table-bordered > tfoot > tr > th,
.table.table-bordered > thead > tr > td,
.table.table-bordered > tbody > tr > td,
.table.table-bordered > tfoot > tr > td {
  border-top: none;
  border-left: none;
}
.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  vertical-align: middle;
}
.table-striped > tbody > tr:nth-child(2n+1) {
  background-color: #efeff0;
}
.editable-element {
  border-bottom: 1px dashed #0088CC;
  text-decoration: none;
  opacity: 0.5;
  display: inline;
}
.ng-table th {
  text-align: left !important;
}
.ng-table .plus,
.ng-table .minus {
  font-weight: bold;
  padding-left: 18px;
  position: relative;
}
.ng-table .plus:before,
.ng-table .minus:before {
  content: "";
  border-width: 4px;
  border-style: solid;
  left: 8px;
  top: 50%;
  position: absolute;
  margin-top: -2px;
}
.ng-table .plus {
  color: green;
}
.ng-table .plus:before {
  border-color: green;
  border-top: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.ng-table .minus {
  color: red;
}
.ng-table .minus:before {
  border-color: red;
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.odd {
  background: #EFEFF0;
}
.even {
  background: #ffffff;
}
@media (max-width: 767px) {
  .ng-table-pager {
    text-align: center;
  }
  .ng-table-pager .ng-table-counts {
    float: none !important;
  }
  .ng-table-pager .pagination {
    display: block;
  }
  .ng-table-pager .pagination li {
    display: inline-block;
  }
}
table.grid thead th {
  vertical-align: bottom;
}
.table > thead > tr > th[st-sort]:before {
  font-family: FontAwesome;
  content: "\f0dc";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
}
.st-sort-ascent:before {
  content: "\f0de" !important;
}
.st-sort-descent:before {
  content: "\f0dd" !important;
}
table.grid thead tr {
  display: inline-block;
}
.table.grid > tbody > tr > td,
.table.bg-white > tbody > tr > td {
  cursor: pointer;
}
@media (max-width: 767px) {
  .table.bg-white input.form-control[st-search],
  .table.bg-white input.form-control.ui-select-search {
    min-width: 70px;
  }
}
.table thead.sticky-header-class {
  background: #ffffff !important;
  z-index: 5 !important;
  left: inherit !important;
}
.table thead.sticky-header-class tr {
  display: table;
}
/* ---------------------------------------------------------------------- */
/*  User profile
/* ---------------------------------------------------------------------- */
.user-left {
  border-right: 1px solid #DDDDDD;
  padding-right: 15px;
}
@media (max-width: 991px) {
  .user-left {
    border-right: none;
    padding-right: 0;
    margin-bottom: 15px;
  }
}
.user-left a:hover,
.user-left a:focus {
  text-decoration: none;
}
.user-left td .edit-user-info {
  visibility: hidden;
}
.user-left tr:hover .edit-user-info {
  visibility: visible;
}
.user-image {
  position: relative;
  display: inline-block;
}
.user-image img {
  max-width: 150px;
}
.user-image .user-image-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}
.user-image:hover .user-image-buttons {
  display: block;
}
.user-edit-image-buttons {
  display: inline;
}
#projects .progress {
  margin-bottom: 0;
}
.ltwt {
  font-size: 12px;
  line-height: 15px;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.ltwt .ltwt_tweet {
  margin-bottom: 30px;
  padding: 0 0 0 45px;
  position: relative;
}
.ltwt .ltwt_tweet:before {
  content: "\e74b";
  font-family: 'themify';
  font-size: 30px;
  margin-left: -45px;
  position: absolute;
  top: 6px;
  color: #46b8da;
}
.ltwt .ltwt_tweet .ltwt_tweet_text {
  margin-bottom: 5px;
  margin-top: 0;
}
@media (min-width: 768px) {
  .customer-template-image-container {
    width: 50%;
    margin: auto;
  }
}
.customer-template-image-container img {
  max-width: 100%;
}
.customer-template-image-container .template-edit-image-buttons {
  display: inline;
}
/* ---------------------------------------------------------------------- */
/*  Spinner
/* ---------------------------------------------------------------------- */
.csspinner {
  position: relative;
}
.csspinner:before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.csspinner:after {
  z-index: 2;
  content: "";
  position: absolute;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}
/* ---------------------------------------------------------------------- */
/*  Calendar
/* ---------------------------------------------------------------------- */
.calAlert {
  width: 595px;
  float: right;
  margin-bottom: 5px;
}
.calXBtn {
  float: right;
  margin-top: -5px;
  margin-right: -5px;
}
.calWell {
  float: left;
  margin-bottom: 40px;
}
.fc-event.openSesame .fc-event-skin {
  background-color: #e5e50b;
  color: black;
}
.fc-event.customFeed .fc-event-skin {
  background-color: #84deaf;
  color: black;
}
.calTools {
  margin-bottom: 10px;
}
/* ---------------------------------------------------------------------- */
/*  ToDo
/* ---------------------------------------------------------------------- */
.todo {
  list-style: none;
  margin: 0;
  padding: 0;
}
.todo li .todo-actions {
  background: white;
  border-bottom: 1px solid #E4E6EB;
  margin-left: 0 !important;
  padding: 10px 10px 10px 35px !important;
  position: relative;
  display: block;
  color: #8B91A0;
}
.todo li .todo-actions:hover,
.todo li .todo-actions:focus {
  text-decoration: none;
  background-color: #F4F6F9 !important;
}
.todo li .todo-actions > i {
  color: #C7CBD5;
  font-size: 18px;
  margin: 0 5px 0 0;
  position: absolute;
  left: 10px;
  top: 11px;
  cursor: pointer;
}
.todo li .label {
  position: absolute;
  right: 10px;
  padding: 6px;
}
.todo .todo-tools {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}
/* ---------------------------------------------------------------------- */
/*  Chat
/* ---------------------------------------------------------------------- */
.discussion {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block;
}
.discussion .other {
  float: left;
}
.discussion .other .message:last-child .message-text {
  border-radius: 16px 16px 16px 0;
  float: left;
}
.discussion .other .message:last-child .message-avatar {
  display: block;
}
.discussion .other .message:last-child .message-name {
  display: none;
}
.discussion .other .message {
  float: left;
}
.discussion .other .message:first-child .message-name {
  display: block !important;
}
.discussion .other .message-text {
  background-color: #e5e5ea;
  color: #2C2F3B;
  padding-left: 22px;
  margin-left: 32px;
}
.discussion .other .message-avatar {
  left: 10px;
}
.discussion .self {
  float: right;
}
.discussion .self .message:last-child .message-text {
  border-radius: 16px 16px 0;
  float: left;
}
.discussion .self .message:last-child .message-avatar {
  display: block;
}
.discussion .self .message:last-child .message-name {
  display: none;
}
.discussion .self .message {
  float: right;
}
.discussion .self .message:first-child .message-name {
  display: block !important;
}
.discussion .self .message-text {
  background-color: #00d449;
  color: #fff;
  padding-right: 22px;
  margin-right: 32px;
}
.discussion .self .message-avatar {
  float: right;
}
.discussion .self .message-name {
  margin-left: auto !important;
  margin-right: 48px;
  text-align: right;
}
.discussion .self .message-avatar {
  right: 10px;
}
.discussion li {
  position: relative;
  margin: 0 0 10px 0;
  width: 80%;
}
.discussion li.messages-date {
  display: inline-block;
  color: #8e8e93;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  margin: 10px 0;
  text-align: center;
  width: 100% !important;
}
.discussion li.nextSame {
  margin-bottom: 0 !important;
}
.discussion li.nextSame .message-text {
  border-radius: 16px !important;
}
.discussion li.nextSame .message-avatar {
  display: none !important;
}
.discussion .message {
  margin: 1px 10px 0 10px;
  clear: both;
}
.discussion .message-name {
  color: #8e8e93;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 2px;
  margin-top: 7px;
  margin-left: 48px;
  display: none;
}
.discussion .message-text {
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.2;
  min-height: 30px;
  min-width: 48px;
  padding: 6px 16px 9px;
}
.discussion .message-avatar {
  display: none;
  position: absolute;
  bottom: -5px;
  height: 29px;
  width: 29px;
}
.discussion .message-avatar img {
  border-radius: 100%;
  width: 100%;
}
.message-bar {
  position: relative;
  background: #f7f7f8;
  height: 44px;
  width: 100%;
  display: table;
}
.message-bar .message-inner {
  height: 100%;
  padding: 0 8px;
  display: table-row;
  width: 100%;
}
.message-bar .message-area {
  display: table-cell;
}
.message-bar textarea,
.message-bar input {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: none;
  font-family: inherit;
  height: 28px;
  line-height: 20px;
  margin: 8px 0;
  padding: 3px 8px;
  resize: none;
  width: 100%;
}
.message-bar .link {
  padding: 0 10px;
  line-height: 44px;
  position: relative;
  display: table-cell;
  width: 30px;
  vertical-align: middle;
}
.message-bar a.icon-only {
  color: #8e8e93;
  font-size: 20px;
  margin: 0;
  margin-right: 8px;
}
/* ---------------------------------------------------------------------- */
/*  Messages
/* ---------------------------------------------------------------------- */
.panel-body.messages {
  padding: 0;
}
.messages-list {
  border-right: 1px solid #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  top: 65px;
  bottom: 0;
}
.messages-list .messages-item {
  border-bottom: 1px solid #c8c7cc;
  padding: 5px 15px 5px 25px;
  position: relative;
}
.messages-list .messages-item:hover {
  background-color: #ffffff;
  cursor: pointer !important;
}
.messages-list .messages-item:hover .messages-item-star {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
}
.messages-list .messages-item.active {
  background-color: #ffffff;
}
.messages-list .messages-item.starred .messages-item-star {
  display: block;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.messages-list .messages-item span {
  color: #48b1cc;
  display: block;
}
.messages-list .messages-item span .spam {
  font-style: normal;
}
.messages-list .messages-item .messages-item-star,
.messages-list .messages-item .messages-item-attachment,
.messages-list .messages-item .messages-item-time,
.messages-list .messages-item .messages-item-actions {
  position: absolute;
}
.messages-list .messages-item .messages-item-star {
  color: #48b1cc;
  left: 7px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0s;
  -moz-transition: opacity 0.3s ease-in-out 0s;
  -ms-transition: opacity 0.3s ease-in-out 0s;
  -o-transition: opacity 0.3s ease-in-out 0s;
}
.messages-list .messages-item .messages-item-star:hover,
.messages-list .messages-item .messages-item-star:active,
.messages-list .messages-item .messages-item-star:focus {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.messages-list .messages-item .messages-item-attachment {
  left: 7px;
  top: 25px;
  color: #5F8295;
}
.messages-list .messages-item .messages-item-avatar {
  border-radius: 4px;
  float: left;
  height: 40px;
  width: 40px;
}
.messages-list .messages-item .messages-item-from,
.messages-list .messages-item .messages-item-subject,
.messages-list .messages-item .messages-item-content {
  margin-left: 45px;
}
.messages-list .messages-item .messages-item-from {
  font-weight: bold;
  margin-top: 2px;
  color: #aeacb4;
}
.messages-list .messages-item .messages-item-time {
  right: 15px;
  top: 8px;
}
.messages-list .messages-item .messages-item-time .text {
  color: #8e8e93;
  font-size: 11px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0s;
  -moz-transition: opacity 0.3s ease-in-out 0s;
  -ms-transition: opacity 0.3s ease-in-out 0s;
  -o-transition: opacity 0.3s ease-in-out 0s;
  width: 65px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions > a,
.messages-list .messages-item .messages-item-time .messages-item-actions .dropdown > a {
  margin-left: 5px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions > div {
  display: inline-block;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .dropdown-menu {
  margin-top: 0;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon {
  border-radius: 30px;
  display: inline-block;
  height: 11px;
  margin: 0 5px 0 -13px;
  width: 11px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon.red {
  background: #C82E29;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon.blue {
  background: #5F8295;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon.green {
  background: #1FBBA6;
}
.messages-list .messages-item .messages-item-subject {
  font-size: 14px;
  margin-bottom: 4px;
  color: #5b5b60;
}
.messages-list .messages-item .messages-item-content {
  font-size: 12px;
  color: #c2c2c5;
  margin-bottom: 4px;
}
.messages-list .messages-item .messages-item-preview {
  color: #aeacb4;
  font-size: 12px;
}
.wrap-options {
  border-bottom: 1px solid #eeeeee;
  min-height: 65px;
}
.messages-search {
  padding: 15px;
  width: 100%;
}
@media (max-width: 1200px) {
  .messages-search {
    display: none;
    position: absolute;
    top: 0;
  }
}
.message-actions {
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #EFEFF4;
}
.message-actions .actions {
  list-style: none;
}
.message-actions .actions > li {
  float: left;
  height: 65px;
  position: relative;
}
@media (max-width: 480px) {
  .message-actions .actions > li {
    display: none;
  }
}
.message-actions .actions > li:after {
  background: rgba(0, 0, 0, 0.07);
  content: "";
  height: 14px;
  position: absolute;
  right: -4px;
  top: 26px;
  width: 1px;
}
.message-actions .actions > li.actions-dropdown {
  display: none;
}
@media (max-width: 480px) {
  .message-actions .actions > li.actions-dropdown {
    display: block;
  }
}
.message-actions .actions > li > a,
.message-actions .actions > li > span > a {
  font-size: 12px;
  font-weight: 600;
  line-height: 65px;
  padding: 0 13px;
  white-space: nowrap;
  color: #8e8e93;
}
.message-actions .actions > li.email-list-toggle {
  display: none;
}
@media (max-width: 768px) {
  .message-actions .actions > li.email-list-toggle {
    display: block !important;
  }
}
.message-header {
  border-bottom: 1px solid #ffffff;
  padding: 75px 15px 10px;
}
.message-header .message-item-avatar {
  border-radius: 4px;
  float: left;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.message-header .message-from {
  font-weight: bold;
  color: #5b5b60;
}
.message-header .message-to {
  color: #c2c2c5;
}
.message-header .message-time {
  color: #c2c2c5;
  font-size: 11px;
}
.message-subject {
  padding: 20px 10px 0;
  color: #5b5b60;
  font-size: 17px;
}
.message-content {
  padding: 20px 10px;
  color: #8e8e93;
}
.inbox {
  border-spacing: 0;
  height: 100%;
  margin: 0 -15px;
}
.inbox .col {
  float: left;
  height: 100%;
  vertical-align: top;
}
.inbox .email-options {
  width: 240px !important;
  border-right: 1px solid #dee5e7;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .inbox .email-options {
    display: none;
  }
}
.inbox .email-options .email-options-title {
  color: #808285;
  font-size: 10px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.inbox .email-options .main-options {
  margin: 0;
  list-style: none;
  padding: 0;
}
.inbox .email-options .main-options li {
  line-height: 34px;
}
.inbox .email-options .main-options li .badge {
  margin-top: 8px;
}
.no-messages {
  bottom: 0;
  left: 0;
  margin-top: -34px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}
.inbox .email-list {
  width: 360px !important;
  border-right: 1px solid #dee5e7;
  background: #ffffff;
}
@media (max-width: 768px) {
  .inbox .email-list {
    width: 100% !important;
    border-right: none;
    background: #ffffff;
    position: relative;
  }
}
.inbox .wrap-list {
  width: 359px;
  height: 100% !important;
  position: relative;
}
.inbox .wrap-list .close-message-search {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 100;
  display: none;
}
.inbox .wrap-list .search-open .close-message-search {
  display: block;
}
.inbox .wrap-list .search-open .open-message-search {
  display: none;
}
.inbox .wrap-list .search-open .messages-options .btn-group {
  display: none;
}
.inbox .wrap-list .search-open .messages-search {
  display: block !important;
  right: 50px;
  left: 0;
  width: auto;
}
@media (max-width: 768px) {
  .inbox .wrap-list {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .inbox .wrap-list .messages-options {
    display: none;
  }
}
.inbox .email-reader {
  height: 100%;
  margin-left: 600px;
  overflow: hidden;
  position: relative;
  padding: 0;
  background: #ffffff;
}
@media (max-width: 1200px) {
  .inbox .email-reader {
    margin-left: 360px;
  }
}
@media (max-width: 768px) {
  .inbox .email-reader {
    margin-left: 0;
  }
}
/* ie8 fixes */
.ie8 .messages-list .messages-item .messages-item-star {
  display: none;
}
.ie8 .messages-list .messages-item:hover .messages-item-star {
  display: block;
}
/**/
/* ie8 fixes */
.ie8 .messages-list .messages-item .messages-item-time .messages-item-actions {
  display: none;
}
/**/
/* ---------------------------------------------------------------------- */
/*  Modals
/* ---------------------------------------------------------------------- */
.modal-backdrop {
  background-color: #000;
  bottom: 0;
  position: fixed;
}
.modal-backdrop.in {
  opacity: 0.4;
}
.modal-backdrop {
  z-index: 10011 !important;
}
.modal {
  z-index: 10012 !important;
}
.modal-content {
  background: #e8e8e8;
  box-shadow: none;
}
.modal-footer,
.modal-header {
  border-color: #b5b5b5;
}
.ng-aside.horizontal .modal-dialog {
  min-height: 100% !important;
  bottom: 0;
  height: auto !important;
}
.ng-aside.horizontal .modal-dialog .modal-content {
  height: auto !important;
  min-height: 100% !important;
}
.close {
  font-size: 35px;
  margin-left: 10px;
}
/* ---------------------------------------------------------------------- */
/*  Maps
/* ---------------------------------------------------------------------- */
#map_canvas {
  position: relative;
}
.angular-google-map-container {
  height: 400px;
}
/* ---------------------------------------------------------------------- */
/*  Pricing Tables
/* ---------------------------------------------------------------------- */
.pricing-table .plan {
  border: none;
  margin: 20px 0;
  position: relative;
  padding: 0;
  list-style: none;
  border: 1px solid #e2e2e4;
  border-bottom: none;
  text-align: center;
}
.pricing-table .plan li {
  padding: 10px 15px;
  color: #8e8e93;
  border-bottom: 1px solid #e2e2e4;
}
.pricing-table .plan li.plan-name {
  padding: 15px;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  background: #d5d4d8;
  border-top: 0;
  border-bottom: 0;
}
.pricing-table .plan li.plan-price {
  background: #c8c7cc;
  color: #ffffff;
  font-size: 18px;
  padding: 6px 20px;
  border-bottom: none;
}
.pricing-table .plan li.plan-price h3 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2em;
  padding: 30px 0;
  font-weight: 600;
  color: #ffffff;
}
.pricing-table .plan li.plan-price h3 .fa,
.pricing-table .plan li.plan-price h3 span {
  display: inline-block;
}
.pricing-table .plan li.plan-price h3 .price-cents {
  font-size: 50%;
  vertical-align: super;
  margin-left: -10px;
}
.pricing-table .plan li.plan-price h3 .price-month {
  font-size: 30%;
  font-style: italic;
  margin-left: -20px;
  font-weight: 300;
  color: #ffffff;
}
.pricing-table .plan li.plan-action {
  margin-top: 10px;
  border-top: 0;
}
.pricing-table .plan .btn.deselect-plan {
  display: none;
  margin-top: 0;
}
.pricing-table .plan.featured {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9) !important;
}
.pricing-table .plan.featured .plan-name {
  background: #94929b;
}
.pricing-table.selected {
  box-shadow: 0 0 1px 3px #46b8da;
}
.pricing-table.selected .btn.select-plan {
  display: none;
}
.pricing-table.selected .btn.deselect-plan {
  display: inline-block;
  background-color: #ddd;
  border-color: #ddd;
}
.pricing-table.selected .btn.deselect-plan:hover {
  background-color: #ddd !important;
}
.pricing-green .plan li strong {
  color: #C82E29;
}
.pricing-green .plan li.plan-name {
  background: #23d1b9;
}
.pricing-green .plan li.plan-price {
  background: #1FBBA6;
  color: #ffffff;
}
.pricing-green .plan.featured .plan-name {
  background: #106458;
}
.pricing-blue .plan li strong {
  color: #5F8295;
}
.pricing-blue .plan li.plan-name {
  background: #6c8fa1;
}
.pricing-blue .plan li.plan-price {
  background: #5F8295;
  color: #ffffff;
}
.pricing-blue .plan.featured .plan-name {
  background: #374c57;
}
.pricing-red .plan li strong {
  color: #C82E29;
}
.pricing-red .plan li.plan-name {
  background: #d63a35;
}
.pricing-red .plan li.plan-price {
  background: #C82E29;
  color: #ffffff;
}
.pricing-red .plan.featured .plan-name {
  background: #731b18;
}
/* ---------------------------------------------------------------------- */
/*  404 and 500 error
/* ---------------------------------------------------------------------- */
.error-full-page .page-error {
  margin-top: 60px;
}
.page-error {
  text-align: center;
}
.page-error .error-number {
  display: block;
  font-size: 158px;
  font-weight: 300;
  letter-spacing: -10px;
  line-height: 128px;
  margin-top: 0;
  text-align: center;
}
.page-error .error-details {
  display: block;
  padding-top: 0;
  text-align: center;
}
.page-error .error-details .btn-return {
  margin: 10px 0;
}
.page-error .error-details h3 {
  margin-top: 0;
}
.error-full-page {
  overflow: hidden;
}
.error-full-page img {
  display: none;
}
.error-full-page #canvas {
  position: absolute;
  top: 0px;
  left: 0px;
}
.error-full-page #sound {
  position: absolute;
  width: 30%;
  height: 30%;
  overflow-y: auto;
  margin-left: 35%;
  -moz-border-radius: 15px;
  border-radius: 15px;
  opacity: 0.3;
  margin-top: 5%;
}
.error-full-page .video {
  position: absolute;
  width: 90%;
  height: 80%;
  margin-left: 5%;
  margin-top: 5%;
}
/* ---------------------------------------------------------------------- */
/*  Page Title
/* ---------------------------------------------------------------------- */
#page-title {
  border-bottom: 1px solid #eee;
  padding: 50px 0;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
@media (max-width: 767px) {
  #page-title {
    text-align: center;
    padding: 20px 0;
  }
}
#page-title .row {
  margin: 0 15px;
}
#page-title h1 {
  color: #333;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  #page-title h1 {
    font-size: 24px;
  }
}
#page-title .mainDescription {
  font-family: "Lato", sans-serif;
  color: #777;
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin-top: 0;
}
#page-title .mainDescription small {
  font-size: 60%;
  margin-top: 10px;
  display: block;
}
#page-title .breadcrumb {
  background-color: transparent !important;
  font-size: 12px;
  left: auto !important;
  margin: -30px 0 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 30px !important;
  top: 50% !important;
  width: auto !important;
  font-family: "Lato", sans-serif;
}
@media (max-width: 767px) {
  #page-title .breadcrumb {
    left: 0 !important;
    margin: 15px 0 0 !important;
    position: relative !important;
    right: 0 !important;
    top: 0 !important;
  }
}
#page-title .breadcrumb > li + li {
  padding: 0 5px;
  position: relative;
}
#page-title .breadcrumb > li + li:before {
  color: #007AFF;
  content: "/ ";
  padding: 0 5px;
}
#page-title.page-title-center {
  text-align: center;
}
#page-title.page-title-center .breadcrumb {
  left: 0 !important;
  margin: 15px 0 0 !important;
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
}
/**
    Style guide information
*/
.style-info {
  color: #fff;
  background-color: #eda200;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 0;
  border-color: transparent;
  font-weight: 600;
}
.style-info.alert-dismissible .close {
  top: -5px;
  text-shadow: none;
  color: #fff !important;
  opacity: 1;
}
.style-info.account-explorer {
  margin-bottom: 0;
}
/**
    Heading styling
*/
.main-content:not(.dohy-dashboard-container) #page-title:not(.no-header) {
  background-color: #f7f7f8;
}
.main-content:not(.dohy-dashboard-container) #page-title:not(.no-header) h1 {
  color: #4a4a4a;
}
.main-content:not(.dohy-dashboard-container) #page-title:not(.no-header) .mainDescription {
  color: #4a4a4a;
}
.main-content.ingram-micro-main-content:not(.dohy-dashboard-container) #page-title:not(.no-header) {
  background-color: #f7f7f8;
}
.main-content.ingram-micro-main-content:not(.dohy-dashboard-container) #page-title:not(.no-header) h1 {
  color: #4a4a4a;
}
.main-content.ingram-micro-main-content:not(.dohy-dashboard-container) #page-title:not(.no-header) .mainDescription {
  color: #4a4a4a;
}
/* ---------------------------------------------------------------------- */
/*  Tooltips
/* ---------------------------------------------------------------------- */
.static-tooltip .tooltip {
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  position: relative;
}
.tooltip-inner {
  word-wrap: break-word;
}
/* ---------------------------------------------------------------------- */
/*  Popover
/* ---------------------------------------------------------------------- */
.static-popover .popover {
  display: block;
  float: left;
  margin: 20px;
  position: relative;
  width: 260px;
}
/* ---------------------------------------------------------------------- */
/*  Datepicker
/* ---------------------------------------------------------------------- */
.picker__box {
  border-color: #ffffff !important;
}
.picker__nav--prev:before,
.picker__nav--next:before {
  border: none !important;
  display: block;
  height: auto !important;
  margin: 0 auto;
  width: auto !important;
  font-family: 'themify';
}
.picker__nav--next:before {
  content: "\e649" !important;
}
.picker__nav--prev:before {
  content: "\e64a" !important;
}
.picker__button--close:before {
  content: "\e646" !important;
  font-family: 'themify';
}
.picker__button--clear:before {
  border: none !important;
  top: auto !important;
  width: auto !important;
  font-family: 'themify';
  content: "\e6a5" !important;
  font-size: 1.1em;
}
.picker__weekday {
  color: #48b1cc !important;
  text-align: center;
}
[pick-a-date],
[pick-a-time] {
  cursor: pointer !important;
}
[date-picker] {
  padding: 5px 20px !important;
  min-width: 230px;
}
[date-picker] th {
  color: #48b1cc;
  font-weight: bold;
}
[date-picker] .switch {
  color: #5b5b60;
}
[date-picker] .active,
[date-picker] .now {
  background-color: #48b1cc !important;
  background-image: none !important;
  border: none !important;
  text-shadow: none !important;
  color: #ffffff !important;
}
[date-picker] .now {
  background-color: #ffffff !important;
  color: #48b1cc !important;
  font-weight: bold;
}
[date-picker] .active:hover,
[date-picker] .now:hover,
[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active,
[date-picker] .active.disabled,
[date-picker] .now.disabled,
[date-picker] .active[disabled],
[date-picker] .now[disabled] {
  color: #ffffff !important;
  background-color: #48b1cc !important;
}
[date-picker] .disabled {
  color: #c2c2c5 !important;
}
[date-picker] .disabled.active,
[date-picker] .disabled.now {
  color: #ffffff !important;
}
.indigeweb-skeuocard input {
  color: #007fbc;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 6px !important;
  padding: 10px 16px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.indigeweb-skeuocard input:focus {
  border: 1px solid #4bafca !important;
  -webkit-box-shadow: 0px 0px 0 3px #e0f1fc !important;
  -moz-box-shadow: 0px 0px 0 3px #e0f1fc !important;
  box-shadow: 0px 0px 0 3px #e0f1fc !important;
}
.indigeweb-skeuocard .has-success .form-control {
  border-color: #2ecc71 !important;
}
.indigeweb-skeuocard .has-success .glyphicon {
  color: #2ecc71;
}
.indigeweb-skeuocard .has-error .form-control {
  border-color: #e74c3c !important;
}
.indigeweb-skeuocard .has-error .glyphicon {
  color: #e74c3c;
}
.indigeweb-skeuocard .has-error .help-block {
  color: #e74c3c;
}
.indigeweb-skeuocard ::-webkit-input-placeholder {
  color: #cdcdcd;
}
.indigeweb-skeuocard :-moz-placeholder {
  color: #cdcdcd;
}
.indigeweb-skeuocard ::-moz-placeholder {
  color: #cdcdcd;
}
.indigeweb-skeuocard :-ms-input-placeholder {
  color: #cdcdcd;
}
.indigeweb-skeuocard .form-control-feedback {
  position: absolute;
  right: 8px;
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  color: #fff !important;
  background: #47b1cc;
  border: 1px solid #357ebd;
  border-radius: 4px;
  height: 25px;
  padding: 0 5px;
  line-height: 25px;
  font-size: 14px;
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item .close.ui-select-match-close {
  margin-left: 0px;
  margin-top: 5px;
}
.app-sonicwall-body .ui-select-multiple.ui-select-bootstrap .ui-select-match-item,
.app-sonicwall-body tags-input .tags .tag-item {
  background-color: #06293d;
  border: 1px solid #06293d;
}
.app-brandforce-body .ui-select-multiple.ui-select-bootstrap .ui-select-match-item,
.app-brandforce-body tags-input .tags .tag-item {
  background-color: #06293d;
  border: 1px solid #06293d;
}
.app-blueequinox-body .ui-select-multiple.ui-select-bootstrap .ui-select-match-item,
.app-blueequinox-body tags-input .tags .tag-item {
  background-color: #1279C0 !important;
  border: 1px solid #2A3D46 !important;
}
.contacts .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  font-size: 12px;
  height: 22px;
  line-height: 22px;
}
.daterangepicker.dropdown-menu.opensleft.show-calendar {
  z-index: 9999;
}
table.reflow {
  width: 100%;
}
table.reflow th,
table.reflow td {
  text-align: left;
  border-bottom: 1px dashed silver;
}
table.reflow .cell-label {
  display: none;
}
@media screen and (max-width: 480px) {
  table.reflow th {
    display: none;
  }
  table.reflow tr td {
    float: left;
    clear: left;
    display: block;
    width: 100%;
  }
  table.reflow tr td:last-child {
    padding-bottom: 40px;
    border-bottom: 0;
  }
  table.reflow .cell-label {
    display: block;
    float: left;
  }
  table.reflow .cell-content {
    display: block;
    float: right;
  }
}
.privacy-center-info {
  max-width: 1000px;
  margin: 0 auto;
}
.privacy-center-info fieldset {
  padding-top: 30px!important;
  padding-left: 20px !important;
}
.privacy-center-info fieldset legend {
  top: 5px !important;
}
.privacy-center-info table {
  margin-bottom: 0px;
}
@media screen and (min-width: 768px) {
  .privacy-center-info table.session-table .session-date {
    width: 20%;
  }
  .privacy-center-info table.session-table .session-duration {
    width: 15%;
  }
  .privacy-center-info table.session-table .session-views {
    width: 15%;
  }
  .privacy-center-info table.session-table .session-location {
    width: 30%;
  }
  .privacy-center-info table.session-table .session-landing {
    width: 20%;
  }
  .privacy-center-info td.text-adjust-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1px;
  }
  .privacy-center-info td.text-adjust-col span.text-adjust-inner-content {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .privacy-center-info table.cookie-table {
    word-break: break-word;
  }
}
@media screen and (min-width: 768px) {
  .privacy-center-info table.cookie-table .cookie-name {
    width: 25%;
  }
  .privacy-center-info table.cookie-table .cookie-value {
    width: 65%;
  }
  .privacy-center-info table.cookie-table .cookie-link {
    width: 10%;
  }
}
/* ---------------------------------------------------------------------- */
/*  Pages
/* ---------------------------------------------------------------------- */
.pages-modal .tab-content {
  max-height: 350px;
  overflow: auto;
}
.pages-modal .tab-content h1 {
  line-height: 65px;
}
.pages-modal .tab-content .template-select {
  border-bottom: 1px solid #eee;
  margin: 20px 0;
  padding-bottom: 20px;
}
.pages-modal.no-padding.pages-padding-top {
  padding-top: 1px !important;
}
/* ---------------------------------------------------------------------- */
/*  Page Detail
/* ---------------------------------------------------------------------- */
.page-detail {
  margin: 0 -15px !important;
}
.page-detail #iframe-website {
  border: none;
  width: 100%;
  height: 100%;
}
/* ---------------------------------------------------------------------- */
/*  Customer Detail
/* ---------------------------------------------------------------------- */
.customer-detail .no-location {
  line-height: 330px;
}
.customer-detail .notes {
  padding: 0;
  margin-bottom: 0;
}
.customer-detail .notes li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px;
}
.customer-detail .notes li:last-child {
  border-bottom: none;
}
.customer-detail span.user-notes {
  white-space: pre-wrap;
}
.side-margin {
  margin: 5px;
}
.autocomplete ul {
  padding: 0px;
}
.customer-tags ul.dropdown-menu {
  max-height: 138px;
  z-index: 1090;
  top: inherit !important;
}
.customer-tags .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  min-width: 50%;
}
.intl-tel-input {
  display: block !important;
}
contact-details-component .btn-container,
anonymous-contact-details-component .btn-container {
  margin-top: 10px;
  position: relative;
}
contact-details-component .share-btn-container .share-dossier-btn,
anonymous-contact-details-component .share-btn-container .share-dossier-btn {
  position: absolute;
  right: 0px;
  top: -40px;
  z-index: 99;
}
contact-details-component .back-btn,
anonymous-contact-details-component .back-btn {
  position: absolute;
  left: 0px;
  top: -40px;
  z-index: 99;
}
contact-details-component .outcall-leads .outcall-picker .dropdown-menu,
anonymous-contact-details-component .outcall-leads .outcall-picker .dropdown-menu {
  margin-top: -285px !important;
}
contact-details-component .container-fullw,
anonymous-contact-details-component .container-fullw {
  border-bottom: none !important;
}
contact-details-component .loading-contacts,
anonymous-contact-details-component .loading-contacts {
  position: relative;
}
contact-details-component .contact-details-left-pane,
anonymous-contact-details-component .contact-details-left-pane {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}
contact-details-component .contact-details-left-pane .contact-profile-stats,
anonymous-contact-details-component .contact-details-left-pane .contact-profile-stats {
  margin-left: -15px;
  padding-left: 15px;
  margin-bottom: 15px;
}
contact-details-component .contact-details-left-pane .contact-profile-stats .contact-image .contact-image-container,
anonymous-contact-details-component .contact-details-left-pane .contact-profile-stats .contact-image .contact-image-container {
  position: relative;
  display: inline-block;
}
contact-details-component .contact-details-left-pane .contact-profile-stats .contact-image img,
anonymous-contact-details-component .contact-details-left-pane .contact-profile-stats .contact-image img {
  max-width: 100%;
  width: 100%;
}
contact-details-component .contact-details-left-pane .contact-profile-stats .contact-image span.fa,
anonymous-contact-details-component .contact-details-left-pane .contact-profile-stats .contact-image span.fa {
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}
contact-details-component .contact-details-left-pane .contact-profile-stats .account-info img,
anonymous-contact-details-component .contact-details-left-pane .contact-profile-stats .account-info img {
  width: 30px;
  margin-right: 5px;
}
contact-details-component .contact-details-left-pane .contact-social-icons ul,
anonymous-contact-details-component .contact-details-left-pane .contact-social-icons ul {
  list-style: none;
  padding: 0;
  bottom: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}
contact-details-component .contact-details-left-pane .contact-social-icons ul li,
anonymous-contact-details-component .contact-details-left-pane .contact-social-icons ul li {
  display: inline-block;
  vertical-align: bottom;
}
contact-details-component .contact-details-left-pane .contact-social-icons ul li span,
anonymous-contact-details-component .contact-details-left-pane .contact-social-icons ul li span {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  line-height: 35px;
  border: 2px solid #fff;
  margin-right: 5px;
  text-align: center;
  vertical-align: bottom;
}
contact-details-component .contact-details-left-pane .contact-social-icons ul li.website-icon span,
anonymous-contact-details-component .contact-details-left-pane .contact-social-icons ul li.website-icon span {
  font-size: 36px;
  line-height: 40px;
  color: #666;
  display: inline-block;
  border: unset;
}
contact-details-component .contact-details-left-pane .contact-social-icons ul li.website-icon span.gray-bg-color,
anonymous-contact-details-component .contact-details-left-pane .contact-social-icons ul li.website-icon span.gray-bg-color {
  color: #c8c7cc !important;
}
contact-details-component .contact-details-left-pane .contact-social-icons ul li .gray-bg,
anonymous-contact-details-component .contact-details-left-pane .contact-social-icons ul li .gray-bg {
  background: #c8c7cc !important;
}
contact-details-component .contact-details-left-pane .contact-stats,
anonymous-contact-details-component .contact-details-left-pane .contact-stats {
  background: #47B2D2;
}
contact-details-component .contact-details-left-pane .contact-stats span.description,
anonymous-contact-details-component .contact-details-left-pane .contact-stats span.description {
  display: block;
  color: #ECF2FE;
}
contact-details-component .contact-details-left-pane .contact-stats span.value,
anonymous-contact-details-component .contact-details-left-pane .contact-stats span.value {
  font-size: 30px;
  color: #FFFBFF;
}
contact-details-component .contact-details-left-pane .contact-stats .sessions,
anonymous-contact-details-component .contact-details-left-pane .contact-stats .sessions {
  width: 20%;
  float: left;
}
contact-details-component .contact-details-left-pane .contact-stats .page-views,
anonymous-contact-details-component .contact-details-left-pane .contact-stats .page-views {
  width: 27%;
  float: left;
  border-left: 1px solid #8e8e93;
  padding-left: 5%;
}
contact-details-component .contact-details-left-pane .contact-stats .session-duration,
anonymous-contact-details-component .contact-details-left-pane .contact-stats .session-duration {
  width: 32%;
  float: left;
  border-left: 1px solid #8e8e93;
  padding-left: 5%;
}
contact-details-component .contact-details-left-pane .contact-stats .clicks,
anonymous-contact-details-component .contact-details-left-pane .contact-stats .clicks {
  width: 21%;
  float: left;
  border-left: 1px solid #8e8e93;
  padding-left: 5%;
}
contact-details-component .contact-details-left-pane .contact-attribution a,
anonymous-contact-details-component .contact-details-left-pane .contact-attribution a {
  cursor: default;
}
contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn,
anonymous-contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn {
  border: 1px solid #65b2c1;
  margin-right: 5px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 4px;
  font-size: 12px;
  font-family: helvetica, sans-serif;
  padding: 3px 6px;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.3);
  color: #ECF2FE;
  background-color: #47B2D2;
}
contact-details-component .contact-details-left-pane .contact-details-tab .tabbable,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable {
  background: transparent;
}
contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .tab-content > .tab-pane,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .tab-content > .tab-pane {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: -15px;
  margin-right: -15px;
}
contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs {
  border-bottom: 2px solid #ddd;
}
contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li {
  margin-bottom: -1px;
  border: 1px solid #8e8e93;
  font-weight: bold;
  margin-right: -1px;
  background: #eee;
}
contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li a,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li a {
  box-shadow: none !important;
  background: transparent !important;
  padding: 15px 15px;
}
@media all and (max-width: 492px) {
  contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li a,
  anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li a {
    padding: 15px 3px;
    font-size: 11px;
  }
}
@media all and (min-width: 768px) and (max-width: 1171px) {
  contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li a,
  anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li a {
    padding: 15px 3px;
    font-size: 11px;
  }
}
contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li.active,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .tabbable .nav-tabs li.active {
  background: #fff;
}
@media all and (min-width: 768px) {
  contact-details-component .contact-details-left-pane .contact-details-tab .address-pane .details,
  anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .address-pane .details {
    margin-top: 80px;
  }
}
contact-details-component .contact-details-left-pane .contact-details-tab .details,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details {
  margin-bottom: 5px;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details span.description,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details span.description {
  font-weight: bold;
  display: inline-block;
  width: 35%;
  float: left;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details ul.contact-tags,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details ul.contact-tags {
  list-style: none;
  display: inline-block;
  padding-left: 0;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details ul.contact-tags li,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details ul.contact-tags li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details ul.contact-tags li a.contact-tags-btn,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details ul.contact-tags li a.contact-tags-btn {
  border: 1px solid #94BDF0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  font-size: 18px;
  font-family: helvetica, sans-serif;
  padding: 3px 6px;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.3);
  color: #94BDF0;
  background-color: #ECF2FE;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ECF2FE), to(#ECF2FE));
  background-image: -webkit-linear-gradient(top, #ECF2FE, #ECF2FE);
  background-image: -moz-linear-gradient(top, #ECF2FE, #ECF2FE);
  background-image: -ms-linear-gradient(top, #ECF2FE, #ECF2FE);
  background-image: -o-linear-gradient(top, #ECF2FE, #ECF2FE);
  background-image: linear-gradient(to bottom, #ECF2FE, #ECF2FE);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#ECF2FE, endColorstr=#ECF2FE);
}
contact-details-component .contact-details-left-pane .contact-details-tab .details .full-name,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details .full-name {
  font-size: 18px;
  padding-right: 10px;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details .value,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details .value {
  display: inline-block;
  width: 65%;
  word-break: break-word;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details .address-desc,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details .address-desc {
  display: block;
}
contact-details-component .contact-details-left-pane .contact-details-tab .details .address-value,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .details .address-value {
  margin-left: 0;
}
contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group {
  padding-right: 0;
}
contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group .clip-radio,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group .clip-radio {
  margin-top: 0;
}
contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group .clip-radio label,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group .clip-radio label {
  margin-right: 5px;
}
contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group .intl-tel-input.allow-dropdown input[type=text],
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .form-group .intl-tel-input.allow-dropdown input[type=text] {
  padding-right: 30px;
}
contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .address-fields,
anonymous-contact-details-component .contact-details-left-pane .contact-details-tab .edit-contact-info .address-fields {
  padding-right: 0;
  margin-bottom: 20px;
}
contact-details-component .contact-details-left-pane .ui-select-multiple.ui-select-bootstrap input.ui-select-search,
anonymous-contact-details-component .contact-details-left-pane .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  min-width: 50%;
}
contact-details-component .contact-details-right-pane,
anonymous-contact-details-component .contact-details-right-pane {
  background: #FCFAFD;
  padding-left: 25px;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  margin-left: -1px;
}
contact-details-component .contact-details-right-pane .panel-white,
anonymous-contact-details-component .contact-details-right-pane .panel-white {
  border: none !important;
}
contact-details-component .contact-details-right-pane .activity-date-filter span.filter-label,
anonymous-contact-details-component .contact-details-right-pane .activity-date-filter span.filter-label {
  position: absolute;
  left: 10%;
  margin-top: 5px;
}
contact-details-component .contact-details-right-pane .activity-date-filter .input-group,
anonymous-contact-details-component .contact-details-right-pane .activity-date-filter .input-group {
  width: 100%;
  float: right;
  margin-top: -10px;
}
contact-details-component .contact-details-right-pane .activity-date-filter .input-group .dropdown-menu,
anonymous-contact-details-component .contact-details-right-pane .activity-date-filter .input-group .dropdown-menu {
  left: 10px !important;
}
@media all and (max-width: 991px) {
  contact-details-component .contact-details-right-pane .activity-date-filter .input-group .dropdown-menu,
  anonymous-contact-details-component .contact-details-right-pane .activity-date-filter .input-group .dropdown-menu {
    padding: 15px 3px;
    left: -25% !important;
  }
}
contact-details-component .contact-details-right-pane .activity-date-filter .input-group input,
anonymous-contact-details-component .contact-details-right-pane .activity-date-filter .input-group input {
  border: none;
  background: none !important;
  border-bottom: 1px solid #e1e1e1;
  width: 50%;
  float: right;
  right: 10px;
}
contact-details-component .contact-details-right-pane .activity-filter select.form-control,
anonymous-contact-details-component .contact-details-right-pane .activity-filter select.form-control {
  width: 30%;
  display: inline-block;
  margin-left: 10px;
}
contact-details-component .contact-details-right-pane .timeline-xs,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs {
  margin-left: 15px;
  margin-bottom: 15px;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #ffffff;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content:before,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content:before {
  position: absolute;
  top: 11px;
  left: 24px;
  content: '';
  border-right: solid 7px #ccc;
  border-bottom: solid 7px transparent;
  border-top: solid 7px transparent;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content:after,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content:after {
  position: absolute;
  top: 12px;
  left: 25px;
  content: '';
  border-right: solid 6px #fff;
  border-bottom: solid 6px transparent;
  border-top: solid 6px transparent;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.activity-title,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.activity-title,
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.activity-time,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.activity-time,
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data {
  padding-left: 0;
  padding-right: 2px;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data {
  padding-top: 5px;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data .activity-text-wrap,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data .activity-text-wrap {
  display: inline-block;
  max-width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data .activity-text-wrap.text-no-wrap-activity,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.content-data .activity-text-wrap.text-no-wrap-activity {
  display: inline;
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
}
contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.activity-time,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .activity-content .activity-inner-content div.activity-time {
  text-align: right;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item:after,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item:after {
  width: 36px;
  border-radius: 50%;
  height: 36px;
  margin-left: -18px;
  top: 0;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item:last-child,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item:last-child {
  border: none !important;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success {
  border-left-color: #c8c7cc;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success:after,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success:after {
  border-color: #c8c7cc;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success i.fa,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success i.fa {
  z-index: 1;
  position: absolute;
  left: -9px;
  font-size: 18px;
  top: 9px;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.email-type:after,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.email-type:after {
  border-color: #a4e9f3;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.email-type i.fa,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.email-type i.fa {
  color: #a4e9f3;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.form-type:after,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.form-type:after {
  border-color: rgba(2, 169, 47, 0.45);
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.form-type i.fa,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.form-type i.fa {
  color: rgba(2, 169, 47, 0.45);
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.asset-type:after,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.asset-type:after {
  border-color: #ccc;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.asset-type i.fa,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.asset-type i.fa {
  color: #ccc;
}
contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.user-notes-type img.img-activity-icon,
anonymous-contact-details-component .contact-details-right-pane .timeline-xs .timeline-item.success.user-notes-type img.img-activity-icon {
  border-radius: 60%;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 4px;
  left: -14px;
  z-index: 1;
}
contact-details-component .contact-details-right-pane .panel-activity h4,
anonymous-contact-details-component .contact-details-right-pane .panel-activity h4 {
  margin-left: 15px;
}
contact-details-component .contact-details-right-pane .panel-activity h4 span.fa,
anonymous-contact-details-component .contact-details-right-pane .panel-activity h4 span.fa {
  color: #48b1cc;
}
contact-details-component .contact-details-right-pane .panel-activity .panel-tools,
anonymous-contact-details-component .contact-details-right-pane .panel-activity .panel-tools {
  left: -20px;
  top: -5px;
}
contact-details-component .contact-details-right-pane .panel-activity .panel-tools a,
anonymous-contact-details-component .contact-details-right-pane .panel-activity .panel-tools a {
  color: inherit;
}
contact-details-component .contact-details-right-pane .panel-activity .panel-tools a i,
anonymous-contact-details-component .contact-details-right-pane .panel-activity .panel-tools a i {
  font-weight: bold;
  font-size: 16px;
}
[datepicker] .btn-info.active span {
  color: #ffffff;
}
.ingram-micro-main-content .contact-attribution .contact-attribution-btn {
  background-color: #0077b4 !important;
  border: 1px solid #0077b4 !important;
}
.ingram-micro-main-content .contact-details-tab .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  line-height: 0.8;
}
.sonicwall-main-content contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn,
.sonicwall-main-content anonymous-contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn {
  background-color: #06293d;
  border: 1px solid #06293d;
}
.brandforce-main-content contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn,
.brandforce-main-content anonymous-contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn {
  background-color: #06293d;
  border: 1px solid #06293d;
}
.blueequinox-main-content contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn,
.blueequinox-main-content anonymous-contact-details-component .contact-details-left-pane .contact-attribution .contact-attribution-btn {
  background-color: #2A3D46;
  border: 1px solid #2A3D46;
}
.cisco-pdf-dossier .contact-details-right-pane {
  background: transparent;
  border: 0;
}
.cisco-pdf-dossier .contact-details-left-pane {
  border: 0;
}
/* ---------------------------------------------------------------------- */
/*  Order Detail
/* ---------------------------------------------------------------------- */
.order-detail .order-date input,
.order-detail .order-date .input-group-btn {
  z-index: 0;
}
.order-detail .dropdown-menu.ng-valid-date li table:focus {
  outline: none!important;
}
.order-detail .order-notes {
  padding: 0;
  margin-bottom: 0;
}
.order-detail .order-notes li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px;
}
.order-detail .order-notes li:last-child {
  border-bottom: none;
}
.order-detail span.user-notes {
  white-space: pre-wrap;
}
.order-detail #orderItems .fa-edit {
  opacity: 0.8;
}
.order-detail #orderItems .fa-edit:hover {
  opacity: 1;
}
.order-detail #orderItems .fa-times-circle-o {
  opacity: 0.8;
}
.order-detail #orderItems .fa-times-circle-o:hover {
  opacity: 1;
}
.order-detail span.customer-info {
  cursor: pointer;
}
.order-detail .ordered-customer {
  background: #f7f7f8 !important;
  border-radius: 0 0 0 0 !important;
  color: #5b5b60;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.8;
  padding: 5px 5px;
  transition-duration: 0.1s;
  box-shadow: none !important;
}
/* ---------------------------------------------------------------------- */
/*  Site Analytics
/* ---------------------------------------------------------------------- */
.site-analytics .rate strong {
  font-size: 18px;
}
.site-analytics .rate .value {
  margin-right: 10px;
}
.site-analytics .rate .analytic-state-name {
  font-size: 27px;
}
.site-analytics .device-info {
  clear: left;
}
@media screen and (min-width: 992px) {
  .site-analytics .device-info .icon-panel {
    padding: 15% 0;
  }
}
@media screen and (max-width: 768px) {
  .site-analytics .device-info .icon-panel {
    text-align: center;
  }
  .site-analytics .device-info .icon-panel h1 {
    display: inline-block;
  }
  .site-analytics .device-info .icon-panel h3 {
    display: block;
  }
  .site-analytics .device-info .icon-panel h5 {
    display: block;
  }
}
/* ---------------------------------------------------------------------- */
/*  Posts
/* ---------------------------------------------------------------------- */
.posts-modal .tab-content {
  max-height: 350px;
  overflow: auto;
}
.posts-modal .tab-content h1 {
  line-height: 65px;
}
#component-setting-modal .blog-title {
  font-family: 'LatoBold';
  margin: 0;
  font-weight: normal;
}
#component-setting-modal .blog-title a {
  color: #636e7b;
  font-weight: normal;
  font-family: Open Sans, Source Sans Pro, Helvetica, Arial, sans-serif;
}
#component-setting-modal .blog-meta {
  border-bottom: none!important;
  border-top: none!important;
  text-transform: none!important;
  color: #9E9E9E;
  font-family: Georgia, serif;
  font-size: 12px;
  font-style: italic;
  padding: 0 5px 7px 4px;
  margin: 0;
}
#component-setting-modal .post_tags {
  padding: 0;
  margin-bottom: 20px;
}
#component-setting-modal .post_tags li {
  display: inline-block;
  margin-bottom: 10px;
  background: #47b1cc;
  padding: 5px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
}
#component-setting-modal .post_tags li a {
  color: #ffffff;
}
#component-setting-modal .blog-thumb img {
  display: block;
  height: 200px;
  max-width: 100%;
}
/* ---------------------------------------------------------------------- */
/*  Tables
/* ---------------------------------------------------------------------- */
.tableView {
  float: right;
  margin-right: 5px;
}
div[st-table] strong {
  display: none;
}
table tbody td .screenshot {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 2px;
}
table thead th[st-sort] {
  cursor: pointer;
}
table.table-list-view tr {
  height: 55px;
}
table.grid thead tr {
  width: 100%;
}
table.grid thead th {
  display: inline-block;
}
table.grid tbody {
  margin-top: 10px;
  display: block;
}
table.grid tbody .screenshot {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
table.grid tbody tr {
  width: 24%;
  float: left;
  margin: 5px;
  background: white!important;
  border-radius: 5px;
  border-bottom: 3px solid #eeeeee;
}
table.grid tbody td {
  float: left;
  width: 100%;
  border: 0;
  padding: 2px 15px;
  display: block;
}
table.grid tbody td:first-child {
  padding-top: 15px;
}
table.grid tbody td:last-child {
  padding-bottom: 15px;
}
table.grid tbody td strong {
  width: 90px;
  display: block;
  float: left;
}
@media (max-width: 767px) {
  table.grid tbody tr {
    width: 100%!important;
  }
}
@media (min-width: 768px) {
  table.grid tbody tr {
    width: 48.68% !important;
  }
}
@media (min-width: 1175px) {
  table.grid tbody tr {
    width: 32.38% !important;
  }
}
@media (min-width: 1700px) {
  table.grid tbody tr {
    width: 24.4% !important;
  }
}
@media (min-width: 2000px) {
  table.grid tbody tr {
    width: 24.55% !important;
  }
}
.table > tfoot > tr > td {
  padding: 0;
  border: 0;
}
table.bg-white.table-list-view tbody tr:hover td:first-child {
  border-left: #32CD32 4px solid;
}
table.bg-white.table-list-view tbody tr td:first-child,
table.bg-white.table-list-view tbody tr th:first-child {
  border-left: transparent 4px solid;
}
table.bg-white.table-list-view thead tr th:first-child {
  border-left: transparent 4px solid;
}
.add-component-modal .tab-content {
  max-height: 300px;
  height: 100%;
  overflow: auto;
}
.add-component-modal .tabbable.tabs-left.email-components .nav-tabs {
  overflow-y: hidden !important;
}
.btn-comment-o {
  color: #fff;
  background-color: #F0E301;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-comment-o:hover,
.btn-comment-o:focus,
.btn-comment-o:active,
.btn-comment-o.active,
.open > .dropdown-toggle.btn-comment-o {
  color: #fff;
  background-color: #F0E301;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-comment-o:active,
.btn-comment-o.active,
.open > .dropdown-togglle.btn-comment-o {
  background-image: none;
}
.btn-comment-o.disabled,
.btn-comment-o[disabled],
fieldset[disabled] .btn-comment-o,
.btn-comment-o.disabled:hover,
.btn-comment-o[disabled]:hover,
fieldset[disabled] .btn-comment-o:hover,
.btn-comment-o.disabled:focus,
.btn-comment-o[disabled]:focus,
fieldset[disabled] .btn-comment-o:focus,
.btn-comment-o.disabled:active,
.btn-comment-o[disabled]:active,
fieldset[disabled] .btn-comment-o:active,
.btn-comment-o.disabled.active,
.btn-comment-o[disabled].active,
fieldset[disabled] .btn-comment-o.active {
  background-color: #F0E301;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-comment-o .badge {
  color: #F0E301;
  background-color: #fff;
}
.btn-vine {
  color: #fff;
  background-color: #00B389;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-vine:hover,
.btn-vine:focus,
.btn-vine:active,
.btn-vine.active,
.open > .dropdown-toggle.btn-vine {
  color: #fff;
  background-color: #00B389;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-vine:active,
.btn-vine.active,
.open > .dropdown-toggle.btn-vine {
  background-image: none;
}
.btn-vine.disabled,
.btn-vine[disabled],
fieldset[disabled] .btn-vine,
.btn-vine.disabled:hover,
.btn-vine[disabled]:hover,
fieldset[disabled] .btn-vine:hover,
.btn-vine.disabled:focus,
.btn-vine[disabled]:focus,
fieldset[disabled] .btn-vine:focus,
.btn-vine.disabled:active,
.btn-vine[disabled]:active,
fieldset[disabled] .btn-vine:active,
.btn-vine.disabled.active,
.btn-vine[disabled].active,
fieldset[disabled] .btn-vine.active {
  background-color: #00B389;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-vine .badge {
  color: #00B389;
  background-color: #fff;
}
.btn-desktop {
  color: #fff;
  background-color: #00A8CB;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-desktop:hover,
.btn-desktop:focus,
.btn-desktop:active,
.btn-desktop.active,
.open > .dropdown-toggle.btn-desktop {
  color: #fff;
  background-color: #00A8CB;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-desktop:active,
.btn-desktop.active,
.open > .dropdown-toggle.btn-desktop {
  background-image: none;
}
.btn-desktop.disabled,
.btn-desktop[disabled],
fieldset[disabled] .btn-desktop,
.btn-desktop.disabled:hover,
.btn-desktop[disabled]:hover,
fieldset[disabled] .btn-desktop:hover,
.btn-desktop.disabled:focus,
.btn-desktop[disabled]:focus,
fieldset[disabled] .btn-desktop:focus,
.btn-desktop.disabled:active,
.btn-desktop[disabled]:active,
fieldset[disabled] .btn-desktop:active,
.btn-desktop.disabled.active,
.btn-desktop[disabled].active,
fieldset[disabled] .btn-desktop.active {
  background-color: #00A8CB;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-desktop .badge {
  color: #00A8CB;
  background-color: #fff;
}
.btn-youtube {
  color: #fff;
  background-color: #CF3427;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-youtube:hover,
.btn-youtube:focus,
.btn-youtube:active,
.btn-youtube.active,
.open > .dropdown-toggle.btn-youtube {
  color: #fff;
  background-color: #CF3427;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-youtube:active,
.btn-youtube.active,
.open > .dropdown-toggle.btn-youtube {
  background-image: none;
}
.btn-youtube.disabled,
.btn-youtube[disabled],
fieldset[disabled] .btn-youtube,
.btn-youtube.disabled:hover,
.btn-youtube[disabled]:hover,
fieldset[disabled] .btn-youtube:hover,
.btn-youtube.disabled:focus,
.btn-youtube[disabled]:focus,
fieldset[disabled] .btn-youtube:focus,
.btn-youtube.disabled:active,
.btn-youtube[disabled]:active,
fieldset[disabled] .btn-youtube:active,
.btn-youtube.disabled.active,
.btn-youtube[disabled].active,
fieldset[disabled] .btn-youtube.active {
  background-color: #CF3427;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-youtube .badge {
  color: #CF3427;
  background-color: #fff;
}
.btn-yelp {
  color: #fff;
  background-color: #CE2200;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-yelp:hover,
.btn-yelp:focus,
.btn-yelp:active,
.btn-yelp.active,
.open > .dropdown-toggle.btn-yelp {
  color: #fff;
  background-color: #CE2200;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-yelp:active,
.btn-yelp.active,
.open > .dropdown-toggle.btn-yelp {
  background-image: none;
}
.btn-yelp.disabled,
.btn-yelp[disabled],
fieldset[disabled] .btn-yelp,
.btn-yelp.disabled:hover,
.btn-yelp[disabled]:hover,
fieldset[disabled] .btn-yelp:hover,
.btn-yelp.disabled:focus,
.btn-yelp[disabled]:focus,
fieldset[disabled] .btn-yelp:focus,
.btn-yelp.disabled:active,
.btn-yelp[disabled]:active,
fieldset[disabled] .btn-yelp:active,
.btn-yelp.disabled.active,
.btn-yelp[disabled].active,
fieldset[disabled] .btn-yelp.active {
  background-color: #CE2200;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-yelp .badge {
  color: #CE2200;
  background-color: #fff;
}
.btn-vimeo-square {
  color: #fff;
  background-color: #1ab7ea;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-vimeo-square:hover,
.btn-vimeo-square:focus,
.btn-vimeo-square:active,
.btn-vimeo-square.active,
.open > .dropdown-toggle.btn-vimeo-square {
  color: #fff;
  background-color: #1ab7ea;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-vimeo-square:active,
.btn-vimeo-square.active,
.open > .dropdown-toggle.btn-vimeo-square {
  background-image: none;
}
.btn-vimeo-square.disabled,
.btn-vimeo-square[disabled],
fieldset[disabled] .btn-vimeo-square,
.btn-vimeo-square.disabled:hover,
.btn-vimeo-square[disabled]:hover,
fieldset[disabled] .btn-vimeo-square:hover,
.btn-vimeo-square.disabled:focus,
.btn-vimeo-square[disabled]:focus,
fieldset[disabled] .btn-vimeo-square:focus,
.btn-vimeo-square.disabled:active,
.btn-vimeo-square[disabled]:active,
fieldset[disabled] .btn-vimeo-square:active,
.btn-vimeo-square.disabled.active,
.btn-vimeo-square[disabled].active,
fieldset[disabled] .btn-vimeo-square.active {
  background-color: #1ab7ea;
  border-color: rgba(0, 0, 0, 0.2);
}
.app-sidebar-fixed #page-actions {
  top: 0px;
  position: relative;
}
.app-sidebar-fixed #page-actions .btn-wide {
  min-width: 110px;
}
#page-actions {
  top: 0px;
  position: relative;
}
.btn-vimeo-square .badge {
  color: #1ab7ea;
  background-color: #fff;
}
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
.ng-aside.vertical .modal-dialog .modal-content {
  max-height: none!important;
}
.ng-aside.vertical .modal-dialog .modal-body {
  max-height: 420px!important;
}
@media (max-width: 767px) {
  .ng-aside.vertical .modal-dialog .modal-body {
    max-height: 100%!important;
  }
}
.blurred-editor {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  overflow: hidden;
}
.blurred-editor.ng-hide-remove {
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.loading-editor {
  position: relative;
  background: #f1f1f1;
  margin-top: -56px;
}
.loading-editor h1 {
  margin-top: 10%;
  font-size: 36px !important;
}
.loading-editor.ng-hide-add {
  -webkit-animation: fadeOut 1s;
  -moz-animation: fadeOut 1s;
  -ms-animation: fadeOut 1s;
  animation: fadeOut 1s;
}
.component-sortable {
  padding: 0;
  overflow: hidden;
}
.component-sortable .componentActions span.fa {
  padding: 10px;
  text-align: center;
  line-height: 18px;
}
.component-sortable .componentActions p {
  margin-bottom: 0;
  font-size: 14px;
}
.component-sortable .componentActions h4 {
  padding: 17px;
  color: #444;
  font-size: 18px;
  margin: 0;
  height: 56px;
  font-weight: bold;
}
.sortable-ghost .delete-component,
.sortable-ghost .duplicate,
.sortable-ghost .component-settings,
.sortable-ghost .add-component,
.sortable-ghost .add-new-component {
  display: none!important;
}
#componentloader {
  position: relative;
  width: 100%;
  min-height: 500px;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  clear: both;
}
#componentloader h1,
#componentloader h2,
#componentloader h3,
#componentloader h4,
#componentloader h5 {
  font-weight: normal;
  color: inherit;
  font-family: inherit;
}
#componentloader .component-wrap {
  background: #f1f1f1;
}
#componentloader .component-wrap-post {
  margin-bottom: 0px!important;
}
#componentloader .component-sortable.dragging .delete-component,
#componentloader .component-sortable.dragging .duplicate,
#componentloader .component-sortable.dragging .component-settings,
#componentloader .component-sortable.dragging .add-component,
#componentloader .component-sortable.dragging .add-new-component {
  display: none!important;
}
#componentloader .fragment {
  border: none;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%!important;
}
#componentloader .fragment .component-inner {
  clear: both;
}
#componentloader .fragment .circular {
  display: none;
  visibility: none;
}
#componentloader .fragment.email .cn-wrapper li {
  display: none;
}
#componentloader .fragment.email .cn-wrapper li:first-child {
  display: block!important;
  -webkit-transform: rotate(75deg) skew(60deg) !important;
  transform: rotate(75deg) skew(60deg) !important;
}
#componentloader .fragment:hover .component-inner,
#componentloader .fragment:hover .bg.cover {
  -webkit-box-shadow: inset 0px 0px 0px 2px orange;
  -moz-box-shadow: inset 0px 0px 0px 2px orange;
  box-shadow: inset 0px 0px 0px 2px orange;
}
#componentloader .fragment:hover .circular {
  display: block;
  visibility: visible;
}
#componentloader .fragment:hover .add-component-container {
  position: relative;
  z-index: 11;
  display: block;
}
#componentloader .fragment:hover .hover-action {
  z-index: 11;
}
#componentloader .fragment.ng-enter {
  -webkit-animation: bounceIn 1s;
  -moz-animation: bounceIn 1s;
  -ms-animation: bounceIn 1s;
  animation: bounceIn 1s;
}
#componentloader .fragment.ng-leave {
  -webkit-animation: bounceOut 1s;
  -moz-animation: bounceOut 1s;
  -ms-animation: bounceOut 1s;
  animation: bounceOut 1s;
}
.componentActions {
  position: relative;
  max-height: 0px;
  -webkit-box-shadow: inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  box-shadow: inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  background: #efa022;
  color: white;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
}
.componentActions span {
  padding: 20px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.componentActions.display-settings {
  max-height: 56px;
}
.componentActions .reorder {
  float: left;
  pointer-events: all;
}
.componentActions .reorder:hover {
  color: #54C8E8;
}
.componentActions .single-reorder {
  float: left;
  pointer-events: all;
}
.componentActions .single-reorder i {
  margin-top: 8px;
}
.componentActions .single-reorder span {
  padding: 0;
  text-align: center;
  line-height: 19px;
  display: block;
  height: 56px;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}
.componentActions .single-reorder span:hover {
  color: #54C8E8;
}
.componentActions .hidden-component {
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 60px;
}
.componentActions .delete {
  float: right;
  pointer-events: all;
}
.componentActions .delete:hover {
  color: #d9534f;
}
.componentActions .duplicate {
  float: right;
  pointer-events: all;
}
.componentActions .duplicate:hover {
  color: #219265;
}
.componentActions .settings-btn {
  float: right;
  pointer-events: all;
}
.componentActions .settings-btn:hover {
  color: #696969;
}
.editable-title {
  display: none;
}
#component-setting-modal .tab-content h4 {
  margin: 2px 10px 0 0;
  color: #b6b6b6;
}
#component-setting-modal .product-ui-select .ui-select-dropdown.dropdown-menu {
  top: inherit !important;
}
.editor-toolbar-wrap {
  z-index: 1088;
}
#editor-toolbar.email-toolbar .cke_top {
  overflow: auto;
}
#editor-toolbar.email-toolbar .cke_top .cke_toolbox {
  height: 56px;
  display: block;
  width: 1130px;
}
#editor-toolbar.common-toolbar::-webkit-scrollbar {
  display: none;
}
#editor-toolbar.common-toolbar #cke_1_top::-webkit-scrollbar {
  display: none;
}
#editor-toolbar {
  z-index: 100;
  display: block;
  width: 100%;
  height: 54px;
  overflow-x: inherit;
  overflow-y: hidden;
}
#editor-toolbar.controls-fixed {
  top: 128px;
}
#editor-toolbar #cke_1_top {
  overflow: auto;
}
#editor-toolbar #cke_1_top #cke_1_toolbox {
  height: 56px;
  display: block;
  width: 1130px;
}
#editor-toolbar .cke_chrome {
  border: none;
  border-radius: 0;
  background: #efa022;
  padding: 0;
  height: 54px;
}
#editor-toolbar .cke_chrome a.cke_button_disabled:active,
#editor-toolbar .cke_chrome a.cke_button_disabled:focus,
#editor-toolbar .cke_chrome a.cke_button_disabled:hover,
#editor-toolbar .cke_chrome a.cke_button_off:active,
#editor-toolbar .cke_chrome a.cke_button_off:focus,
#editor-toolbar .cke_chrome a.cke_button_off:hover {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0;
}
#editor-toolbar .cke_chrome .cke_bottom,
#editor-toolbar .cke_chrome .cke_top {
  padding: 0;
  background: none;
}
#editor-toolbar .cke_chrome .cke_toolgroup {
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  margin: 0;
}
#editor-toolbar .cke_chrome .cke_toolbar_separator {
  display: none;
}
#editor-toolbar .cke_chrome .cke_button_on {
  background: rgba(255, 255, 255, 0.5);
}
#editor-toolbar .cke_chrome .cke_combo_button {
  margin-top: 13px;
  padding: 2px 2px!important;
}
#editor-toolbar .cke_chrome a.cke_button {
  height: 50px;
  width: 40px;
}
#editor-toolbar .cke_chrome a.cke_button .cke_button_icon {
  background: none!important;
  font: normal normal normal 14px/1 FontAwesome;
  text-align: center;
  position: relative;
  display: block;
  color: white;
  width: 100%;
  line-height: 50px;
  opacity: 0.8;
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__bold_icon:before {
  content: "\f032";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__undo_icon:before {
  content: "\f0e2";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__redo_icon:before {
  content: "\f01e";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__italic_icon:before {
  content: "\f033";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__numberedlist_icon:before {
  content: "\f0cb";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__table_icon:before {
  content: "\f0ce";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__bulletedlist_icon:before {
  content: "\f0ca";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__justifyleft_icon:before {
  content: "\f036";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__justifycenter_icon:before {
  content: "\f037";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__justifyright_icon:before {
  content: "\f038";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__justifyblock_icon:before {
  content: "\f039";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__link_icon:before {
  content: "\f0c1";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__unlink_icon:before {
  content: "\f127";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__anchor_icon:before {
  content: "\f13d";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__mediamanager_icon:before {
  content: "\f03e";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__fontawesome_icon:before {
  content: "\f024";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__textcolor_icon:before {
  content: "\f040";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__doksoft_button_icon:before {
  content: "\f0c8";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__doksoft_button_email_icon:before {
  content: "\f1a5";
}
#editor-toolbar .cke_chrome a.cke_button .cke_button__doksoft_font_awesome_icon:before {
  content: "\f024";
}
#editor-toolbar .cke_chrome .cke_combo_button {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  border-radius: 0;
  display: table-cell;
  float: none;
  height: 50px;
  margin: 0;
  cursor: pointer;
  padding: 2px 10px;
  vertical-align: middle;
}
#editor-toolbar .cke_chrome .cke_combo_button span {
  color: #fff;
}
#editor-toolbar .cke_chrome .cke_combo_button .cke_combo_arrow,
#editor-toolbar .cke_chrome .cke_button_arrow {
  border-top: 3px solid #fff;
}
#editor-toolbar .cke_chrome .cke_button_arrow {
  margin-top: 24px;
}
#editor-toolbar .cke_chrome a.cke_button__textcolor {
  padding: 2px 10px;
}
#editor-toolbar .cke_chrome .cke_combo_button:hover,
#editor-toolbar .cke_chrome .cke_combo_on .cke_combo_button {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
}
#editor-toolbar .cke_chrome div.cke_combopanel {
  background-color: #efa022;
  border: medium none;
  border-radius: 0;
  height: auto;
  margin-top: 1px;
  max-width: 200px;
  overflow: visible;
  visibility: visible;
  height: 250px;
  width: auto;
}
#editor-toolbar .cke_chrome div.cke_combopanel__fontsize {
  max-width: 120px;
}
#editor-toolbar .cke_chrome div.cke_combopanel__lineheight {
  max-width: 110px;
}
#editor-toolbar .cke_inner {
  display: inline-block;
  background: #efa022;
  height: 54px;
}
.modal-body .nav-tabs h4 {
  margin: 2px 10px 0 0;
  color: #b6b6b6;
}
.post_content_div {
  min-height: 500px;
}
.nav.navbar-nav .edit-wrap .fr-element.fr-view {
  min-width: 80px;
}
.edit-wrap {
  margin: -1px;
  position: relative;
}
.edit-wrap .editable {
  min-height: 10px;
}
.edit-wrap .editable img {
  max-width: 100%;
}
.edit-wrap .editable:focus {
  outline: none;
}
.edit-wrap:hover {
  outline: 1px dashed #efa022;
  margin: -1px;
  display: block;
  z-index: 999;
}
.edit-wrap:hover .editable-title {
  position: absolute;
  left: 0;
  top: -20px;
  background: #efa022;
  color: #fff !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding: 0 15px;
  display: block;
  z-index: 999;
  font-family: Helvetica, Arial;
  font-weight: 500;
  font-size: 14px;
  height: 20px;
  line-height: 19px;
}
ul.nav-bar {
  list-style: none;
}
.pac-container {
  z-index: 10020 !important;
}
div[class^="spacing-"] {
  width: 60px;
}
.spacing-mt {
  margin: -30px auto 15px;
}
.spacing-mr,
.spacing-ml {
  margin: 65px auto 0;
}
.spacing-mb,
.spacing-pr,
.spacing-pl,
.spacing-pb {
  margin: 0 auto 0;
}
.spacing-pt {
  margin: -30px auto 15px;
}
.nav-undernav {
  background: transparent !important;
  z-index: 11 !important;
}
.masthead-undernav {
  z-index: 9 !important;
}
.li-nav-undernav {
  position: relative;
  z-index: 11 !important;
}
.inline-table-cell {
  display: table-cell;
}
.slider-opacity .rz-bubble {
  display: none;
}
select.hour-start-select {
  padding-left: 5px;
  padding-right: 5px;
}
.post-categories-ui-select ul.dropdown-menu {
  max-height: 100px;
  min-height: 100px;
  top: -100px;
}
.post-tags-ui-select ul.dropdown-menu {
  max-height: 100px;
  min-height: 100px;
}
.max-width-custom-input {
  display: block;
  width: 100%;
  padding: 10px !important;
  border-radius: 4px;
}
/* ---------------------------------------------------------------------- */
/*  Billing
/* ---------------------------------------------------------------------- */
.billing .btn.paynow {
  text-align: center;
  display: none;
}
.billing .btn.paynow.active {
  display: block;
}
.billing .no-pad-lr {
  padding-left: 0px;
  padding-right: 0px;
}
.billing .vert-center {
  padding-top: 10px;
  padding-bottom: 10px;
}
.billing .pricing-table {
  background-color: white;
}
.billing .fa-spinner {
  font-size: 3em;
}
#card-container {
  margin-bottom: 30px;
}
#card-container .jp-card-container {
  transform: scale(1) !important;
}
@media (max-width: 480px) {
  #card-container .jp-card-container {
    transform: scale(0.75) translateX(-25%) !important;
    position: relative;
    left: 20%;
  }
}
@media (max-width: 480px) {
  #card-container {
    margin-bottom: 0;
  }
  .billing-credit-card > .row:last-child {
    margin-bottom: 100px;
  }
}
.view-transaction-modal .paid {
  margin: 0 auto 15px;
  display: block;
}
/* ---------------------------------------------------------------------- */
/*  Social Feed
/* ---------------------------------------------------------------------- */
.social-feed #postContent {
  color: black;
}
.social-feed .masonry-brick .panel-heading {
  color: white;
  min-height: 35px;
  padding: 10px;
}
.social-feed .masonry-brick .panel-heading .fa-facebook,
.social-feed .masonry-brick .panel-heading .fa-twitter,
.social-feed .masonry-brick .panel-heading .fa-google-plus {
  font-size: 18px;
}
.social-feed .masonry-brick .panel-footer {
  padding: 8px;
  min-height: 35px;
}
.social-feed .masonry-brick .facebook .panel-heading {
  background: #3b5998;
}
.social-feed .masonry-brick .twitter .panel-heading {
  background: #00aced;
}
.social-feed .feeds {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.social-feed .feeds li {
  border-bottom: 1px solid #eee;
  padding-top: 5px;
}
.social-feed .feeds li:last-child {
  border-bottom: none;
}
.comments {
  padding: 15px;
  max-height: 350px;
  height: 100%;
  overflow: auto;
  margin-bottom: 0;
}
.comments li {
  list-style-type: none;
  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
}
.comments li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.manage-feed .tab-content {
  min-height: 200px;
}
.manage-feed .tab-content ul li:last-child .parentaccount {
  border-bottom: none;
  margin-bottom: 0px;
}
.manage-feed .fb-admin-pages {
  margin: 0;
  padding: 0;
}
.manage-feed .fb-admin-pages li {
  line-height: 50px;
  text-indent: 10px;
  display: block;
  float: left;
  width: 100%;
}
.manage-feed .fb-admin-pages li .fa {
  line-height: 50px;
  font-size: 18px;
}
.manage-feed .fb-admin-pages li .fa:hover {
  opacity: 0.7;
}
.manage-feed .fb-admin-pages li .parentaccount {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.manage-feed .fb-admin-pages li .subaccounts li {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.manage-feed .fb-admin-pages li .subaccounts li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
/* ---------------------------------------------------------------------- */
/*  Products List
/* ---------------------------------------------------------------------- */
.products table.grid thead th {
  width: 20% !important;
  float: left;
}
@media (max-width: 767px) {
  .products table.grid thead th {
    width: 50% !important;
  }
}
.products table.grid .product-img {
  height: 80px;
  text-align: center;
}
.products table.grid .product-img h3 {
  line-height: 50px;
}
.products .blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.products .no-stripe {
  height: 255px;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  min-height: 100%;
  padding: 15px;
  margin-left: -15px;
  margin-top: 60px;
  margin-bottom: 60px;
  z-index: 999;
}
.products .no-stripe .no-stripe-wrap {
  margin-top: 60px;
}
.products .table-list-view .sticky-header-class tr > th {
  width: 20%;
}
.products .table-list-view tbody tr td {
  width: 15%;
}
.products .table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.products .table-list-view span.text-adjust-inner-content {
  display: inline-block;
  max-width: 102%;
}
.products .products-filter-header {
  background: #fff;
}
.products .products-filter-header .ui-select-bootstrap > .ui-select-match > .ui-select-toggle {
  padding-left: 10px;
}
@media all and (max-width: 767px) {
  .products .products-filter-header .ui-select-bootstrap > .ui-select-match > .ui-select-toggle .caret {
    right: 4px;
  }
}
.app-btn-default {
  background-color: #48b1cc !important;
  border-color: #ffffff !important;
  color: #fff !important;
}
.app-btn-default.hover {
  color: #fff !important;
}
.app-ingram-body .app-btn-default {
  background-color: #0077b4 !important;
  border-color: #ffffff !important;
  color: #fff !important;
}
.app-sonicwall-body .app-btn-default {
  background-color: #fc6b0c !important;
  border-color: #ffffff !important;
  color: #fff !important;
}
.app-sonicwall-body .sonicwall-main-content .btn-info {
  background-color: transparent !important;
  border-color: #fc6b0c !important;
  color: #fc6b0c;
}
.app-sonicwall-body .sonicwall-main-content ul.dropdown-menu li table .btn-info {
  background-color: #fc6b0c !important;
}
.app-sonicwall-body .sonicwall-main-content .top-bar-action-buttons {
  background: transparent !important;
}
.app-sonicwall-body button.btn-primary,
.app-sonicwall-body label.btn-primary,
.app-sonicwall-body button.btn-info,
.app-sonicwall-body .btn-file.btn-primary {
  background-color: #fc6b0c !important;
  border-color: #ffffff !important;
}
.app-sonicwall-body .btn-primary.btn-o.btn-hollow {
  background: none!important;
  border: 1px solid #fc6b0c !important;
  color: #fc6b0c !important;
}
.app-sonicwall-body .btn-primary.btn-o.btn-hollow:hover {
  color: #fc6b0c !important;
}
.app-brandforce-body .app-btn-default {
  background-color: #BF2323 !important;
  border-color: #ffffff !important;
  color: #fff !important;
}
.app-brandforce-body .brandforce-main-content .btn-info {
  background-color: transparent !important;
  border-color: #BF2323 !important;
  color: #BF2323;
}
.app-brandforce-body .brandforce-main-content ul.dropdown-menu li table .btn-info {
  background-color: #BF2323 !important;
}
.app-brandforce-body button.btn-primary,
.app-brandforce-body label.btn-primary,
.app-brandforce-body button.btn-info,
.app-brandforce-body .btn-file.btn-primary {
  background-color: #BF2323 !important;
  border-color: #ffffff !important;
}
.app-brandforce-body .btn-primary.btn-o.btn-hollow {
  background: none!important;
  border: 1px solid #BF2323 !important;
  color: #BF2323 !important;
}
.app-brandforce-body .btn-primary.btn-o.btn-hollow:hover {
  color: #BF2323 !important;
}
.app-blueequinox-body .app-btn-default {
  background-color: #1279C0 !important;
  border-color: #ffffff !important;
  color: #fff !important;
}
.app-blueequinox-body .blueequinox-main-content .btn-info {
  background-color: transparent !important;
  border-color: #1279C0 !important;
  color: #1279C0;
}
.app-blueequinox-body .blueequinox-main-content ul.dropdown-menu li table .btn-info {
  background-color: #1279C0 !important;
}
.app-blueequinox-body button.btn-primary,
.app-blueequinox-body label.btn-primary,
.app-blueequinox-body button.btn-info,
.app-blueequinox-body .btn-file.btn-primary {
  background-color: #1279C0 !important;
  border-color: #ffffff !important;
}
.app-blueequinox-body .btn-primary.btn-o.btn-hollow {
  background: none!important;
  border: 1px solid #1279C0 !important;
  color: #1279C0 !important;
}
.app-blueequinox-body .btn-primary.btn-o.btn-hollow:hover {
  color: #1279C0 !important;
}
.app-btn-default-dark-gray {
  border: none !important;
  background: #404040 !important;
  color: #FFF;
}
.app-btn-default-dark-gray:hover {
  color: #F7F7F8;
}
.btn.app-btn-default .fa-th-list,
.btn.app-btn-default .fa-th {
  padding: 4px;
}
.transparent-button {
  background: none !important;
  border: none !important;
  padding: 2px 12px;
}
.transparent-button .fa,
.transparent-button [class^="ti-"],
.transparent-button [class*=" ti-"] {
  color: #9B9B9B;
  font-size: 28px;
}
.transparent-button .fa:hover,
.transparent-button [class^="ti-"]:hover,
.transparent-button [class*=" ti-"]:hover {
  color: #4A4A4A;
}
.transparent-button.active .fa,
.transparent-button.active [class^="ti-"],
.transparent-button.active [class*=" ti-"] {
  color: #4A4A4A;
}
.btn-area-res .btn {
  position: relative;
  overflow: hidden;
  line-height: inherit !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  font-size: 14px;
}
.btn-area-res .btn:before {
  padding-right: 5px;
}
.btn-area-res .export_btn {
  color: white;
  background: #eda200;
}
.btn-area-res .btn span:not(.fa) {
  font-family: "Raleway", sans-serif;
  line-height: inherit !important;
}
.btn-area-res .btn-group > .btn:nth-child(2),
.btn-area-res .btn-group > .btn:nth-child(2):hover,
.btn-area-res .right-round,
.btn-area-res right-round:hover {
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px !important;
}
.btn-area-res .btn-group .btn {
  padding-left: 10px;
  padding-right: 10px;
}
.btn-area-res .libtn {
  width: 100px;
}
.btn-area-res .fix-btn-1 {
  float: left;
  width: 20%;
  display: inline-block;
  margin-left: 0.4rem;
}
.btn-area-res .fix-btn {
  float: left;
  display: inline-block;
  margin-left: 0.4rem;
}
.btn-area-res .text-right .fix-btn {
  margin-right: 0px;
}
.btn-area-res .input-icon {
  width: 100%;
}
.btn-area-res .mobile-text {
  display: none ;
}
.btn-area-res .desktop-text {
  display: inline-block;
}
.btn-area-res .mob-row,
.btn-area-res .mob-sub-row {
  display: inline;
}
@media (min-width: 1200px) {
  .btn-area-res .libtn,
  .btn-area-res .addnew {
    min-width: 100px;
    width: 100px;
  }
  .btn-area-res .btn-group .btn {
    width: 50px;
  }
  .btn-area-res .addnew {
    width: auto;
  }
}
@media (min-width: 992px) {
  .btn-area-res .libtn {
    width: 100px;
    min-width: 100px;
  }
  .btn-area-res .btn-group .btn {
    width: 50px;
  }
  .btn-area-res .addnew {
    width: auto;
  }
  .btn-area-res .export_btn_select {
    width: auto !important;
  }
}
@media (max-width: 820px) {
  .btn-area-res .fix-btn {
    display: inline-block;
    margin: 0px 0.4rem;
  }
  .btn-area-res .libtn {
    min-width: 84px;
    width: 84px;
  }
  .btn-area-res .btn-group .btn {
    width: 45px;
  }
  .btn-area-res .addnew {
    width: auto;
  }
  .btn-area-res .desktop-text {
    display: none;
  }
  .btn-area-res .mobile-text {
    display: inline-block;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .btn-area-res .libtn {
    min-width: 84px;
    width: 84px;
  }
  .btn-area-res .btn-group .btn {
    width: 45px;
  }
  .btn-area-res .addnew {
    width: auto;
  }
  .btn-area-res .desktop-text {
    display: none;
  }
  .btn-area-res .mobile-text {
    display: inline-block;
  }
}
@media (min-width: 320px) and (max-width: 710px) {
  .btn-area-res .fix-btn {
    width: 20%;
    display: inline-block;
  }
  .btn-area-res .mob-row {
    display: block;
    float: left;
    width: 100%;
  }
  .btn-area-res .mob-row:nth-child(2) {
    margin-top: 1rem;
  }
  .btn-area-res .mob-row .fix-btn:nth-child(1) {
    margin-left: 0px;
  }
  .btn-area-res .btn-2 {
    float: none;
    text-align: left;
    padding-left: 10px!important;
  }
  .btn-area-res .mob-row .fix-btn.mob-sub-right {
    float: right;
    margin-left: auto;
    margin-right: 0px;
    text-align: right;
    width: auto;
  }
  .btn-area-res .fix-btn-1 {
    width: 70%;
    display: inline-block;
    margin-left: 0px;
  }
  .btn-area-res .libtn {
    width: 65px;
    min-width: 58px;
    font-size: 12px;
    padding: 8px 6px 6px 2px;
    font-weight: bold;
  }
  .btn-area-res .btn-group .btn {
    width: 45px;
    padding-left: 10px;
  }
  .btn-area-res .btn .desktop {
    display: none;
  }
  .btn-area-res .btn .mobile-text {
    display: inline-block;
  }
  .btn-area-res .text-right .fix-btn {
    margin-right: 0px;
    margin-left: 0.8rem;
    width: auto;
  }
  .btn-area-res .desktop-text {
    display: none;
  }
  .btn-area-res .mobile-text {
    display: inline-block;
  }
  .btn-area-res .mob-sub-row {
    width: 50%;
    display: inline-block;
  }
  .btn-area-res .mob-sub-row.text-right .fix-btn {
    float: inherit;
    text-align: left;
    width: auto;
  }
  .btn-area-res .mob-sub-row .fix-btn {
    width: 48%;
    display: inline-block;
    margin: 0px;
  }
  .btn-area-res .fix-btn.grp.padding-0 {
    float: none;
    text-align: left;
    padding-right: 10px!important;
  }
  .btn-area-res .row-1 .fix-btn {
    width: auto!important;
  }
}
@media (min-width: 375px) and (max-width: 710px) {
  .btn-area-res .libtn {
    width: 78px;
  }
  .btn-area-res .btn-group .btn {
    width: 45px;
  }
}
@media (min-width: 425px) and (max-width: 710px) {
  .btn-area-res .mob-sub-row .fix-btn {
    width: 49%;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .btn.app-btn-default .fa-th-list,
  .btn.app-btn-default .fa-th {
    vertical-align: middle;
  }
}
/* ---------------------------------------------------------------------- */
/*  Customers
/* ---------------------------------------------------------------------- */
.contacts thead.sticky-header-class {
  height: auto !important;
}
.contacts ul.social {
  list-style: none;
}
@media (min-width: 320px) and (max-width: 710px) {
  .contacts .action-btn {
    padding-left: 2px !important;
  }
}
.contacts .action-btn {
  background-color: #40546d;
  border-radius: 4px !important;
  color: white;
}
.contacts .action-btn:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  position: static;
}
.contacts .action-btn:hover {
  background-color: #627894;
  border-radius: 4px !important;
  color: white;
}
.contacts .btn-group.open .action-btn:after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.contacts .btn-group .dropdown-menu {
  width: 140px;
  margin-left: -40px;
  padding: 3px;
  min-width: 140px;
}
.contacts .btn-group .dropdown-menu a {
  display: block;
  width: 130px;
  padding: 7px;
  color: black;
}
.contacts .btn-group .dropdown-menu a:hover {
  background-color: #e5e5e5;
}
@media all and (min-width: 768px) {
  .contacts table.grid tbody tr {
    min-height: 277px;
    max-height: 300px;
  }
  .contacts table.grid tbody td strong {
    width: 21%;
  }
}
.contacts table.grid tbody td.tags-col span,
.contacts table.grid tbody td.address-col span {
  width: 79%;
  display: inline-block;
}
.contacts .action-btn-top .transparent-button {
  margin-top: 5px;
}
.contacts .action-btn-top .transparent-button .fa-download {
  padding-top: 2px;
}
.contacts .social {
  list-style-type: none;
  margin-bottom: 0;
}
.contacts .column-width {
  width: 8.3%;
}
.contacts .filter-row-header th.column-width:not(:first-child) {
  width: 10%;
}
@media (max-width: 767px) {
  .contacts .column-width {
    width: 19%;
    display: table-cell;
  }
  .contacts .column-width .ui-select-container.ui-select-bootstrap.dropdown,
  .contacts .column-width input {
    min-width: 70px;
  }
}
@media (min-width: 992px) {
  .contacts .tag-width {
    width: 15%;
  }
}
.contacts .two-letter-label {
  display: inline-block;
}
.contacts table.table-list-view tr > th {
  width: 8.8%;
}
.contacts table.table-list-view th.tags-col {
  width: 15%;
}
.contacts table.table-list-view tr > th:first-child {
  width: 5%;
}
.contacts table.table-list-view tbody tr > td {
  width: 8.8%;
}
.contacts table.table-list-view tbody tr > td:first-child {
  width: 5%;
}
.contacts table.table-list-view tbody td.tags-col {
  width: 15%;
}
.contacts table.table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.contacts table.table-list-view span.text-adjust-inner-content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contacts table.table-list-view .sticky-filter-header th.column-width:first-child {
  width: 4% !important;
}
.contacts .contact-panel-body {
  padding-left: 0;
  padding-right: 0;
}
.contacts .table thead tr.sticky-filter-header {
  background: #fff;
}
.contacts .sticky-filter-header .column-width {
  width: 8.3% !important;
}
.contacts .sticky-filter-header .tags_search {
  width: 12% !important;
}
.contacts .sticky-filter-header .tags_search .ui-select-container {
  max-width: 140px;
}
.contacts .sticky-filter-header .tags_search .ui-select-container .fa-times.text-danger {
  position: absolute;
  right: 1px;
  top: 9px;
}
.population-contacts table.bg-white th,
.population-contacts table.bg-white td {
  width: 10% !important;
}
.population-contacts table .accountDisplay {
  min-width: 85px;
}
.population-contacts table.grid tbody td:not(.hidden) {
  display: block;
}
.population-contacts table .tags_search .ui-select-container {
  max-width: 140px;
}
.population-contacts table .tags_search .ui-select-container .fa-times.text-danger {
  position: absolute;
  right: 1px;
  top: 9px;
}
.population-contacts .ui-select-match .btn-link {
  color: #a94442;
  margin-right: 0 !important;
  position: absolute;
  right: 0px;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search:not([placeholder='']) {
  max-width: 100% !important;
}
.form-horizontal .editable {
  line-height: 30px;
}
.form-horizontal .editable.editable-hide {
  line-height: auto;
}
#import-contact-modal .preview-customers .form-group {
  height: 34px;
}
#import-contact-modal .field-mapper .field-status {
  height: 30px;
  line-height: 45px;
}
#import-contact-modal .import-contact-modal-options .list-group-item {
  min-height: 180px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  font-size: 22px!important;
}
.editable-empty,
.editable-empty:hover,
.editable-empty:focus,
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
  color: #858585 !important;
}
.editable-error,
.editable-error:hover,
.editable-error:focus,
a.editable-error,
a.editable-error:hover,
a.editable-error:focus {
  color: #D14 !important;
}
textarea#newNote {
  max-width: 100%;
}
.contact-email-address {
  text-transform: lowercase;
}
.modal-body.contact-modal .ui-select-multiple input.ui-select-search {
  max-width: 100% !important;
}
/* ---------------------------------------------------------------------- */
/*  Orders
/* ---------------------------------------------------------------------- */
.orders .column-width {
  width: 12.5%;
}
@media (max-width: 767px) {
  .orders .column-width {
    width: 50% !important;
  }
}
.orders table.grid thead th {
  float: left;
}
.orders .col-style {
  line-height: 34px;
  margin-left: 5px;
}
.orders .table-list-view .sticky-header-class tr > th {
  width: 12%;
}
.orders .table-list-view .sticky-header-class tr th:first-child {
  width: 5%;
}
.orders .table-list-view .sticky-header-class tr th.customer-col {
  width: 17%;
}
.orders .table-list-view .sticky-header-class tr th.item-col {
  width: 18%;
}
.orders .table-list-view .sticky-header-class tr th.center-col {
  text-align: initial;
}
.orders .table-list-view .sticky-header-class tr.order-filter-header th {
  width: 12%;
}
.orders .table-list-view .sticky-header-class tr.order-filter-header th:first-child {
  width: 5%;
}
.orders .table-list-view .sticky-header-class tr.order-filter-header th.customer-col {
  width: 17%;
}
.orders .table-list-view .sticky-header-class tr.order-filter-header th.item-col {
  width: 18%;
}
@media (max-width: 768px) {
  .orders .table-list-view .sticky-header-class tr th.customer-col {
    width: 20%;
  }
  .orders .table-list-view .sticky-header-class tr th.center-col {
    text-align: initial;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .orders .table-list-view .sticky-header-class tr th:first-child {
    width: 7%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .orders .table-list-view tr th {
    width: 12%;
  }
  .orders .table-list-view tr th:first-child {
    width: 7% !important;
  }
  .orders .table-list-view tr th.item-col {
    width: 36% !important;
  }
  .orders .table-list-view tr th.center-col {
    text-align: center;
  }
}
.orders .table-list-view tr > th {
  width: 11%;
}
.orders .table-list-view tr th:first-child {
  width: 7%;
}
.orders .table-list-view tr th.customer-col {
  width: 18%;
}
.orders .table-list-view tr th.item-col {
  width: 20%;
}
.orders .table-list-view tbody tr > td {
  width: 12%;
}
.orders .table-list-view tbody tr > td:first-child {
  width: 5%;
}
.orders .table-list-view tbody tr td.customer-col {
  width: 17%;
}
.orders .table-list-view tbody tr td.item-col {
  width: 18%;
}
.orders .table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.orders .table-list-view span.text-adjust-inner-content {
  display: block;
  max-width: 100%;
}
.orders .order-filter-header {
  background: #fff;
}
.order-quantity-span {
  float: left;
  clear: both;
}
.pages-top small {
  display: inline-block;
  overflow: hidden;
}
.pages-top small.text-adjusts {
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* ---------------------------------------------------------------------- */
/*  Traffic Fingerprint
/* ---------------------------------------------------------------------- */
site-traffic-component .table-list-view th,
site-traffic-component .table-list-view td {
  width: 25%;
}
site-traffic-component table.grid tbody td strong {
  width: 120px;
}
customer-traffic-component .table-list-view th,
customer-traffic-component .table-list-view td {
  width: 20%;
}
customer-traffic-component .table-list-view td.user-finger-print {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
customer-traffic-component table.grid tbody td strong {
  width: 120px;
}
customer-traffic-component table.grid tbody tr {
  min-height: 185px;
}
.text-adjust-outerTable {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
.text-adjust-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 200px;
}
.text-adjust-complexTable {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100px;
}
.text-adjust-small-table {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  max-width: 75px;
}
.text-adjust {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.text-adjust-span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.contact-tag span.ui-select-match-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.choose-account ul {
  margin-bottom: 0;
  padding-left: 0;
}
.choose-account ul li {
  list-style-type: none;
  margin-bottom: 15px;
}
.choose-account ul li .account-logo {
  width: 130px;
  background: transparent;
  padding: 5px;
  border-radius: 3px;
  margin-right: 15px;
  float: left;
  min-height: 40px;
}
@media all and (max-width: 1024px) {
  .choose-account ul li .account-logo {
    width: 100%;
  }
}
.choose-account ul li .account-logo img {
  max-width: 120px;
  max-height: 30px;
  margin: 0 auto;
  display: block;
}
.choose-account ul li span {
  line-height: 40px;
}
@media all and (max-width: 496px) {
  .choose-account ul li span {
    word-break: break-all;
    float: left;
    width: 200px;
  }
  .choose-account ul li span.largeview {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 376px) {
  .choose-account ul li span {
    width: 170px;
  }
}
@media all and (min-width: 497px) {
  .choose-account ul li span.mobileview {
    display: none;
  }
}
.choose-account ul li:last-child {
  margin-bottom: 0;
}
.choose-account ul li:hover {
  background: #f1f1f1;
  border-radius: 3px;
}
.choose-account ul li i {
  line-height: 40px;
  color: #4bafca;
  margin-right: 10px;
}
.choose-account fieldset {
  margin-bottom: 0;
}
.filemanager-options {
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fcfcfc;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  -moz-box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
}
.filemanager-options li {
  display: inline-block;
  padding: 0px 15px;
  font-size: 12px;
  color: #666;
}
.filemanager-options li a {
  color: #666;
}
.filemanager-options li a.active {
  color: #48b1cc;
  font-weight: 600;
  border-bottom: 3px solid #48b1cc;
}
.filemanager-options li a.disabled {
  opacity: 0.5;
  cursor: default;
}
.filemanager-options li a.disabled:hover {
  text-decoration: none;
}
.filemanager-options li i {
  font-size: 14px;
  margin-right: 5px;
  color: #444;
}
.filemanager-options .paginationBtns {
  padding: 0;
}
.filemanager-options .paginationBtns li {
  padding: 0;
}
.filemanager-options .ckbox {
  margin-top: 5px;
}
.filemanager-options .ckbox label {
  margin: 0 !important;
}
.filemanager-options .filter-type {
  border: 0;
}
.filemanager-options .filter-type a:nth-child(1) {
  padding-right: 7px;
  font-weight: bold;
}
.filemanager-options .filter-type a {
  display: inline-block;
  margin-right: 10px;
  text-transform: uppercase;
}
.filemanager-options .filter-type a:last-child {
  margin-right: 0;
}
.filemanager-options .filter-type a:first-child {
  margin-left: 10px;
}
.filemanager-options .filter-type a.active {
  text-decoration: underline;
}
.filemanager .thmb {
  border: 1px solid #fcfcfc;
  background: #fcfcfc;
  border-radius: 3px;
  margin-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  -moz-box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
}
.filemanager .thmb.checked {
  border-color: #ccc;
}
.filemanager .thmb::after {
  clear: both;
  display: block;
  content: '';
}
.filemanager .ckbox {
  position: absolute;
  top: 4px;
  left: 5px;
  display: none;
}
.filemanager .fm-group {
  position: absolute;
  top: 15px;
  right: 5px;
  display: none;
}
.filemanager .fm-toggle {
  padding: 1px 4px;
  line-height: normal;
  background: #fff;
  border-radius: 2px;
}
.filemanager .fm-menu {
  min-width: 120px;
}
.filemanager .fm-menu a {
  font-size: 12px;
  color: #333;
}
.filemanager .fm-menu i {
  margin-right: 7px;
  color: #999;
  width: 16px;
  font-size: 13px;
}
.filemanager .thmb-prev {
  background: #eee;
  overflow: hidden;
}
.filemanager .thmb-prev a {
  display: block;
}
.filemanager .thmb-prev img {
  max-height: 150px;
  width: 100%;
}
.filemanager .fm-title {
  margin-bottom: 0;
  font-size: 13px;
  padding: 8px;
  background-color: #8e8e93;
}
.filemanager .fm-title .fa-lock {
  font-size: 14px;
}
.filemanager .fm-title .fa {
  color: #e8e8e8;
}
.filemanager .fm-title a {
  color: #ffffff;
}
.filemanager .fm-title a span {
  width: 78%;
}
@media all and (max-width: 1023px) {
  .filemanager .fm-title a span {
    width: 70%;
  }
}
.fm-sidebar {
  padding-left: 10px;
}
.fm-sidebar .subtitle a {
  color: #999;
  font-size: 11px;
}
.fm-sidebar .subtitle a:hover {
  text-decoration: none;
  color: #333;
}
.fm-sidebar .tag-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.fm-sidebar .tag-list li {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
}
.fm-sidebar .tag-list li a {
  display: block;
  padding: 2px 7px;
  font-size: 11px;
  background: #fcfcfc;
  border-radius: 3px;
  color: #666;
}
.fm-sidebar .tag-list li a:hover {
  color: #fff;
  text-decoration: none;
  background: #428BCA;
}
.folder-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.folder-list li {
  display: block;
  border-bottom: 1px solid #ddd;
}
.folder-list li a {
  display: block;
  padding: 5px 0;
  color: #666;
}
.folder-list li a:hover {
  color: #333;
  text-decoration: none;
}
.folder-list li i {
  margin-right: 10px;
  width: 16px;
}
.modal-footer.media-footer {
  position: absolute;
  width: 100%;
  bottom: -10;
}
.app-ingram-body .filemanager-options li a.active {
  color: #0077b4 !important;
  border-bottom: 3px solid #0077b4 !important;
}
.app-sonicwall-body #media-manager-modal .modal-header {
  background-color: #06293d;
}
.app-sonicwall-body #media-manager-modal .filemanager-options li a.active {
  color: #06293d;
  border-bottom: 3px solid #06293d;
}
.app-sonicwall-body #media-manager-modal .clip-check.check-primary input[type="checkbox"]:checked + label:before {
  border-color: #06293d;
}
.app-brandforce-body #media-manager-modal .modal-header {
  background-color: #06293d;
}
.app-brandforce-body #media-manager-modal .filemanager-options li a.active {
  color: #06293d;
  border-bottom: 3px solid #06293d;
}
.app-brandforce-body #media-manager-modal .clip-check.check-primary input[type="checkbox"]:checked + label:before {
  border-color: #06293d;
}
.app-blueequinox-body #media-manager-modal .modal-header {
  background-color: #2A3D46;
}
.app-blueequinox-body #media-manager-modal .filemanager-options li a.active {
  color: #06293d;
  border-bottom: 3px solid #2A3D46;
}
.app-blueequinox-body #media-manager-modal .clip-check.check-primary input[type="checkbox"]:checked + label:before {
  border-color: #2A3D46;
}
#media-manager-modal .imgedit-wrap {
  padding: 25px;
}
#media-manager-modal .modal-header {
  background-color: #48b1cc;
}
#media-manager-modal .modal-header span.addMedia {
  margin-top: 5px;
}
#media-manager-modal .modal-header span.addMedia i.fa {
  font-size: 22px;
  color: #dfdfe0;
}
#media-manager-modal h4.modal-title {
  color: #ffffff;
}
#media-manager-modal .modal-body {
  padding: 0;
}
#media-manager-modal .modal-body .uploadArea {
  width: 100%;
  min-height: 200px;
  padding: 10px;
  border: 3px dashed #aaaaaa;
  text-align: center;
  border-radius: 0;
  background-color: transparent;
}
#media-manager-modal .modal-body .uploadArea h4 {
  margin-bottom: 25px;
}
#media-manager-modal .modal-body .uploadArea label.btn {
  background-color: #fff;
  color: #96969b;
  border-radius: 1px;
}
#media-manager-modal .modal-footer {
  margin-top: 0;
}
#media-manager-modal .modal-footer.upload {
  height: calc(100% - 60px);
  overflow: auto;
}
#media-manager-modal .single-filemanager .filemanager-options {
  margin: 0;
}
#media-manager-modal .asset-body {
  padding-left: 10px;
  padding-right: 0;
}
#media-manager-modal .asset-body:nth-child(4n+1) {
  padding-left: 10px;
}
@media all and (min-width: 992px) {
  #media-manager-modal .asset-body {
    width: 20%;
  }
}
#media-manager-modal .filemanager-options {
  margin: 0 15px 0 15px;
  z-index: 9999;
  position: relative;
}
#media-manager-modal .filemanager-options .ckbox {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
#media-manager-modal .filemanager-options hr {
  margin: 10px 0;
}
@media all and (min-width: 767px) {
  #media-manager-modal .small-view {
    display: none;
  }
}
#media-manager-modal .small-view.small-footer {
  margin-left: 0;
  margin-right: 0;
}
#media-manager-modal .small-view.small-footer .paginationBtns {
  text-align: center;
  margin-top: 15px;
}
#media-manager-modal .small-view.small-footer .indi-select {
  width: 70%;
}
@media all and (max-width: 490px) {
  #media-manager-modal .small-view.small-footer .indi-select {
    width: 80%;
  }
}
@media all and (max-width: 767px) {
  #media-manager-modal .large-view {
    display: none;
  }
}
#media-manager-modal .options-footer {
  background-color: #f3f3f3;
}
#media-manager-modal .options-footer.large-view {
  margin: 0;
  padding-top: 15px;
}
@media (min-width: 491px) and (max-width: 768px) {
  #media-manager-modal .options-footer.large-view .action-dropdown {
    min-width: 85px;
  }
}
#media-manager-modal .options-footer.large-view .indi-select .fa-angle-down {
  background-color: #ffffff;
}
#media-manager-modal .filemanager .media {
  height: 340px;
  max-height: 470px;
  overflow-y: scroll;
  margin-right: 15px;
  padding: 0px 15px 0px 20px;
}
#media-manager-modal .filemanager .filter-media {
  padding: 20px;
  margin-right: 15px;
  padding: 20px 5px 0px 20px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #media-manager-modal .filemanager .filter-media.filter-view .btn-group {
    margin-bottom: 15px;
    float: left;
  }
}
#media-manager-modal .filemanager .filter-media.filter-view .indi-select {
  display: inline-block;
}
@media all and (max-width: 490px) {
  #media-manager-modal .filemanager .filter-media.filter-view .indi-select {
    width: 70%;
    float: right;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  #media-manager-modal .filemanager .filter-media.filter-view .indi-select {
    width: 65%;
  }
}
#media-manager-modal .filemanager .filter-media.filter-view .indi-select select.form-control {
  padding-right: 30px;
}
#media-manager-modal .filemanager .filter-media.filter-view .search-media {
  float: right;
  padding-right: 5px;
}
#media-manager-modal .filemanager .filter-media.filter-view .search-media .input-icon {
  display: inline-block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #media-manager-modal .filemanager .filter-media.filter-view .search-media .libtn {
    float: right;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  #media-manager-modal .filemanager .filter-media.filter-view .search-media .input-icon {
    width: 57%;
  }
  #media-manager-modal .filemanager .filter-media.filter-view .search-media .libtn {
    float: right;
    min-width: 95px;
  }
}
@media all and (max-width: 490px) {
  #media-manager-modal .filemanager .filter-media.filter-view .search-media {
    float: unset;
    padding-right: 10px;
  }
  #media-manager-modal .filemanager .filter-media.filter-view .search-media .input-icon {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  #media-manager-modal .filemanager .filter-media.filter-view .search-media .libtn {
    float: right;
  }
}
@media (min-width: 491px) and (max-width: 768px) {
  #media-manager-modal .filemanager .filter-media.filter-view .search-media .libtn {
    min-width: 70px;
  }
}
#media-manager-modal .filemanager .view-toggle {
  padding-left: 10px;
  display: inline-block;
}
#media-manager-modal .filemanager .view-toggle .btn-default.btn:first-child {
  margin-right: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
#media-manager-modal .filemanager .view-toggle .btn-default.btn:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-color: #eeeeee !important;
}
#media-manager-modal .filemanager .view-toggle .btn-default.btn:last-child.active {
  border: 1px solid #ccc !important;
}
#media-manager-modal .filemanager .view-toggle .btn-default.active {
  border: 1px solid #ccc !important;
  border-radius: 4px;
}
#media-manager-modal .filemanager .dropdown-menu {
  position: absolute;
  top: 100%;
  left: -100px;
  margin: 0;
  padding: 2px 0;
}
#media-manager-modal .filemanager .dropdown-menu > li > a {
  padding: 2px 20px;
}
#media-manager-modal .filemanager .thmb-prev {
  background: #eee;
  overflow: hidden;
  width: 100%;
  height: 115px;
  position: relative;
  text-align: center;
}
#media-manager-modal .filemanager .thmb-prev img {
  max-height: 150px;
  width: auto;
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  right: 0;
  top: 0;
}
#media-manager-modal .filemanager .thmb-prev img.pdfThumbnail {
  width: 100%;
  max-height: unset;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #media-manager-modal .filemanager .thmb-prev img.pdfThumbnail {
    width: 100%;
    top: 0px;
    max-height: 115px;
  }
}
#media-manager-modal .filemanager .thmb-prev .folder {
  cursor: pointer;
  font-size: 130px;
  text-align: center;
  line-height: 150px;
  opacity: 0.5;
  position: absolute;
  left: 0;
  right: 0;
}
#media-manager-modal label[for="upload_image"] {
  margin-bottom: 0 !important;
}
#media-manager-modal .all .media > *:not([id]),
#media-manager-modal .all .media #all-warning-box,
#media-manager-modal .image .media #image-warning-box,
#media-manager-modal .video .media #video-warning-box,
#media-manager-modal .audio .media #audio-warning-box,
#media-manager-modal .document .media #document-warning-box {
  display: inline;
}
#media-manager-modal .media .water_mark_share {
  background-color: #ffffff;
  opacity: 0.5;
  filter: alpha(opacity=60);
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 70px;
  text-align: center;
  padding: 40px;
  left: 0;
  right: 0;
}
#media-manager-modal .document .media > [class*="documnet"] {
  display: inline;
}
#media-manager-modal .audio .media > [class*="audio"] {
  display: inline;
}
#media-manager-modal .video .media > [class*="video"] {
  display: inline;
}
#media-manager-modal .image .media > [class*="image"] {
  display: inline;
}
#media-manager-modal .all [data-active="all"],
#media-manager-modal .video [data-active="video"],
#media-manager-modal .audio [data-active="audio"],
#media-manager-modal .image [data-active="image"],
#media-manager-modal .document [data-active="document"],
#media-manager-modal .shared [data-active="shared"],
#media-manager-modal .fonts [data-active="font"] {
  text-decoration: underline;
  color: #48b1cc;
}
#media-manager-modal .shared [data-active="shared"] {
  text-decoration: underline !important ;
}
#media-manager-modal [class*="image"] ~ #all-warning-box,
#media-manager-modal [class*="audio"] ~ #all-warning-box,
#media-manager-modal [class*="document"] ~ #all-warning-box,
#media-manager-modal [class*="video"] ~ #all-warning-box,
#media-manager-modal [class*="image"] ~ #image-warning-box,
#media-manager-modal [class*="audio"] ~ #audio-warning-box,
#media-manager-modal [class*="document"] ~ #document-warning-box,
#media-manager-modal [class*="video"] ~ #video-warning-box {
  display: none !important;
}
#media-manager-modal [id="warning-box"] {
  padding: 20px;
  color: red;
  font-size: 20px;
}
#media-manager-modal .modal-dialog.modal-lg {
  max-width: calc(100% - 30px);
  margin: 15px auto;
}
#media-manager-modal form.editable-text .editable-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
#media-manager-modal form.editable-text .editable-controls .editable-input {
  max-width: 100%;
  -webkit-user-select: text;
}
#media-manager-modal form.editable-text .editable-controls .editable-buttons {
  display: block;
  position: absolute;
  top: -40px;
  width: 100%;
  text-align: center;
}
#media-manager-modal .asset-body-list .body-asset {
  border: 0.5px solid #e5e4e4;
  padding: 0 10px;
  background-color: #f8f8f8;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 5px;
}
#media-manager-modal .asset-body-list .body-asset .clip-check label {
  margin-right: 0;
}
#media-manager-modal .asset-body-list .body-asset a.editable-click {
  border-bottom: none;
  color: #5b5b60;
}
#media-manager-modal .asset-body-list .body-asset a.editable-click:hover {
  color: #5b5b60;
}
#media-manager-modal .asset-body-list .body-asset .text-adjust-inner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 700px;
  display: inline-block;
}
@media (min-width: 491px) and (max-width: 999px) {
  #media-manager-modal .asset-body-list .body-asset .text-adjust-inner {
    max-width: 400px;
  }
}
@media all and (max-width: 490px) {
  #media-manager-modal .asset-body-list .body-asset {
    margin-right: 0;
  }
  #media-manager-modal .asset-body-list .body-asset .text-adjust-inner {
    max-width: 190px;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  #media-manager-modal .asset-body-list .body-asset .text-adjust-inner {
    max-width: 130px;
  }
}
#media-manager-modal .asset-body-list .body-asset form.editable-text .editable-controls {
  position: relative;
  top: -4px;
}
#media-manager-modal .asset-body-list .body-asset form.editable-text .editable-controls .editable-buttons {
  display: inline-block;
  position: relative;
  top: 0;
  width: inherit;
}
#media-manager-modal .asset-body-list .list-fm-title {
  padding: 8px;
  float: right;
  margin-top: 10px;
  margin-bottom: 0;
}
#media-manager-modal .asset-body-list .list-fm-title i.fa.pull-right {
  margin-left: 0.5em;
}
#media-manager-modal .asset-body-list .list-fm-title .fa-lock {
  font-size: 16px;
}
#media-manager-modal .asset-body-list .dropdown-group {
  float: right;
  top: 15px;
}
#media-manager-modal .replace-media-upload {
  cursor: pointer;
  text-align: center;
  width: 100%;
  padding-right: 10px;
  font-size: 13px;
}
#media-manager-modal .media-paging-overlay {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  background: #000000;
  z-index: 9999;
}
#media-manager-modal .media-paging-overlay h1 {
  position: fixed;
  display: inline-block;
  text-align: center;
  color: white;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 15px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #media-manager-modal .media-paging-overlay h1 {
    width: 90%;
    transform: translate(0);
    position: relative;
  }
}
#media-manager-modal .pagination {
  margin: 0;
  padding: 0;
}
#media-manager-modal .paginationBtns ul.pagination {
  padding-top: 3px;
}
@media all and (min-width: 768px) {
  #media-manager-modal .paging-media {
    text-align: right;
    min-height: 34px;
    padding-right: 10px;
    padding-left: 0px;
  }
}
#media-manager-modal .paging-limit-container .radio-inline {
  margin-bottom: 7px !important;
}
#media-manager-modal .paging-limit-container .radio-inline input {
  margin-top: 2px;
}
#media-manager-modal .filter-media .indi-select i.fa {
  right: 20px;
}
@media all and (max-width: 767px) {
  #media-manager-modal .mobile-view .media {
    margin-right: 25px;
  }
}
#media-manager-modal .media-modal-new-media {
  background: #eee;
}
#media-manager-modal .media-modal-new-page-email {
  width: 100%;
  min-height: 200px;
  text-align: center;
  border-radius: 0;
  background-color: transparent;
}
#media-manager-modal .media-modal-new-page-email .media-modal-page {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  text-align: left;
}
/* ---------------------------------------------------------------------- */
/*  NG Tags Input
/* ---------------------------------------------------------------------- */
tags-input {
  box-shadow: none;
  border: none;
  padding: 0;
  min-height: 34px;
}
tags-input .host {
  margin: 0;
}
tags-input .tags {
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #eeeeee;
  margin: 0;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
tags-input .tags .tag-item {
  color: white;
  background: #47b1cc !important;
  border: 1px solid #357ebd;
  border-radius: 4px;
}
tags-input .tags .tag-item .remove-button:hover {
  text-decoration: none;
}
tags-input .tags .tag-item.selected {
  color: white;
  background: #d9534f;
  border: 1px solid #d43f3a;
}
tags-input .tags:hover {
  background: none;
  border: 1px solid #d5d5d5;
}
tags-input .tags .input {
  -webkit-user-select: text;
}
tags-input .tags .input:focus {
  outline: none;
}
tags-input .tags.focused {
  box-shadow: none!important;
  border-color: #e1e1e1 !important;
  background-color: #F8F8F8 !important;
}
tags-input .autocomplete {
  border-radius: 4px;
}
tags-input .autocomplete .suggestion-item.selected {
  color: #262626;
  background-color: whitesmoke;
}
tags-input .autocomplete .suggestion-item.selected em {
  color: #262626;
  background-color: whitesmoke;
}
tags-input .autocomplete .suggestion-item em {
  color: black;
  background-color: white;
}
tags-input.ng-invalid .tags {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
tags-input.ti-input-lg,
.input-group-lg tags-input {
  min-height: 46px;
}
tags-input.ti-input-lg .tags,
.input-group-lg tags-input .tags {
  border-radius: 6px;
}
tags-input.ti-input-lg .tags .tag-item,
.input-group-lg tags-input .tags .tag-item {
  height: 38px;
  line-height: 37px;
  font-size: 18px;
  border-radius: 6px;
}
tags-input.ti-input-lg .tags .tag-item .remove-button,
.input-group-lg tags-input .tags .tag-item .remove-button {
  font-size: 20px;
}
tags-input.ti-input-lg .tags .input,
.input-group-lg tags-input .tags .input {
  height: 38px;
  font-size: 18px;
}
tags-input.ti-input-sm,
.input-group-sm tags-input {
  min-height: 30px;
}
tags-input.ti-input-sm .tags,
.input-group-sm tags-input .tags {
  border-radius: 3px;
}
tags-input.ti-input-sm .tags .tag-item,
.input-group-sm tags-input .tags .tag-item {
  height: 22px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 3px;
}
tags-input.ti-input-sm .tags .tag-item .remove-button,
.input-group-sm tags-input .tags .tag-item .remove-button {
  font-size: 16px;
}
tags-input.ti-input-sm .tags .input,
.input-group-sm tags-input .tags .input {
  height: 22px;
  font-size: 12px;
}
.has-feedback tags-input .tags {
  padding-right: 30px;
}
.has-feedback.has-success tags-input .tags {
  border-color: #3c763d;
}
.has-feedback.has-success tags-input .tags.focused {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.has-feedback.has-error tags-input .tags {
  border-color: #a94442;
}
.has-feedback.has-error tags-input .tags.focused {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-feedback.has-warning tags-input .tags {
  border-color: #8a6d3b;
}
.has-feedback.has-warning tags-input .tags.focused {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.input-group tags-input {
  padding: 0;
  display: table-cell;
}
.input-group tags-input:not(:first-child) .tags {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group tags-input:not(:last-child) .tags {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-lg tags-input:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.input-group-lg tags-input:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.input-group-sm tags-input tags-input:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.input-group-sm tags-input tags-input:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.new-campaign-modal-form tags-input.ng-invalid .tags {
  box-shadow: none;
  border: 1px solid #eeeeee;
}
.new-campaign-modal-form tags-input.ng-invalid.ng-dirty .tags {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
/* ---------------------------------------------------------------------- */
/*  Product Detail
/* ---------------------------------------------------------------------- */
.product-detail #variation-manager .ui-select-bootstrap .ui-select-toggle > .caret {
  margin-right: -4px;
}
.product-detail #variation-manager .ui-select-toggle {
  padding-right: 20px;
}
.product-detail #variation-manager #panel4 .panel-body {
  border-top: 1px solid #eee;
}
.product-detail .well {
  position: relative;
}
.product-detail .well .form-control-feedback {
  text-align: left;
  height: auto;
  width: auto;
  margin-right: 16px;
}
.product-detail .well .form-control-feedback.glyphicon {
  top: 8px;
}
.product-detail .well .form-control-feedback.fa {
  top: 20px;
}
.product-detail .well .email-subject-status {
  position: absolute;
  right: 3em;
  top: 12px;
}
.product-detail .mini-editor {
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  overflow: auto;
}
.product-detail .show-fullscreen {
  position: absolute;
  right: 0;
  margin-right: 18px;
  z-index: 999;
}
.product-detail #componentloader {
  min-height: 100% !important;
}
.product-detail a#convert {
  color: #5b5b60;
}
.product-detail .asset-slider .slick-track {
  min-width: 100%;
}
.product-detail .asset-slider .slick-prev {
  left: 0;
  z-index: 1;
}
.product-detail .asset-slider .slick-next {
  right: 0;
  z-index: 1;
}
.product-detail .asset-slider .slick-prev:before,
.product-detail .asset-slider .slick-next:before {
  font-size: 26px;
  opacity: 0.25;
}
.iconpicker-popover .btn.disabled {
  pointer-events: none;
}
.asset-slide {
  height: 65px;
  width: 65px;
}
.asset-slide img {
  max-width: 100%;
}
/* ---------------------------------------------------------------------- */
/*  Integrations
/* ---------------------------------------------------------------------- */
.integrations .integration-avatar {
  min-height: 38px;
}
.integrations .single-integration.ng-enter {
  -webkit-animation: bounceIn 1s;
  -moz-animation: bounceIn 1s;
  -ms-animation: bounceIn 1s;
  animation: bounceIn 1s;
}
.integrations .single-integration.ng-leave {
  -webkit-animation: bounceOut 1s;
  -moz-animation: bounceOut 1s;
  -ms-animation: bounceOut 1s;
  animation: bounceOut 1s;
}
/* ---------------------------------------------------------------------- */
/*  business profile
/* ---------------------------------------------------------------------- */
.business .min-height45 {
  min-height: 45px;
}
.business .multiple-hours .checkbox {
  margin-right: 0px !important;
}
.business pre.business-description {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: none;
  background: none;
  margin: 5px;
  padding: 0px;
  table-layout: fixed;
  width: 100%;
  display: table;
}
.business td.business-profile-label {
  vertical-align: top;
  width: 100px;
}
/* ---------------------------------------------------------------------- */
/*   Settings
/* ---------------------------------------------------------------------- */
.sitesettings .favicon-image {
  background: url('https://s3-us-west-2.amazonaws.com/indigenous-admin/Browser-Mockup.png') no-repeat;
  height: 60px;
  background-size: cover;
  margin-bottom: 15px;
  max-width: 575px;
  /* <- image alignment with favicons gets distorted at greater width */
}
.sitesettings .favicon-image .pagetitle {
  margin-left: 94px;
  position: absolute;
  margin-top: 12px;
}
.sitesettings .favicon-image .pageurl {
  margin-left: 104px;
  position: absolute;
  margin-top: 37px;
}
.sitesettings .favicon-image .top-favicon {
  width: 16px;
  height: 16px;
  right: -75px;
  position: relative;
  top: 11px;
}
.sitesettings .favicon-image .bottom-favicon {
  width: 16px;
  height: 16px;
  right: -64px;
  position: relative;
  top: 35px;
}
.sitesettings .favicon-image .default-rvlvr-logo {
  height: 5px !important;
}
.sitesettings .tab-pane {
  min-height: 230px;
}
.sitesettings .tab-pane .website-cname-url {
  background: #f3f3f3;
  padding: 10px;
  height: auto;
  font-weight: 600;
  color: #3b3b3b;
  border-radius: 0px;
  word-wrap: break-word;
}
.sitesettings .input-icon i {
  left: auto;
  right: 10px;
  position: absolute;
  top: 10px;
}
.sitesettings .settings-redirect-pages ul.ui-select-choices {
  max-height: 120px;
}
.websiteEmails .tabbable {
  margin-bottom: 0;
}
.websiteEmails .modal-footer {
  background-color: #e6e8e8;
  border: 1px solid #e5e5e5;
}
.websiteEmails .script-wrap {
  max-width: 600px;
  position: relative;
}
.websiteEmails .script-wrap .pre-wrap-script {
  font-size: 14px;
  padding: 10px;
  padding-top: 20px;
  text-align: left;
  background-color: #e2e2e2;
}
.websiteEmails .script-wrap .send-to-dev {
  color: #969696 !important;
  text-decoration: underline !important;
  cursor: pointer;
  display: block;
}
.websiteEmails .script-wrap .copy-text {
  position: absolute;
  top: 0;
  right: 0;
}
.existing-account-content-js-listener {
  background-color: #ffffff;
}
.existing-account-content-js-listener .modal-body {
  padding: 40px;
}
.existing-account-content-js-listener .modal-body input {
  height: 60px;
}
.existing-account-content-js-listener .modal-body .email-input-field {
  margin-top: 40px;
  margin-bottom: 40px;
}
.existing-account-content-js-listener .modal-body .btn-send-email {
  border-radius: 20px;
  margin-bottom: 30px;
  min-width: 150px;
}
.create-campaign .panel-light-grey {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.create-campaign .mobile-bg {
  background: url('https://s3-us-west-2.amazonaws.com/indigenous-admin/mobile-template.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  height: 495px;
  margin: 30px 0;
}
.create-campaign .desktop-bg {
  background: url('https://s3-us-west-2.amazonaws.com/indigenous-admin/desktop-template.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  height: 495px;
  margin: 30px 0;
}
.create-campaign .datetimepicker .swtich,
.create-campaign .datetimepicker .switch {
  background: none!important;
  width: auto;
  display: table-cell;
}
.create-campaign .delivery-selector .btn-info {
  background-color: #48b1cc !important;
  border-color: #36869a;
}
.create-campaign .delivery-selector .btn-info span {
  color: white;
}
.create-campaign tags-input .tags {
  background-color: white!important;
}
.create-campaign .customer-list .ui-select-dropdown.dropdown-menu,
.create-campaign .email-template-list .ui-select-dropdown.dropdown-menu {
  top: inherit !important;
}
.create-campaign .well {
  position: relative;
}
.create-campaign .well .form-control-feedback {
  text-align: left;
  height: auto;
  width: auto;
  margin-right: 16px;
}
.create-campaign .well .form-control-feedback.glyphicon {
  top: 8px;
}
.create-campaign .well .form-control-feedback.fa {
  top: 20px;
}
.create-campaign .well .email-subject-status {
  position: absolute;
  right: 3em;
  top: 12px;
}
.create-campaign .mini-editor {
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  overflow: auto;
}
.create-campaign .show-fullscreen {
  position: absolute;
  right: 0;
  margin-right: 18px;
  z-index: 999;
}
.create-campaign #componentloader {
  min-height: 100% !important;
}
.create-campaign .swMain > ul li > a:before {
  width: 96%!important;
  left: -45% !important;
}
.create-campaign .swMain > ul li:first-child a:before {
  display: none!important;
}
.create-campaign .swMain > ul li:last-child a:before {
  width: 100%!important;
  left: -47% !important;
  max-width: 100%;
}
.create-campaign .avatars li img {
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 2px;
}
.create-campaign .campaign-step {
  min-height: 350px;
}
.create-campaign .campaign-step-wrap .campaign-step.next.ng-hide-remove {
  -webkit-animation: fadeInRight 1.5s;
  -moz-animation: fadeInRight 1.5s;
  -ms-animation: fadeInRight 1.5s;
  animation: fadeInRight 1.5s;
}
.create-campaign .campaign-step-wrap .campaign-step.next.ng-hide-add {
  position: absolute;
  top: 0;
  -webkit-animation: fadeOutLeft 1.5s;
  -moz-animation: fadeOutLeft 1.5s;
  -ms-animation: fadeOutLeft 1.5s;
  animation: fadeOutLeft 1.5s;
}
.create-campaign .campaign-step-wrap .campaign-step.prev.ng-hide-remove {
  -webkit-animation: fadeOutRight 1.5s;
  -moz-animation: fadeOutRight 1.5s;
  -ms-animation: fadeOutRight 1.5s;
  animation: fadeOutRight 1.5s;
}
.create-campaign .campaign-step-wrap .campaign-step.prev.ng-hide-add {
  position: absolute;
  top: 0;
  -webkit-animation: fadeInLeft 1.5s;
  -moz-animation: fadeInLeft 1.5s;
  -ms-animation: fadeInLeft 1.5s;
  animation: fadeInLeft 1.5s;
}
.s-well .s-well-datepicker table {
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 320px) and (max-width: 374px) {
  .s-well {
    padding: 19px 2px;
  }
  .s-well button.btn-sm {
    padding: 5px 8px;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .s-well {
    padding: 19px 2px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .s-well button.btn-sm {
    padding: 5px 5.6px;
  }
}
@media (min-width: 1441px) and (max-width: 1920px) {
  .s-well button.btn-sm {
    padding: 5px 10.5px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .nav-open .s-well {
    padding: 19px 2px;
  }
  .nav-open .s-well button.btn-sm {
    padding: 5px 5.3px;
  }
  .nav-open .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 12.3px;
  }
  .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 14.6px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .nav-open .s-well button.btn-sm {
    padding: 5px 8px;
  }
  .nav-open .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 18px;
  }
  .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 22px;
  }
}
@media (min-width: 1281px) and (max-width: 1366px) {
  .nav-open .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 20.7px;
  }
  .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 24.5px;
  }
}
@media (min-width: 1367px) and (max-width: 1440px) {
  .nav-open .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 23.5px;
  }
  .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 27.5px;
  }
}
@media (min-width: 1441px) and (max-width: 1920px) {
  .nav-open .s-well button.btn-sm {
    padding: 5px 8px;
  }
}
@media (min-width: 1921px) and (max-width: 1980px) {
  .nav-open .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 40px;
  }
  .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 44px;
  }
}
@media (min-width: 1981px) {
  .nav-open .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 58px;
  }
  .email-campaign-main .col-md-6 .s-well button.btn-sm {
    padding: 5px 62px;
  }
}
/* ---------------------------------------------------------------------- */
/*  Campaigns
/* ---------------------------------------------------------------------- */
.campaigns table.grid thead th {
  width: 11%;
  float: left;
}
@media (max-width: 767px) {
  .campaigns table.grid thead th {
    width: 25% !important;
  }
}
.campaigns .table-list-view .sticky-header-class tr > th {
  width: 10%;
}
.campaigns .table-list-view tbody tr > td {
  width: 10%;
}
.campaigns .table-list-view td.name-col {
  width: 11%;
}
.campaigns .table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.campaigns .table-list-view span.text-adjust-inner-content {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.campaigns .table-list-view .single-email-campagin span.text-adjust-inner-content {
  display: inline-block;
  padding-top: 5px;
}
.campaigns .table-list-view tr.campaign-filter-header th:first-child,
.campaigns .table-list-view tr.campaign-filter-header th:nth-child(2) {
  width: 5%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .campaigns .table-list-view tr.campaign-filter-header th:first-child,
  .campaigns .table-list-view tr.campaign-filter-header th:nth-child(2) {
    width: 7%;
  }
}
.campaigns .campaign-filter-header {
  background: #fff;
}
.campaigns .table.grid tr.campaign-filter-header th {
  height: 53px;
}
.disabled {
  pointer-events: none;
}
.create-campaign-spinner {
  position: absolute;
  font-size: 50px!important;
  left: 50%;
  z-index: 1;
  margin-top: 40%;
  top: 0;
}
.ui-select-match-text {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.campaign-tag-container .ui-select-dropdown {
  top: inherit !important;
}
/* ---------------------------------------------------------------------- */
/*  Topics
/* ---------------------------------------------------------------------- */
.manage-topics .topics-panel-body {
  padding-left: 0;
  padding-right: 0;
}
.manage-topics table.grid thead th {
  width: 12.5%;
  float: left;
}
.manage-topics table.bg-white th,
.manage-topics table.bg-white td {
  width: 10%;
}
.manage-topics table.bg-white .col-title {
  width: 20% !important;
}
.manage-topics table.bg-white td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.manage-topics table.bg-white span.text-adjust-inner-content {
  display: inline-block;
  max-width: 102%;
}
.manage-topics .table thead tr.sticky-filter-header {
  background: #fff;
}
/* ---------------------------------------------------------------------- */
/*  Emails
/* ---------------------------------------------------------------------- */
.email-templates table.grid thead th {
  width: 20%;
  float: left;
}
@media (max-width: 767px) {
  .email-templates table.grid thead th {
    width: 25% !important;
  }
}
.email-templates table.table-list-view .email-title {
  line-height: 38px;
}
.email-templates table.table-list-view .sticky-header-class tr > th {
  width: 20%;
}
.email-templates table.table-list-view tbody tr td {
  width: 20%;
}
.email-templates table.table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.email-templates table.table-list-view span.text-adjust-inner-content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.email-templates table.table-list-view .email-filter-header th:first-child {
  width: 15%;
}
.email-templates .email-panel-body {
  padding-left: 0;
  padding-right: 0;
}
.email-templates .email-filter-header {
  background: #fff;
}
.email-templates .email-filter-header th:first-child {
  width: 15%;
}
.email-templates .email-filter-header th {
  height: 53px;
}
.email-templates .table.grid td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.email-templates .table.grid td.text-adjust-col div.text-adjust-inner-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 300px;
}
@media (max-width: 768px) {
  .help-topic-container .container-fullw {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.help-support-container {
  height: calc(70vh);
  margin-left: -15px;
  margin-right: -15px;
}
.help-support-container iframe {
  border: unset;
}
.customers table.table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.customers table.table-list-view span.text-adjust-inner-content {
  display: inline-block;
  max-width: 100%;
}
.customers table.table-list-view .check-col {
  width: 5%;
}
.customers table.table-list-view .id-col {
  width: 8%;
}
.customers table.table-list-view .subDomain-col {
  width: 15%;
}
.customers table.table-list-view .customDomain-col {
  width: 15%;
}
.customers table.table-list-view .signup-col {
  width: 11%;
}
.customers table.table-list-view .trial-col {
  width: 10%;
}
.customers table.table-list-view .plan-col {
  width: 14%;
}
.customers table.table-list-view .zip-col {
  width: 12%;
}
.customers table.table-list-view .state-col {
  width: 10%;
}
.customers table.table-list-view thead tr.sticky-filter-header {
  background: #fff;
}
.customers .customer-filter-header {
  background: #fff;
}
.customers .customer-panel-body {
  padding-left: 0;
  padding-right: 0;
}
.customers .action-btn {
  background-color: #40546d;
  border-radius: 4px !important;
  color: white;
}
.customers .action-btn:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  position: static;
}
.customers .action-btn:hover {
  background-color: #627894;
  border-radius: 4px !important;
  color: white;
}
.customers .btn-group.open .action-btn:after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.customers .btn-group .dropdown-menu {
  width: 170px;
  margin-left: -40px;
  padding: 3px;
  min-width: 170px;
}
.customers .btn-group .dropdown-menu a {
  display: block;
  width: 160px;
  padding: 7px;
  color: black;
}
.customers .btn-group .dropdown-menu a:hover {
  background-color: #e5e5e5;
}
.customers .customer-business-logo {
  height: auto;
  max-height: 48px;
  width: auto;
  max-width: 200px;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
.customers.main-customer-list table.table-list-view .check-col {
  width: 5%!important;
}
.customers.main-customer-list table.table-list-view .id-col {
  width: 6%!important;
}
.customers.main-customer-list table.table-list-view .subDomain-col {
  width: 13%!important;
}
.customers.main-customer-list table.table-list-view .customDomain-col {
  width: 12%!important;
}
.customers.main-customer-list table.table-list-view .organization-col {
  width: 14%!important;
}
.customers.main-customer-list table.table-list-view .signup-col {
  width: 10%!important;
}
.customers.main-customer-list table.table-list-view .trial-col {
  width: 10%!important;
}
.customers.main-customer-list table.table-list-view .plan-col {
  width: 11%!important;
}
.customers.main-customer-list table.table-list-view .zip-col {
  width: 11%!important;
}
.customers.main-customer-list table.table-list-view .state-col {
  width: 8%!important;
}
.live-visitor .live-visitor-details {
  margin-left: -15px;
  margin-top: -15px;
}
.live-visitor .live-visitor-details .visitor-left-pane {
  max-height: 400px;
  overflow: auto;
  padding-right: 0;
}
.live-visitor .live-visitor-details .visitor-left-pane table {
  width: 100%;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr {
  border-collapse: collapse;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr:nth-child(even) {
  background-color: #efeeef;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr:nth-child(odd) {
  background-color: #FFF;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr.active-visitor-detail td:first-child {
  border-left: 3px solid #11a111;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr th {
  font-weight: bold;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr td,
.live-visitor .live-visitor-details .visitor-left-pane table tr th {
  border-left: 1px solid #efeeef;
  border-right: 1px solid #efeeef;
  padding: 4px 0px 4px 4px;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr td:last-child,
.live-visitor .live-visitor-details .visitor-left-pane table tr th:last-child {
  border-right: none;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr td:first-child,
.live-visitor .live-visitor-details .visitor-left-pane table tr th:first-child {
  border-left: none;
  padding-left: 6px;
}
.live-visitor .live-visitor-details .visitor-left-pane table tr td:first-child {
  border-left: 3px solid transparent;
}
.live-visitor .live-visitor-details .visitor-left-pane table.visitor-class {
  table-layout: fixed;
}
.live-visitor .live-visitor-details .visitor-left-pane table.visitor-class td {
  word-break: break-word;
}
.live-visitor .live-visitor-details .visitor-left-pane table.visitor-class th,
.live-visitor .live-visitor-details .visitor-left-pane table.visitor-class td {
  width: 30%;
}
.live-visitor .live-visitor-details .visitor-left-pane table.visitor-class th:first-child,
.live-visitor .live-visitor-details .visitor-left-pane table.visitor-class td:first-child {
  width: 40%;
}
.live-visitor .live-visitor-details .visitor-left-pane ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.live-visitor .live-visitor-details .visitor-left-pane ul li.active-visitor-detail .visitor-address {
  border-left: #32CD32 5px solid !important;
  padding-left: 10px !important;
}
.live-visitor .live-visitor-details .visitor-left-pane ul li {
  line-height: 2.5em;
  float: left;
  width: 100%;
  border-bottom: 1px solid #e2e2e4;
}
.live-visitor .live-visitor-details .visitor-left-pane ul li:nth-child(even) {
  background-color: #efefef;
}
.live-visitor .live-visitor-details .visitor-right-pane {
  line-height: 1.6em;
  border: 1px solid #efeeef;
  padding: 10px;
}
.live-visitor .live-visitor-details .visitor-right-pane .row {
  margin-left: 0px;
}
.live-visitor .live-visitor-details .visitor-right-pane .main-info,
.live-visitor .live-visitor-details .visitor-right-pane .full-info {
  background-color: #ffffff;
  padding-top: 7px;
  width: 100%;
}
.live-visitor .live-visitor-details .visitor-right-pane .main-info .image-outer,
.live-visitor .live-visitor-details .visitor-right-pane .full-info .image-outer {
  padding: 0px 0px 0px 5px;
}
.live-visitor .live-visitor-details .visitor-right-pane .main-info .image-outer img.logo,
.live-visitor .live-visitor-details .visitor-right-pane .full-info .image-outer img.logo {
  width: 80px;
  height: 80px;
  border-radius: 60%;
}
.live-visitor .live-visitor-details .visitor-right-pane .main-info .fingerprintId,
.live-visitor .live-visitor-details .visitor-right-pane .full-info .fingerprintId {
  word-break: break-word;
}
.live-visitor .live-visitor-details .visitor-right-pane .adv-info {
  font-size: 12px;
}
.live-visitor .live-visitor-details .visitor-right-pane .adv-info > li div {
  padding-right: 0px;
  padding-left: 5px;
  word-break: break-word;
}
.live-visitor .live-visitor-details .visitor-right-pane ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.live-visitor .live-visitor-details .visitor-right-pane ul li {
  display: inline-block;
  width: 48%;
}
@media all and (max-width: 767px) {
  .live-visitor .live-visitor-details .visitor-right-pane ul li {
    display: inline-block;
    width: 100%;
  }
}
.live-visitor .live-visitor-details .visitor-right-pane ul.page-events {
  padding: 5px;
  max-height: 200px;
  overflow-y: scroll;
}
.live-visitor .live-visitor-details .visitor-right-pane ul.page-events li {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
}
.live-visitor .live-visitor-details .visitor-right-pane ul.page-events > li {
  border-left: 1px solid #47b2d1;
  position: relative;
}
.live-visitor .live-visitor-details .visitor-right-pane ul.page-events > li:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: #47b2d1;
}
.live-visitor .live-visitor-details .visitor-right-pane ul.page-events > li:last-child {
  border-left: none;
}
.live-visitor .live-visitor-details .visitor-right-pane ul.page-events > li:last-child:after {
  background: #04e261;
}
.live-visitor .live-visitor-details .visitor-right-pane .page-event-tooltip .tooltip-inner {
  word-wrap: break-word;
}
.live-visitor .live-visitor-details .visitor-address {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.live-visitor .live-visitor-details .visitor-account {
  padding-top: 10px;
  padding-bottom: 10px;
}
.live-visitor .live-visitor-details .visitor-timestamp {
  color: #48b1cc;
  padding-top: 10px;
  padding-bottom: 10px;
}
#dahs-live h4 {
  font-weight: 300;
  font-size: 24px;
}
#dahs-live .analytics-live-widget-container {
  border: none;
}
#dahs-live .visitor-left-pane table tr th:nth-child(2),
#dahs-live .visitor-left-pane table tr td:nth-child(2) {
  display: none;
}
/* ---------------------------------------------------------------------- */
/* Single Customer Site Analytics
/* ---------------------------------------------------------------------- */
.single-customer-site-analytics .highcharts-container {
  width: 100% !important;
}
.server-side-pagination-container .custom-paging-overlay {
  height: 100%;
  width: 100%;
  opacity: 0.6;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  background: #000000;
  z-index: 5;
  min-height: 800px;
}
.server-side-pagination-container .custom-paging-overlay h1 {
  position: fixed;
  display: inline-block;
  text-align: center;
  color: white;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 15px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .server-side-pagination-container .custom-paging-overlay h1 {
    width: 90%;
    transform: translate(0);
  }
}
.server-side-pagination-container .custom-paging-overlay.recipient-list-loading-overlay,
.server-side-pagination-container .custom-paging-overlay.participants-list-loading-overlay {
  position: fixed;
}
@media all and (min-width: 768px) {
  .server-side-pagination-container .custom-paging-overlay.recipient-list-loading-overlay h1,
  .server-side-pagination-container .custom-paging-overlay.participants-list-loading-overlay h1 {
    transform: inherit;
  }
}
.server-side-pagination-container .table > thead > tr > th.sort {
  cursor: pointer;
}
.server-side-pagination-container .table > thead > tr > th.sort:before {
  font-family: FontAwesome;
  content: "\f0dc";
  padding-right: 3px;
  vertical-align: middle;
}
.server-side-pagination-container .table > thead > tr > th.sort.st-sort-descent:before {
  content: "\f0dd" !important;
}
.server-side-pagination-container .table > thead > tr > th.sort.st-sort-ascent:before {
  content: "\f0de" !important;
}
.save-footer {
  background: #444;
  position: absolute;
  width: 100%;
  width: 106%;
  right: -30px;
  bottom: -45px;
  z-index: 9997;
}
.save-footer.stick-to-bottom {
  bottom: 0 !important;
  position: fixed !important;
  width: 100% !important;
  left: 0;
}
.save-footer button {
  margin-right: 90px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.save-footer button.save-explore {
  border-radius: 0;
}
.customer-style-overrides .ssb-spectrum-color-picker-small .sp-preview-inner,
.customer-style-overrides .ssb-spectrum-color-picker-small .sp-preview {
  width: 8.5em;
  height: 34px;
  border-radius: 0;
}
.customer-style-overrides .business-spectrum-color-picker .sp-replacer.sp-light {
  padding: 0;
}
.customer-style-overrides .form-group {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .sm-padding-right-0 {
    padding-right: 0!important;
  }
  .sm-padding-left-0 {
    padding-left: 0!important;
  }
}
@media (max-width: 767px) {
  .xs-padding-0 {
    padding: 0!important;
  }
}
customer-style-component .user-image img {
  width: 100%;
}
.email-preview-container {
  top: 0 !important;
  margin: 0 !important;
}
.email-preview-container .edit-wrap:hover {
  outline: none;
}
.email-preview-container .ssb-active-component:before {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  box-shadow: none !important;
  pointer-events: none;
}
.input-icon-right {
  display: block;
  position: relative;
}
.input-icon-right input {
  padding-right: 30px !important;
  padding-left: 6px;
}
.input-icon-right i {
  bottom: 0;
  color: #48b1cc;
  display: inline-block;
  right: 0;
  line-height: 35px;
  padding: 0 10px;
  position: absolute;
  top: -1px;
  z-index: 2;
}
ul.top-account-dropdown-sub-menu {
  list-style: none;
  padding: 5px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
ul.top-account-dropdown-sub-menu li {
  line-height: 2em;
  cursor: pointer;
  clear: both;
}
ul.top-account-dropdown-sub-menu li:hover {
  background-color: #eeeeee;
}
ul.top-account-dropdown-sub-menu li a {
  color: inherit;
}
ul.top-account-dropdown-sub-menu li a .account-logo {
  width: 90px;
  background: transparent;
  border-radius: 3px;
  margin-right: 10px;
  float: left;
  padding: 1px;
  min-height: 35px;
}
ul.top-account-dropdown-sub-menu li a .account-logo img {
  max-width: 80px;
  max-height: 30px;
  margin: 0 auto;
  display: block;
}
.top-account-dropdown-menu {
  min-width: 200px !important;
  width: 400px;
}
.top-account-dropdown-menu li .current-account {
  background: #A1A3A6;
  padding: 5px;
  color: #fff;
}
.app-text-success {
  color: #25bf89;
}
.app-text-danger {
  color: #f51710;
}
.indi-date-picker {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: white;
  float: right;
  width: 100%;
  max-width: 330px;
}
.indi-date-picker label {
  padding: 0;
  margin: 0;
}
.indi-date-picker input,
.indi-date-picker .date-range-pdf {
  background: transparent !important;
  border: 0;
  font-weight: bold;
  font-size: 22px;
  padding: 0;
  margin: 0;
}
.indi-date-picker input:focus,
.indi-date-picker .date-range-pdf:focus {
  background: transparent !important;
}
@media screen and (max-width: 855px) {
  .indi-date-picker {
    width: 330px;
  }
}
@media screen and (max-width: 768px) {
  .indi-date-picker input {
    font-size: 20px;
  }
}
.disabled-with-opacity {
  pointer-events: none;
  opacity: 0.5;
}
.top-bar-action-buttons {
  font-size: 22px;
}
.resource-builder-page-content .component-wrap {
  background: transparent !important;
}
resource-builder.ssb-site-builder .ssb-page-section .ssb-edit-control-component.ssb-edit-control-element .ssb-settings-btn {
  top: 2px;
}
resource-builder.ssb-site-builder .ssb-page-section .ssb-edit-control-component.ssb-edit-control-element .ssb-edit-control-active {
  margin: 0.25em 0 0 1.5em;
}
@media all and (max-width: 767px) {
  .tbl-scroll-small-screen {
    overflow-x: auto;
  }
}
.tbl-scroll-small-screen td.contact-wrap-col {
  word-break: break-word;
}
.raw-btn-top-bar .btn {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.raw-page-html {
  width: 100%;
}
.raw-page-html iframe {
  height: calc(100vh - 180px);
  width: 100%;
  border: none;
  background-color: #FFFFFF;
}
.raw-page-html-code-mirror {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 1px;
}
.raw-page-html-code-mirror .CodeMirror {
  height: 100%;
}
.raw-page-html-code-mirror .CodeMirror .CodeMirror-hscrollbar,
.raw-page-html-code-mirror .CodeMirror .CodeMirror-vscrollbar,
.raw-page-html-code-mirror .CodeMirror .CodeMirror-scrollbar-filler {
  z-index: 3;
}
.raw-page-html-code-mirror .CodeMirror .CodeMirror-gutters {
  min-height: 100% !important;
}
.raw-html-buttons .btn {
  opacity: 0.5;
}
.raw-html-buttons .btn.active {
  opacity: 1;
}
.page-raw-content {
  background: #fff;
  border-left: 1px solid #eee;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: space-around;
  padding: 7px;
}
.page-raw-content .row {
  min-height: 50px;
  flex: 1;
  margin: 0 !important;
  display: flex;
  flex-flow: row nowrap;
  align-content: space-around;
}
.page-raw-content .row.top-bar-code {
  flex-basis: 300px;
  max-height: calc(100vh - 180px);
}
.page-raw-content .rg-bottom {
  cursor: row-resize;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: -7px;
}
.page-raw-content section {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1px;
  background: #ddd;
  border: 7px solid #fff;
  flex: 1;
  min-width: 30px;
}
.page-raw-content section#one.resizable {
  flex: 0 1 60%;
  max-height: 100%;
}
.page-raw-content section#two.resizable {
  flex: 0 0 20%;
}
.page-raw-content .overlay-iframe {
  top: 0;
  height: 50px;
  left: 50px;
  right: 50px;
  background: transparent;
  position: absolute;
}
.page-raw-content.email-raw-content {
  background: transparent;
  margin-left: -15px;
}
.page-raw-content.email-raw-content section {
  background: transparent;
  border: 7px solid transparent;
}
.ssb-page-raw-mode {
  margin-top: -2px;
}
.ssb-page-raw-mode + .ssb-sidebar .ssb-sidebar-icons .nav {
  border-right: none;
}
.ssb-email-raw-mode #ssb-main.row {
  margin: 0;
}
.ssb-email-raw-mode + email-sidebar .ssb-sidebar-icons .nav {
  border-right: none;
}
.new-campaign-schedule-modal tags-input .tags .input {
  min-width: 75%;
}
@media all and (max-width: 767px) {
  .new-campaign-schedule-modal tags-input .tags .input {
    min-width: 100%;
  }
}
.modal-body.ssb-pages-modal h1 {
  font-size: 36px !important;
}
.well.empty-page h1 {
  font-size: 36px !important;
}
.website-settings tags-input ul.tag-list li.tag-item a.remove-button {
  font-size: 18px !important;
  vertical-align: inherit !important;
}
indi-login-modal .modal-body {
  min-height: 370px;
}
indi-login-modal .modal-title {
  font-size: 1.5em;
}
.indi-iframe-login {
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  border: none;
  height: 0;
  max-height: 90vh;
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}
.indi-iframe-login.visible {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  visibility: visible;
  pointer-events: auto;
  height: auto;
  min-height: 350px;
}
.indi-login-modal-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 90vh;
  background: white;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.is-indi-modal {
  height: auto;
}
.is-indi-modal body {
  height: auto;
  background: #fff;
}
.is-indi-modal .main-login {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}
.is-indi-modal .logo,
.is-indi-modal .box-login,
.is-indi-modal fieldset,
.is-indi-modal .row {
  padding: 0!important;
  margin: 0!important;
  border: 0 none;
  border-radius: 0;
}
.is-indi-modal fieldset {
  margin-top: 15px!important;
}
.is-indi-modal .social {
  margin: 0 0 15px -5px !important;
  padding: 0!important;
}
.is-indi-modal .logo img {
  max-height: 90px!important;
  max-width: 100%!important;
}
.is-indi-modal .error {
  font-size: 12px;
  font-size: 4.45vmin;
  margin: -6px 0 -10px;
  height: 1em;
  color: orange;
}
.is-indi-modal .box-login legend,
.is-indi-modal .social,
.is-indi-modal fieldset > p,
.is-indi-modal .new-account,
.is-indi-modal .copyright,
.is-indi-modal a[href="/forgotpassword"] {
  display: none;
}
.topbar-settings .quote-items-count {
  position: absolute;
  top: 2px;
  border-radius: 50%;
  background: #eea236;
  color: white;
  width: auto;
  min-width: 20px;
  padding: 5px;
  margin-left: -5px;
  font-size: 12px;
}
population-analytics-component .live-traffic-data {
  background: white;
  margin-left: 25%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.07);
  min-height: 200px;
}
population-analytics-component .live-traffic-data h4 {
  margin: 10px;
}
population-analytics-component .live-traffic-data #live-platform-traffic-chart {
  margin-bottom: -15px;
}
@media (max-width: 767px) {
  population-analytics-component .live-traffic-data {
    margin-left: 45%;
  }
}
population-analytics-component .live-traffic-count {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 23%;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
population-analytics-component .live-traffic-count h4 {
  margin: 10px;
}
population-analytics-component .live-traffic-count span {
  position: absolute;
  top: 33%;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 72px;
}
@media (max-width: 767px) {
  population-analytics-component .live-traffic-count {
    width: 40%;
  }
}
population-analytics-component .panel-heading .input-group {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  population-analytics-component .panel-heading .input-group {
    position: relative;
    top: -30px;
    right: 0px;
  }
}
@media (max-width: 767px) {
  population-analytics-component .account-overview-panel h4 {
    font-size: 15px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats {
    margin-right: 5px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .description {
    font-size: 10px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .value {
    font-size: 12px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .percentage-value {
    font-size: 10px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .percentage-value .invalid-data {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1250px) {
  population-analytics-component .account-overview-panel .account-overview-stats {
    margin-right: 5px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .description {
    font-size: 11px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .value {
    font-size: 15px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .percentage-value {
    font-size: 10px !important;
  }
  population-analytics-component .account-overview-panel .account-overview-stats .percentage-value .invalid-data {
    margin-left: 0 !important;
  }
}
population-analytics-component .account-overview-panel .account-overview-stats {
  cursor: pointer;
  padding-top: 5px;
  margin-top: 10px;
  display: inline-block;
  margin-right: 20px;
  border-top: 3px solid transparent;
  opacity: 0.6;
}
population-analytics-component .account-overview-panel .account-overview-stats .description {
  padding-top: 5px;
}
population-analytics-component .account-overview-panel .account-overview-stats .value {
  font-size: 30px;
  color: #4a4a4a;
}
population-analytics-component .account-overview-panel .account-overview-stats .percentage-value {
  float: left;
}
population-analytics-component .account-overview-panel .account-overview-stats .percentage-value .invalid-data {
  margin-left: 10px;
}
population-analytics-component .account-overview-panel .account-overview-stats.active {
  border-top: 3px solid #48b1cc;
  opacity: 1;
}
population-analytics-component .account-overview-panel .highcharts-container .highcharts-tooltip > span {
  min-width: 200px;
}
@media (min-width: 768px) {
  population-analytics-component .population-right-pane {
    padding-left: 0 !important;
  }
}
population-analytics-component .population-right-pane .visitor-details-panel .visitor-details-panel-stats {
  margin-top: 10px;
  display: inline-block;
  margin-right: 5px;
}
population-analytics-component .population-right-pane .visitor-details-panel .visitor-details-panel-stats .description {
  font-size: 12px;
}
population-analytics-component .population-right-pane .visitor-details-panel .visitor-details-panel-stats .value {
  font-size: 30px;
}
population-analytics-component .population-right-pane .visitor-details-panel .panel-wrapper .panel-body {
  padding: 15px 0;
  padding-top: 0;
}
fingerprint-details-component .single-fingerprint-details {
  min-height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 6px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
  background-color: #ffffff;
}
fingerprint-details-component .single-fingerprint-details.page-views-fingerprint {
  border-left: 6px solid #2f76bb;
}
fingerprint-details-component .single-fingerprint-details.contact-fingerprint {
  border-left: 6px solid #25b589 !important;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .col-xs-3 {
  padding: 0px;
  text-align: center;
  height: 70px;
  line-height: 70px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .col-xs-9 {
  padding: 0px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details img {
  width: 50px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-header {
  margin-left: 0px;
  margin-right: 20px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-header h4.fingerprint-visitor-details {
  margin: 0px;
  font-size: 16px;
  margin-right: 35px;
  word-break: break-all;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-header span.fingerprint-location-time {
  font-size: 11px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-view-details {
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 16px;
  cursor: pointer;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-device-view {
  position: absolute;
  right: 35px;
  cursor: pointer;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-device-view.fa-mobile {
  font-size: 20px;
  top: -2px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details .fingerprint-device-view.fa-desktop {
  font-size: 14px;
  top: 2px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid {
  margin-top: 5px;
  padding-right: 0px;
  padding-left: 0px;
  display: inline-block;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid .description {
  font-size: 12px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid .value {
  font-size: 14px;
  color: #000000d6;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid .page-view {
  float: right;
  margin-right: 10px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid .page-view .fa-eye {
  font-size: 16px;
  padding: 3px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid .page-view:hover + .page-events {
  display: block;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid ul.page-events {
  width: 267%;
  display: none;
  z-index: 99;
  position: absolute;
  right: 0%;
  list-style: none;
  margin: 0;
  margin-top: 8px;
  padding: 15px;
  background: #444;
  color: #fff;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid ul.page-events li {
  border-left: 1px solid #47b2d1;
  position: relative;
  width: 100%;
  display: inline-block;
  padding-left: 15px;
  padding-bottom: 10px;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid ul.page-events li:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: #47b2d1;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid ul.page-events li:last-child {
  border-left: none;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid ul.page-events li .text-adjust {
  width: 70%;
}
fingerprint-details-component .single-fingerprint-details .fingerprint-details-grid ul.page-events:after {
  top: -6px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #444;
  border-left: 6px solid transparent;
  position: absolute;
  content: '';
  right: 15px;
}
/* ---------------------------------------------------------------------- */
/*  Resource List
/* ---------------------------------------------------------------------- */
.resourceManagement .resource-panel-body,
.resourceDetails .resource-panel-body {
  padding-left: 0;
  padding-right: 0;
}
.resourceManagement div.text-adjust-col,
.resourceDetails div.text-adjust-col {
  display: block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resourceManagement .file-name,
.resourceDetails .file-name {
  font-size: 11px;
  font-weight: bold;
}
.resourceManagement .file-details,
.resourceDetails .file-details {
  font-size: 10px;
  font-weight: normal;
  padding-top: 3px;
}
.resourceManagement table.grid,
.resourceDetails table.grid {
  background: #f3f3f3 !important;
}
.resourceManagement table.grid thead th,
.resourceDetails table.grid thead th {
  width: 16%;
}
.resourceManagement table.grid td.text-adjust-col,
.resourceDetails table.grid td.text-adjust-col {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.resourceManagement table.grid tbody,
.resourceDetails table.grid tbody {
  padding: 15px 30px;
  margin-top: 0px;
}
.resourceManagement table.grid tbody tr.resource-checked,
.resourceDetails table.grid tbody tr.resource-checked {
  background-color: #c3c4c5ab !important;
}
.resourceManagement table.grid tbody tr,
.resourceDetails table.grid tbody tr {
  border-bottom: none;
  border: 1px solid #ddd;
  border-radius: 0px;
  margin: 10px;
}
.resourceManagement table.grid tbody tr td:last-child,
.resourceDetails table.grid tbody tr td:last-child,
.resourceManagement table.grid tbody tr td:first-child,
.resourceDetails table.grid tbody tr td:first-child {
  padding: 0;
}
.resourceManagement table.grid tbody tr td .resource-list-footer,
.resourceDetails table.grid tbody tr td .resource-list-footer {
  padding: 10px;
}
.resourceManagement table.grid tbody tr td .resource-list-footer.folder-type-footer,
.resourceDetails table.grid tbody tr td .resource-list-footer.folder-type-footer {
  background: #48b1cc;
  color: #fff;
}
.resourceManagement table.grid tbody tr td .button-group .inline-block,
.resourceDetails table.grid tbody tr td .button-group .inline-block {
  margin: 3px;
}
@media (min-width: 1175px) {
  .resourceManagement table.grid tbody tr,
  .resourceDetails table.grid tbody tr {
    width: 31%!important;
  }
}
.resourceManagement .table-list-view .check-col,
.resourceDetails .table-list-view .check-col {
  width: 10%;
}
.resourceManagement .table-list-view .name-col,
.resourceDetails .table-list-view .name-col {
  width: 20%;
}
.resourceManagement .table-list-view .sharedWith-col,
.resourceDetails .table-list-view .sharedWith-col {
  width: 10%;
}
.resourceManagement .table-list-view .downloaded-col,
.resourceDetails .table-list-view .downloaded-col {
  width: 10%;
}
.resourceManagement .table-list-view .type-col,
.resourceDetails .table-list-view .type-col {
  width: 10%;
}
.resourceManagement .table-list-view .modified-col,
.resourceDetails .table-list-view .modified-col {
  width: 10%;
}
.resourceManagement .table-list-view .usedBy-col,
.resourceDetails .table-list-view .usedBy-col {
  width: 10%;
}
.resourceManagement .table-list-view .views-col,
.resourceDetails .table-list-view .views-col {
  width: 10%;
}
.resourceManagement .table-list-view .downloads-col,
.resourceDetails .table-list-view .downloads-col {
  width: 10%;
}
.resourceManagement .table-list-view tbody tr td,
.resourceDetails .table-list-view tbody tr td {
  width: 15%;
}
.resourceManagement .table-list-view td.text-adjust-col,
.resourceDetails .table-list-view td.text-adjust-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
.resourceManagement .table-list-view span.text-adjust-inner-content,
.resourceDetails .table-list-view span.text-adjust-inner-content {
  display: inline-block;
  max-width: 102%;
}
.resourceManagement .sticky-header-class .check-col,
.resourceDetails .sticky-header-class .check-col {
  width: 10%;
}
.resourceManagement .sticky-header-class .name-col,
.resourceDetails .sticky-header-class .name-col {
  width: 20%;
}
.resourceManagement .sticky-header-class .sharedWith-col,
.resourceDetails .sticky-header-class .sharedWith-col {
  width: 10%;
}
.resourceManagement .sticky-header-class .downloaded-col,
.resourceDetails .sticky-header-class .downloaded-col {
  width: 10%;
}
.resourceManagement .sticky-header-class .type-col,
.resourceDetails .sticky-header-class .type-col {
  width: 10%;
}
.resourceManagement .sticky-header-class .modified-col,
.resourceDetails .sticky-header-class .modified-col {
  width: 15%;
}
.resourceManagement .sticky-header-class .usedBy-col,
.resourceDetails .sticky-header-class .usedBy-col {
  width: 10%;
}
.resourceManagement .sticky-header-class .views-col,
.resourceDetails .sticky-header-class .views-col {
  width: 10%;
}
.resourceManagement .sticky-header-class .downloads-col,
.resourceDetails .sticky-header-class .downloads-col {
  width: 10%;
}
.resourceManagement .thmb-prev,
.resourceDetails .thmb-prev {
  background: #eee;
  overflow: hidden;
  width: 100%;
  height: 280px;
  position: relative;
}
.resourceManagement .thmb-prev img,
.resourceDetails .thmb-prev img {
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.resourceManagement .thmb-prev img.pdfThumbnail,
.resourceDetails .thmb-prev img.pdfThumbnail {
  position: relative;
  width: 100%;
}
.resourceManagement .thmb-prev span,
.resourceDetails .thmb-prev span {
  margin-top: 25px;
}
.resourceManagement .thmb-prev .div-overlay,
.resourceDetails .thmb-prev .div-overlay {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.resourceManagement .thmb-prev .div-overlay span,
.resourceDetails .thmb-prev .div-overlay span {
  vertical-align: middle;
  display: table-cell;
  color: #DBDBDB;
}
.resourceManagement .thmb-prev:hover .div-overlay,
.resourceDetails .thmb-prev:hover .div-overlay {
  display: table;
}
.resourceManagement .thmb-desc,
.resourceDetails .thmb-desc {
  background-color: #48b1cc;
  color: #fff;
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.resourceManagement .detail-prev,
.resourceDetails .detail-prev {
  background-color: #f7f7f8;
}
.resourceManagement .detail-prev img,
.resourceDetails .detail-prev img {
  margin: unset;
}
.resourceManagement .shared-domains,
.resourceDetails .shared-domains {
  list-style: none;
  padding-left: 10px;
}
.resourceManagement .shared-domain-content,
.resourceDetails .shared-domain-content {
  background-color: #FFFFFF;
  position: relative;
  margin-left: 40px;
  max-height: 150px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.resourceManagement .shared-domain-content .shared-panel,
.resourceDetails .shared-domain-content .shared-panel {
  padding: 5px 15px;
}
@media (max-width: 767px) {
  .resourceManagement .shared-domain-content .shared-panel .clip-check,
  .resourceDetails .shared-domain-content .shared-panel .clip-check {
    text-align: left;
  }
}
.resourceManagement .shared-domain-content .shared-panel .checkbox label,
.resourceDetails .shared-domain-content .shared-panel .checkbox label {
  margin-top: 0;
}
.resourceManagement .breadcrumb,
.resourceDetails .breadcrumb {
  margin-bottom: 0;
}
.resourceManagement .action-btn button,
.resourceDetails .action-btn button {
  width: 100px;
}
.resourceManagement .action-btn .open > .dropdown-menu,
.resourceDetails .action-btn .open > .dropdown-menu {
  left: -58px;
}
.resourceManagement .action-btn .dropdown.open .dropdown-toggle span.caret,
.resourceDetails .action-btn .dropdown.open .dropdown-toggle span.caret {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.resourceManagement .resourceFileManager.pageViewBody,
.resourceDetails .resourceFileManager.pageViewBody {
  margin-left: -15px;
  margin-right: -15px;
}
.resourceManagement .resourceFileManager ul.dropdown-menu,
.resourceDetails .resourceFileManager ul.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -120px;
}
.resourceManagement .percent,
.resourceDetails .percent {
  position: absolute;
  width: 300px;
  height: 14px;
  z-index: 1;
  text-align: center;
  font-size: 0.8em;
  color: white;
}
.resourceManagement .progress-bar,
.resourceDetails .progress-bar {
  width: 300px;
  height: 15px;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#b5b5b9), to(#b5b5b9));
}
.resourceManagement .uploaded,
.resourceDetails .uploaded {
  padding: 0;
  height: 15px;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#28a1c5), to(#28a1c5));
}
.resourceManagement .action-btn,
.resourceDetails .action-btn {
  float: right;
  list-style: none;
  margin-bottom: 0;
}
.resourceManagement .action-btn li,
.resourceDetails .action-btn li {
  float: left;
}
.resourceManagement .action-btn li a.topbar-media,
.resourceDetails .action-btn li a.topbar-media {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: block;
  color: #8e8e93;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-transform: uppercase;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  padding: 5px 15px 5px 15px;
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .resourceManagement .action-btn li a.topbar-media,
  .resourceDetails .action-btn li a.topbar-media {
    font-size: 9px;
    line-height: 25px;
    padding: 5px 10px;
  }
}
.resourceManagement .action-btn li a.topbar-media i,
.resourceDetails .action-btn li a.topbar-media i {
  display: block;
  font-size: 17px;
}
.resourceManagement #kitname,
.resourceDetails #kitname {
  border-color: #ddd;
  height: 35px;
  font-size: 16px;
}
.resourceManagement .detailContainer,
.resourceDetails .detailContainer {
  margin: 0 !important;
  border-top: 1px solid #ddd;
}
.resourceManagement .detailContainer .detailView,
.resourceDetails .detailContainer .detailView {
  border-right: 1px solid #ddd;
}
@media (max-width: 767px) {
  .resourceManagement .detailContainer .detailView .control-label,
  .resourceDetails .detailContainer .detailView .control-label {
    width: 100%;
    text-align: left;
  }
  .resourceManagement .detailContainer .detailView .clip-radio,
  .resourceDetails .detailContainer .detailView .clip-radio {
    text-align: left;
  }
}
.resourceManagement .detailContainer .detailView .clip-radio,
.resourceDetails .detailContainer .detailView .clip-radio {
  margin-bottom: 5px;
}
.resourceManagement #pdf-container > object,
.resourceDetails #pdf-container > object {
  height: 400px;
  padding: 0;
}
.resourceManagement ul.campaign-top-bar,
.resourceDetails ul.campaign-top-bar {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  text-transform: uppercase;
}
.resourceManagement ul.campaign-top-bar li,
.resourceDetails ul.campaign-top-bar li {
  border-bottom: 3px transparent solid;
  display: inline-block;
  padding: 15px;
  padding-bottom: 12px;
}
.resourceManagement ul.campaign-top-bar li.active,
.resourceDetails ul.campaign-top-bar li.active {
  border-bottom: 3px #48b1cc solid;
}
.resourceManagement ul.campaign-top-bar li a,
.resourceDetails ul.campaign-top-bar li a {
  color: inherit;
}
.resourceDetails .libtn {
  width: 100%;
}
@media (max-width: 767px) {
  .resourceDetails .libtn {
    padding: 8px 6px 6px 6px;
  }
  .resourceDetails .mob-sub-row {
    width: 60%;
  }
}
.resourceDetails .breadcrumb-campaign {
  list-style: none;
  padding: 0;
}
.resourceDetails .breadcrumb-campaign.pageView {
  font-size: 16px;
  margin-bottom: 0;
}
.resourceDetails .breadcrumb-campaign li.breadcrumb-item {
  display: inline;
}
.resourceDetails .breadcrumb-campaign li.breadcrumb-item a {
  color: inherit;
}
.resourceDetails .mainTitle span.pageViewTitle {
  font-size: 22px;
  word-break: break-all;
}
.resourceDetails .breadcrumb-campaign > li + li:before {
  padding: 0 5px;
  color: #4a4a4a;
  content: "\003E";
}
.resourceDetails .campaign-group-btn .btn {
  margin: 0 3px;
}
.resourceDetails .socialElement {
  position: absolute;
  right: 0px;
  width: 500px;
  z-index: 100;
  top: 50px;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #d4d2d2;
  border-radius: 5px;
}
.resourceDetails .socialElement::after {
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 10px;
  border-bottom-color: #f5f5f5;
  border-top-width: 0;
  content: " ";
  top: -10px;
  right: 120px;
}
.resourceDetails .socialElement.existPublish::after {
  right: 40px;
}
.resourceDetails .socialElement.existPDF::after {
  right: 165px;
}
.resourceDetails .socialElement.social-publish::after {
  right: 30px;
}
.resourceDetails .socialElement .url-wraper {
  display: inline-block;
  width: 475px;
  margin-bottom: 5px;
}
.resourceDetails .socialElement .url-wraper input#sharePageUrl {
  border: 1px solid #a7a2a2 !important;
}
.resourceDetails .socialElement pre {
  display: inline-block;
  width: 100%;
  height: 30px;
  border-radius: 0;
  padding: 5px 0px;
  background-color: white;
}
.resourceDetails .socialElement .copy-link {
  width: 76px;
  background-color: #a7a2a2;
  height: 34px;
  cursor: pointer;
  color: #000000;
  padding: 10px 6px;
  line-height: 13px;
  font-size: 14px;
  font-family: sans-serif !important;
  right: 0;
  position: absolute;
  top: 0;
}
.resourceDetails .socialElement .copy-link.ng-click-active {
  background-color: #d0d0d0;
}
.resourceDetails .socialElement .social-element-byline {
  /* Using IcoMoon Free, GPL */
  /********************************* MEDIA QUEROES *********************************/
}
.resourceDetails .socialElement .social-element-byline .glyph .icon {
  font-size: 32px;
}
@font-face {
  font-family: 'IcoMoon-Free';
  src: url('~../js/scripts/sharebar/IcoMoon-Free.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.resourceDetails .socialElement .social-element-byline .icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'IcoMoon-Free' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px !important;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.resourceDetails .socialElement .social-element-byline .icon-linkedin:before {
  content: "\eaca";
}
.resourceDetails .socialElement .social-element-byline .icon-pinterest:before {
  content: "\ead1";
}
.resourceDetails .socialElement .social-element-byline .icon-google-plus:before {
  content: "\ea8b";
}
.resourceDetails .socialElement .social-element-byline .icon-twitter:before {
  content: "\ea96";
}
.resourceDetails .socialElement .social-element-byline .icon-facebook:before {
  content: "\ea90";
}
.resourceDetails .socialElement .social-element-byline .icon-email:before {
  content: "\e945";
}
.resourceDetails .socialElement .social-element-byline .social-sidebar {
  display: block;
  height: 45px;
  left: 0;
  margin-bottom: 0px;
  position: relative;
  top: 0;
  -webkit-padding-start: 0px;
  width: 100%;
  z-index: 500;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li,
.resourceDetails .socialElement .social-element-byline .social-sidebar li a {
  float: left;
  margin-right: 5px;
  text-align: center;
  width: 45px;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li {
  line-height: 45px;
  list-style: none;
  text-indent: 0;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span {
  color: #ffffff;
  height: 45px;
  float: left;
  font-size: 20px;
  font-size: 2rem;
  line-height: 45px;
  width: 45px;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span.icon-twitter {
  background: #55ACEE;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span.icon-facebook {
  background: #3B5998;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span.icon-email {
  background: #5C7B98;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span.icon-linkedin {
  background: #007BB6;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span.icon-pinterest {
  background: #BE000F;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar li span.icon-google-plus {
  background: #DD4B39;
}
.resourceDetails .socialElement .social-element-byline .social-sidebar.fixed {
  position: fixed;
}
@media (max-width: 600px) {
  .resourceDetails .socialElement .social-element-byline .social-sidebar {
    height: 32px;
  }
  .resourceDetails .socialElement .social-element-byline .social-sidebar li span {
    height: 32px;
    line-height: 32px;
    font-size: 18px;
  }
  .resourceDetails .socialElement .social-element-byline .glyph .icon {
    font-size: 24px;
  }
}
indi-pdf-thumbnail div.csspinner {
  margin-top: 40%;
}
indi-pdf-thumbnail img.pdfThumbnail {
  position: relative;
  width: 100%;
}
.campaign-resource-management indi-pdf-thumbnail div.csspinner {
  margin-top: 110px !important;
}
.campaign-resource-management indi-pdf-thumbnail.dashboard-pdf-preview div.csspinner {
  margin-top: 40% !important;
  min-height: 30px;
}
.campaign-resource-management indi-pdf-thumbnail.dashboard-pdf-preview div.csspinner:before {
  background: transparent !important;
}
.ingram-micro-main-content .resourceManagement .thmb-desc,
.ingram-micro-main-content .resourceDetails .thmb-desc {
  background-color: #0077b4;
}
.ingram-micro-main-content .resource-list-footer.folder-type-footer {
  background: #0077b4 !important;
}
.ingram-micro-main-content ul.campaign-top-bar li.active {
  border-bottom: 3px #0077b4 solid;
}
.indi-select-dropdown {
  overflow: hidden;
  position: relative;
}
.indi-select-dropdown:before,
.indi-select-dropdown:after {
  content: "";
  position: absolute;
  right: 20px;
  top: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #c1c1c1;
}
.indi-select-dropdown:after {
  top: 14px;
  border-top-color: #fff;
}
.indi-select-dropdown select {
  line-height: 1;
  margin-right: 10px;
  padding: 6px 25px;
}
.indi-select i.fa {
  position: absolute;
  right: 10px;
  font-size: 22px;
  font-weight: lighter;
  top: 5px;
  pointer-events: none;
}
.indi-select select::-ms-expand {
  display: none;
}
resource-details-component .template-preview-container,
account-campaign-details-component .template-preview-container {
  background: url(~../img/loading.gif) center center no-repeat;
  height: calc(100vh - 150px);
}
resource-details-component .template-preview-container #frame_template_loader,
account-campaign-details-component .template-preview-container #frame_template_loader {
  border: 0;
  width: 100%;
  height: 100%;
}
account-resource-explorer-component .campaign-resource-management .indi-select,
resource-explorer-component .campaign-resource-management .indi-select {
  margin-bottom: 5px;
}
account-resource-explorer-component .campaign-resource-management .indi-select select.form-control,
resource-explorer-component .campaign-resource-management .indi-select select.form-control {
  padding: 6px 30px;
  padding-left: 10px;
}
.add-resource-to-campaign-modal-body {
  padding-bottom: 30px;
}
.add-resource-to-campaign-modal-body .resource-type-tile {
  cursor: pointer;
  border: 1px solid #D3D3D3;
  background: #f7f7f8;
}
.add-resource-to-campaign-modal-body .resource-type-tile .type-icon i.fa {
  font-size: 40px;
}
.add-resource-to-campaign-modal-body .resource-type-tile .type-icon i.far {
  font-size: 40px;
}
.add-resource-to-campaign-modal-body .resource-type-tile .media-content span.title {
  font-weight: bold;
  font-size: 15px;
}
.add-resource-to-campaign-modal-header {
  border: none;
}
.add-resource-to-campaign-modal-header .modal-title {
  font-size: 18px;
}
/* ---------------------------------------------------------------------- */
/*  New Campaign Modal
/* ---------------------------------------------------------------------- */
.new-campaign-modal .autocomplete ul {
  z-index: 3;
}
.new-campaign-modal .shared-domain-content {
  position: relative;
  max-height: 150px;
  overflow-y: scroll;
  box-sizing: border-box;
}
.new-campaign-modal .shared-panel {
  padding: 5px 15px;
}
.new-campaign-modal .modal-footer {
  text-align: center;
}
.new-campaign-modal .gray-backgroud {
  background-color: #eceeee;
  padding: 10px;
}
.new-campaign-modal .radio.clip-radio.radio-primary {
  margin-top: 0px !important;
}
.new-campaign-modal .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  width: 100% !important;
  max-width: 80%;
}
/* ---------------------------------------------------------------------- */
/*  Resource Settings Modal
/* ---------------------------------------------------------------------- */
.resource-settings-modal .modal-footer {
  text-align: center;
}
.resource-settings-modal .accounts {
  float: right;
}
.resource-settings-modal .campaigns {
  margin-right: 11px;
}
.resource-settings-modal .gray-backgroud {
  background: #17252d14;
  padding: 10px;
  width: 48%;
  height: 300px;
  overflow-y: auto;
  border: #b5b5b5 1px solid;
}
.resource-settings-modal .shared-domain-content-settings {
  position: relative;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .campaign-resource-management table.grid tbody tr {
    width: 47.1% !important;
  }
}
@media (min-width: 1175px) {
  .campaign-resource-management table.grid tbody tr {
    width: 31%!important;
  }
}
/* ---------------------------------------------------------------------- */
/*  Campaign Setting Modal
/* ---------------------------------------------------------------------- */
.campaign-setting-modal-header,
.campaign-setting-modal-footer {
  border-color: transparent;
  background-color: #FFFFFF;
}
.campaign-setting-modal-body {
  padding-bottom: 0;
}
.campaign-setting-modal-body .clip-check.check-primary input[type=checkbox]:checked + label:before {
  border-color: #9b9b9b !important;
}
.setting-campaign-savBtn {
  width: 170px;
  margin: 0 auto;
  display: block;
}
.main-preview {
  background-color: #fff;
  padding: 20px;
}
.main-preview .close {
  font-size: 24px;
}
.main-preview .text-bolder {
  font-weight: bolder;
}
.main-preview div {
  margin-top: 10px;
}
.main-preview h1 {
  line-height: 1.2;
}
page-analytics-component .page-analytics-top-bar,
organization-analytics-component .page-analytics-top-bar,
customer-page-analytics-component .page-analytics-top-bar {
  height: 100px;
  margin-bottom: 15px;
}
page-analytics-component .page-analytics-top-bar .arrow-up,
organization-analytics-component .page-analytics-top-bar .arrow-up,
customer-page-analytics-component .page-analytics-top-bar .arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #25bf89;
  display: inline-block;
}
page-analytics-component .page-analytics-top-bar .arrow-down,
organization-analytics-component .page-analytics-top-bar .arrow-down,
customer-page-analytics-component .page-analytics-top-bar .arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #ff3b31;
  display: inline-block;
}
@media (max-width: 768px) {
  page-analytics-component .page-analytics-top-bar,
  organization-analytics-component .page-analytics-top-bar,
  customer-page-analytics-component .page-analytics-top-bar {
    height: 200px;
  }
}
page-analytics-component .page-analytics-top-bar li,
organization-analytics-component .page-analytics-top-bar li,
customer-page-analytics-component .page-analytics-top-bar li {
  list-style: none;
  float: left;
  border: 1px solid #DBDBDB;
  border-left: 1px;
  color: #4a4a4a;
  width: 12%;
  min-height: 100px;
  height: 100px;
  padding: 20px 10px 20px 10px;
  font-size: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
page-analytics-component .page-analytics-top-bar li:first-child,
organization-analytics-component .page-analytics-top-bar li:first-child,
customer-page-analytics-component .page-analytics-top-bar li:first-child {
  border-left: 1px solid #DBDBDB;
}
page-analytics-component .page-analytics-top-bar li.lead,
organization-analytics-component .page-analytics-top-bar li.lead,
customer-page-analytics-component .page-analytics-top-bar li.lead {
  margin-bottom: 0;
}
page-analytics-component .page-analytics-top-bar li.timeOnPage,
organization-analytics-component .page-analytics-top-bar li.timeOnPage,
customer-page-analytics-component .page-analytics-top-bar li.timeOnPage {
  width: 25%;
}
page-analytics-component .page-analytics-top-bar li h4,
organization-analytics-component .page-analytics-top-bar li h4,
customer-page-analytics-component .page-analytics-top-bar li h4 {
  float: left;
  font-weight: bold;
  color: #ffffff;
  text-transform: none;
  width: 90%;
}
page-analytics-component .page-analytics-top-bar li .custom-class,
organization-analytics-component .page-analytics-top-bar li .custom-class,
customer-page-analytics-component .page-analytics-top-bar li .custom-class {
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
}
page-analytics-component .page-analytics-top-bar li span.data,
organization-analytics-component .page-analytics-top-bar li span.data,
customer-page-analytics-component .page-analytics-top-bar li span.data {
  font-size: 30px;
  font-weight: bold;
}
page-analytics-component .page-analytics-top-bar li .variance-up,
organization-analytics-component .page-analytics-top-bar li .variance-up,
customer-page-analytics-component .page-analytics-top-bar li .variance-up {
  color: #25bf89;
  font-size: 15px;
}
page-analytics-component .page-analytics-top-bar li .variance-down,
organization-analytics-component .page-analytics-top-bar li .variance-down,
customer-page-analytics-component .page-analytics-top-bar li .variance-down {
  color: #ff3b31;
  font-size: 15px;
}
page-analytics-component .page-analytics-top-bar li span,
organization-analytics-component .page-analytics-top-bar li span,
customer-page-analytics-component .page-analytics-top-bar li span {
  line-height: 25px;
}
page-analytics-component .page-analytics-top-bar li.conver-rate,
organization-analytics-component .page-analytics-top-bar li.conver-rate,
customer-page-analytics-component .page-analytics-top-bar li.conver-rate {
  width: 16%;
}
page-analytics-component .page-analytics-top-bar li.content-analytics,
organization-analytics-component .page-analytics-top-bar li.content-analytics,
customer-page-analytics-component .page-analytics-top-bar li.content-analytics {
  background-color: #4a4a4a;
}
@media screen and (min-width: 1025px) {
  page-analytics-component .page-analytics-top-bar li.content-analytics a,
  organization-analytics-component .page-analytics-top-bar li.content-analytics a,
  customer-page-analytics-component .page-analytics-top-bar li.content-analytics a {
    padding-top: 15px;
    display: inline-block;
  }
}
@media screen and (max-width: 767px) {
  page-analytics-component .page-analytics-top-bar li,
  organization-analytics-component .page-analytics-top-bar li,
  customer-page-analytics-component .page-analytics-top-bar li {
    width: 33%;
  }
  page-analytics-component .page-analytics-top-bar li.timeOnPage,
  organization-analytics-component .page-analytics-top-bar li.timeOnPage,
  customer-page-analytics-component .page-analytics-top-bar li.timeOnPage {
    width: 33%;
  }
  page-analytics-component .page-analytics-top-bar li span.data,
  organization-analytics-component .page-analytics-top-bar li span.data,
  customer-page-analytics-component .page-analytics-top-bar li span.data {
    font-size: 24px;
  }
  page-analytics-component .page-analytics-top-bar li.conver-rate,
  organization-analytics-component .page-analytics-top-bar li.conver-rate,
  customer-page-analytics-component .page-analytics-top-bar li.conver-rate {
    width: 33%;
  }
  page-analytics-component .page-analytics-top-bar li p,
  organization-analytics-component .page-analytics-top-bar li p,
  customer-page-analytics-component .page-analytics-top-bar li p {
    font-size: 10px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  page-analytics-component .page-analytics-top-bar li,
  organization-analytics-component .page-analytics-top-bar li,
  customer-page-analytics-component .page-analytics-top-bar li {
    font-size: 10px;
  }
  page-analytics-component .page-analytics-top-bar li span.data,
  organization-analytics-component .page-analytics-top-bar li span.data,
  customer-page-analytics-component .page-analytics-top-bar li span.data {
    font-size: 28px;
  }
}
@media screen and (min-width: 2560px) {
  page-analytics-component .page-analytics-top-bar li,
  organization-analytics-component .page-analytics-top-bar li,
  customer-page-analytics-component .page-analytics-top-bar li {
    font-size: 14px;
  }
  page-analytics-component .page-analytics-top-bar li span.data,
  organization-analytics-component .page-analytics-top-bar li span.data,
  customer-page-analytics-component .page-analytics-top-bar li span.data {
    font-size: 32px;
  }
}
page-analytics-component .page-analytics-stats-bar,
organization-analytics-component .page-analytics-stats-bar,
customer-page-analytics-component .page-analytics-stats-bar {
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
@media (max-width: 768px) {
  page-analytics-component .page-analytics-stats-bar.acc-stats-bar,
  organization-analytics-component .page-analytics-stats-bar.acc-stats-bar,
  customer-page-analytics-component .page-analytics-stats-bar.acc-stats-bar {
    margin-left: 0;
    margin-right: 0;
  }
}
page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar,
organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar,
customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar {
  padding-left: 0px;
}
page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li,
organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li,
customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li {
  width: 25%;
}
page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.conver-rate,
organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.conver-rate,
customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.conver-rate {
  width: 16%;
}
@media (max-width: 1024px) and (min-width: 768px) {
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li {
    font-size: 10px;
  }
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li span.data,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li span.data,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li span.data {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li {
    width: 33%;
  }
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.timeOnPage,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.timeOnPage,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.timeOnPage {
    width: 33%;
  }
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li span.data,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li span.data,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li span.data {
    font-size: 28px;
  }
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.conver-rate,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.conver-rate,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li.conver-rate {
    width: 33%;
  }
  page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li p,
  organization-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li p,
  customer-page-analytics-component .page-analytics-stats-bar ul.page-analytics-top-bar li p {
    font-size: 10px;
  }
}
page-analytics-component .page-analytics,
organization-analytics-component .page-analytics,
customer-page-analytics-component .page-analytics {
  margin-top: 20px;
}
page-analytics-component .page-analytics .countries-map .panel-wrapper .panel-body-inner,
organization-analytics-component .page-analytics .countries-map .panel-wrapper .panel-body-inner,
customer-page-analytics-component .page-analytics .countries-map .panel-wrapper .panel-body-inner {
  margin-left: 15px;
  margin-right: 15px;
  overflow: hidden;
}
@media screen and (max-width: 831px) and (min-width: 766px) {
  page-analytics-component .page-analytics .nav-tabs li a,
  organization-analytics-component .page-analytics .nav-tabs li a,
  customer-page-analytics-component .page-analytics .nav-tabs li a {
    padding: 15px 8px;
  }
}
page-analytics-component .page-analytics .page-title span,
organization-analytics-component .page-analytics .page-title span,
customer-page-analytics-component .page-analytics .page-title span {
  display: block;
}
page-analytics-component .page-analytics .page-title h1,
organization-analytics-component .page-analytics .page-title h1,
customer-page-analytics-component .page-analytics .page-title h1 {
  display: inline-block;
}
page-analytics-component .page-analytics .page-title .all-domains,
organization-analytics-component .page-analytics .page-title .all-domains,
customer-page-analytics-component .page-analytics .page-title .all-domains {
  display: inline-block;
}
page-analytics-component .page-analytics .page-title .all-domains select,
organization-analytics-component .page-analytics .page-title .all-domains select,
customer-page-analytics-component .page-analytics .page-title .all-domains select {
  background-color: transparent;
  color: #8e8e93;
  padding-right: 25px;
}
page-analytics-component .page-analytics text.highcharts-title,
organization-analytics-component .page-analytics text.highcharts-title,
customer-page-analytics-component .page-analytics text.highcharts-title {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 16px !important;
}
page-analytics-component .page-analytics .panel-heading,
organization-analytics-component .page-analytics .panel-heading,
customer-page-analytics-component .page-analytics .panel-heading {
  padding: 10px 0px 0 10px;
  min-height: 0px;
}
page-analytics-component .page-analytics .panel-heading .panel-title,
organization-analytics-component .page-analytics .panel-heading .panel-title,
customer-page-analytics-component .page-analytics .panel-heading .panel-title {
  font-weight: 300;
  font-size: 16px!important;
  color: #333333;
}
page-analytics-component .page-analytics .country-map .usa,
organization-analytics-component .page-analytics .country-map .usa,
customer-page-analytics-component .page-analytics .country-map .usa {
  transform: translateY(25%);
}
page-analytics-component .page-analytics .page-filtes .filter-heading,
organization-analytics-component .page-analytics .page-filtes .filter-heading,
customer-page-analytics-component .page-analytics .page-filtes .filter-heading {
  padding-top: 8px;
  display: inline-block;
}
@media screen and (max-width: 992px) {
  page-analytics-component .page-analytics .page-filtes .filter-heading,
  organization-analytics-component .page-analytics .page-filtes .filter-heading,
  customer-page-analytics-component .page-analytics .page-filtes .filter-heading {
    display: block;
  }
}
@media screen and (min-width: 993px) {
  page-analytics-component .page-analytics .page-filtes .indi-select,
  organization-analytics-component .page-analytics .page-filtes .indi-select,
  customer-page-analytics-component .page-analytics .page-filtes .indi-select {
    margin-left: 20px;
  }
}
@media screen and (max-width: 992px) {
  page-analytics-component .page-analytics .page-filtes .indi-select,
  organization-analytics-component .page-analytics .page-filtes .indi-select,
  customer-page-analytics-component .page-analytics .page-filtes .indi-select {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  page-analytics-component .page-analytics .page-filtes .indi-select select,
  organization-analytics-component .page-analytics .page-filtes .indi-select select,
  customer-page-analytics-component .page-analytics .page-filtes .indi-select select {
    min-width: 180px;
    max-width: 200px;
  }
}
page-analytics-component .page-analytics .page-filtes .indi-select select,
organization-analytics-component .page-analytics .page-filtes .indi-select select,
customer-page-analytics-component .page-analytics .page-filtes .indi-select select {
  padding-right: 30px;
}
page-analytics-component .page-analytics .chart-legends,
organization-analytics-component .page-analytics .chart-legends,
customer-page-analytics-component .page-analytics .chart-legends {
  position: absolute;
  bottom: 12%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #3b3b3b;
  border: 1px solid #989898;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
page-analytics-component .page-analytics .chart-legends ul,
organization-analytics-component .page-analytics .chart-legends ul,
customer-page-analytics-component .page-analytics .chart-legends ul {
  list-style-type: none;
  padding: 17px;
  margin-bottom: 0;
}
page-analytics-component .page-analytics .chart-legends ul li i,
organization-analytics-component .page-analytics .chart-legends ul li i,
customer-page-analytics-component .page-analytics .chart-legends ul li i {
  float: right;
  padding-left: 10px;
  margin-top: 2px;
}
page-analytics-component .page-analytics .chart-legends ul li span,
organization-analytics-component .page-analytics .chart-legends ul li span,
customer-page-analytics-component .page-analytics .chart-legends ul li span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
page-analytics-component .page-analytics .chart-legends.noData,
organization-analytics-component .page-analytics .chart-legends.noData,
customer-page-analytics-component .page-analytics .chart-legends.noData {
  border: unset;
}
page-analytics-component .page-analytics .chart-legend-text,
organization-analytics-component .page-analytics .chart-legend-text,
customer-page-analytics-component .page-analytics .chart-legend-text {
  position: absolute;
  bottom: 2%;
  left: 40%;
  color: #3b3b3b;
  cursor: pointer;
  font-size: 12px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
}
page-analytics-component .page-analytics .chart-legend-text i,
organization-analytics-component .page-analytics .chart-legend-text i,
customer-page-analytics-component .page-analytics .chart-legend-text i {
  color: #4E8EC9;
  font-size: 14px;
}
page-analytics-component .page-analytics .hideChart,
organization-analytics-component .page-analytics .hideChart,
customer-page-analytics-component .page-analytics .hideChart {
  display: none;
}
page-analytics-component .chart-request-container .panel-body-inner-div,
organization-analytics-component .chart-request-container .panel-body-inner-div,
customer-page-analytics-component .chart-request-container .panel-body-inner-div {
  display: table;
}
page-analytics-component .chart-request-container .panel-body-inner-div .request-inner-container,
organization-analytics-component .chart-request-container .panel-body-inner-div .request-inner-container,
customer-page-analytics-component .chart-request-container .panel-body-inner-div .request-inner-container {
  display: table-cell;
  vertical-align: middle;
}
page-analytics-component .chart-request-container .panel-body-inner-div .request-inner-container .fa,
organization-analytics-component .chart-request-container .panel-body-inner-div .request-inner-container .fa,
customer-page-analytics-component .chart-request-container .panel-body-inner-div .request-inner-container .fa {
  cursor: pointer;
  color: #48b1cc;
}
page-analytics-component .countries-map .panel-body-inner,
organization-analytics-component .countries-map .panel-body-inner,
customer-page-analytics-component .countries-map .panel-body-inner {
  height: 500px;
}
page-analytics-component .countries-map .panel-body-inner #visitor_locations_global,
organization-analytics-component .countries-map .panel-body-inner #visitor_locations_global,
customer-page-analytics-component .countries-map .panel-body-inner #visitor_locations_global {
  height: 480px;
}
@media all and (max-width: 767px) {
  page-analytics-component .countries-map .panel-body-inner,
  organization-analytics-component .countries-map .panel-body-inner,
  customer-page-analytics-component .countries-map .panel-body-inner {
    height: 400px;
  }
  page-analytics-component .countries-map .panel-body-inner #visitor_locations_global,
  organization-analytics-component .countries-map .panel-body-inner #visitor_locations_global,
  customer-page-analytics-component .countries-map .panel-body-inner #visitor_locations_global {
    height: 380px;
  }
}
.data-analytics {
  z-index: 1;
}
.data-analytics .export-data-analytics {
  position: absolute;
  right: 20px;
  top: 10px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .data-analytics .export-data-analytics {
    text-align: right;
    position: relative;
  }
}
.data-analytics .tab-content > .tab-pane,
.data-analytics .pill-content > .pill-pane {
  padding: 0!important;
}
.data-analytics .nav-tabs {
  background: #fff;
}
.data-analytics .nav-tabs > li.active > a,
.data-analytics .nav-tabs > li.active > a:focus,
.data-analytics .nav-tabs > li.active > a:hover {
  color: #fff !important;
  background-color: #48b1cc;
}
.data-analytics table.tbl-campaign-analytics {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}
.data-analytics table.tbl-campaign-analytics thead {
  background: #000;
  color: #fff;
}
.data-analytics table.tbl-campaign-analytics tr {
  height: 40px;
}
.data-analytics table.tbl-campaign-analytics tr th,
.data-analytics table.tbl-campaign-analytics tr td {
  padding: 8px;
  border: 1px solid #ddd;
}
.data-analytics table.tbl-campaign-analytics tfoot tr {
  height: 45px;
}
.data-analytics table.tbl-campaign-analytics tfoot tr.row-pagination {
  height: 0px;
}
.data-analytics table.tbl-campaign-analytics tfoot tr.row-pagination td {
  padding: 0;
  border-bottom: 0;
}
.app-ingram-body .data-analytics .nav-tabs > li.active > a,
.app-ingram-body .data-analytics .nav-tabs > li.active > a:focus,
.app-ingram-body .data-analytics .nav-tabs > li.active > a:hover {
  background-color: #0077b4;
  color: #fff !important;
}
.app-ingram-body .data-analytics .chart-request-container .panel-body-inner-div .request-inner-container .fa {
  color: #0077b4;
}
.app-ingram-body .chart-request-container .panel-body-inner-div .request-inner-container .fa {
  color: #0077b4;
}
.app-ingram-body .ssb-site-template-grid-item-inner-blank-page div.blank-page .fa {
  color: #0077b4;
}
.app-ingram-body organization-analytics-component .all-domains {
  margin-left: 20px;
}
@media screen and (max-width: 1024px) {
  .app-ingram-body organization-analytics-component .all-domains {
    margin-left: 0px;
  }
}
.resourceManagement.dashboard-content #page-title {
  border-bottom: unset;
}
.resourceManagement.dashboard-content #page-title h1 {
  display: inline;
}
.resourceManagement.dashboard-content #page-title .right-icon {
  font-size: 28px;
  padding: 16px 16px 0 0;
}
.resourceManagement.dashboard-content #page-title .right-icon.admin-dashboard i {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.resourceManagement.dashboard-content #page-title .right-icon i {
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
}
.resourceManagement.dashboard-content .col-sm-12 {
  padding: 0px;
}
.resourceManagement.dashboard-content .white-space {
  white-space: nowrap;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li {
  float: left;
  border: 1px solid #DBDBDB;
  border-left: 1px;
  color: #4a4a4a;
  width: 12%;
  min-height: 100px;
  height: 100px;
  padding: 20px 10px 20px 10px;
  font-size: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li:first-child {
  border-left: 1px solid #DBDBDB;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.lead {
  margin-bottom: 0;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.timeOnPage {
  width: 25%;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li h4 {
  float: left;
  font-weight: bold;
  color: #ffffff;
  text-transform: none;
  width: 90%;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li .custom-class {
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li span.data {
  font-size: 30px;
  font-weight: bold;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li .variance-up {
  color: #25bf89;
  font-size: 15px;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li .variance-down {
  color: #ff3b31;
  font-size: 15px;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li span {
  line-height: 25px;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.conver-rate {
  width: 16%;
}
.resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.content-analytics {
  background-color: #4a4a4a;
}
@media screen and (min-width: 1025px) {
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.content-analytics a {
    padding-top: 15px;
    display: inline-block;
  }
}
@media screen and (max-width: 767px) {
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.timeOnPage {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li span.data {
    font-size: 24px;
  }
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li.conver-rate {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li p {
    font-size: 10px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li {
    font-size: 10px;
  }
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li span.data {
    font-size: 28px;
  }
}
@media screen and (min-width: 2560px) {
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li {
    font-size: 14px;
  }
  .resourceManagement.dashboard-content.resourceManagement ul.campaign-top-bar li span.data {
    font-size: 32px;
  }
}
.resourceManagement.dashboard-content.resourceManagement ul.outcall-leads-box li {
  width: 10.5%;
}
.resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar {
  padding: 0;
  margin-right: 15px;
  margin-left: 15px;
}
@media (max-width: 768px) {
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar.acc-stats-bar {
    margin-left: 0;
    margin-right: 0;
  }
}
.resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li {
  width: 14%;
}
.resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li.conver-rate {
  width: 16%;
}
@media (max-width: 1024px) and (min-width: 768px) {
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li {
    font-size: 10px;
  }
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li span.data {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li.timeOnPage {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li span.data {
    font-size: 28px;
  }
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li.conver-rate {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.campaign-top-bar li p {
    font-size: 10px;
  }
}
.resourceManagement.dashboard-content.resourceManagement .campaign-stats-bar ul.outcall-leads-box li {
  width: 12%;
}
.resourceManagement.dashboard-content.resourceManagement .table-list-view .check-col {
  width: 10%;
}
.resourceManagement.dashboard-content.resourceManagement .table-list-view .name-col {
  width: 90%;
}
.resourceManagement.dashboard-content.resourceManagement.campaign-resource-content .stats-container {
  padding: 0;
  margin-right: 20px;
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content .stats-container {
    margin-right: 15px;
  }
}
.resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li {
  width: 16.5%;
}
.resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li.conver-rate {
  width: 17.5%;
}
@media screen and (max-width: 767px) {
  .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li {
    width: 33%;
  }
  .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li.conver-rate {
    width: 33%;
  }
}
@media screen and (max-width: 494px) {
  .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li {
    padding: 10px;
  }
  .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li p {
    font-size: 10px;
  }
  .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li span.data {
    font-size: 22px;
  }
  .variance-up .resourceManagement.dashboard-content.resourceManagement.campaign-resource-content ul.campaign-top-bar li .variance-down {
    font-size: 15px;
  }
}
.resourceManagement.dashboard-content.resourceManagement.dashboard-content-preview ul.campaign-top-bar li {
  width: 20%;
}
.resourceManagement.dashboard-content.resourceManagement.dashboard-content-preview ul.preview-account li {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 494px) {
  .resourceManagement.dashboard-content.resourceManagement.dashboard-content-preview ul.preview-account li {
    width: 50%;
  }
}
.resourceManagement.dashboard-content .list {
  border-left: 1px solid #ddd;
}
.resourceManagement.dashboard-content .list .form-control {
  height: 45px;
}
.resourceManagement.dashboard-content .list .form-control.sort-content,
.resourceManagement.dashboard-content .list .form-control.search-content {
  background-color: #f8f8f8f8;
  padding-left: 20px;
}
.resourceManagement.dashboard-content .list .input-icon-right i {
  background-color: #2F76BB;
  width: 60px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
}
.resourceManagement.dashboard-content .list .input-icon-right.drop-down i {
  background-color: transparent;
  color: black;
}
.resourceManagement.dashboard-content .list .input-icon-right .ti-search {
  cursor: pointer;
}
.resourceManagement.dashboard-content .list .campaigns.articles:last-child {
  border: none;
}
@media screen and (max-width: 767px) {
  .resourceManagement.dashboard-content .list .mobile-search {
    margin-bottom: 20px;
  }
}
.resourceManagement.dashboard-content .left-filter h4 {
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.resourceManagement.dashboard-content .article-left-border {
  border-right: 0.8px solid #ddd;
}
.resourceManagement.dashboard-content .campaigns {
  margin: 20px 0;
  padding: 13px 0;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.resourceManagement.dashboard-content .campaigns.articles {
  color: #4a4a4a;
  background-color: transparent;
}
.resourceManagement.dashboard-content .campaigns.articles .campaign-header {
  text-transform: uppercase;
  padding: 0;
}
.resourceManagement.dashboard-content .campaigns.articles .campaign-header .normal-font {
  text-transform: none;
}
.resourceManagement.dashboard-content .campaigns.articles .campaign-header .sponsored-icon {
  padding-left: 5px;
}
.resourceManagement.dashboard-content .campaigns.articles .campaign-header .article-filters .upper-case {
  font-size: 12px;
  color: #2F76BB;
}
.resourceManagement.dashboard-content .campaigns.camp-folder {
  background-color: #2F76BB;
  color: #ffffff;
}
.resourceManagement.dashboard-content .campaigns.camp-folder .campaign-header h3 {
  color: #ffffff;
}
.resourceManagement.dashboard-content .campaigns .campaign-header h3 {
  color: #4a4a4a;
  font-weight: bold;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
  padding-bottom: 2px;
}
.resourceManagement.dashboard-content .campaigns .campaign-header h3 .text-adjust-inner-content {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.resourceManagement.dashboard-content .active-campaign-articles .campaigns {
  background-color: #ffffff;
  color: #4a4a4a;
  border: 1px solid #888888;
  padding: 0px;
}
.resourceManagement.dashboard-content .active-campaign-articles .campaigns [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.resourceManagement.dashboard-content .active-campaign-articles .campaigns .campaign-header {
  padding: 4.5px;
  min-height: 115px;
}
.resourceManagement.dashboard-content .active-campaign-articles .campaigns .campaign-header .categories {
  display: inline-block;
}
.resourceManagement.dashboard-content .active-campaign-articles .campaigns .image-box {
  max-width: unset;
}
.resourceManagement.dashboard-content .active-campaign-articles .campaigns.articles .campaign-header h3 {
  width: 90%;
  line-height: 23px;
}
.resourceManagement.dashboard-content .image-box {
  max-width: 150px;
  max-height: 105px;
  overflow: hidden;
  margin: 0 auto;
}
.resourceManagement.dashboard-content .image-box img {
  width: 100%;
}
.resourceManagement.dashboard-content .image-box .blog-post-image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 130px;
}
.resourceManagement.dashboard-content .campaign-footer {
  color: #ffffff;
  background-color: #165795;
  padding: 5px;
  display: none;
}
.resourceManagement.dashboard-content .campaign-footer .full-width {
  margin-right: 0;
  margin-left: 0;
}
.resourceManagement.dashboard-content .campaign-footer .full-width i {
  padding-left: 10px;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -6px;
}
.resourceManagement.dashboard-content .campaign-footer .full-width span {
  font-size: 18px;
  font-weight: bold;
}
.resourceManagement.dashboard-content table:not(.table-list-view) tr td {
  position: relative;
}
.resourceManagement.dashboard-content table:not(.table-list-view) tr td .status-icon {
  position: absolute;
  z-index: 1;
  top: 15px;
}
.resourceManagement.dashboard-content table:not(.table-list-view) tr td .status-icon.left {
  left: 18px;
}
.resourceManagement.dashboard-content table:not(.table-list-view) tr td .status-icon.right {
  right: 15px;
}
.resourceManagement.dashboard-content table:not(.table-list-view) tr td .status-icon.with-border {
  border: 3px solid #fff;
  border-radius: 50%;
  width: 23px;
  height: 23px;
}
.resourceManagement.dashboard-content table:not(.table-list-view) tr td .status-icon.with-border .icon-size {
  font-size: 20px;
  margin-top: -2px;
}
.resourceManagement.dashboard-content table:not(.table-list-view) .text-adjust-col {
  background-color: #3076bb;
}
.resourceManagement.dashboard-content table:not(.table-list-view) .text-adjust-col.file-details span.text-adjust-inner-content {
  display: block;
  color: #ffffff;
  font-size: 14px;
}
.resourceManagement.dashboard-content table:not(.table-list-view) .text-adjust-col.file-details span.text-adjust-inner-content.file-name {
  font-size: 16px;
  font-weight: 600;
}
.resourceManagement.dashboard-content .campaign-status-modal {
  border-radius: 0;
  box-shadow: unset;
}
.resourceManagement.dashboard-content .campaign-status-modal li div {
  color: inherit !important;
  padding: 5px 15px;
  cursor: pointer;
}
.resourceManagement.dashboard-content .campaign-status-modal li div i.icon-size {
  float: right;
  width: auto;
  padding-right: 0;
  padding-left: 0;
}
.resourceManagement.dashboard-content .campaign-list-modal {
  position: absolute;
  width: 70%;
  z-index: 3;
  background-color: #ffffff;
  left: 22%;
  border: 1px solid #888888;
}
.resourceManagement.dashboard-content .campaign-list-modal .selected-campaign {
  background: #2F76BB;
  color: #ffffff;
  padding-left: 20px;
  height: 30px;
  line-height: 30px;
}
.resourceManagement.dashboard-content .campaign-list-modal .input-form input {
  width: 100%;
  background-color: #f7f7f8;
}
.resourceManagement.dashboard-content .campaign-list-modal ul {
  max-height: 145px;
  overflow-y: auto;
  overflow-x: hidden;
}
.resourceManagement.dashboard-content .campaign-list-modal ul li {
  list-style: none;
  height: auto;
  line-height: 1.8;
  padding: 0 20px;
  color: #4a4a4a;
  word-break: break-all;
  cursor: pointer;
}
.resourceManagement.dashboard-content .campaign-list-modal ul li:hover {
  background-color: #eee;
}
.resourceManagement.dashboard-content .articles .campaign-footer {
  background-color: #dbdbdb;
  color: #4a4a4a;
}
.resourceManagement.dashboard-content .green {
  color: #25bf89;
}
.resourceManagement.dashboard-content .white {
  color: #ffffff;
}
.resourceManagement.dashboard-content .icon-size {
  font-size: 22px;
  width: 100%;
}
.resourceManagement.dashboard-content .gray {
  color: gray;
}
.resourceManagement.dashboard-content .light-gray {
  color: #DBDBDB;
}
.resourceManagement.dashboard-content .no-data {
  font-size: 20px;
  padding: 20px;
  color: #ff0000;
}
.resourceManagement.dashboard-content .known-contacts {
  border-left: 1px solid #ddd;
}
.resourceManagement.dashboard-content .known-contacts .pagination-icon {
  cursor: pointer;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details {
  min-height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 6px solid #25bf89;
  margin-bottom: 15px;
  background-color: #ffffff;
  color: #8e8e93;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details.page-views-fingerprint {
  border-left: 6px solid #2f76bb;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details.contact-fingerprint {
  border-left: 6px solid #25b589 !important;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .col-xs-3 {
  padding: 0px;
  text-align: center;
  height: 70px;
  line-height: 60px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .col-xs-9 {
  padding: 0px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details img {
  width: 50px;
  height: 50px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-header {
  margin-left: 0px;
  margin-right: 20px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-header h4.fingerprint-visitor-details {
  margin: 0px;
  font-size: 16px;
  margin-right: 35px;
  word-break: break-all;
  color: #0077b4;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-header span.fingerprint-location-time {
  font-size: 11px;
  margin-right: 35px;
  display: inline-block;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-view-details {
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 16px;
  cursor: pointer;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-device-view {
  position: absolute;
  right: 18px;
  cursor: pointer;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-device-view.fingerprint-icon {
  font-size: 30px !important;
  top: -2px !important;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details .fingerprint-device-view.fa-desktop {
  font-size: 14px;
  top: 2px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid {
  margin-top: 5px;
  padding-right: 0px;
  padding-left: 0px;
  display: inline-block;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid .description {
  font-size: 12px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid .value {
  font-size: 14px;
  color: #000000d6;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid .page-view {
  float: right;
  margin-right: 20px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid .page-view .fa-eye {
  font-size: 20px;
  padding: 3px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid .page-view.show-events + .page-events {
  display: block;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events {
  width: 267%;
  max-height: 275px;
  overflow-y: auto;
  display: none;
  z-index: 99999;
  position: absolute;
  right: 0%;
  list-style: none;
  margin: 0;
  margin-top: 8px;
  padding: 15px;
  background: #444;
  color: #fff;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events li {
  border-left: 1px solid #47b2d1;
  position: relative;
  width: 100%;
  display: inline-block;
  padding-left: 15px;
  padding-bottom: 10px;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events li:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: #47b2d1;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events li:last-child {
  border-left: none;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events li .text-adjust {
  width: 70%;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events li .page-list {
  word-break: break-all;
}
.resourceManagement.dashboard-content .known-contacts .single-fingerprint-details .fingerprint-details-grid ul.page-events:after {
  top: -6px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #444;
  border-left: 6px solid transparent;
  position: absolute;
  content: '';
  right: 15px;
}
.resourceManagement.dashboard-content .job-report-configuration .row:nth-child(2) {
  color: #000;
  font-weight: bold;
}
.resourceManagement.dashboard-content .job-report-configuration .row:nth-child(even) {
  background-color: #efeff0;
}
.resourceManagement.dashboard-content .job-report-configuration .overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.resourceManagement.dashboard-content .job-report-configuration .tooltip .tooltip-inner {
  max-width: 350px;
  width: 350px;
}
.resourceManagement .main-title {
  word-wrap: break-word;
  width: 90%;
}
.resourceManagement .mainTitle,
.resourceManagement .title-dropdown {
  display: inline;
}
.resourceManagement .preview-title {
  /*position: absolute;
        top: 19px;*/
}
.resourceManagement .arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #25bf89;
  display: inline-block;
}
.resourceManagement .arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #ff3b31;
  display: inline-block;
}
.artcile-component .categories {
  font-size: 12px;
  color: #2f76bb;
}
.artcile-component.ssb-blog-edit-writing-mode .ssb-sidebar {
  width: 100%;
}
.artcile-component .campaign-header .normal-font.demo-view {
  display: block !important;
}
.artcile-component .left-filter .input-icon-right i.fa {
  pointer-events: none;
}
.text-caps {
  text-transform: uppercase;
  color: #C6D9EC;
}
.text-caps.vendor {
  color: #ffffff;
}
.upper-case {
  text-transform: uppercase;
}
.content-resource-modal {
  top: 47%;
  transform: translateY(-50%) !important;
}
dashboard-content-preview-component .template-preview-container {
  background: url(~../img/loading.gif) center center no-repeat;
  height: calc(100vh - 150px);
}
dashboard-content-preview-component .template-preview-container #frame_template_loader {
  border: 0;
  width: 99%;
  height: 100%;
}
dashboard-content-preview-component .template-preview-container.no-bg-image {
  background: none!important;
}
dashboard-content-preview-component .breadcrumb-title {
  text-transform: uppercase;
  color: #4a4a4a;
}
dashboard-content-preview-component .dashboard-content-preview .socialElement {
  left: -50px;
  right: 0;
  top: 65px;
}
dashboard-content-preview-component .dashboard-content-preview .socialElement::after {
  left: 12%;
  right: 0;
  border-bottom-color: #8e8e93;
}
@media (max-width: 767px) {
  dashboard-content-preview-component .dashboard-content-preview .socialElement::after {
    left: 4%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  dashboard-content-preview-component .dashboard-content-preview .socialElement::after {
    left: 14%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  dashboard-content-preview-component .dashboard-content-preview .socialElement {
    width: 400px;
  }
}
@media (max-width: 767px) and (min-width: 376px) {
  dashboard-content-preview-component .dashboard-content-preview .socialElement {
    width: 360px;
    left: 0;
  }
}
@media (max-width: 375px) {
  dashboard-content-preview-component .dashboard-content-preview .socialElement {
    width: 330px;
    left: 0;
  }
}
dashboard-content-preview-component .dashboard-content-preview .socialElement .url-wraper {
  width: 100%;
}
dashboard-content-preview-component .dashboard-content-preview p.customFonts {
  word-break: break-all;
}
dashboard-content-detail-component .date-range {
  margin-right: -15px;
}
dashboard-content-detail-component .list-description .action-btn {
  background-color: #fff;
}
dashboard-content-detail-component .list-description .action-btn:hover {
  background-color: #fff;
}
dashboard-content-detail-component .btn-group .btn-info-8,
dashboard-content-account-detail-component .btn-group .btn-info-8,
dashboard-content-detail-component .btn-group .btn-info-9,
dashboard-content-account-detail-component .btn-group .btn-info-9 {
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 3px;
  padding-right: 3px;
}
dashboard-content-detail-component .btn-group .btn-info-8.active,
dashboard-content-account-detail-component .btn-group .btn-info-8.active,
dashboard-content-detail-component .btn-group .btn-info-9.active,
dashboard-content-account-detail-component .btn-group .btn-info-9.active {
  background-color: transparent !important;
  color: #4a4a4a;
}
dashboard-content-detail-component .btn-group .btn-info-8 .fa-icon,
dashboard-content-account-detail-component .btn-group .btn-info-8 .fa-icon,
dashboard-content-detail-component .btn-group .btn-info-9 .fa-icon,
dashboard-content-account-detail-component .btn-group .btn-info-9 .fa-icon {
  font-size: 24px;
}
dashboard-content-detail-component .resourceManagement table.grid,
dashboard-content-account-detail-component .resourceManagement table.grid {
  background: #f7f7f8 !important;
}
dashboard-content-detail-component .resourceManagement table.grid tbody,
dashboard-content-account-detail-component .resourceManagement table.grid tbody {
  padding: 0;
}
dashboard-content-detail-component .resourceManagement table.grid tbody tr,
dashboard-content-account-detail-component .resourceManagement table.grid tbody tr {
  width: 32%!important;
  margin: 0 10px 20px 10px;
}
dashboard-content-detail-component .resourceManagement table.grid tbody tr:nth-child(3n),
dashboard-content-account-detail-component .resourceManagement table.grid tbody tr:nth-child(3n) {
  margin-right: 0;
}
dashboard-content-detail-component .resourceManagement table.grid tbody tr:nth-child(3n+1),
dashboard-content-account-detail-component .resourceManagement table.grid tbody tr:nth-child(3n+1) {
  margin-left: 0;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail {
  text-align: center;
  padding: 0;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .img-box,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .img-box {
  width: inherit;
  height: 200px;
  overflow: hidden;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .img-box img,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .img-box img {
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  right: 0;
  top: 0;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .ingram-blank-article-default-bg,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .ingram-blank-article-default-bg {
  background-color: gray;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail:hover .div-overlay,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail:hover .div-overlay {
  display: block;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-overlay,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-overlay {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 92%;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 92%;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-overlay span,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-overlay span {
  margin-top: 110px;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail:hover .div-resource-overlay,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail:hover .div-resource-overlay {
  display: block;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-resource-overlay,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-resource-overlay {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-resource-overlay span,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .div-resource-overlay span {
  margin-top: 110px;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .resource-list-footer .text-adjust-col,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .resource-list-footer .text-adjust-col {
  background-color: #3076bb;
  overflow: unset;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .resource-list-footer .text-adjust-col.file-details span.file-detail-content,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .resource-list-footer .text-adjust-col.file-details span.file-detail-content {
  display: block;
  color: #fff;
  font-size: 14px;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .resource-list-footer .text-adjust-col.file-details span.file-detail-content.file-name,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .thumbnail .resource-list-footer .text-adjust-col.file-details span.file-detail-content.file-name {
  font-size: 16px;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:hover .list-view-data,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:hover .list-view-data {
  background-color: #e7e7e9;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:hover .list-view-data .list,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:hover .list-view-data .list {
  border-left: 4px solid #32cd32;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .text-adjust-col,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .text-adjust-col {
  background-color: unset;
  color: #5b5b60;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .fa-circle,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .fa-circle {
  width: 15%;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .list,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .list {
  border-left: 4px solid transparent;
  padding: 16px;
  display: flex;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .list .text-adjust,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .list .text-adjust {
  padding-top: 5px;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .list .text-adjust-inner-content,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view .list-view-data .list .text-adjust-inner-content {
  word-break: break-all;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:nth-child(even) .list-view-data .list,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:nth-child(even) .list-view-data .list {
  background-color: #e7e7e9;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:nth-child(odd) .list-view-data .list,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item.list-view:nth-child(odd) .list-view-data .list {
  background-color: #ffffff;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .status-icon,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .status-icon {
  position: absolute;
  z-index: 1;
  top: 15px;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .status-icon.left,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .status-icon.left {
  left: 23px;
}
dashboard-content-detail-component .resourceManagement .list-group .item.grid-group-item .status-icon.with-border.right,
dashboard-content-account-detail-component .resourceManagement .list-group .item.grid-group-item .status-icon.with-border.right {
  right: 23px;
}
dashboard-content-detail-component .resourceManagement .stats-container,
dashboard-content-account-detail-component .resourceManagement .stats-container {
  padding: 0;
  margin-left: 15px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  dashboard-content-detail-component .resourceManagement .stats-container,
  dashboard-content-account-detail-component .resourceManagement .stats-container {
    margin-left: 0px;
    margin-right: 0px;
  }
}
dashboard-content-detail-component .countries-map h4.panel-title,
dashboard-content-account-detail-component .countries-map h4.panel-title {
  color: #333333;
  font-size: 18px;
}
@media (max-width: 767px) {
  dashboard-content-detail-component .large-view,
  dashboard-content-account-detail-component .large-view {
    display: none;
  }
}
@media all and (min-width: 767px) {
  dashboard-content-detail-component .small-view,
  dashboard-content-account-detail-component .small-view {
    display: none;
  }
}
dashboard-content-management-component .grp-icon,
dashboard-content-component .grp-icon {
  padding: 10px;
  padding-top: 5px;
  width: 50px;
  text-align: center;
  float: left;
}
dashboard-content-management-component .grp-icon .article,
dashboard-content-component .grp-icon .article {
  color: #A1A3A6;
}
dashboard-content-management-component .grp-icon .light-gray,
dashboard-content-component .grp-icon .light-gray {
  color: #DBDBDB;
}
dashboard-content-management-component .grp-icon .share,
dashboard-content-component .grp-icon .share {
  color: #A1A3A6;
}
dashboard-content-management-component .clip-check.check-primary input[type=checkbox]:checked + label:before,
dashboard-content-component .clip-check.check-primary input[type=checkbox]:checked + label:before {
  border-color: #9b9b9b !important;
}
dashboard-content-management-component ul.message-container-top,
dashboard-content-component ul.message-container-top {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
dashboard-content-management-component ul.message-container-top .pagination-icons,
dashboard-content-component ul.message-container-top .pagination-icons {
  float: right;
  cursor: pointer;
  color: #ffffff;
  margin: 10px;
}
dashboard-content-management-component ul.message-container-top .broad-message,
dashboard-content-component ul.message-container-top .broad-message {
  background-color: #4a4a4a;
  color: #ffffff;
  padding: 10px;
}
dashboard-content-management-component ul.message-container-top .broad-message .message-size,
dashboard-content-component ul.message-container-top .broad-message .message-size {
  font-size: 45px;
  color: #25bf89;
  width: 50px;
  float: left;
  text-align: center;
  padding-top: 3px;
}
dashboard-content-management-component ul.message-container-top .broad-message .message-body,
dashboard-content-component ul.message-container-top .broad-message .message-body {
  display: inline-block;
  margin-left: 5px;
  width: 90%;
}
dashboard-content-management-component ul.message-container-top .broad-message .message-body .message-title,
dashboard-content-component ul.message-container-top .broad-message .message-body .message-title {
  text-transform: uppercase;
  font-size: 24px;
}
dashboard-content-management-component .indi-select i.fa,
dashboard-content-component .indi-select i.fa {
  right: 0;
  top: 0;
  line-height: 47px !important;
}
dashboard-content-management-component .resourceDetailSocial .socialElement,
dashboard-content-component .resourceDetailSocial .socialElement {
  top: unset;
  right: unset;
}
dashboard-content-management-component .resourceDetailSocial .socialElement::after,
dashboard-content-component .resourceDetailSocial .socialElement::after {
  top: -11px;
  right: unset;
}
dashboard-content-management-component .resourceDetailSocial .socialElement .form-control,
dashboard-content-component .resourceDetailSocial .socialElement .form-control {
  height: 34px !important;
}
.sonicwall-9 .resourceManagement.dashboard-content .list .input-icon-right i {
  background-color: #06293d;
  color: #fff;
}
.brandforce-10 .resourceManagement.dashboard-content .list .input-icon-right i {
  background-color: #06293d;
  color: #fff;
}
.blueequinox-11 .resourceManagement.dashboard-content .list .input-icon-right i {
  background-color: #2A3D46;
  color: #fff;
}
.campaign-report .resourceManagement.dashboard-content #campaign-report .text-uppercase {
  color: #5b5b60;
  font-weight: 300;
}
.campaign-report .resourceManagement.dashboard-content .data-analytics table.tbl-campaign-analytics tr th {
  background: #49494C;
  color: white;
  font-size: 12px;
}
.campaign-report .resourceManagement.dashboard-content .data-analytics table.tbl-campaign-analytics tr td {
  font-size: 10px;
  word-break: break-all;
}
